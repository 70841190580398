// @ts-strict-ignore
import { ScribeTypes } from '@dialogue/services'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'

import type { ReduxState } from 'app/redux'

import type { MemberChargesState } from './types'

export const INITIAL_STATE: MemberChargesState = {
  charges: {},
}

export const { reducer, actions: memberChargesActions } = createSlice({
  name: '@@memberCharges',
  initialState: INITIAL_STATE,
  reducers: {
    get: (state, action: PayloadAction<number | string>) => {
      if (!state.charges[action.payload]) {
        state.charges[action.payload] = {}
      }
      state.charges[action.payload].fetching = true
      state.charges[action.payload].error = null
    },
    getSuccess: (
      state,
      action: PayloadAction<{
        patientId: number | string
        charges: ScribeTypes.UserCharge[]
      }>,
    ) => {
      const { patientId, charges } = action.payload
      if (!state.charges[patientId]) {
        state.charges[patientId] = {}
      }
      state.charges[patientId].charges = charges
      state.charges[patientId].fetching = false
      state.charges[patientId].error = null
    },
    getFailure: (
      state,
      action: PayloadAction<{
        patientId: string | number
        error: Error
      }>,
    ) => {
      const { error, patientId } = action.payload

      if (!state.charges[patientId]) {
        state.charges[patientId] = {}
      }
      state.charges[patientId].fetching = false
      state.charges[patientId].error = error
    },
    cancel: (
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        chargeId: string
        reason: string
      }>,
    ) => {
      if (!state.charges[payload.patientId]) {
        state.charges[payload.patientId] = {}
      }

      state.charges[payload.patientId].cancelling = true
      state.charges[payload.patientId].cancelError = null
    },
    cancelSuccess: (
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        charge: ScribeTypes.UserCharge
      }>,
    ) => {
      if (!state.charges[payload.patientId]) {
        state.charges[payload.patientId] = {}
      }
      state.charges[payload.patientId].cancelling = false
      state.charges[payload.patientId].cancelError = null

      const index = findIndex(state.charges[payload.patientId].charges, {
        id: payload.charge.id,
      })

      state.charges[payload.patientId].charges[index] = payload.charge
    },
    cancelFailure: (
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        error: Error
      }>,
    ) => {
      if (!state.charges[payload.patientId]) {
        state.charges[payload.patientId] = {}
      }
      state.charges[payload.patientId].cancelling = false
      state.charges[payload.patientId].cancelError = payload.error
    },
    cancelClear: (
      state,
      { payload: patientId }: PayloadAction<string | number>,
    ) => {
      if (!state.charges[patientId]) {
        state.charges[patientId] = {}
      }

      state.charges[patientId].cancelling = false
      state.charges[patientId].cancelError = null
    },
  },
})

export default reducer

export const selectPatientCharges = (
  state: ReduxState,
  patientId: number | string,
) => state.memberCharges.charges[patientId]?.charges

export const selectPatientPendingChargesCount = createSelector(
  selectPatientCharges,
  (charges) => {
    if (!charges) {
      return null
    }

    return charges.filter(
      (charge) =>
        charge?.attributes?.status === ScribeTypes.ChargeStatus.PENDING,
    ).length
  },
)

export const selectPatientChargeCancelled = (
  state: ReduxState,
  patientId: number | string,
  chargeId: string,
) =>
  find(state.memberCharges.charges[patientId]?.charges, { id: chargeId })
    ?.attributes.status === ScribeTypes.ChargeStatus.CANCELLED

export const selectPatientChargesCancelling = (
  state: ReduxState,
  patientId: number | string,
) => state.memberCharges.charges[patientId]?.cancelling

export const selectPatientChargesCancelError = (
  state: ReduxState,
  patientId: number | string,
) => state.memberCharges.charges[patientId]?.cancelError

export const selectPatientChargesFetching = (
  state: ReduxState,
  patientId: number | string,
) => state.memberCharges.charges[patientId]?.fetching
