// @ts-strict-ignore
import { WebAuth } from 'auth0-js'

import config from 'app/config'

const DEFAULT_AUTH_OPTIONS = {
  clientID: config.AUTH0_CLIENT_ID,
  domain: config.AUTH0_ENDPOINT,
  scope: 'openid profile email offline_access',
  // responseType: 'token id_token',
  audience: config.AUTH0_AUDIENCE,
  // redirectUri: POPUP_AUTH ? undefined : endpoints.login_callback
}

interface AuthResponse {
  accessToken: string
  expiresIn: number
}

interface AuthClient {
  authorize: () => Promise<AuthResponse>
  renewAuth: () => Promise<AuthResponse>
  logout: () => void
}

export class WebAuthClient implements AuthClient {
  private auth0 = new WebAuth({
    ...DEFAULT_AUTH_OPTIONS,
    responseType: 'token',
    redirectUri: window.location.origin,
  })

  authorize = () =>
    new Promise<AuthResponse>((resolve, reject) => {
      this.auth0.popup.authorize(
        { owp: true, prompt: 'login' } as any,
        (err, res: any) => {
          if (err) {
            console.warn('Authentication Error', err)
            const errorMsg = err.errorDescription || err.original
            reject(errorMsg)
          } else {
            resolve(res)
          }
        },
      )
    })

  renewAuth = () =>
    new Promise<AuthResponse>((resolve, reject) => {
      this.auth0.checkSession({}, (err, res) => {
        // Package does not return statusCode, so recreate statusCode from app/auth/service.js
        if (err && err.code === 'login_required') {
          console.warn('Could not renew session', err)
          reject({ error: err.error, statusCode: 403 })
        } else if (err) {
          console.warn('Could not renew session due to non-client issue', err)
          reject({ error: err.error })
        } else {
          resolve(res)
        }
      })
    })

  logout = () => {
    const navbackQs = `${encodeURIComponent(window.location.href)}`
    const returnTo = `${config.LOGOUT_CALLBACK_URL}?navback=${navbackQs}`

    this.auth0.logout({ returnTo })
  }
}

export class ElectronAuthClient implements AuthClient {
  private authOpts = { ...DEFAULT_AUTH_OPTIONS }
  private get electronAuth() {
    return window.electronApi.auth
  }

  authorize = async () => {
    const { access_token, expires_in } =
      await this.electronAuth.createAuthWindow(this.authOpts)
    return { accessToken: access_token, expiresIn: expires_in }
  }

  renewAuth = async () => {
    const result = await this.electronAuth.refreshTokens(this.authOpts)
    if (!result) {
      throw new Error('No token to refresh.')
    }

    const { access_token, expires_in } = result
    return { accessToken: access_token, expiresIn: expires_in }
  }

  logout = () => {
    this.electronAuth.logout(this.authOpts)
  }
}

window.electronAuth = new ElectronAuthClient()
