import type {
  EnrichedEpisode,
  ItemEnrichedEpisode,
  PageEnrichedEpisode,
  ResponseGetEpisodes,
} from '@dialogue/coredata'
import { EmergencyRoomTypes } from '@dialogue/services'
import { notification } from 'antd'
import {
  all,
  call,
  put,
  select,
  spawn,
  takeEvery,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import Config from 'app/config'
import i18n from 'app/i18n'
import type { ReduxState } from 'app/redux'
import { Tags } from 'app/redux/api/emergency-room/api'
import { assignedEpisodesApi } from 'app/redux/api/emergency-room/assigned-episodes'
import { episodesApi } from 'app/redux/api/emergency-room/episodes'
import { selectUserId } from 'app/redux/authentification/selectors'
import { UserPostType } from 'app/redux/chat/types'
import { episodesActions, EpisodesTypes } from 'app/redux/episodes'
import { selectMyAssignedEpisodesFromCache } from 'app/redux/episodes/selectors'
import {
  navigationSidebarActions,
  NavigationSidebarTypes,
} from 'app/redux/navigation-sidebar'
import { selectMyAssignedEpisodesCount } from 'app/redux/navigation-sidebar/selectors'
import { patientsActions } from 'app/redux/patients'
import Routes from 'app/services/routes'

import { initEmergencyRoomClient, poll, takeKeyedLatest } from './utils'

export const practitionersState = (state: ReduxState) => state.practitioners

interface PostProps {
  sender?: string
  update_channel_state?: boolean
  dialogue_type?: string
  answer?: string
}

interface Post {
  message: string
  user_id: string
  props: PostProps
  type: string
}

export const POLLING_INTERVAL_MS = 2000

export function* notifyForPost(
  episodeId: string,
  post: Post,
  episode: EnrichedEpisode,
) {
  if (Notification.permission === 'default') {
    // need permissions, web-only
    yield* call(Notification.requestPermission)
  }
  if (Notification.permission === 'denied') {
    console.warn('skipping new message notification, permission denied')
    return
  }

  const navBack = Routes.channel(episode.patient_id.toString(), episodeId)

  if (window.location.hash.indexOf(navBack) === 0) {
    console.debug('Not notifying as already on episode page')
    return
  }

  let title = 'Untitled Episode'

  if (episode.title) {
    title = episode.title
  }

  const practitioners = yield* select(practitionersState)
  const practitioner =
    practitioners.mmIdMap[post.user_id] &&
    practitioners.profiles[practitioners.mmIdMap[post.user_id]]
  const practitionerName =
    practitioner &&
    [practitioner.first_name, practitioner.last_name].filter(Boolean).join(' ')

  const userName =
    practitionerName ||
    (post.user_id === Config.COREDATA_MM_UID
      ? i18n.t('glossary:clinic')
      : [episode.patient_first_name, episode.patient_last_name].join(' '))

  const notification = new Notification(title, {
    body: `${userName}: ${post.message}`,
    tag: episodeId, // allows grouping of multiple notifs from same ep
    renotify: true,
  })

  notification.onclick = () => {
    window.location.hash = '#' + navBack
  }
}

export function* getEpisodesFromState({
  payload: { episodesState, limit, offset, resolvedByMe },
}: ActionType<typeof episodesActions.requestEpisodesFromState>) {
  const emergencyRoom = yield* call(initEmergencyRoomClient)

  try {
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const { data, meta }: ResponseGetEpisodes = yield* call(
      emergencyRoom.getEpisodes,
      episodesState,
      offset,
      limit,
      resolvedByMe,
    )

    yield* put(
      episodesActions.requestEpisodesFromStateSuccess(
        episodesState,
        data,
        meta.total_items,
      ),
    )
  } catch (e) {
    yield* put(
      episodesActions.requestEpisodesFromStateFailure(episodesState, e),
    )
    console.error('Failed to load episodes', e)
  }
}

export function* handleNewPostForEpisode(post: Post & Record<string, any>) {
  const userId = yield* select(selectUserId)

  // ensure assigned episodes data is available in the store
  const assignedEpisodesPromise = yield* put(
    // @ts-ignore ThunkActions are valid Actions
    yield* call(
      assignedEpisodesApi.endpoints.getMyAssignedEpisodes.initiate,
      undefined,
      { forceRefetch: false },
    ),
  )

  // wait until the data is fetched
  yield assignedEpisodesPromise

  // get the data from cache
  const { data: assignedEpisodes = [] } = yield* select(
    selectMyAssignedEpisodesFromCache,
  )

  const assignedEpisode = assignedEpisodes.find(
    (ep) => ep.id === post.channel_id,
  )

  const practitioners = yield* select(practitionersState)
  const mmId = (userId && practitioners.profiles?.[userId]?.mm_id) || null

  const isSystem =
    post.props &&
    post.props.dialogue_type &&
    post.props.dialogue_type.indexOf('episode_') === 0

  const isRasaQuestion =
    post.props.sender === 'rasa' &&
    post.type !== UserPostType.INTERNAL &&
    post.type !== UserPostType.LEGACY_INTERNAL //TODO: Remove UserPostType.LEGACY_INTERNAL once it's completely deprecated

  const isRasaAnswer =
    post.props.answer && post.props.update_channel_state === false

  const shouldNotify =
    !isSystem &&
    assignedEpisode &&
    post.user_id !== mmId &&
    !isRasaQuestion &&
    !isRasaAnswer

  if (shouldNotify) {
    yield* spawn(notifyForPost, post.channel_id, post, assignedEpisode)
  }
}

export function* getMyAssignedEpisodesCount() {
  const emergencyRoom = yield* call(initEmergencyRoomClient)
  const previousCount = yield* select(selectMyAssignedEpisodesCount)

  try {
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const {
      meta: { total_items },
    }: PageEnrichedEpisode = yield* call(
      emergencyRoom.getMyAssignedEpisodes,
      EmergencyRoomTypes.EpisodeState.ACTIVE,
      0,
      1, // we don't care about the episodes data, we only want the total count
    )

    yield* put(
      navigationSidebarActions.requestMyAssignedEpisodesCountSuccess(
        total_items,
      ),
    )

    // if the count has changed, invalidate the cache of assigned episodes
    // TODO: do this in RTK once we fetch the count from there
    if (total_items !== previousCount) {
      yield* put(
        assignedEpisodesApi.util.invalidateTags([
          { type: Tags.AssignedEpisode, id: 'LIST' },
        ]),
      )
    }
  } catch (error) {
    yield* put(
      navigationSidebarActions.requestMyAssignedEpisodesCountError(error),
    )
  }
}

export function* initPollingEpisode({
  payload,
}: ActionType<(typeof episodesActions)['initPollingEpisode']>) {
  // @ts-ignore [er-types] ER typings are what reflects the actual data received
  const episode: EnrichedEpisode = yield* call(
    getEpisode,
    episodesActions.getEpisode(payload.episodeId),
  )
  if (episode) {
    // set active family member to match episode subject, for patient-profile card
    const episodeSubject = episode.subject_id
    yield* put(
      patientsActions.setActiveFamilyMember(episode.patient_id, episodeSubject),
    )

    yield* put(episodesActions.startPollingEpisode(payload.episodeId))
  }
}

export function* createEpisode({
  payload: { patientId, channel },
}: ActionType<(typeof episodesActions)['createEpisode']>) {
  try {
    const er = yield* call(initEmergencyRoomClient)
    const communicationMethods =
      channel === 'ccq'
        ? [EmergencyRoomTypes.CommunicationMethods.ccq]
        : [EmergencyRoomTypes.CommunicationMethods.audio]
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const episode: EnrichedEpisode = yield* call(
      er.createEpisode,
      patientId,
      communicationMethods,
    )
    yield* put(
      episodesActions.createEpisodeSuccess({ episodeId: episode.id, episode }),
    )
    window.location.hash = '#' + Routes.channel(patientId, episode.id)
    return episode
  } catch (error) {
    yield* put(episodesActions.createEpisodeError({ patientId, error }))
  }
}

export function* getEpisode({
  payload,
}: ActionType<
  | (typeof episodesActions)['startPollingEpisode']
  | (typeof episodesActions)['getEpisode']
>) {
  const er = yield* call(initEmergencyRoomClient)

  try {
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const { data: episode }: ItemEnrichedEpisode = yield* call(
      er.getEpisode,
      payload.episodeId,
    )
    yield* put(episodesActions.getEpisodeSuccess(episode.id, episode))

    // Ensure we update the RTKq cache also
    yield* put<ReturnType<typeof episodesApi.util.upsertQueryData>>(
      episodesApi.util.upsertQueryData(
        'getEpisode',
        { episodeId: episode.id },
        episode,
      ),
    )

    return episode
  } catch (e) {
    yield* put(episodesActions.getEpisodeError(payload.episodeId, e))
    return null
  }
}

export function* resolveAllEpisodes() {
  const er = yield* call(initEmergencyRoomClient)

  try {
    yield* call(er.markAllEpisodesAsResolved)
  } catch (e) {
    notification.error({
      message: i18n.t('errorMessages.resolveAllEpisodesError'),
      description: String(e),
    })
  }
}

export default function* episodeSagas() {
  yield* all([
    takeKeyedLatest(
      EpisodesTypes.REQUEST_EPISODES_FROM_STATE,
      (action) => action.payload.episodesState,
      getEpisodesFromState,
    ),
    poll(
      NavigationSidebarTypes.START_POLL_MY_ASSIGNED_EPISODES,
      NavigationSidebarTypes.STOP_POLL_MY_ASSIGNED_EPISODES,
      POLLING_INTERVAL_MS,
      getMyAssignedEpisodesCount,
    ),
    takeEvery(
      NavigationSidebarTypes.REQUEST_MY_ASSIGNED_EPISODES_COUNT,
      getMyAssignedEpisodesCount,
    ),
    takeEvery(EpisodesTypes.INIT_POLLING_EPISODE, initPollingEpisode),
    poll(
      EpisodesTypes.START_POLLING_EPISODE,
      EpisodesTypes.STOP_POLLING_EPISODE,
      POLLING_INTERVAL_MS,
      getEpisode,
    ),
    takeLeading(EpisodesTypes.CREATE_EPISODE, createEpisode),
    takeLatest(EpisodesTypes.GET_EPISODE, getEpisode),
    takeEvery(EpisodesTypes.RESOLVE_ALL_ACTIVE_EPISODES, resolveAllEpisodes),
  ])
}
