import { selectUserProfile } from 'app/redux/authentification/selectors'
import type { EnrichedPractitionerProfile } from 'app/redux/authentification/types'
import { selectDialogueLocation } from 'app/redux/locations'
import { selectPatientProfile } from 'app/redux/patients/selectors'
import type { PatientProfile } from 'app/redux/patients/types'
import { selectOnePractitionerLicense } from 'app/redux/user-management/reducer'

import {
  extractPhoneNumberAreaCode,
  extractPhoneNumberMinusAreaCode,
  safeJoin,
} from './helpers'

export type Field = {
  selector: Function
  arguments?: string[] // patientId, etc
  formatter?: string | Function
}

export type FieldsType = Record<string, Field>

// The AUTOFILLING_FIELDS object is a collection of fields used for autofilling data
// in a pdf document. These fields are associated with specific selectors and formatter
// functions, allowing for easy retrieval and display of relevant information within
// the application.

// Overview
// Field Structure
// Each field in the AUTOFILLING_FIELDS object is defined by a set of properties:

// selector: A function that retrieves the relevant data for the field. Should be
// a selector from the application's Redux store.

// arguments (optional): An array of strings representing the arguments to be passed
// to the selector function. These arguments help identify the specific data needed
// for autofilling.

// formatter: A string or function that processes the data retrieved by the selector,
// providing the actual value to be autofilled. If no formatter is provided, the data
// retrieved by the selector will be used as the autofill value.

export const AUTOFILLING_FIELDS: FieldsType = {
  patient_dia_id: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'id',
  },
  patient_first_name: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'first_name',
  },
  patient_family_name: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'last_name',
  },
  patient_full_name: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string =>
      safeJoin([profile.first_name, profile.last_name], ' '),
  },
  patient_initials: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string =>
      [profile.first_name, profile.last_name]
        .filter(Boolean)
        .map((name) => name.charAt(0).toUpperCase())
        .join(''),
  },
  patient_phone_number_full: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'phone_number',
  },
  patient_phone_number_area_code: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string | null =>
      extractPhoneNumberAreaCode(profile.phone_number || ''),
  },
  patient_phone_number_seven_digit: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string | null =>
      extractPhoneNumberMinusAreaCode(profile.phone_number || ''),
  },
  patient_postal_code: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'postal_code',
  },
  patient_city: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'locality',
  },
  patient_street_address: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string =>
      safeJoin([profile.street_number2, profile.street_number], '-'),
  },
  patient_province: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string | undefined => {
      return profile.admin_area_iso_code?.split('-')?.[1]
    },
  },
  patient_local_address: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string =>
      safeJoin(
        [
          safeJoin([profile.street_number2, profile.street_number], '-'),
          profile.locality,
        ],
        ', ',
      ),
  },
  patient_full_address: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile) =>
      safeJoin(
        [
          safeJoin([profile.street_number2, profile.street_number], '-'),
          profile.locality,
          safeJoin(
            [profile.admin_area_iso_code?.split('-')?.[1], profile.postal_code],
            ' ',
          ),
          profile.country_iso_code,
        ],
        ', ',
      ),
  },
  patient_healthcard: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'healthcard_number',
  },
  patient_dob_full: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'birthdate',
  },
  patient_dob_year: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string => {
      return profile.birthdate.split('-')[0]
    },
  },
  patient_dob_month: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string => {
      return profile.birthdate.split('-')?.[1]
    },
  },
  patient_dob_day: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile): string => {
      return profile.birthdate.split('-')?.[2]
    },
  },
  patient_sex_checkbox_f: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile) =>
      profile.sex === 'F' ? 'Yes' : 'Off',
  },
  patient_sex_checkbox_m: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: (profile: PatientProfile) =>
      profile.sex === 'M' ? 'Yes' : 'Off',
  },
  patient_sex_text: {
    selector: selectPatientProfile,
    arguments: ['patientId'],
    formatter: 'sex',
  },
  provider_full_name: {
    selector: selectUserProfile,
    formatter: (provider: EnrichedPractitionerProfile) =>
      safeJoin([provider.givenName, provider.familyName], ' '),
  },
  provider_license_number: {
    selector: selectOnePractitionerLicense,
    arguments: ['licenseId'],
    formatter: 'license_number',
  },
  provider_billing_number: {
    selector: selectOnePractitionerLicense,
    arguments: ['licenseId'],
    formatter: 'billing_number',
  },
  todays_date: {
    selector: () => new Date(),
  },
  // mapped separately, but the autofill logic is using the date picker to set
  // date and leaving it up to the PDF form settings to decide on the format
  todays_date_year: {
    selector: () => new Date(),
  },
  todays_date_month: {
    selector: () => new Date(),
  },
  todays_date_day: {
    selector: () => new Date(),
  },
  dialogue_fax: {
    selector: selectDialogueLocation,
    arguments: ['provinceCode'],
    formatter: 'fax_number',
  },
  dialogue_fax_area_code: {
    selector: selectDialogueLocation,
    arguments: ['patientId'],
    formatter: (location: Record<string, any> | null): string | null =>
      extractPhoneNumberAreaCode(location?.fax_number || ''),
  },
  dialogue_fax_seven_digit: {
    selector: selectDialogueLocation,
    arguments: ['patientId'],
    formatter: (location: Record<string, any> | null): string | null =>
      extractPhoneNumberMinusAreaCode(location?.fax_number || ''),
  },
  dialogue_phone_number_full: {
    selector: selectDialogueLocation,
    arguments: ['provinceCode'],
    formatter: 'phone_number',
  },
  dialogue_phone_number_area_code: {
    selector: selectDialogueLocation,
    arguments: ['patientId'],
    formatter: (location: Record<string, any> | null): string | null =>
      extractPhoneNumberAreaCode(location?.phone_number || ''),
  },
  dialogue_phone_number_seven_digit: {
    selector: selectDialogueLocation,
    arguments: ['patientId'],
    formatter: (location: Record<string, any> | null): string | null =>
      extractPhoneNumberMinusAreaCode(location?.phone_number || ''),
  },
  dialogue_province: {
    selector: selectDialogueLocation,
    arguments: ['provinceCode'],
    formatter: 'province_code',
  },
  dialogue_postal_code: {
    selector: selectDialogueLocation,
    arguments: ['provinceCode'],
    formatter: 'zip_code',
  },
  dialogue_city: {
    selector: selectDialogueLocation,
    arguments: ['provinceCode'],
    formatter: 'city',
  },
  dialogue_street_address: {
    selector: selectDialogueLocation,
    arguments: ['provinceCode'],
    formatter: 'street_address',
  },
  dialogue_full_address: {
    selector: selectDialogueLocation,
    arguments: ['provinceCode'],
    formatter: (location: Record<string, any> | null): string =>
      safeJoin(
        [
          location?.street_address,
          location?.city,
          location?.province_code,
          location?.zip_code,
        ],
        ', ',
      ),
  },
}
