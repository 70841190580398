import type { PanelTab } from 'app/containers/episode-member-panel/types'
import type { ProfileTab } from 'app/containers/member-profile-page/types'

const routes = {
  channelPost: (
    patientId: string | number,
    episodeId: string,
    postId: string,
  ) => `/chat/${patientId}/${episodeId}?postId=${postId}`,
  channel: (patientId: number | string, episodeId = '', tab?: PanelTab) =>
    `/chat/${patientId}/${episodeId}${tab ? `?episodeTab=${tab}` : ''}`,
  patientNote: (
    patientId: number | string,
    episodeId: string,
    noteId: number,
  ) =>
    `${routes.channel(patientId, episodeId)}?noteId=${noteId}&episodeTab=notes`,
  userProfile: (userId: string) => `/users/${userId}`,
  consult: (patientId: string | number, episodeId: string) =>
    `/consult/${patientId}/${episodeId}`,
  mentions: () => '/mentions',
  dashboard: () => '/dashboard',
  schedule: () => '/schedule',
  practitionerSchedule: (practitionerId: string | number) =>
    `/schedule?tab=single-view&practitioner-id=${practitionerId}`,
  pending: () => '/pending',
  resolved: () => '/resolved',
  followups: () => '/reminders',
  tasks: () => '/tasks',
  faxesInbox: () => '/faxes-inbox',
  faxesOutbox: () => '/faxes-outbox',
  assignedToMe: () => '/assigned-to-me',
  ownDraftNotes: () => '/own-draft-notes',
  login: () => '/login',
  memberProfile: (patientId: number | string, tab?: ProfileTab) =>
    `/member/${patientId}${tab ? `?tab=${tab}` : ''}`,
}

export default routes
