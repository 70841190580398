import type {
  CollectionIncomingFaxDocument,
  FaxesApiGetFileDownloadUrl1Request,
  FaxesApiGetReceivedFaxesRequest,
  FaxesApiGetReceivedFaxRequest,
  FaxesApiGetSentFaxesRequest,
  FaxesApiLinkReceivedFaxToMemberRequest,
  FaxesApiSplitIncomingFaxDocumentRequest,
  FaxesApiUnlinkReceivedFaxFromAnyMemberRequest,
  FaxesApiUpdateDocumentBytes0Request,
  FaxesApiUpdateReceivedFaxRequest,
  ItemDownloadUrlResponse,
  ItemIncomingFaxDocument,
  OutgoingFaxDocument,
  PageIncomingFaxDocument,
  PageOutgoingFaxDocument,
  SendFaxRequest,
} from '@dialogue/document-center'
import { decamelizeKeys } from 'humps'

import { documentCenterApi, Tags } from './api'

export const faxesApi = documentCenterApi.injectEndpoints({
  endpoints: (builder) => ({
    getIncomingFaxes: builder.query<
      PageIncomingFaxDocument,
      FaxesApiGetReceivedFaxesRequest
    >({
      query: (params) => ({
        url: '/faxes/incoming',
        params,
      }),
      providesTags: (result, _error) =>
        result
          ? [
              { type: Tags.IncomingFaxDocument, id: 'LIST' },
              ...result.data.map(({ id }) => ({
                type: Tags.IncomingFaxDocument,
                id,
              })),
            ]
          : [{ type: Tags.IncomingFaxDocument, id: 'LIST' }],
    }),

    getReceivedFax: builder.query<
      ItemIncomingFaxDocument,
      FaxesApiGetReceivedFaxRequest
    >({
      query: ({ documentId }) => `/faxes/incoming/${documentId}`,
      providesTags: (_result, _error, { documentId }) => [
        { type: Tags.IncomingFaxDocument, id: documentId },
      ],
    }),

    updateIncomingFax: builder.mutation<
      ItemIncomingFaxDocument,
      FaxesApiUpdateReceivedFaxRequest
    >({
      query: ({ documentId, patchIncomingFaxDocRequest }) => ({
        url: `/faxes/incoming/${documentId}`,
        method: 'PATCH',
        body: decamelizeKeys(patchIncomingFaxDocRequest),
      }),
      invalidatesTags: (_result, _error, { documentId }) => [
        { type: Tags.IncomingFaxDocument, id: 'LIST' },
        { type: Tags.IncomingFaxDocument, id: documentId },
      ],
    }),

    updateIncomingFaxBytes: builder.mutation<
      ItemIncomingFaxDocument,
      FaxesApiUpdateDocumentBytes0Request
    >({
      query: ({ documentId, document, reason }) => {
        const body = new FormData()
        body.append('document', document)
        body.append('reason', reason)

        return {
          url: `/faxes/incoming/${documentId}/file`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (_result, _error, { documentId }) => [
        { type: Tags.IncomingFaxDocument, id: 'LIST' },
        { type: Tags.IncomingFaxDocument, id: documentId },
      ],
    }),

    assignMemberToFaxDocument: builder.mutation<
      ItemIncomingFaxDocument,
      FaxesApiLinkReceivedFaxToMemberRequest
    >({
      query: ({ documentId, bodyFaxesLinkReceivedFaxToMember }) => ({
        url: `/faxes/incoming/${documentId}/link`,
        method: 'POST',
        body: bodyFaxesLinkReceivedFaxToMember,
      }),
      invalidatesTags: () => [{ type: Tags.IncomingFaxDocument, id: 'LIST' }],
    }),

    unassignMemberToFaxDocument: builder.mutation<
      ItemIncomingFaxDocument,
      FaxesApiUnlinkReceivedFaxFromAnyMemberRequest
    >({
      query: ({ documentId }) => ({
        url: `/faxes/incoming/${documentId}/link`,
        method: 'DELETE',
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: Tags.IncomingFaxDocument, id: 'LIST' },
              {
                type: Tags.IncomingFaxDocument,
                id: result.data.id,
              },
            ]
          : [{ type: Tags.IncomingFaxDocument, id: 'LIST' }],
    }),

    splitFax: builder.mutation({
      query: ({
        documentId,
        files,
        pageNumbers,
      }: FaxesApiSplitIncomingFaxDocumentRequest) => {
        const body = new FormData()
        files.forEach((file) => body.append('files', file))
        // TODO: Remove when DC API is updated to require page_numbers.
        // @ts-ignore
        body.append('page_numbers', pageNumbers)

        return {
          url: `/faxes/incoming/${documentId}/split`,
          method: 'POST',
          body,
        }
      },
      transformResponse: (response: CollectionIncomingFaxDocument) =>
        response.data,
      // Invalidate the list to remove the split fax and load the newly created sections
      invalidatesTags: () => [{ type: Tags.IncomingFaxDocument, id: 'LIST' }],
    }),

    getIncomingFaxDownloadUrl: builder.query({
      query: ({ documentId }: FaxesApiGetFileDownloadUrl1Request) => ({
        url: `/faxes/incoming/${documentId}/download-url`,
      }),
      transformResponse: (response: ItemDownloadUrlResponse) =>
        response.data.url,
    }),

    sendFax: builder.mutation<OutgoingFaxDocument, SendFaxRequest>({
      query: (body) => ({
        url: '/faxes/outgoing',
        method: 'POST',
        body,
      }),
    }),

    getOutgoingFaxes: builder.query<
      PageOutgoingFaxDocument,
      FaxesApiGetSentFaxesRequest
    >({
      query: (params) => ({
        url: '/faxes/outgoing',
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              {
                type: Tags.OutgoingFaxDocument,
                id: 'LIST',
              },
              ...result.data.map(({ id }) => ({
                type: Tags.OutgoingFaxDocument,
                id,
              })),
            ]
          : [],
    }),

    getOutgoingFaxDownloadUrl: builder.query({
      query: ({ documentId }: FaxesApiGetFileDownloadUrl1Request) => ({
        url: `/faxes/outgoing/${documentId}/download-url`,
      }),
      transformResponse: (response: ItemDownloadUrlResponse) =>
        response.data.url,
    }),
  }),
})

export const {
  useGetIncomingFaxesQuery,
  useLazyGetReceivedFaxQuery,
  useUpdateIncomingFaxMutation,
  useUpdateIncomingFaxBytesMutation,
  useAssignMemberToFaxDocumentMutation,
  useUnassignMemberToFaxDocumentMutation,
  useSplitFaxMutation,
  useGetIncomingFaxDownloadUrlQuery,
  useLazyGetIncomingFaxDownloadUrlQuery,
  useSendFaxMutation,
  useGetOutgoingFaxesQuery,
  useGetOutgoingFaxDownloadUrlQuery,
  useLazyGetOutgoingFaxDownloadUrlQuery,
} = faxesApi
