import React from 'react'

import { Modal, notification } from 'antd'
import * as recaptcha from 'recaptcha-v3'
import { END, eventChannel } from 'redux-saga'
import { all, call, delay, fork, put, race, take } from 'typed-redux-saga/macro'

import config from 'app/config'
import { RequestAccessModal } from 'app/containers/request-access'
import i18n from 'app/i18n'
import {
  requestAccess,
  requestAccessFailure,
  requestAccessSuccess,
} from 'app/redux/rlac'

import { initEmergencyRoomClient, takeKeyedLatest } from '../utils'

import { modalConfig } from './utils'
export function* recaptchaChallenge() {
  const instance = yield* call(recaptcha.load, config.RECAPTCHA_SITE_KEY, {
    autoHideBadge: true,
  })
  return yield* call([instance, instance.execute], 'request_access')
}

export function* checkErPatientAccess(patientId: number | string) {
  const er = yield* call(initEmergencyRoomClient)
  const result = yield* call(er.checkAccessToPatientRecord, patientId)
  return result.data.has_access
}

export function* requestAccessWithCaptcha(patientId: number | string) {
  const recaptchaResponse = yield* call(recaptchaChallenge)

  const er = yield* call(initEmergencyRoomClient)
  const result = yield* call(
    er.requestAccessToPatientRecord,
    patientId,
    recaptchaResponse,
  )
  return result.data.has_access
}

export function* pollForAccess(patientId: number | string) {
  while (!(yield* call(checkErPatientAccess, patientId))) {
    yield* delay(2000)
  }
  return true
}

export enum ModalEvent {
  CLOSED = 'CLOSED',
  REQUEST_ACCESS = 'REQUEST_ACCESS',
}

// FIXME: render the modal in react once we migrate routing
// and dispatch corresponding actions directly
export const createModal = (url: string) =>
  eventChannel<ModalEvent>((emitter) => {
    const modal = Modal.info({
      content: React.createElement(
        RequestAccessModal,
        {
          closeModal: () => {
            emitter(ModalEvent.CLOSED)
            emitter(END)
          },
          url,
          sendRequestAccess: () => {
            emitter(ModalEvent.REQUEST_ACCESS)
          },
        },
        null,
      ),
      ...modalConfig,
    })

    return () => modal.destroy()
  })

export function* sendAccessRequestMessage(path: string) {
  try {
    const cpUrl = `${config.APP_SCHEME}${path}`

    const er = yield* call(initEmergencyRoomClient)
    yield* call(er.requestEpisodeAccess, cpUrl)
  } catch (e) {
    yield* call(notification.error, {
      message: i18n.t('requestAccess.errorMessage'),
    })
  }
}

export function* handleAccessModal(cpPath: string) {
  const modalEvents = yield* call(createModal, cpPath)
  try {
    while (true) {
      const event = yield* take(modalEvents)
      if (event === ModalEvent.REQUEST_ACCESS) {
        yield* fork(sendAccessRequestMessage, cpPath)
      } else if (event === ModalEvent.CLOSED) {
        return false
      }
    }
  } finally {
    modalEvents.close()
  }
}

export function* doRequestAccess({
  payload: { patientId, urlPath },
}: ReturnType<typeof requestAccess>) {
  try {
    let hasAccess = yield* call(checkErPatientAccess, patientId)

    if (!hasAccess) {
      hasAccess = yield* call(requestAccessWithCaptcha, patientId)
    }

    if (!hasAccess) {
      const [gotAccess] = yield* race([
        call(pollForAccess, patientId),
        call(handleAccessModal, urlPath),
      ])

      hasAccess = !!gotAccess
    }

    yield* put(requestAccessSuccess({ patientId, hasAccess }))
  } catch (error) {
    console.error('failed to request access', error)
    yield* put(requestAccessFailure({ patientId, error }))
  }
}

export default function* rlacSagas() {
  yield* all([
    takeKeyedLatest(
      requestAccess,
      (action) => action.payload.patientId,
      doRequestAccess,
    ),
  ])
}
