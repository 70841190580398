import axios, { type AxiosResponse } from 'axios'
import { all, call, put, takeLeading } from 'typed-redux-saga/macro'

import config from 'app/config'
import {
  type Intent,
  rasaIntentsAction,
  RasaIntentsActionType,
} from 'app/redux/rasa-intents'

export function* getRasaIntents() {
  try {
    const response = (yield* call(
      axios.get,
      config.RASA_INTENTS_URL,
    )) as AxiosResponse<{ intents: Intent[] }>
    yield* put(rasaIntentsAction.getRasaIntentsSuccess(response.data.intents))
  } catch (error) {
    yield* put(rasaIntentsAction.getRasaIntentsFailure(error))
  }
}

export default function* rasaIntentsSagas() {
  yield* all([
    takeLeading(RasaIntentsActionType.GET_RASA_INTENTS, getRasaIntents),
  ])
}
