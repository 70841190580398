import { all } from 'typed-redux-saga/macro'

import schedulingAppointmentSagas from './appointments'
import schedulingGroupsSagas from './groups'
import schedulingPractitionerSagas from './practitioner'

export default function* schedulingSagas() {
  yield* all([
    schedulingAppointmentSagas(),
    schedulingPractitionerSagas(),
    schedulingGroupsSagas(),
  ])
}
