import type { ReactNode } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import {
  Col,
  Divider,
  Row,
  Space,
  Typography,
  type RowProps,
  Tooltip,
} from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CopyButton } from 'app/components/copy-button/copy-button'
import { colors } from 'app/theme'

import { formatDocumentDate } from '../../helpers'

interface MetadataField {
  label: string
  value?: string
}

export interface OutgoingFaxMetadata {
  fax_package_properties: MetadataField[]
  document_properties: MetadataField[]
}

export interface DocumentActivityMetadata {
  receiver_number?: string
  outgoing_fax_document_id?: string
}

export const LineItem = ({
  label,
  value,
}: {
  label: string
  value: ReactNode
}) => (
  <Space
    direction="vertical"
    size={4}
    css={`
      display: flex;
      flex-direction: column;
    `}
  >
    <Typography.Text type="secondary">{label}</Typography.Text>
    <Typography.Text>{value}</Typography.Text>
  </Space>
)

export const DetailLine = ({
  label,
  value,
  alignLabel = 'top',
}: {
  label: string
  value: ReactNode
  alignLabel?: RowProps['align']
}) => (
  <Row align={alignLabel} gutter={8}>
    <Col span={6}>
      <Typography.Text
        type="secondary"
        css={`
          height: 100%;
        `}
      >
        {label}
      </Typography.Text>
    </Col>
    <Col span={18}>{value}</Col>
  </Row>
)

export const HeaderLine = ({
  title,
  extra,
}: {
  title: string
  extra?: ReactNode
}) => (
  <>
    <Divider
      css={`
        margin: 12px 0;
      `}
    />
    <div
      css={`
        display: flex;
        align-items: baseline;
        justify-content: space-between;
      `}
    >
      <Typography.Text
        strong
        data-dd-privacy="allow"
        css={`
          margin-bottom: 5px;
        `}
      >
        {title}
      </Typography.Text>
      {extra}
    </div>
  </>
)

export const ActivitiesContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
  padding-top: 4px;

  .ant-timeline-item {
    padding-bottom: 12px;
  }
`

const ActivityToolTipButton = styled(InfoCircleOutlined)`
  color: ${colors.primary};
  font-size: 12px;
  margin-left: 4px;
`

const ToolTipText = ({ data }: { data: Array<string> }) => {
  return (
    <Space direction="vertical" size={0}>
      {data.map((item) => (
        <Typography.Text key={item}>{item}</Typography.Text>
      ))}
    </Space>
  )
}

const ActivityToolTip = ({ data = [] }: { data: Array<string> }) => {
  return (
    <Tooltip
      title={<ToolTipText data={data} />}
      color={'white'}
      placement="right"
      arrowPointAtCenter
    >
      <ActivityToolTipButton data-testid="activity-tooltip" />
    </Tooltip>
  )
}

export const ActivityTimelineBlock = ({
  activityType,
  timestamp,
  tooltipMessages,
  personName,
  resourceTitle,
  metadataFields,
}: {
  activityType: string
  timestamp: string
  tooltipMessages: Array<string>
  personName?: string
  resourceTitle?: string
  metadataFields?: Array<string>
}) => {
  const { t } = useTranslation()

  return (
    <Space direction="vertical" size={0}>
      <Typography.Text strong>
        {personName
          ? `${activityType} ${t('documents.activity.by')} ${personName}`
          : activityType}
        {tooltipMessages.length > 0 && (
          <ActivityToolTip data={tooltipMessages} />
        )}
      </Typography.Text>
      {resourceTitle && <Typography.Text>{resourceTitle}</Typography.Text>}
      {metadataFields?.map((field, i) => (
        <Space key={i}>
          <Typography.Text type="secondary">{field}</Typography.Text>
          <CopyButton value={field} />
        </Space>
      ))}
      <Typography.Text type="secondary">
        {formatDocumentDate({ t, date: timestamp, includeTime: true })}
      </Typography.Text>
    </Space>
  )
}

export const PanelContent = styled(Space)`
  min-width: 160px;
  width: 100%;
`

export const FormContainer = styled(Space)`
  display: flex;
  & > *:first-child {
    flex: 1;
    max-width: 95%;
  }
`
