// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { type MentionsState, MentionsTypes } from './types'

export type MentionsActions = ActionType<typeof actions>

export { actions as mentionsActions }
export * from './types'

export const INITIAL_STATE: MentionsState = {
  fetching: false,
  map: {},
  order: [],
  lastViewedAt: null,
  currentlyViewing: false,
  mentionedMostRecentlyAt: null,
}

export const reducer: Reducer<typeof INITIAL_STATE, MentionsActions> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MentionsTypes.SET:
        if (!action.payload.mentions) break

        const order = Object.keys(action.payload.mentions)
        order.sort(
          (keyA, keyB) =>
            action.payload.mentions[keyB].create_at -
            action.payload.mentions[keyA].create_at,
        )

        const mostRecentMention = action.payload.mentions[order && order[0]]

        const mentionedMostRecentlyAt =
          mostRecentMention && mostRecentMention.create_at

        draft.map = action.payload.mentions
        draft.order = order
        draft.mentionedMostRecentlyAt = mentionedMostRecentlyAt
        break
      case MentionsTypes.START_VIEWING:
        draft.currentlyViewing = true
        draft.lastViewedAt = action.payload.lastViewedAt
        break
      case MentionsTypes.STOP_VIEWING:
        draft.currentlyViewing = false
        draft.lastViewedAt = action.payload.lastViewedAt
        break
    }
  })

export default reducer
