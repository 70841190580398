import { Region } from './model'

import config from '.'

export const regionIncludes = (region: Region) => {
  return config.REGIONS.includes(region)
}

const US_DATE_FORMAT = 'MM / DD / YYYY'
const DEFAULT_DATE_FORMAT = 'DD / MM / YYYY'

export const DATE_FORMAT = regionIncludes(Region.US)
  ? US_DATE_FORMAT
  : DEFAULT_DATE_FORMAT
