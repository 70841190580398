import type { FormInstance } from 'antd'

import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'

import { DocumentProperties } from './document-properties'
import { FaxProperties } from './fax-properties'
import { MemberProperties } from './member-properties'
import { TasksSection } from './tasks-section'

export const ProcessedPanel = ({
  document,
  form,
}: {
  document: ViewerIncomingFaxDocument
  form: FormInstance
}) => {
  return (
    <>
      <TasksSection
        documentId={document.id}
        episodeId={document.member_document?.episode_id}
        memberIdFieldValue={document.member_document?.member_id}
      />
      <MemberProperties document={document} form={form} readonly />
      <DocumentProperties document={document} form={form} readonly />
      <FaxProperties document={document} />
    </>
  )
}
