import { useCallback, type MouseEvent, forwardRef } from 'react'

import { Button, type ButtonProps } from 'antd'
import { useLinkClickHandler, useHref } from 'react-router-dom'

interface Props extends Omit<ButtonProps, 'href'> {
  to: string
  replace?: boolean
  state?: any
}

/**
 * Ant-design `<Button />` wrapper around react-router's
 * `<Link />` component.
 */
export const NavButton = forwardRef<HTMLElement, Props>(
  ({ to, onClick, target, state, replace = false, ...rest }, ref) => {
    const href = useHref(to)
    const routerClickHandler = useLinkClickHandler(to, {
      replace,
      state,
      target,
    })

    const handleClick = useCallback(
      (event: MouseEvent<HTMLAnchorElement>) => {
        onClick?.(event)
        if (!event.defaultPrevented) {
          routerClickHandler(event)
        }
      },
      [onClick, routerClickHandler],
    )

    return <Button {...rest} href={href} onClick={handleClick} ref={ref} />
  },
)

export default NavButton
