export enum PractitionerTypes {
  REQUEST_PROFILES = '@@practitioners/REQUEST_PROFILES',
  REQUEST_PROFILES_SUCCESS = '@@practitioners/REQUEST_PROFILES_SUCCESS',
  REQUEST_PROFILES_FAILURE = '@@practitioners/REQUEST_PROFILES_FAILURE',

  ASSIGN_EPISODE = '@@practitioners/ASSIGN_EPISODE',
  BATCH_ASSIGN_EPISODES = '@@practitioners/BATCH_ASSIGN_EPISODES',
  ASSIGN_EPISODE_SUCCESS = '@@practitioners/ASSIGN_EPISODE_SUCCESS',
  ASSIGN_EPISODE_FAILURE = '@@practitioners/ASSIGN_EPISODE_FAILURE',

  UNASSIGN_EPISODE = '@@practitioners/UNASSIGN_EPISODE',
  UNASSIGN_EPISODE_SUCCESS = '@@practitioners/UNASSIGN_EPISODE_SUCCESS',
  UNASSIGN_EPISODE_FAILURE = '@@practitioners/UNASSIGN_EPISODE_FAILURE',
}

export interface Practitioner {
  id: string
  mm_id: string
  picture: string
  first_name: string
  last_name: string
  nickname: string
  email: string
  locale: string
  username: string
  online?: boolean
}

export interface PractitionerState {
  profiles: Record<string, Practitioner>
  mmIdMap: Record<string, string>
  fetchingProfiles: boolean
  errorFetchingProfiles: Error
}
