import { useEffect } from 'react'

import { useAppSelector } from 'app/hooks'
import {
  selectDarkModeEnabled,
  selectRedactionModeEnabled,
} from 'app/redux/theming'

enum ThemeClassName {
  REDACT_MODE = 'redactMode',
  DARK_MODE = 'darkMode',
}

const setTheme = (themeName: ThemeClassName, enabled: boolean) => {
  document.documentElement.classList.toggle(themeName, enabled)
}

/**
 * Sync document's classnames to the store's global themes.
 *
 * (Global themes include darkMode and redactionMode)
 **/
export const useThemeSync = () => {
  const darkModeEnabled = useAppSelector(selectDarkModeEnabled)
  const redactionModeEnabled = useAppSelector(selectRedactionModeEnabled)

  useEffect(() => {
    setTheme(ThemeClassName.DARK_MODE, darkModeEnabled)
  }, [darkModeEnabled])

  useEffect(() => {
    setTheme(ThemeClassName.REDACT_MODE, redactionModeEnabled)
  }, [redactionModeEnabled])

  return { darkModeEnabled, redactionModeEnabled }
}
