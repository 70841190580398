import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

export const selectProperties = (state: ReduxState) =>
  state.episodeMeta.properties

export const selectPropertiesErrors = (state: ReduxState) =>
  state.episodeMeta.errorProperties

export const selectIssueTypes = (state: ReduxState) =>
  state.episodeMeta.properties?.health_issue_types

export const selectIssueTypesAsOptions = createSelector(
  selectIssueTypes,
  (issueTypes) =>
    issueTypes?.map(({ id, name }) => ({
      label: name,
      value: id,
    })),
)

export const selectPriorities = (state: ReduxState) =>
  state.episodeMeta.properties?.priorities

export const selectPrioritiesAsOptions = createSelector(
  selectPriorities,
  (priorities) =>
    priorities?.map(({ id, name }) => ({ label: name, value: id })),
)
