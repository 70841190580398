import { combineReducers } from 'redux'

import appointments from './appointments'
import groupsMapping from './groups-mapping'
import practitioner from './practitioner'

export default combineReducers({
  appointments,
  groupsMapping,
  practitioner,
})
