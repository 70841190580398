import { useCallback } from 'react'

/**
 * A small custom hook for preventing the event from bubbling up the DOM tree.
 *
 * Usage:
 *
 * ```tsx
 * const { stopPropagation } = useStopPropagation()
 *
 * return (
 *  <div onClick={() => console.log('Will not fire!')}>
 *    <button onClick={stopPropagation}>Click me</button>
 * </div>
 * )
 * ```
 */
export const useStopPropagation = () => {
  const stopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation()
  }, [])

  return { stopPropagation }
}
