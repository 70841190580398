// @ts-strict-ignore
import { formatUserDisplayName } from 'app/lib/helpers'
import type { ReduxState } from 'app/redux'

export const selectEpisodeViewEpisode = (state: ReduxState) =>
  state.episodeView.episode

export const selectEpisodeLoading = (state: ReduxState) =>
  state.episodeView.episode?.fetchingEpisode === true

export const selectEpisodeLoaded = (state: ReduxState) => {
  return state.episodeView.episode?.fetchingEpisode === false
}

export const selectCurrentEpisodeId = (state: ReduxState) =>
  state.episodeView.episode.id

export const selectCurrentEpisodeBrandId = (state: ReduxState) =>
  selectEpisodeViewEpisode(state)?.brand_id ?? 'dia'

export const selectAreEpisodePropsComplete = (state: ReduxState) => {
  const episode = selectEpisodeViewEpisode(state)

  return !!(episode?.title && episode?.health_issue_type_id)
}

export const selectPatientId = (state: ReduxState): number =>
  state.episodeView.episode.patient_id

export const selectEpisodeTitle = (state: ReduxState) => {
  const episode = selectEpisodeViewEpisode(state)
  return episode?.title
}

export const selectEpisodeMemberDisplayName = (state: ReduxState) => {
  const episode = selectEpisodeViewEpisode(state)

  if (!episode) return null

  return formatUserDisplayName(
    episode.patient_first_name,
    episode.patient_last_name,
    episode.patient_preferred_name,
  )
}
