import { Timeline, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import type { ViewerIHDocument } from 'app/redux/documents/viewer'

import { formatDocumentType } from '../../helpers'

import {
  ActivitiesContainer,
  ActivityTimelineBlock,
  DetailLine,
  HeaderLine,
  PanelContent,
} from './common'

interface Props {
  document: ViewerIHDocument
}

export const InputHealthPanel = ({
  document: { created_at: createdAt, name, type, source },
}: Props) => {
  const { t } = useTranslation()

  return (
    <PanelContent size={4} direction="vertical">
      <Typography.Text>{name}</Typography.Text>
      <HeaderLine title={t('documents.sections.properties')} />
      {type && (
        <DetailLine
          label={t('documents.type')}
          value={
            <Typography.Text>{formatDocumentType(t, type)}</Typography.Text>
          }
        />
      )}
      <DetailLine
        label={t('documents.source')}
        value={
          <Typography.Text>
            {source || t('documents.notAvailable')}
          </Typography.Text>
        }
      />
      <HeaderLine title={t('documents.sections.activity')} />
      <ActivitiesContainer>
        <Typography.Text type="secondary">
          {t('tasks.sortOrderText')}
        </Typography.Text>
        <Timeline>
          <Timeline.Item>
            <ActivityTimelineBlock
              activityType={t('documents.activity.created')}
              timestamp={createdAt}
              tooltipMessages={[]}
            />
          </Timeline.Item>
        </Timeline>
      </ActivitiesContainer>
    </PanelContent>
  )
}
