import { useCallback, useEffect } from 'react'

import { TaskPriority } from '@dialogue/coredata'
import { Alert, Form, Spin } from 'antd'
import { useTranslation } from 'react-i18next'

import { TaskPriorityInput } from 'app/components/tasks/task-priority-input'
import { TaskDescripitionInput } from 'app/containers/tasks/task-description-input'
import { usePersistedValue } from 'app/hooks'

export interface NextSteps {
  priority?: TaskPriority
  description?: string
}

const defaultNextSteps: NextSteps = {
  priority: TaskPriority.NONE,
  description: undefined,
}

export const NextStepsForm = ({
  isLoading = false,
  initialValues,
  onChange = () => {},
  disabled,
}: {
  isLoading?: boolean
  initialValues: NextSteps
  onChange: (newValues: NextSteps) => void
  disabled?: boolean
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (isLoading) return
    form.resetFields()
  }, [form, isLoading])

  const handleValuesChange = useCallback(
    (_: any, v: NextSteps) => {
      onChange(v)
    },
    [onChange],
  )

  return (
    <Spin spinning={isLoading}>
      <Form
        disabled={disabled}
        id="task-next-steps"
        initialValues={initialValues}
        form={form}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          name="priority"
          label={t('tasks.nextSteps.priority')}
          data-dd-privacy="allow"
        >
          <TaskPriorityInput data-testid={'next-steps-priority'} />
        </Form.Item>
        <Form.Item name="description">
          <TaskDescripitionInput
            label={t('tasks.nextSteps.instructions')}
            placeholder={t('tasks.nextSteps.instructionsPlaceholder')}
            data-testid={'next-steps-description'}
            data-dd-action-name="task-form-next-steps-description"
          />
        </Form.Item>
        <Alert
          message={t('tasks.nextSteps.infoText')}
          type="info"
          showIcon
          data-dd-privacy="allow"
        />
      </Form>
    </Spin>
  )
}

export const usePersistedNextSteps = (persistedValueId: string) => {
  const [nextSteps, setNextSteps, , isNextStepsLoaded] = usePersistedValue(
    defaultNextSteps,
    persistedValueId,
  )

  const handleValuesChange = useCallback(
    (newValue: NextSteps) => {
      setNextSteps(newValue)
    },
    [setNextSteps],
  )

  return {
    nextSteps,
    setNextSteps: handleValuesChange,
    isLoadingNextSteps: !isNextStepsLoaded,
  }
}
