import type { ScribeTypes } from '@dialogue/services'
import moment from 'moment'
import { all, call, put, takeLatest } from 'typed-redux-saga/macro'

import { birthdateToAge } from 'app/lib/helpers'
import { addDependentActions } from 'app/redux/add-dependent'
import { patientsActions } from 'app/redux/patients'
import { initScribeClient } from 'app/sagas/utils'

export function* createDependent({
  payload,
}: ReturnType<typeof addDependentActions.request>) {
  const { memberId, formValues } = payload

  const momentDate = moment(formValues?.birthdate, 'DD/MM/YYYY')
  const age = birthdateToAge(momentDate)

  const attributes = {
    first_name: formValues.firstName,
    last_name: formValues.lastName,
  } as ScribeTypes.CreateDependentValues

  try {
    const scribe = yield* call(initScribeClient)

    if (age >= 14) {
      attributes.email = formValues.email

      yield* call(scribe.createFamilyDependent, memberId, attributes)
    } else {
      attributes.birthdate = momentDate.toISOString()

      yield* call(scribe.createFamilyChild, memberId, attributes)
    }

    yield* put(addDependentActions.success())

    // Refresh the user's family data
    yield* put(patientsActions.requestProfile(Number(memberId), false))
  } catch (error) {
    yield* put(addDependentActions.failure({ error }))
  }
}

export default function* addDependentSagas() {
  yield* all([takeLatest(addDependentActions.request, createDependent)])
}
