import { createApi } from '@reduxjs/toolkit/query/react'

import { API_NAMES, getDynamicBaseUrl } from '../utils'

export enum Tags {
  IncomingFaxDocument = 'IncomingFaxDocument',
  OutgoingFaxDocument = 'OutgoingFaxDocument',
  MemberDocument = 'MemberDocument',
}

export const documentCenterApi = createApi({
  tagTypes: Object.values(Tags),
  reducerPath: 'documentCenterApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.DOCUMENT_CENTER),
  endpoints: () => ({}),
})
