import { call, put, all, takeLatest } from 'typed-redux-saga/macro'

import { schedulingPractitionerActions } from 'app/redux/scheduling/practitioner'

import { initEmeraldV2Client } from '../utils'

export function* getSchedule({
  payload: { practitionerId, selectedDate },
}: ReturnType<typeof schedulingPractitionerActions.get>) {
  try {
    const emerald = yield* call(initEmeraldV2Client)
    const data = yield* call(
      emerald.getPractitionerWeekSchedule,
      practitionerId,
      selectedDate,
    )
    yield* put(schedulingPractitionerActions.getSuccess(data))
  } catch (e) {
    yield* put(schedulingPractitionerActions.getFailure(e))
  }
}

export default function* schedulingPractitionerSagas() {
  yield* all([takeLatest(schedulingPractitionerActions.get, getSchedule)])
}
