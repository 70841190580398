// @ts-strict-ignore
import type {
  AllowedStatusTransitions,
  Note,
  NoteWithSubNote,
} from '@dialogue/notepad-client'
import {
  createSelector,
  createSlice,
  type Draft,
  type PayloadAction,
} from '@reduxjs/toolkit'
import keyBy from 'lodash/keyBy'

import type { ReduxState } from 'app/redux'

import {
  Operation,
  type NoteCreationDataComplete,
  type NoteWithTempId,
} from '../types'

interface NotesState {
  [Operation.FETCH]: boolean
  [Operation.UPDATE]: boolean
  [Operation.DELETE]: boolean
  [Operation.FINALIZE]: boolean
}

export type PatientAction<T extends Record<any, any> = {}> = PayloadAction<
  { patientId: number } & T
>

interface PatientNotesState {
  [Operation.FETCH]: boolean
  [Operation.UPDATE]: boolean
  [Operation.CREATE]: boolean
  [Operation.DELETE]: boolean
  [Operation.FINALIZE]: boolean
  draftId: number | null
  error: Error | null
  errorNoteId: number | null
  lastCreatedNoteId: number | null
  lastCreatedSubNoteId: number | null
  // FIXME: use Note once we deprecate nested sub notes in response
  notes: Array<Omit<NoteWithTempId, 'sub_notes'>> | null
  notesOperations: { [noteId: number]: NotesState }
}

interface State {
  [patientId: number | string]: PatientNotesState
}

export type UpdateNoteInfo = {
  id: number
  signature?: string
} & (
  | {
      values: Record<string, unknown>
      status?: AllowedStatusTransitions
    }
  | {
      values?: Record<string, unknown>
      status: AllowedStatusTransitions
    }
)

const getState = (state: Draft<State>, patientId: number | string) => {
  if (!state[patientId]) {
    state[patientId] = {
      [Operation.FETCH]: false,
      [Operation.UPDATE]: false,
      [Operation.CREATE]: false,
      [Operation.DELETE]: false,
      [Operation.FINALIZE]: false,
      error: null,
      notes: null,
      draftId: null,
      errorNoteId: null,
      lastCreatedNoteId: null,
      lastCreatedSubNoteId: null,
      notesOperations: {},
    }
  }

  return state[patientId]
}

const getNoteOperations = (state: Draft<PatientNotesState>, noteId: number) => {
  if (!state.notesOperations[noteId]) {
    state.notesOperations[noteId] = {
      [Operation.FETCH]: false,
      [Operation.UPDATE]: false,
      [Operation.DELETE]: false,
      [Operation.FINALIZE]: false,
    }
  }

  return state.notesOperations[noteId]
}

// FIXME: subnote forms should be fetching their own notes,
// remove this once that is implemented
const getSubNoteOperations = (
  state: Draft<PatientNotesState>,
  noteId: number,
) => {
  const subNoteIds = state.notes?.find(
    (note) => note.id === noteId,
  )?.sub_note_ids

  const operations: NotesState[] = []

  subNoteIds?.forEach((id) => {
    operations.push(getNoteOperations(state, id))
  })

  return operations
}

const setNoteAndSubnotesOperations = (
  patient: PatientNotesState,
  noteId: number,
  subNotes: UpdateNoteInfo[],
  operation: Exclude<Operation, Operation.CREATE>,
) => {
  if (subNotes?.length) {
    subNotes.forEach((subNote) => {
      const subNoteOperations = getNoteOperations(patient, subNote.id)

      subNoteOperations[operation] = true
    })
  }
  const notesOperations = getNoteOperations(patient, noteId)
  notesOperations[operation] = true
}

export const { reducer, actions: patientNotesActions } = createSlice({
  name: '@@structuredNotes/patient/notes',
  initialState: {} as State,
  reducers: {
    get(state, { payload }: PatientAction<{ noteId: number }>) {
      const patient = getState(state, payload.patientId)
      const notesOperations = getNoteOperations(patient, payload.noteId)
      notesOperations[Operation.FETCH] = true

      // FIXME: to be refactored once subnotes fetch themselves.
      const subNoteOperations = getSubNoteOperations(patient, payload.noteId)

      subNoteOperations.forEach((ops) => {
        ops[Operation.FETCH] = true
      })

      patient.error = null
    },

    create(
      state,
      {
        payload,
      }: PatientAction<{
        note: NoteCreationDataComplete
        setAsCurrentDraft?: boolean
      }>,
    ) {
      const patient = getState(state, payload.patientId)

      patient[Operation.CREATE] = true
      patient.error = null
    },

    finalize(
      state,
      {
        payload,
      }: PatientAction<{
        note: UpdateNoteInfo
        subNotes?: UpdateNoteInfo[]
      }>,
    ) {
      const patient = getState(state, payload.patientId)
      patient[Operation.FINALIZE] = true
      patient.error = null

      setNoteAndSubnotesOperations(
        patient,
        payload.note.id,
        payload.subNotes,
        Operation.FINALIZE,
      )
    },

    update(
      state,
      {
        payload,
      }: PatientAction<{
        note: UpdateNoteInfo
        subNotes?: UpdateNoteInfo[]
      }>,
    ) {
      const patient = getState(state, payload.patientId)
      patient[Operation.UPDATE] = true
      patient.error = null

      setNoteAndSubnotesOperations(
        patient,
        payload.note.id,
        payload.subNotes,
        Operation.UPDATE,
      )
    },

    clearLastCreated(state, { payload }: PatientAction) {
      const patient = getState(state, payload.patientId)
      patient.lastCreatedNoteId = null
      patient.lastCreatedSubNoteId = null
    },

    received(
      state,
      {
        payload,
      }: PatientAction<{
        note: Note | NoteWithSubNote
        operation: Operation
        parentNoteId?: number
        setAsCurrentDraft?: boolean
      }>,
    ) {
      const patient = getState(state, payload.patientId)
      const notesOperations = getNoteOperations(patient, payload.note.id)
      if (payload.operation === Operation.CREATE) {
        if (payload.parentNoteId) {
          patient.lastCreatedSubNoteId = payload.note.id
        } else {
          patient.lastCreatedNoteId = payload.note.id
        }
      }
      // getting single notes should not update patient level state
      if (payload.operation !== Operation.FETCH) {
        patient[payload.operation] = false
      }

      if (payload.operation !== Operation.CREATE) {
        notesOperations[payload.operation] = false

        const subNoteOperations = getSubNoteOperations(patient, payload.note.id)

        subNoteOperations.forEach((ops) => {
          // @ts-ignore ts weirdly doesn't see that we check for != create
          // before getting to this scope.
          ops[payload.operation] = false
        })
      }

      patient.error = null

      patient.notes = patient.notes || []

      const existingIndex = patient.notes.findIndex(
        (note: Note) => note.id === payload.note.id,
      )

      if (existingIndex === -1) {
        patient.notes.unshift(payload.note as NoteWithSubNote)
      } else {
        patient.notes[existingIndex] = {
          ...payload.note,
          tempId: patient.notes?.[existingIndex]?.tempId,
        }
      }

      // FIXME: subnote forms should be fetching their own notes,
      // remove this once that is implemented
      if ('sub_notes' in payload.note) {
        for (const subNote of payload.note.sub_notes) {
          const existingIndex = patient.notes.findIndex(
            (note: Note) => note.id === subNote.id,
          )

          if (existingIndex === -1) {
            patient.notes.unshift(subNote)
          } else {
            patient.notes[existingIndex] = {
              ...subNote,
              tempId: patient.notes?.[existingIndex]?.tempId,
            }
          }
        }
      }

      if (payload.parentNoteId) {
        // creating a new subnote, make sure parent has the reference
        const parentNote = patient.notes.find(
          (note) => note.id === payload.parentNoteId,
        )

        if (parentNote?.sub_note_ids.indexOf(payload.note.id) === -1) {
          parentNote.sub_note_ids.push(payload.note.id)
        }
      } else if (
        payload.operation === Operation.CREATE &&
        payload.setAsCurrentDraft !== false
      ) {
        // set as draft if created & not a subnote
        patient.draftId = payload.note.id
      }
    },

    cancel(
      state,
      {
        payload,
      }: PatientAction<{
        noteId: number
        parentNoteId?: number
      }>,
    ) {
      const patient = getState(state, payload.patientId)
      const notesOperations = getNoteOperations(patient, payload.noteId)

      patient[Operation.DELETE] = true
      notesOperations[Operation.DELETE] = true

      patient.error = null
    },

    deleteDraft(
      state,
      {
        payload,
      }: PatientAction<{
        draftId: number
        parentNoteId?: number
      }>,
    ) {
      const patient = getState(state, payload.patientId)
      const notesOperations = getNoteOperations(patient, payload.draftId)

      patient[Operation.DELETE] = true
      notesOperations[Operation.DELETE] = true

      patient.error = null
    },

    draftDeleted(
      state,
      {
        payload,
      }: PatientAction<{
        draftId: number
        parentNoteId?: number
      }>,
    ) {
      const patient = getState(state, payload.patientId)

      patient[Operation.DELETE] = false
      delete patient.notesOperations[payload.draftId]
      patient.error = null
      if (!payload.parentNoteId) {
        patient.draftId = null
      }

      const parentNote = patient.notes?.find(
        (note: Note) =>
          note.id ===
          (payload.parentNoteId ? payload.parentNoteId : payload.draftId),
      )

      if (payload.parentNoteId) {
        // if deleting a subnote, remove the reference from the parent
        parentNote.sub_note_ids = parentNote?.sub_note_ids?.filter(
          (id) => id !== payload.draftId,
        )
      }

      const noteIds = []
        .concat(
          payload.draftId,
          // We only want to delete the subnotes if we're deleting the parent
          payload.parentNoteId ? [] : parentNote?.sub_note_ids,
        )
        .filter(Boolean)

      noteIds.forEach((nodeId) => {
        const existingDraftIndex = patient.notes?.findIndex(
          (note: Note) => note.id === nodeId,
        )

        if (existingDraftIndex !== -1) {
          patient.notes.splice(existingDraftIndex, 1)
        }
      })
    },

    crudFailure(
      state,
      {
        payload,
      }: PatientAction<{
        draftId?: number
        operation: Operation
        error: Error
      }>,
    ) {
      const patient = getState(state, payload.patientId)
      patient[payload.operation] = false

      if (payload.draftId && payload.operation !== Operation.CREATE) {
        const notesOperations = getNoteOperations(patient, payload.draftId)
        notesOperations[payload.operation] = false
      }

      patient.error = payload.error
      patient.errorNoteId = payload.draftId
    },

    setDraft(state, { payload }: PatientAction<{ draftId: number | null }>) {
      const patient = getState(state, payload.patientId)
      patient.draftId = payload.draftId
      patient.error = null
    },
  },
})

export default reducer

const selectState = (state: ReduxState) => state.structuredNotes.patients.notes

export const selectPatientNotesState = (
  state: ReduxState,
  patientId: string | number,
) => selectState(state)[patientId]

export const selectNotesError = (
  state: ReduxState,
  patientId: string | number,
) => selectPatientNotesState(state, patientId)?.error

/**
 * internal to reducer, get all notes for a patient in a flat list, including
 * subnotes
 */
const selectAllPatientNotes = (state: ReduxState, patientId: number) =>
  selectPatientNotesState(state, patientId)?.notes

export const selectNoteById = (
  state: ReduxState,
  patientId: number,
  id: number,
) => {
  const notes = selectAllPatientNotes(state, patientId)

  return notes?.find((note) => note.id === id)
}

export const selectNotesByIdKeyed = (
  state: ReduxState,
  patientId: number,
  ids: number[] = [],
) => {
  const notes = selectAllPatientNotes(state, patientId)
  return keyBy(
    notes?.filter((note) => ids.includes(note.id)),
    'id',
  )
}

export const selectDraftId = (state: ReduxState, patientId: string | number) =>
  selectPatientNotesState(state, patientId)?.draftId

export const selectLastCreatedSubNoteId = (
  state: ReduxState,
  patientId: string | number,
) => selectPatientNotesState(state, patientId)?.lastCreatedSubNoteId

export const selectIsUpdating = (
  state: ReduxState,
  patientId: string | number,
) => selectPatientNotesState(state, patientId)?.[Operation.UPDATE]

export const selectIsCreating = (
  state: ReduxState,
  patientId: string | number,
) => selectPatientNotesState(state, patientId)?.[Operation.CREATE]

export const selectIsNoteUpdating = (
  state: ReduxState,
  patientId: string | number,
  noteId: number,
) =>
  selectPatientNotesState(state, patientId)?.notesOperations[noteId]?.[
    Operation.UPDATE
  ]

export const selectIsNoteDeleting = (
  state: ReduxState,
  patientId: string | number,
  noteId: number,
) =>
  selectPatientNotesState(state, patientId)?.notesOperations[noteId]?.[
    Operation.DELETE
  ]

export const selectIsNoteFinalizing = (
  state: ReduxState,
  patientId: string | number,
  noteId: number,
) =>
  selectPatientNotesState(state, patientId)?.notesOperations[noteId]?.[
    Operation.FINALIZE
  ]

export const selectIsNoteFetching = (
  state: ReduxState,
  patientId: string | number,
  noteId: number,
) =>
  selectPatientNotesState(state, patientId)?.notesOperations[noteId]?.[
    Operation.FETCH
  ]

export const selectParentNoteId = (
  state: ReduxState,
  patientId: string | number,
  noteId: number,
): number | undefined => {
  return selectPatientNotesState(state, patientId)?.notes?.find((n) =>
    n.sub_note_ids.includes(noteId),
  )?.id
}

/**
 * Get all the subnotes belonging to a parent note.
 *
 * @deprecated Refrain from using, this should be removed once
 * subnote forms become more "standalone".
 */
export const createSelectSubNotes = () =>
  createSelector(selectNoteById, selectAllPatientNotes, (parentNote, notes) => {
    if (!parentNote) {
      return null
    }

    return notes.filter(
      (note) => parentNote.sub_note_ids.indexOf(note.id) !== -1,
    )
  })
