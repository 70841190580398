import type { Provider } from '@dialogue/timekeeper'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

interface State {
  fetching: boolean
  error: Error | null
  [providerId: number]: Provider
}

type ProvidersAction<T = {}> = PayloadAction<{ providerId: number } & T>

const INITIAL_STATE: State = {
  fetching: false,
  error: null,
}

export const { actions: timekeeperProviderActions, reducer } = createSlice({
  name: '@@timekeeper/providers',
  initialState: INITIAL_STATE,
  reducers: {
    get: (state, _action: ProvidersAction) => {
      state.fetching = true
      state.error = null
    },
    received: (state, action: ProvidersAction<{ data: Provider }>) => {
      state[action.payload.providerId] = action.payload.data
      state.fetching = false
    },
    errored: (state, action: ProvidersAction<{ error: Error }>) => {
      state.fetching = false
      state.error = action.payload.error
    },
  },
})

export default reducer
