import type { Reminder } from '@dialogue/coredata'
import { EmergencyRoomTypes } from '@dialogue/services'
import { createSelector } from '@reduxjs/toolkit'
import { produce } from 'immer'
import moment from 'moment'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type { ReduxState } from 'app/redux'

import * as actions from './actions'
import { type RemindersState, RemindersTypes } from './types'

export type RemindersActions = ActionType<typeof actions>

export * from './types'
export { actions as remindersActions }

export const INITIAL_STATE: RemindersState = {
  episodes: {},
  fetching: false,
  fetchingStatusChange: false,
  error: null,
  errorEpisodes: null,

  tags: [],
  tagsError: null,
  tagsFetching: false,

  // ER list
  list: [],
  listCount: 0,
  incompleteCount: null,
  listError: null,
  listFetching: false,
}

export const reducer: Reducer<typeof INITIAL_STATE, RemindersActions> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RemindersTypes.STATUS_CHANGE_REQUEST:
      case RemindersTypes.STATUS_CHANGE_SUCCESS:
      case RemindersTypes.STATUS_CHANGE_FAILURE: {
        const { reminder } = action.payload

        let status = null

        if (action.type === RemindersTypes.STATUS_CHANGE_FAILURE) {
          status = reminder.status
        } else {
          status = action.payload.status
        }

        if (
          status !== EmergencyRoomTypes.ReminderStatus.COMPLETED &&
          status !== EmergencyRoomTypes.ReminderStatus.INCOMPLETE
        ) {
          break
        }

        const fetching = action.type === RemindersTypes.STATUS_CHANGE_REQUEST

        const episode_id = reminder.episode_id
        const newReminder = {
          ...reminder,
          status,
        }

        draft.episodes.error = null
        draft.fetchingStatusChange = fetching

        const episodeFilteredIncomplete =
          draft.episodes[episode_id]?.incomplete?.filter(
            (r) => r.id !== reminder.id,
          ) || []

        const episodeFilteredCompleted =
          draft.episodes[episode_id]?.completed?.filter(
            (r) => r.id !== reminder.id,
          ) || []

        if (status === EmergencyRoomTypes.ReminderStatus.COMPLETED) {
          draft.episodes[episode_id] = {
            incomplete: episodeFilteredIncomplete,
            completed: [newReminder, ...episodeFilteredCompleted],
          }
        } else if (status === EmergencyRoomTypes.ReminderStatus.INCOMPLETE) {
          draft.episodes[episode_id] = {
            incomplete: [newReminder, ...episodeFilteredIncomplete],
            completed: episodeFilteredCompleted,
          }
        }
        break
      }

      case RemindersTypes.CREATE:
        draft.error = null
        draft.episodes.error = null
        break
      case RemindersTypes.CREATE_SUCCESS: {
        const { reminder } = action.payload
        draft.episodes[reminder.episode_id].incomplete.push(reminder)
        break
      }
      case RemindersTypes.CREATE_FAILURE: {
        const { reminder, error } = action.payload
        draft.episodes[reminder.episode_id].error = error
        break
      }

      case RemindersTypes.GET_FOR_EPISODE_REQUEST:
        draft.errorEpisodes = null
        break

      case RemindersTypes.GET_FOR_EPISODE_FETCHING:
        draft.episodes[action.payload.episode_id] = {
          ...draft.episodes[action.payload.episode_id],
          fetching: true,
        }
        break

      case RemindersTypes.GET_FOR_EPISODE_SUCCESS: {
        const { episode_id, episodeReminders } = action.payload
        draft.episodes[episode_id] = episodeReminders
        draft.episodes[episode_id].fetching = false
        break
      }
      case RemindersTypes.GET_FOR_EPISODE_FAILURE: {
        const { episode_id, error } = action.payload
        draft.errorEpisodes = error

        draft.episodes[episode_id] = {
          error,
          completed: [],
          incomplete: [],
          fetching: false,
        }
        break
      }

      case RemindersTypes.GET_TAGS_REQUEST:
        draft.tagsFetching = true
        draft.tagsError = null
        break
      case RemindersTypes.GET_TAGS_SUCCESS:
        draft.tagsFetching = false
        draft.tags = action.payload.tags
        break
      case RemindersTypes.GET_TAGS_ERROR:
        draft.tagsFetching = false
        draft.tagsError = action.payload.error
        break

      case RemindersTypes.GET_LIST_REQUEST:
        draft.listFetching = true
        draft.listError = null
        break
      case RemindersTypes.GET_LIST_SUCCESS:
        draft.list = action.payload.reminders
        draft.listCount = action.payload.count
        draft.listFetching = false
        if (
          action.payload.status === EmergencyRoomTypes.ReminderStatus.INCOMPLETE
        ) {
          draft.incompleteCount = action.payload.count
        }
        break
      case RemindersTypes.GET_LIST_ERROR:
        draft.listError = action.payload.error
        draft.listFetching = false
        break
    }
  })

export default reducer

export const selectCompletedRemindersByEpisode = (
  state: ReduxState,
  episodeId: string,
) => state.reminders.episodes[episodeId]?.completed

export const selectIncompleteRemindersByEpisode = (
  state: ReduxState,
  episodeId: string,
) => state.reminders.episodes[episodeId]?.incomplete

export const selectReminderTags = (state: ReduxState) => state.reminders.tags

export const selectRemindersEpisodesError = (state: ReduxState) =>
  state.reminders.errorEpisodes

export const selectRemindersFetching = (state: ReduxState) =>
  state.reminders.fetching

export const selectRemindersListFetching = (state: ReduxState) =>
  state.reminders.listFetching

export const selectRemindersIncompleteCount = (state: ReduxState) =>
  state.reminders.incompleteCount

const sortByDate = (itemA: Reminder, itemB: Reminder) => {
  const sortDateA = itemA.due_at
  const sortDateB = itemB.due_at
  return moment(sortDateA).valueOf() - moment(sortDateB).valueOf()
}

export const selectEpisodeReminders = createSelector(
  selectCompletedRemindersByEpisode,
  selectIncompleteRemindersByEpisode,
  (completed_reminders = [], incomplete_reminders = []) => {
    const completedReminders = [...completed_reminders]
    const incompleteReminders = [...incomplete_reminders]
    completedReminders.sort(sortByDate)
    incompleteReminders.sort(sortByDate)
    return { completedReminders, incompleteReminders }
  },
)
