import type { EnrichedEpisode } from '@dialogue/coredata'

export enum EpisodeViewTypes {
  CLEAR_EPISODE_VIEW = '@@episodeView/CLEAR_EPISODE_VIEW',

  RESOLVE_EPISODE = '@@episodes/RESOLVE_EPISODE',
  REOPEN_EPISODE = '@@episodes/REOPEN_EPISODE',
  SET_EPISODE_TO_PENDING = '@@episodes/SET_EPISODE_TO_PENDING',

  UPDATE_EPISODE_STATE_SUCCESS = '@@episodes/UPDATE_EPISODE_STATE_SUCCESS',
  UPDATE_EPISODE_STATE_FAILURE = '@@episodes/UPDATE_EPISODE_STATE_FAILURE',
}

export interface Episode
  extends Pick<
    EnrichedEpisode,
    | 'assignee_id'
    | 'bot_status'
    | 'brand_id'
    | 'communication_channel_id'
    | 'communication_methods'
    | 'created_at'
    | 'health_issue_type_id'
    | 'health_issue_type_name'
    | 'id'
    | 'outcome_id'
    | 'patient_first_name'
    | 'patient_id'
    | 'patient_last_name'
    | 'patient_preferred_name'
    | 'priority_id'
    | 'subject_id'
    | 'title'
  > {
  fetchingEpisode?: boolean
  queue: EnrichedEpisode['queue_id']
  queueUpdating?: boolean
  queueError?: Record<string, any>
  state: EnrichedEpisode['state_id']
  teamAction: EnrichedEpisode['team_action_id']
  updatingAssignee?: boolean
  updatingAssigneeError?: Error
}

export interface EpisodeViewState {
  episode: Partial<Episode>
  updatingState: boolean
  updatingStateError?: Error
}
