import type { Task } from '@dialogue/coredata'
import { Avatar, Tooltip } from 'antd'
import { useTheme } from 'styled-components'

import { useAppSelector } from 'app/hooks'
import { selectPractitionerById } from 'app/redux/practitioners/selectors'
import { colors } from 'app/theme'

interface TaskOwnerProps {
  assigneeId?: Task['assignee_id']
  teams: Task['teams']
}

const TaskOwner = ({ assigneeId, teams }: TaskOwnerProps) => {
  const assignee = useAppSelector((state) => {
    if (!assigneeId) return null
    return selectPractitionerById(state, assigneeId)
  })

  const theme = useTheme()

  return (
    <Avatar.Group
      maxCount={4}
      maxStyle={{
        border: 'solid 1px #ccc',
      }}
    >
      {assignee && (
        <Avatar
          gap={6}
          style={{
            backgroundColor: '#fff',
            border: 'solid 1px #ccc',
            color: colors.textDark({ theme }),
          }}
        >
          <Tooltip title={`${assignee.first_name} ${assignee.last_name}`}>
            {`${assignee.first_name?.[0]}${assignee.last_name?.[0]}`}
          </Tooltip>
        </Avatar>
      )}
      {teams &&
        teams.map((team) => (
          <Avatar
            gap={6}
            key={team.id}
            style={{
              border: 'solid 1px #ccc',
              backgroundColor: '#E7E7E7',
              color: colors.textDark({ theme }),
            }}
          >
            <Tooltip
              title={<span data-dd-privacy="allow">{team.name}</span>}
              className="dd-privacy-allow"
            >
              {team.name}
            </Tooltip>
          </Avatar>
        ))}
    </Avatar.Group>
  )
}

export default TaskOwner
