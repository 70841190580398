import type { EmeraldTypes } from '@dialogue/services'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

interface State {
  fetching: boolean
  error: Error | null
  data: EmeraldTypes.V2.ScheduleGroups | null
}

const INITIAL_STATE: State = {
  fetching: false,
  error: null,
  data: null,
}

export const { actions: schedulingGroupsMappingActions, reducer } = createSlice(
  {
    name: '@@scheduling/groupsMapping',
    initialState: INITIAL_STATE,
    reducers: {
      get(state) {
        state.fetching = true
        state.error = null
      },
      getSuccess(
        state,
        { payload }: PayloadAction<EmeraldTypes.V2.ScheduleGroups>,
      ) {
        state.fetching = false
        state.data = payload
      },
      getFailure(state, { payload }: PayloadAction<Error>) {
        state.fetching = false
        state.error = payload
      },
    },
  },
)

export const selectSchedulingGroupsMapping = (state: ReduxState) =>
  state.scheduling.groupsMapping.data

export const selectSchedulingGroupsMappingFetching = (state: ReduxState) =>
  state.scheduling.groupsMapping.fetching

export const selectSchedulingGroupsMappingError = (state: ReduxState) =>
  state.scheduling.groupsMapping.error

export default reducer
