// @ts-strict-ignore
import { Configuration } from '@dialogue/document-center'
import { select } from 'typed-redux-saga/macro'

import config from 'app/config'
import { selectAccessToken } from 'app/redux/authentification/selectors'

export function* getClientConfig() {
  const accessToken = yield* select(selectAccessToken)

  return new Configuration({
    accessToken,
    basePath: config.DOCUMENT_CENTER_URL,
  })
}
