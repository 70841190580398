import { type MouseEvent, useCallback, useRef, useState } from 'react'

import { CheckCircleFilled, CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import { useClipboard } from 'app/hooks'
import { colors } from 'app/theme'

// Implementation lifted from antd
// https://github.com/ant-design/ant-design/blob/984977177ab96efff428f14d8ed7b3c1704282c3/components/typography/Base/index.tsx#L193
export const CopyButton = ({
  value,
  onCopy,
}: {
  value: string
  onCopy?: (value: string) => void
}) => {
  const { t } = useTranslation('common')
  const [copied, setCopied] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const copy = useClipboard(value)

  const handleCopy = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      const clearTimeoutRef = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
      }

      event?.preventDefault()
      event?.stopPropagation()

      setCopied(true)

      copy()

      clearTimeoutRef()
      timeoutRef.current = setTimeout(() => {
        setCopied(false)
      }, 3000)

      onCopy?.(value)
    },
    [copy, onCopy, value],
  )

  const icon = copied ? (
    <CheckCircleFilled
      css={`
        color: ${colors.success};
      `}
    />
  ) : (
    <CopyOutlined
      css={`
        color: ${colors.primary};
      `}
    />
  )

  const text = copied ? t('button.copied') : t('button.copy')

  return (
    <Tooltip title={text}>
      <Button
        css={`
          cursor: pointer;
        `}
        onClick={handleCopy}
        icon={icon}
      />
    </Tooltip>
  )
}
