import type { EmergencyRoomTypes } from '@dialogue/services'
import { action } from 'typesafe-actions'

import { EpisodeMetaTypes } from './types'

export const requestProperties = (property?: string) =>
  action(EpisodeMetaTypes.REQUEST_PROPERTIES, { property })

export const requestPropertiesSuccess = (
  properties: EmergencyRoomTypes.EpisodeProperties,
) => action(EpisodeMetaTypes.REQUEST_PROPERTIES_SUCCESS, properties)

export const requestPropertiesFailure = (error: Record<string, any>) =>
  action(EpisodeMetaTypes.REQUEST_PROPERTIES_FAILURE, null, null, error)

export const updateEpisodeProperties = (
  patientId: number,
  episodeId: string,
  properties: EmergencyRoomTypes.UpdateEpisodeProperties,
  onChange?: () => void,
) =>
  action(EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES, {
    patientId,
    episodeId,
    properties,
    onChange,
  })

export const updateEpisodePropertiesSuccess = () =>
  action(EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES_SUCCESS)

export const updateEpisodePropertiesFailure = (error: Record<string, any>) =>
  action(EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES_FAILURE, null, null, error)

export const updateEpisodeTitle = (
  patientId: number,
  episodeId: string,
  title: string,
) =>
  action(EpisodeMetaTypes.UPDATE_EPISODE_TITLE, {
    patientId,
    episodeId,
    title,
  })
