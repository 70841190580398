import { combineReducers } from 'redux'

import adminEvents from './admin-events'
import appointmentTypes from './appointment-types'
import appointments from './appointments'
import drafts from './drafts'
import pacs from './pacs'
import providers from './providers'
import filterGroupSchedule from './schedule/filter-group-schedule'
import providerSchedule from './schedule/provider-schedule'

export default combineReducers({
  adminEvents,
  appointments,
  appointmentTypes,
  drafts,
  filterGroupSchedule,
  pacs,
  providerSchedule,
  providers,
})
