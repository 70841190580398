export enum MentionsTypes {
  SET = '@@mentions/SET',
  START_VIEWING = '@@mentions/START_VIEWING',
  STOP_VIEWING = '@@mentions/STOP_VIEWING',

  START_POLLING = '@@mentions/START_POLLING',
  STOP_POLLING = '@@mentions/STOP_POLLING',
}

export interface MentionsState {
  fetching: boolean
  map: Record<string, Mention>
  order: string[]
  mentionedMostRecentlyAt: number
  currentlyViewing: boolean
  lastViewedAt: number
}

export interface Mention {
  unread: boolean
  channel_id: string
  mentioned_by_id: number
  message: string
  create_at: number
  app_id: number
  id: string
}
