// @ts-strict-ignore
import { all, call, put, takeEvery, takeLeading } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import * as userManagementActions from 'app/redux/user-management/actions'
import { UserManagementTypes } from 'app/redux/user-management/types'
import { initEmeraldV2Client, initEmergencyRoomClient } from 'app/sagas/utils'

export function* addOrUpdatePractitioner({
  payload: { practitionerId, practitionerProfile },
}: ActionType<typeof userManagementActions.addOrUpdatePractitioner>) {
  try {
    const er = yield* call(initEmergencyRoomClient)

    const practitioner = yield* call(
      er.updatePractitioner,
      Number(practitionerId),
      practitionerProfile,
    )

    yield* put(
      userManagementActions.addOrUpdatePractitionerSuccess(
        practitionerId,
        practitioner,
      ),
    )
  } catch (error) {
    yield* put(
      userManagementActions.addOrUpdatePractitionerFailure(
        practitionerId,
        error,
      ),
    )
  }
}

const practitionerRequestMap: Record<string, boolean> = {}

export function* getPractitioner({
  payload: { practitionerId },
}: ActionType<typeof userManagementActions.getPractitioner>) {
  if (!practitionerRequestMap[practitionerId]) {
    practitionerRequestMap[practitionerId] = true

    try {
      const er = yield* call(initEmergencyRoomClient)

      const practitioner = yield* call(
        er.getPractitioner,
        Number(practitionerId),
      )

      yield* put(
        userManagementActions.getPractitionerSuccess(
          practitionerId,
          practitioner,
        ),
      )
      return practitioner
    } catch (error) {
      yield* put(
        userManagementActions.getPractitionerFailure(practitionerId, error),
      )
    } finally {
      delete practitionerRequestMap[practitionerId]
    }
  }
}

export function* getLicenses({
  payload: { practitionerId },
}: ActionType<typeof userManagementActions.getLicenses>) {
  try {
    const er = yield* call(initEmergencyRoomClient)

    const licenses = yield* call(er.getLicenses, Number(practitionerId))
    yield* put(
      userManagementActions.getLicensesSuccess(practitionerId, licenses),
    )
  } catch (error) {
    yield* put(userManagementActions.getLicensesFailure(practitionerId, error))
  }
}

export function* createLicense({
  payload: { practitionerId, license },
}: ActionType<typeof userManagementActions.createLicense>) {
  try {
    const er = yield* call(initEmergencyRoomClient)

    const newLicense = yield* call(
      er.createLicense,
      Number(practitionerId),
      license,
    )

    yield* put(
      userManagementActions.crudLicenseSuccess(
        practitionerId,
        newLicense.id,
        newLicense,
      ),
    )
  } catch (error) {
    yield* put(userManagementActions.crudLicenseFailure(practitionerId, error))
  }
}

export function* updateLicense({
  payload: { practitionerId, licenseId, license },
}: ActionType<typeof userManagementActions.updateLicense>) {
  try {
    const er = yield* call(initEmergencyRoomClient)

    const updatedLicense = yield* call(
      er.updateLicense,
      Number(practitionerId),
      licenseId,
      license,
    )
    yield* put(
      userManagementActions.crudLicenseSuccess(
        practitionerId,
        licenseId,
        updatedLicense,
      ),
    )
  } catch (error) {
    yield* put(userManagementActions.crudLicenseFailure(practitionerId, error))
  }
}

export function* deleteLicense({
  payload: { practitionerId, licenseId },
}: ActionType<typeof userManagementActions.deleteLicense>) {
  try {
    const er = yield* call(initEmergencyRoomClient)

    yield* call(er.deleteLicense, Number(practitionerId), licenseId)
    yield* put(
      userManagementActions.crudLicenseSuccess(practitionerId, licenseId),
    )
  } catch (error) {
    yield* put(userManagementActions.crudLicenseFailure(practitionerId, error))
  }
}

export function* getSpecializations() {
  const emergencyRoom = yield* call(initEmergencyRoomClient)
  try {
    const specializations = yield* call(
      emergencyRoom.getPractitionerSpecializations,
    )
    yield* put(userManagementActions.getSpecializationsSuccess(specializations))
  } catch (error) {
    yield* put(userManagementActions.getSpecializationsFailure(error))
  }
}

export function* getEmrId({
  payload: { practitionerId, email },
}: ActionType<typeof userManagementActions.getEmrId>) {
  try {
    const emerald = yield* call(initEmeraldV2Client)
    const mapping = yield* call(emerald.getPractitionerMapping, practitionerId)

    if (mapping?.provider_id) {
      yield* put(
        userManagementActions.getEmrIdSuccess(
          practitionerId,
          mapping.provider_id,
        ),
      )
      return
    }
  } catch (error) {
    if (error.status !== 404 && error.code !== 404) {
      yield* put(userManagementActions.getEmrIdFailure(practitionerId, error))
      return
    }
  }

  // if no mapping found, try to create the mapping
  yield* call(updateEmrMapping, { practitionerId, email })
}

export function* updateEmrMapping({
  practitionerId,
  email,
}: {
  practitionerId: string
  email: string
}) {
  try {
    const emerald = yield* call(initEmeraldV2Client)
    const mapping = yield* call(
      emerald.updatePractitionerMapping,
      practitionerId,
      email,
    )

    if (mapping?.provider_id) {
      yield* put(
        userManagementActions.getEmrIdSuccess(
          practitionerId,
          mapping.provider_id,
        ),
      )
    } else {
      yield* put(
        userManagementActions.getEmrIdFailure(
          practitionerId,
          new Error('Mapping unsuccessful'),
        ),
      )
    }
  } catch (error) {
    yield* put(userManagementActions.getEmrIdFailure(practitionerId, error))
  }
}

export default function* userManagementSagas() {
  yield* all([
    takeEvery(
      UserManagementTypes.ADD_OR_UPDATE_PRACTITIONER,
      addOrUpdatePractitioner,
    ),

    takeEvery(UserManagementTypes.GET_PRACTITIONER, getPractitioner),

    takeEvery(UserManagementTypes.GET_LICENSES, getLicenses),

    takeEvery(UserManagementTypes.CREATE_LICENSE, createLicense),
    takeEvery(UserManagementTypes.UPDATE_LICENSE, updateLicense),
    takeEvery(UserManagementTypes.DELETE_LICENSE, deleteLicense),
    takeLeading(UserManagementTypes.GET_SPECIALIZATIONS, getSpecializations),
    takeLeading(UserManagementTypes.GET_EMR_ID, getEmrId),
  ])
}
