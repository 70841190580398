import { type Config, Region } from './model'

// host -> config mapping that will get merged with the config below
const hostOverrides: Record<string, Partial<Config>> = {
  'care-platform-sunlife.us.dialoguecorp.com': {
    AUTH0_CLIENT_ID: 'z3gkd7wVJKVCzkf35Wk8NJLV6dxAQGqd',
  },
  'care-platform-sunlife-demo.us.dialoguecorp.com': {
    AUTH0_CLIENT_ID: 'JCjcdxdxHxtfU15ftEb2YhCRQUBPzacx',
  },
  'care-platform-sunlife-integ.us.dialoguecorp.com': {
    AUTH0_CLIENT_ID: 'mA9QtJk3jDktHhUkhJIzytwn9cyuuivU',
  },
}

export const prodUs: Config = {
  REGIONS: [Region.US],
  REGION_LANGUAGES: ['EN'],
  APP_SCHEME: 'https://care-platform.us.dialoguecorp.com/#/',
  ASSISTANT_URL: 'wss://assistant.us.dialoguecorp.com',
  COREDATA_MM_UID: 'ecaj7jqquiyy5fb1gzp6pwx3ze',
  DATADOG_RELEASE_ENV: 'prod-us',
  EMERGENCY_ROOM_URL: 'https://coredata.us.dialoguecorp.com/v1',
  MATTERMOST_API_URL: 'https://messaging.us.dialoguecorp.com',
  MATTERMOST_SOCKET_URL:
    'wss://messaging.us.dialoguecorp.com:443/api/v4/websocket?dialogue_jwt={accessToken}',
  MULTIPASS_DOMAIN: 'https://multipass.us.dialoguecorp.com',
  MESSAGE_RETRACTION_URL: 'https://message-retraction.us.dialoguecorp.com',
  OPENTOK_API_KEY: '47746711',
  RASA_INTENTS_URL:
    'https://assets.us.dialoguecorp.com/beautiful-mind-intents-prod/intents-export.json',
  SCRIBE_DOMAIN: 'https://scribe.us.dialoguecorp.com',
  TELEPHONE_SERVICE_URL: 'https://telephone.us.dialoguecorp.com',
  TEMPLATES_URL:
    'https://s3.ca-central-1.amazonaws.com/assets.dialogue.co/templates/templates.json', // TODO: use a CDN endpoint that is env based
  USHER_DOMAIN: 'https://usher.us.dialoguecorp.com',
  WALDO_ENDPOINT: 'https://waldo.us.dialoguecorp.com',
  TOKTOK_URL: 'https://toktok.us.dialoguecorp.com',
  EMERALD_API_URL: null,
  EMR_LINK_RECORD_CONFLICT_RESOLUTION_DOCUMENTATION_URL:
    'https://www.notion.so/godialogue/Linking-CP-profile-with-IH-charts-14f44dceafcc49129b2993da4952a86d',
  SNOWPLOW_APP_ID: 'careplatform',
  SNOWPLOW_APP_STAGE: 'prod-us',
  SNOWPLOW_COLLECTION_REL_URL: 'https://lawnmower.us.dialoguecorp.com',
  REQUEST_ACCESS_CHANNEL: null,
  REQUEST_ACCESS_CHANNEL_URL: null,
  PROFILE_MENU_SUBMIT_RESOURCE: null,
  PROFILE_MENU_REPORT_ISSUE: null,
  PROFILE_MENU_CARE_TEAM_NEWSLETTER: null,
  PROFILE_MENU_WFM_REQUEST: null,
  PROFILE_MENUS_LUNCH_SCHEDULE: null,
  RECAPTCHA_SITE_KEY: '6Lcn5e8ZAAAAAKFIIr-ukXarfuts3H3EvUyYEpXE', // same as prod-ca
  AUTH0_CLIENT_ID: 'CHhCD7aLLyIrYXqohUOpdGeMNyb0GDmp',
  AUTH0_AUDIENCE: 'https://api.dialoguecorp.com',
  AUTH0_ENDPOINT: 'auth.us.dialoguecorp.com',
  LOGOUT_CALLBACK_URL: 'https://assets.us.dialoguecorp.com/auth/logout.html',
  LAUNCHDARKLY_ID: '5b3bc20b8b34c314e516ff87', // same as prod-ca
  CMS_URL: 'https://cms.us.dialoguecorp.com',
  ECOUCH_URL: 'https://ecouch.us.dialoguecorp.com',
  NOTEPAD_URL: 'https://notepad.us.dialoguecorp.com',
  STATUSPAGE_COMPONENT_GROUP_ID: 'x069138p86r2',
  FULLCALENDAR_LICENSE: '0571726129-fcs-1680018778', // same as prod-ca
  PDF_APRYSE_LICENSE:
    'Dialogue Health Technologies Inc :PWS:Dialogue 2024::B+2:A13AD17887166CAFDD04F556B8BFC399F86CAE578B400C9198E7F6AE4E5F26BD',
  DOCUMENT_CENTER_URL: 'https://document-center.us.dialoguecorp.com',
  TIMEKEEPER_URL: null,
  SPRIG_ENV_ID: 'Z7eH3dJWyYWH',
  ...hostOverrides[window.location.hostname],
}
