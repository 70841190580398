import { put, all, takeLatest, call } from 'typed-redux-saga/macro'

import { schedulingGroupsMappingActions } from 'app/redux/scheduling/groups-mapping'

import { initEmeraldV2Client } from '../utils'

export function* getSchedulingGroupsMapping() {
  try {
    const emerald = yield* call(initEmeraldV2Client)
    const { data } = yield* call(emerald.getScheduleGroups)
    yield* put(schedulingGroupsMappingActions.getSuccess(data))
  } catch (e) {
    yield* put(schedulingGroupsMappingActions.getFailure(e))
  }
}

export default function* schedulingGroupsSagas() {
  yield* all([
    takeLatest(schedulingGroupsMappingActions.get, getSchedulingGroupsMapping),
  ])
}
