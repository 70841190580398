import {
  type Context,
  type ReactNode,
  createContext,
  useCallback,
  useState,
} from 'react'

interface ContextValues {
  activeDrawer: string | null
  setActiveDrawer: (selectedDrawer: string) => void
  closeDrawer: () => void
}

const placeholderContext: ContextValues = {
  activeDrawer: null,
  setActiveDrawer: (selectedDrawer: string) => {},
  closeDrawer: () => {},
}

export const DrawerManagementContext: Context<ContextValues> =
  createContext(placeholderContext)

export const DrawerManagementProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [activeDrawer, setActiveDrawer] = useState<string | null>(null)

  const handleSetActiveDrawer = useCallback(
    (selectedDrawer: string) => {
      setActiveDrawer(selectedDrawer)
    },
    [setActiveDrawer],
  )

  const handleCloseDrawer = useCallback(() => {
    setActiveDrawer(null)
  }, [setActiveDrawer])

  return (
    <DrawerManagementContext.Provider
      value={{
        activeDrawer,
        setActiveDrawer: handleSetActiveDrawer,
        closeDrawer: handleCloseDrawer,
      }}
    >
      {children}
    </DrawerManagementContext.Provider>
  )
}
