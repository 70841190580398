// @ts-strict-ignore
import type { ScribeTypes } from '@dialogue/services'
import { createAction, createReducer, createSelector } from '@reduxjs/toolkit'
import differenceWith from 'lodash/differenceWith'

import type { ReduxState } from 'app/redux'

import { selectEligibleServices } from '../patients/selectors'

import type { MembersServicesState, MemberServiceListItemState } from './types'

export const INITIAL_STATE: MembersServicesState = {
  services: [],
  error: null,
  fetching: false,
}

export const requestServices = createAction('@@services/REQUEST_SERVICES')

export const requestServicesFailure = createAction<Error>(
  '@@services/REQUEST_SERVICES_FAILURE',
)

export const requestServicesSuccess = createAction<
  ScribeTypes.V2.ServiceDefinition[]
>('@@services/REQUEST_SERVICES_SUCCESS')

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(requestServices, (state) => {
    state.fetching = true
    state.error = null
  })

  builder.addCase(requestServicesFailure, (state, action) => {
    state.error = action.payload
    state.fetching = false
  })

  builder.addCase(requestServicesSuccess, (state, action) => {
    state.services = action.payload
    state.fetching = false
    state.error = null
  })
})

export const selectMembersServices = (state: ReduxState) =>
  state.membersServices?.services

export const selectMembersServicesIds = createSelector(
  selectMembersServices,
  (membersServices) => {
    if (!membersServices) return []
    return membersServices.map((s) => s.id)
  },
)

export const selectUnavailableServices = createSelector(
  (state: ReduxState, patientId: string | number, brandId: string) =>
    selectEligibleServices(state, patientId)?.[brandId],
  selectMembersServicesIds,
  (eligibleServices, availableServicesIds = []) => {
    if (eligibleServices === undefined) {
      return availableServicesIds
    }

    const eligibleIds = Object.keys(eligibleServices)

    const unavailableServicesIds = differenceWith(
      availableServicesIds,
      eligibleIds,
    )
    return unavailableServicesIds
  },
)

export const selectEligibleProgramsAndServices = createSelector(
  (state: ReduxState, patientId: string | number, brandId: string) =>
    selectEligibleServices(state, patientId)?.[brandId],
  (eligibleServices = {}) => {
    const programsAndServices = Object.keys(eligibleServices).reduce(
      (acc, name) => {
        const { [`appt_type_${name}`]: program } = eligibleServices[name] || {}
        if (program) {
          acc.push(`${program}:${name}`)
          acc.push(program)
        }
        return acc
      },
      [] as string[],
    )
    return Array.from(new Set(programsAndServices))
  },
)

export const selectEligibleServicesAndOptions = createSelector(
  (state: ReduxState, patientId: string | number, brandId: string) =>
    selectEligibleServices(state, patientId)?.[brandId],
  (brandServices: ScribeTypes.V2.EligibleServices = {}) => {
    if (brandServices === undefined) {
      return []
    }

    const membersServices: MemberServiceListItemState[] = Object.keys(
      brandServices,
    ).map((serviceId) => ({
      serviceId,
      options: brandServices[serviceId],
    }))

    return membersServices
  },
)
