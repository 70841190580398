import { createAction, createReducer } from '@reduxjs/toolkit'

interface ProviderAvailability {
  fetching: boolean
  error: Error | null
  hasAvailabilities: boolean | null
}

export const INITIAL_STATE: ProviderAvailability = {
  fetching: false,
  error: null,
  hasAvailabilities: null,
}

export const getPractitionerAvailabilities = createAction<{
  practitionerId: number
  appointmentType: string
  provinceCode: string
  languageCode: string
  startDate: string
  lookaheadHours: number
}>('@@availabilities/GET_PRACTITIONER_AVAILABILITIES')

export const getPractitionerAvailabilitiesSuccess = createAction<{
  hasAvailabilities: boolean
}>('@@availabilities/GET_PRACTITIONER_AVAILABILITIES_SUCCESS')

export const getPractitionerAvailabilitiesFailure = createAction<{
  error: Error
}>('@@availabilities/GET_PRACTITIONER_AVAILABILITIES_FAILURE')

export const clearPractitionerAvailabilities = createAction<undefined>(
  '@@availabilities/CLEAR_PRACTITIONER_AVAILABILITIES',
)

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(getPractitionerAvailabilities, (state) => {
    state.fetching = true
    state.hasAvailabilities = null
    state.error = null
  })

  builder.addCase(getPractitionerAvailabilitiesSuccess, (state, action) => {
    state.fetching = false
    state.hasAvailabilities = action.payload.hasAvailabilities
  })

  builder.addCase(getPractitionerAvailabilitiesFailure, (state, action) => {
    state.fetching = false
    state.error = action.payload.error
  })

  builder.addCase(clearPractitionerAvailabilities, (state) => {
    state.fetching = false
    state.hasAvailabilities = null
    state.error = null
  })
})
