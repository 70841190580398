import { all, call, put, takeLatest } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import {
  patientSearchActions,
  PatientSearchTypes,
} from 'app/redux/patient-search'
import { initScribeV2Client, SEARCH_LIMIT } from 'app/sagas/utils'

export function* searchPatient({
  payload,
}: ActionType<typeof patientSearchActions.searchPatient>) {
  const { query } = payload

  try {
    const scribeV2 = yield* call(initScribeV2Client)
    const { data: results } = yield* call(scribeV2.getUsers, {
      search: query,
      limit: SEARCH_LIMIT,
    })
    yield* put(patientSearchActions.searchPatientSuccess(results))
  } catch (error) {
    yield* put(patientSearchActions.searchPatientError(error))
  }
}

export default function* patientSearchSagas() {
  yield* all([takeLatest(PatientSearchTypes.SEARCH_PATIENT, searchPatient)])
}
