import type { UsherTypes } from '@dialogue/services'
import type {
  ChannelMembership,
  ServerChannel,
} from '@mattermost/types/channels'
import type { FileInfo } from '@mattermost/types/files'
import type {
  Post as MMPost,
  PostList as MMPostList,
  PostMetadata,
} from '@mattermost/types/posts'
import type { UserProfile } from '@mattermost/types/users'

export enum ChatTypes {
  // generic chat events
  LOAD_USER_SUCCESS = '@@chat/LOAD_USER_SUCCESS',
  LOAD_MEMBERSHIPS_SUCCESS = '@@chat/LOAD_MEMBERSHIPS_SUCCESS',
  GOT_STATUSES = '@@chat/GOT_STATUSES',
  JOIN_CHANNEL_SUCCESS = '@@chat/JOIN_CHANNEL_SUCCESS',
  LEAVE_CHANNEL_SUCCESS = '@@chat/LEAVE_CHANNEL_SUCCESS',
  CLEAR_ALL = '@@chat/CLEAR_ALL',

  // channel specific events
  INIT_CHANNEL = '@@chat/INIT_CHANNEL',
  INIT_CHANNEL_SUCCESS = '@@chat/INIT_CHANNEL_SUCCESS',
  INIT_CHANNEL_FAILURE = '@@chat/INIT_CHANNEL_FAILURE',
  LOAD_CHANNEL_SUCCESS = '@@chat/LOAD_CHANNEL_SUCCESS',
  LOAD_POSTS_SUCCESS = '@@chat/LOAD_POSTS_SUCCESS',
  UPDATE_PATIENT_LAST_VIEWED_AT = '@@chat/UPDATE_PATIENT_LAST_VIEWED_AT',
  ADD_POST = '@@chat/ADD_POST',
  CLEAR_CHANNEL = '@@chat/CLEAR_CHANNEL',
  UPDATE_POST = '@@chat/UPDATE_POST',
  USER_TYPING = '@@chat/USER_TYPING',
  SEND_TEXT_MESSAGE = '@@chat/SEND_TEXT_MESSAGE',
  RETRY_MESSAGE = '@@chat/RETRY_MESSAGE',
  UPLOAD_IH_FILE = '@@chat/UPLOAD_IH_FILE',
  SEND_CHARGE = '@@chat/SEND_CHARGE',
  RETRACT_MESSAGE = '@@chat/RETRACT_MESSAGE',
  UPDATE_CHANNEL_VIEWED = '@@chat/UPDATE_CHANNEL_VIEWED',

  SEND_FILE = '@@chat/SEND_FILE',
  UPLOAD_FILE = '@@chat/UPLOAD_FILE',
  UPLOAD_FILE_SUCCESS = '@@chat/UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE = '@@chat/UPLOAD_FILE_FAILURE',
  CANCEL_UPLOADED_FILE = '@@chat/CANCEL_UPLOADED_FILE',
  RECEIVED_FILES_METADATA = '@@chat/RECEIVED_FILES_METADATA',
  // websocket events
  SEND_USER_TYPING = '@@chat/SEND_USER_TYPING',
  WEBSOCKET_EVENT_RECEIVED = '@@chat/WEBSOCKET_EVENT_RECEIVED',
  WEBSOCKET_RESPONSE_RECEIVED = '@@chat/WEBSOCKET_RESPONSE_RECEIVED',
}

export enum UserPostType {
  DEFAULT = '',
  INTERNAL = 'custom_dialogue_system',
  /**
   * @deprecated will be removed once it's completely deprecated
   */
  LEGACY_INTERNAL = 'dialogue_system',
}

export interface Post
  extends Omit<
    MMPost,
    | 'type'
    | 'edit_at'
    | 'is_pinned'
    | 'hashtags'
    | 'localId'
    | 'reply_count'
    | 'metadata'
    | 'create_at'
    | 'update_at'
    | 'delete_at'
    | 'edit_at'
    | 'root_id'
    | 'pending_post_id'
    | 'original_id'
    | 'props'
  > {
  // Introduced in mmv4
  hashtags?: string
  reply_count?: number
  metadata?: PostMetadata
  is_pinned?: boolean
  // Optional in mmv3, but required in mmv4
  parent_id?: string
  create_at?: number
  update_at?: number
  delete_at?: number
  edit_at?: number
  root_id?: string
  pending_post_id?: string
  original_id?: string
  props?: Record<string, any>
  // mmv4 types does not include many of the message types we use
  type?: string
  // Used by the CP but not in mm types
  isCoreData?: boolean
  retryForId?: string
  localId?: string
  isLocal?: boolean
}

export interface PostList extends Omit<MMPostList, 'posts'> {
  posts: Record<string, Post>
}

export interface UploadedFile {
  id?: string
  type: string
  name: string
  localUrl?: string
  additionalPostProps?: Record<string, any>
}

// FileInfo with public link
export type FullFileInfo = FileInfo & { link?: string; localUrl?: string }

export interface ChatChannel {
  order: string[]
  posts: Record<string, Post>
  userTyping?: string | null
  channel?: ServerChannel
  membership?: ChannelMembership
  members?: UserProfile[]
  loading: boolean
  error: Error | null
  fileError: Error | null
  uploadedFile?: UploadedFile
  uploadingFile?: boolean
  fileMetadata: Record<string, FullFileInfo>
  patientMmId?: string
  patientAppId?: string
  patientLastViewedAt?: number
}

export type UserStatuses = Record<string, 'online'>

export interface ChatState {
  channels: Record<string, ChatChannel>
  practitioners: UsherTypes.Physician[]
  user: UserProfile | null
  memberships: Record<string, ChannelMembership>
  statuses: UserStatuses
  sessionId: string
  failedPosts: Record<string, Post>
}

export type WSChannelEventType =
  | 'channel_deleted'
  | 'channel_viewed'
  | 'direct_added'
export type WSPostEventType = 'posted' | 'post_edited' | 'post_deleted'
export type WSSystemEventType =
  | 'preference_changed'
  | 'ephemeral_message'
  | 'status_change'
  | 'hello'
  | 'webrtc'
  | 'system_ephemeral'
export type WSUserEventType =
  | 'new_user'
  | 'leave_team'
  | 'user_added'
  | 'user_updated'
  | 'user_removed'
  | 'typing'
export type WSEventType =
  | WSChannelEventType
  | WSPostEventType
  | WSSystemEventType
  | WSUserEventType

export interface WSEvent {
  event: WSEventType
  data: WSMetadata
  broadcast: WSEventBroadcast
  seq: number
}

export interface WSResponse {
  status: string
  seq_reply: number
  data?: WSMetadata | Record<string, any>
  error?: Record<string, any> // Type if we ever need to use it
}

export type WSMessage = WSEvent | WSResponse

export function isWSEvent(message: WSMessage): message is WSEvent {
  return (message as WSEvent).event !== undefined
}

interface WSEventBroadcast {
  channel_id: string
  team_id: string
  user_id: string
  omit_users: Record<string, boolean> | null
}

interface WSMetadata {
  channel_id?: string
  channel_display_name?: string
  channel_type?: string
  post?: string
  sender_name?: string
  server_version?: string
  status?: string
  team_id?: string
  user_id?: string
}
