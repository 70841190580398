import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

type Fields = Record<string, string | number>

export interface AutofillArguments {
  patientId: number
  provinceCode?: string
  licenseId?: string
}

interface AutofillState {
  fetching: boolean
  error: Error | null
  data: Record<string, string | number>
  arguments: Partial<AutofillArguments>
}

export const INITIAL_STATE: AutofillState = {
  fetching: false,
  error: null,
  data: {},
  arguments: {},
}

export const { actions: autofillActions, reducer } = createSlice({
  name: '@@documents/autofill',
  initialState: INITIAL_STATE,
  reducers: {
    init: (state, action: PayloadAction<AutofillArguments>) => {
      state.error = null
      state.fetching = true
      state.arguments = action.payload
    },
    error: (state, action: PayloadAction<Error>) => {
      state.fetching = false
      state.error = action.payload
    },
    refresh: (state, action: PayloadAction<Partial<AutofillArguments>>) => {
      state.arguments = action.payload
    },
    receive: (state, action: PayloadAction<Fields>) => {
      state.fetching = false
      state.data = action.payload
    },
    reset: (state) => {
      state.data = {}
      state.arguments = {}
    },
  },
})

export default reducer
