import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro'

import { AUTOFILLING_FIELDS } from 'app/containers/documents/models'
import i18n from 'app/i18n'
import { selectUserId } from 'app/redux/authentification/selectors'
import {
  type AutofillArguments,
  autofillActions,
} from 'app/redux/documents/autofill'
import { selectDocumentFields } from 'app/redux/documents/selectors'
import { selectLocations } from 'app/redux/locations'
import { patientsActions } from 'app/redux/patients'
import { selectPatientProfile } from 'app/redux/patients/selectors'
import * as userManagementActions from 'app/redux/user-management/actions'
import { selectOwnPractitionerLicenses } from 'app/redux/user-management/reducer'
import { getLocations } from 'app/sagas/locations'
import { requestProfile } from 'app/sagas/patients'
import { getLicenses, getPractitioner } from 'app/sagas/user-management'

export function* checkBaseAutofillingData(patientId: string) {
  const patientProfile = yield* select(selectPatientProfile, patientId)
  const practitionerId = String(yield* select(selectUserId))
  const practitionerLicenses = yield* select(selectOwnPractitionerLicenses)
  const locations = yield* select(selectLocations)

  if (!patientProfile?.id) {
    yield* call(
      requestProfile,
      patientsActions.requestProfile(Number(patientId), true),
    )
  }

  if (!practitionerLicenses) {
    yield* call(
      getPractitioner,
      userManagementActions.getPractitioner(practitionerId),
    )
    yield* call(getLicenses, userManagementActions.getLicenses(practitionerId))
  }

  if (!locations?.length) {
    yield* call(getLocations)
  }
}

export function* getBaseAutofillArguments(
  payload: ReturnType<typeof autofillActions.init>['payload'],
) {
  const patientProfile = yield* select(selectPatientProfile, payload.patientId)
  const licenses = yield* select(selectOwnPractitionerLicenses)

  let licenseId: string | undefined
  const licensesArray = Object.values(licenses || {})
  if (licensesArray.length === 1) licenseId = String(licensesArray[0].id)
  else if (licensesArray.length > 1) {
    const license = licensesArray.find(
      ({ admin_area_iso_code }) =>
        admin_area_iso_code === patientProfile?.admin_area_iso_code,
    )
    if (license) licenseId = String(license.id)
  }

  const baseArgs: Partial<AutofillArguments> = {
    ...payload,
    licenseId,
    provinceCode: patientProfile?.admin_area_iso_code,
  }

  return baseArgs
}

export function* autofillInit({
  payload,
}: ReturnType<typeof autofillActions.init>) {
  const { patientId } = payload
  try {
    yield* call(checkBaseAutofillingData, String(patientId))
    const baseArguments = yield* call(getBaseAutofillArguments, payload)
    yield* put(autofillActions.refresh(baseArguments))
    yield* call(autofillRefresh, autofillActions.refresh(baseArguments))
  } catch (e) {
    yield* put(autofillActions.error(e))
    yield* call(notification.error, {
      message: i18n.t('documents.autofillPopover.errors.init', {
        description: e.message,
      }),
    })
  }
}

export function* autofillRefresh({
  payload,
}: ReturnType<typeof autofillActions.refresh>) {
  try {
    const autofillData = yield* select(
      selectDocumentFields,
      AUTOFILLING_FIELDS,
      payload,
    )
    yield* put(autofillActions.receive(autofillData))
  } catch (e) {
    yield* put(autofillActions.error(e))
  }
}

export default function* autofillSagas() {
  yield* all([
    takeLatest(autofillActions.init, autofillInit),
    takeLatest(autofillActions.refresh, autofillRefresh),
  ])
}
