import type { MultipassTypes } from '@dialogue/services'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
import { type patientsActions, PatientsTypes } from 'app/redux/patients'

export interface MultipassUserState {
  patientId?: string | number
  fetching?: boolean
  error?: Error | null
  pinAuthenticated?: boolean
  authenticateFetching?: boolean
  authenticateError?: Error | null
  challenges?: null | Array<
    MultipassTypes.GenericChallenge | MultipassTypes.PinChallenge
  >
}

export interface MultipassState {
  [key: string]: MultipassUserState
}

export const selectMultipassStateForProfile = (
  state: ReduxState,
  patientId: number | string,
) => state.multipass[patientId]

export const selectHasPinChallenge = (
  state: ReduxState,
  patientId: number | string,
) =>
  selectMultipassStateForProfile(state, patientId)?.challenges?.some(
    (challenge) => challenge.url === '/authenticate/pin',
  )

export const INITIAL_STATE: MultipassState = {}

export const { actions: multipassActions, reducer } = createSlice({
  name: '@@multipass',
  initialState: INITIAL_STATE,
  reducers: {
    updateSuccess(
      state,
      {
        payload: { patientId },
      }: PayloadAction<{
        patientId: string | number
      }>,
    ) {
      state[patientId] = {
        ...state[patientId],
        fetching: false,
        error: null,
      }
    },
    updateFailure(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        error: Error | null
      }>,
    ) {
      const { patientId, error } = payload

      state[patientId] = {
        ...state[patientId],
        fetching: false,
        error: error,
      }
    },
    authenticatePinRequest(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        email: string
        pin: string
      }>,
    ) {
      const { patientId } = payload

      state[patientId] = {
        ...state[patientId],
        authenticateFetching: true,
        pinAuthenticated: false,
        authenticateError: null,
      }
    },
    authenticatePinSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
      }>,
    ) {
      const { patientId } = payload

      state[patientId] = {
        ...state[patientId],
        authenticateFetching: false,
        pinAuthenticated: true,
        authenticateError: null,
      }
    },
    authenticatePinFailure(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        error: Error
      }>,
    ) {
      const { patientId, error } = payload

      state[patientId] = {
        ...state[patientId],
        authenticateFetching: false,
        pinAuthenticated: false,
        authenticateError: error,
      }
    },
    getChallengesRequest(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        auth_id: string
      }>,
    ) {
      const { patientId } = payload

      state[patientId] = {
        ...state[patientId],
        challenges: null,
      }
    },
    getChallengesSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
        challenges: Array<
          MultipassTypes.GenericChallenge | MultipassTypes.PinChallenge
        >
      }>,
    ) {
      const { patientId, challenges } = payload

      state[patientId] = {
        ...state[patientId],
        challenges,
      }
    },
    getChallengesFailure(
      state,
      {
        payload,
      }: PayloadAction<{
        patientId: string | number
      }>,
    ) {
      const { patientId } = payload

      state[patientId] = {
        ...state[patientId],
        challenges: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      PatientsTypes.UPDATE_PROFILE_REQUEST,
      (
        state,
        action: ReturnType<typeof patientsActions.updateProfileRequest>,
      ) => {
        const {
          payload: { patientId },
        } = action

        state[patientId] = {
          ...state[patientId],
          patientId,
          fetching: true,
          error: null,
        }
      },
    )
  },
})

export default reducer
