// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { RasaIntentsActionType, type RasaIntentsState } from './types'

export type RasaIntentsAction = ActionType<typeof actions>

export { actions as rasaIntentsAction }
export * from './types'

export const INITIAL_STATE: RasaIntentsState = {
  intents: null,
  fetching: false,
  error: null,
}

export const reducer: Reducer<typeof INITIAL_STATE, RasaIntentsAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case RasaIntentsActionType.GET_RASA_INTENTS:
        draft.fetching = true
        draft.error = null
        break
      case RasaIntentsActionType.GET_RASA_INTENTS_SUCCESS:
        draft.intents = action.payload.intents
        draft.fetching = false
        break
      case RasaIntentsActionType.GET_RASA_INTENTS_ERROR:
        draft.error = action.payload.error
        draft.fetching = false
        break
    }
  })

export default reducer
