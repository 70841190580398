import api from 'apisauce'
import { call, put, all, takeLatest } from 'typed-redux-saga/macro'

import { statuspageActions } from 'app/redux/statuspage'
import type {
  StatusInfo,
  PageInfo,
  ComponentInfo,
  Incident,
} from 'app/redux/statuspage/types'

const statuspageApi = api.create({
  baseURL: 'https://status.dialogue.co/api/v2',
})

interface StatuspageSummary {
  status: StatusInfo
  page: PageInfo
  components: ComponentInfo[]
  incidents: Incident[]
}

export const getStatus = async () => {
  const res = await statuspageApi.get<StatuspageSummary>('/summary.json')

  if (!res.ok) {
    throw new Error(`failed to load status page summary (code: ${res.status})`)
  }

  return res.data!
}

export function* fetchStatus() {
  try {
    const data = yield* call(getStatus)
    yield* put(statuspageActions.fetchStatusSuccess(data))
  } catch (e) {
    yield* put(statuspageActions.fetchStatusError(e))
  }
}

export default function* statuspageSagas() {
  yield* all([takeLatest(statuspageActions.fetchStatus, fetchStatus)])
}
