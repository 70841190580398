import { type ReactNode, useEffect } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { updateRedirectUrl } from 'app/redux/authentification'

/**
 * Wrapper around react-router's Route, which
 * will ensure that the user is logged in before
 * rendering the route.
 *
 * This route will wait for the initial auth refresh to complete,
 * and will then render the route if the user is logged in.
 * If the user is _not_ logged in, then the user is redirected to the
 * login page.
 */
export const AuthGuard = ({ children }: { children?: ReactNode }) => {
  const dispatch = useAppDispatch()
  const hasRenewed = useAppSelector(
    (state) => state.authentification.isRenewing === false,
  )
  const isAuthenticated = useAppSelector(
    (state) => state.authentification.isAuthenticated,
  )
  const location = useLocation()

  useEffect(() => {
    if (hasRenewed && !isAuthenticated) {
      // persist redirect URL for after login
      // FIXME: stop passing hash once redirect occurs
      // with react router
      dispatch(updateRedirectUrl(location.pathname))
    }
  }, [hasRenewed, isAuthenticated, location, dispatch])

  if (isAuthenticated) {
    return <>{children}</>
  }

  // don't navigate when token hasn't been refreshed yet
  if (!hasRenewed) {
    // FIXME: loading?
    return null
  }

  return <Navigate to={{ pathname: '/login' }} />
}
