import platformLib from 'platform'

import { version as packageJsonVersion } from '../../../package.json'

enum Target {
  NEXT = 'next',
  BETA = 'beta',
  MASTER = 'master',
}

const isElectron = !!window.electronApi?.isElectron

const appVersion = () =>
  // isElectron ensures window.electronApi is defined hence ! operator
  isElectron ? 'v' + window.electronApi!.getVersion() : 'v' + packageJsonVersion

/**
 * Parse out the application's build target from its version.
 * Refactor: use semver library e.g. https://runkit.com/jasonkuhrt/semver-lib-demo
 */
const parseTarget = (appVersion: string): Target =>
  appVersion.indexOf('-next.') !== -1
    ? Target.NEXT
    : appVersion.indexOf('-beta.') !== -1
      ? Target.BETA
      : Target.MASTER

export const deviceInfo = {
  getOsInfo: () => {
    if (!platformLib.os) return 'unknown'
    return `${platformLib.os.architecture}-${platformLib.os.family}-${platformLib.os.version}`
  },

  getVersion: () => appVersion(),

  isElectron: () => isElectron,

  getDevices: async () => {
    const devices = await navigator.mediaDevices?.enumerateDevices()
    return devices
      ? {
          audio: devices
            .filter((d) => d.kind === 'audioinput')
            .map((d) => d.toJSON()),
          video: devices
            .filter((d) => d.kind === 'videoinput')
            .map((d) => d.toJSON()),
        }
      : {}
  },

  getAppTarget: () => parseTarget(appVersion() || ''),
}

export default deviceInfo
