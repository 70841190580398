import { WriteAccess } from '@dialogue/document-center'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'app/hooks'
import {
  selectHasPermissions,
  selectUserId,
} from 'app/redux/authentification/selectors'
import {
  DOCCENTER_DELETE_MEMBER_DOC,
  DOCCENTER_UPDATE_MEMBER_DOC,
} from 'app/scopes'

export const useCanDeleteDocument = (
  writeAccess?: WriteAccess,
  creatorId?: number,
) => {
  const { t } = useTranslation()
  const activeUser = useAppSelector(selectUserId)

  const hasDeletePermission = useAppSelector((state) =>
    selectHasPermissions(state, [DOCCENTER_DELETE_MEMBER_DOC]),
  )

  if (
    writeAccess === WriteAccess.creator_only &&
    creatorId &&
    creatorId !== activeUser
  ) {
    return {
      isDeletable: false,
      isDeletableTooltip: t('documents.tooltips.documentEditableByCreator'),
    }
  }
  if (writeAccess === WriteAccess.locked && !hasDeletePermission) {
    return {
      isDeletable: false,
      isDeletableTooltip: t('documents.tooltips.documentLocked'),
    }
  }
  if (writeAccess !== WriteAccess.creator_only && !hasDeletePermission) {
    return {
      isDeletable: false,
      isDeletableTooltip: t('documents.tooltips.missingDeletePermission'),
    }
  }
  return {
    isDeletable: true,
    isDeletableTooltip: null,
  }
}

export const useCanEditDocument = (
  writeAccess?: WriteAccess,
  creatorId?: number,
) => {
  const { t } = useTranslation()
  const activeUser = useAppSelector(selectUserId)
  const hasUpdatePermission = useAppSelector((state) =>
    selectHasPermissions(state, [DOCCENTER_UPDATE_MEMBER_DOC]),
  )

  if (!hasUpdatePermission) {
    return {
      isEditable: false,
      isEditableTooltip: t('documents.tooltips.missingUpdatePermission'),
    }
  }
  if (
    writeAccess === WriteAccess.creator_only &&
    creatorId &&
    creatorId !== activeUser
  ) {
    return {
      isEditable: false,
      isEditableTooltip: t('documents.tooltips.documentEditableByCreator'),
    }
  }
  if (writeAccess === WriteAccess.locked) {
    return {
      isEditable: false,
      isEditableTooltip: t('documents.tooltips.documentLocked'),
    }
  }
  return {
    isEditable: true,
    isEditableTooltip: null,
  }
}
