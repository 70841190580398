import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ChangeEvent,
  type FC,
  type MouseEvent,
} from 'react'

import {
  ExportOutlined,
  FlagOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  IncomingFaxDocumentPriority,
  IncomingFaxDocumentStatus,
  type BodyFaxesLinkReceivedFaxToMember,
  type PatchIncomingFaxDocRequest,
  type PatchMemberDocRequest,
} from '@dialogue/document-center'
import {
  Button,
  Form,
  Input,
  Select,
  Tooltip,
  Typography,
  notification,
} from 'antd'
import { camelizeKeys, type Camelized } from 'humps'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { DatePicker } from 'app/components/ant-design'
import { EpisodePicker } from 'app/components/episode-picker'
import { MemberPicker } from 'app/components/member-picker'
import { PermissionsGuard } from 'app/components/permissions-guard'
import {
  RadioButonsVariants,
  RadioButtonsGroup,
} from 'app/components/radio-buttons-group'
import type { Owner } from 'app/components/tasks/task-owner-select'
import TasksView from 'app/components/tasks/tasks-view'
import { NoteDisplay } from 'app/containers/note-display'
import TasksCreateNew from 'app/containers/tasks/create-new'
import { OwnerField } from 'app/containers/tasks/owner-field'
import { useAppDispatch, useAppSelector, useVariation } from 'app/hooks'
import { filterSelectOptions, formatPhoneNumber } from 'app/lib/helpers'
import {
  useAssignMemberToFaxDocumentMutation,
  useUpdateIncomingFaxMutation,
} from 'app/redux/api/document-center/faxes'
import { useUpdateMemberDocumentMutation } from 'app/redux/api/document-center/member-documents'
import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'
import * as episodeMetaActions from 'app/redux/episode-meta/actions'
import {
  selectIssueTypes,
  selectPropertiesErrors,
} from 'app/redux/episode-meta/selectors'
import { ER_LIST_TASKS } from 'app/scopes'
import deviceInfo from 'app/services/device-info'
import { Flags } from 'app/services/feature-flags'
import routes from 'app/services/routes'

import { DetailLine, FormContainer, HeaderLine } from '../common'
import {
  convertUndefinedToNull,
  dateAndHoursFormat,
  dateFormat,
  disableFutureDates,
  documentTypeOptions,
  parseDocumentName,
} from '../helpers'

interface Props {
  document: ViewerIncomingFaxDocument
}

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

interface FormValues {
  shared?: Camelized<BodyFaxesLinkReceivedFaxToMember>
  fax?: Camelized<PatchIncomingFaxDocRequest>
  owner?: Owner
  document?: Camelized<PatchMemberDocRequest>
}

interface FormInputDocumentTitleProps {
  value?: string
  onChange?: (e: string) => void
}

export const FormInputDocumentTitle: FC<FormInputDocumentTitleProps> = ({
  value,
  onChange,
}) => {
  const [textAreaValue, setTextAreaState] = useState<string>()
  const [currentDocumentName, extension] = parseDocumentName(value || '')

  const onChangeTextArea = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaState(e.target.value)
    },
    [setTextAreaState],
  )

  const updateFileName = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      const newDocumentName = e.target.value.trim()
      if (newDocumentName && onChange) {
        if (newDocumentName !== currentDocumentName) {
          onChange(`${newDocumentName}.${extension}`)
        }
      } else {
        setTextAreaState(currentDocumentName)
      }
    },
    [onChange, setTextAreaState, extension, currentDocumentName],
  )

  return (
    <Input.TextArea
      defaultValue={currentDocumentName}
      value={textAreaValue}
      onChange={onChangeTextArea}
      onBlur={updateFileName}
      autoSize
      data-testid="document-name-input"
      size="large"
    />
  )
}

export const OldFaxDocumentPanel = ({ document }: Props) => {
  const {
    id: documentId,
    format,
    senderNumber,
    name,
    priority,
    memberDocument,
    receivedAt,
    status,
    assignedTo,
    teamIds,
  } = camelizeKeys<ViewerIncomingFaxDocument>(document)
  const {
    memberId,
    documentDate,
    type: docType,
    episodeId,
    postId,
    createdFromNoteId,
  } = memberDocument || {}

  const navigate = useNavigate()

  const [, documentExtension] = parseDocumentName(name)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [
    updateIncomingFax,
    { data: updatedFax, isSuccess, isError, isLoading },
  ] = useUpdateIncomingFaxMutation()
  const [assignMemberToFaxDocument] = useAssignMemberToFaxDocumentMutation()
  const [updateMemberDocument] = useUpdateMemberDocumentMutation()
  const issueTypes = useAppSelector(selectIssueTypes)
  const issueTypesErrors = useAppSelector(selectPropertiesErrors)

  useEffect(() => {
    if (!issueTypes && !issueTypesErrors) {
      dispatch(episodeMetaActions.requestProperties())
    }
  }, [issueTypes, issueTypesErrors, dispatch])

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: t('faxes.success.updating'),
      })
    }
  }, [isSuccess, t])

  useEffect(() => {
    if (isError) {
      notification.error({
        message: t('faxes.error.updating'),
      })
    }
  }, [isError, t])

  const [form] = Form.useForm()
  const memberIdFieldValue = Form.useWatch(['shared', 'memberId'], form)
  const episodeIdFieldValue = Form.useWatch(['document', 'episodeId'], form)
  const createdFromNoteIdFieldValue = Form.useWatch(
    ['document', 'createdFromNoteId'],
    form,
  )
  const hasEpisodeLink = memberIdFieldValue && episodeIdFieldValue
  const hasNoteLink = memberIdFieldValue && createdFromNoteIdFieldValue
  const docTypeValue = Form.useWatch(['document', 'type'], form)

  const isProcessed =
    status === IncomingFaxDocumentStatus.processed ||
    updatedFax?.data.status === IncomingFaxDocumentStatus.processed
  const processButtonDisabled =
    isProcessed || !name || !memberIdFieldValue || !docTypeValue

  const enableTaskSystem = useVariation(Flags.enableTaskSystem, true)

  const openNote = useCallback(() => {
    if (
      memberIdFieldValue &&
      episodeIdFieldValue &&
      createdFromNoteIdFieldValue
    ) {
      const noteRoute = routes.patientNote(
        memberIdFieldValue,
        episodeIdFieldValue,
        createdFromNoteIdFieldValue,
      )
      navigate(noteRoute)
    }
  }, [
    navigate,
    memberIdFieldValue,
    episodeIdFieldValue,
    createdFromNoteIdFieldValue,
  ])

  const episodeRoute = postId
    ? routes.channelPost(memberIdFieldValue, episodeIdFieldValue, postId)
    : routes.channel(memberIdFieldValue, episodeIdFieldValue)

  const stopPropagation = useCallback(
    (e: MouseEvent<HTMLElement>) => e.stopPropagation(),
    [],
  )

  const handleOnInfoChange = useCallback(
    (changedFormValues: FormValues) => {
      if (
        changedFormValues.shared &&
        changedFormValues.shared.memberId &&
        changedFormValues.shared.memberId !== memberId
      ) {
        assignMemberToFaxDocument({
          documentId,
          bodyFaxesLinkReceivedFaxToMember: {
            member_id: changedFormValues.shared.memberId,
          },
        })
        form.setFieldValue(['document', 'episodeId'], null)
        form.setFieldValue(['document', 'createdFromNoteId'], null)
      }

      if (changedFormValues.fax) {
        updateIncomingFax({
          documentId,
          patchIncomingFaxDocRequest: convertUndefinedToNull(
            changedFormValues.fax,
          ),
        })
      }

      // Special case for fax, needs to be split into asignee and/or teams first
      if (changedFormValues.owner) {
        const { assignee, teams } = changedFormValues.owner

        updateIncomingFax({
          documentId: document.id,
          patchIncomingFaxDocRequest: {
            assigned_to: assignee ? Number(assignee) : null,
            team_ids: teams || [],
          },
        })
      }

      if (changedFormValues.document && memberIdFieldValue) {
        const documentBody = convertUndefinedToNull(changedFormValues.document)
        if (documentBody.documentDate) {
          documentBody.documentDate = moment(
            documentBody.documentDate,
          ).toISOString()
        }

        updateMemberDocument({
          documentId,
          memberId: memberIdFieldValue,
          patchMemberDocRequest: documentBody,
        })
      }
    },
    [
      memberId,
      memberIdFieldValue,
      assignMemberToFaxDocument,
      documentId,
      form,
      updateIncomingFax,
      document.id,
      updateMemberDocument,
    ],
  )

  const radioOptions = [
    {
      label: t('faxes.priority.none'),
      value: IncomingFaxDocumentPriority.NONE,
    },
    {
      label: (
        <>
          <FlagOutlined /> {t('faxes.priority.flagged')}
        </>
      ),
      value: IncomingFaxDocumentPriority.FLAGGED,
      variant: RadioButonsVariants.Warning,
    },
    {
      label: (
        <>
          <WarningOutlined /> {t('faxes.priority.urgent')}
        </>
      ),
      value: IncomingFaxDocumentPriority.URGENT,
      variant: RadioButonsVariants.Danger,
    },
  ]

  const documentOptions = useMemo(() => documentTypeOptions(t), [t])

  const onClickProcessFax = useCallback(() => {
    updateIncomingFax({
      documentId,
      patchIncomingFaxDocRequest: {
        status: IncomingFaxDocumentStatus.processed,
      },
    })
  }, [updateIncomingFax, documentId])

  const getProcessingText = useCallback((): string => {
    if (isProcessed) {
      return t('faxes.status.processed')
    }

    if (isLoading) {
      return t('faxes.loading.processing')
    }

    return t('faxes.process')
  }, [isLoading, isProcessed, t])

  return (
    <Form
      key={documentId}
      form={form}
      onValuesChange={handleOnInfoChange}
      initialValues={{
        shared: {
          memberId,
        },
        fax: {
          name,
          priority,
        },
        document: {
          documentDate: !!documentDate && moment(documentDate),
          type: docType,
          episodeId,
          createdFromNoteId,
        },
        owner: {
          assignee: assignedTo,
          teams: teamIds,
        },
      }}
    >
      <Form.Item name={['fax', 'name']} noStyle>
        <FormInputDocumentTitle />
      </Form.Item>
      <HeaderLine title={t('documents.sections.priority')} />
      <InputSection>
        <DetailLine
          label={t('documents.sections.priority')}
          value={
            <Form.Item name={['fax', 'priority']} noStyle>
              <RadioButtonsGroup options={radioOptions} />
            </Form.Item>
          }
        />
        {/* null label to hide it completely, instead of falling back the default label */}
        <DetailLine label="Assignee" value={<OwnerField label={null} />} />
      </InputSection>
      <HeaderLine title={t('documents.sections.member')} />
      <DetailLine
        label={t('documents.sections.member')}
        value={
          <Form.Item name={['shared', 'memberId']} noStyle>
            <MemberPicker
              $extendedInfo
              defaultId={memberId}
              data-cy="fax-member-id-field"
              variant="dobEmail"
            />
          </Form.Item>
        }
      />

      <HeaderLine title={t('documents.sections.properties')} />
      <InputSection>
        <DetailLine
          label={t('documents.format')}
          value={
            <Typography.Text>
              {t(`documents.formatWithExtension`, {
                format,
                ext: documentExtension,
              })}
            </Typography.Text>
          }
        />
        <DetailLine
          label={t('documents.documentDate')}
          alignLabel="middle"
          value={
            <Form.Item noStyle name={['document', 'documentDate']}>
              <DatePicker
                format={dateFormat}
                placeholder={dateFormat}
                disabledDate={disableFutureDates}
                allowClear
                style={{ width: '100%' }}
                data-testid="document-date-picker"
              />
            </Form.Item>
          }
        />
        <DetailLine
          label={t('documents.type')}
          value={
            <Form.Item noStyle name={['document', 'type']}>
              <Select
                options={documentOptions}
                dropdownMatchSelectWidth={false}
                placeholder={t('documents.typePlaceholder')}
                style={{ width: '100%' }}
                showSearch
                allowClear
                filterOption={filterSelectOptions}
                data-testid="document-type-select"
              />
            </Form.Item>
          }
        />
        <DetailLine
          label={t('documents.source')}
          value={t('faxes.faxSource', {
            faxNumber: formatPhoneNumber(senderNumber),
          })}
        />
      </InputSection>

      <HeaderLine title={t('documents.sections.episode')} />
      <InputSection>
        <DetailLine
          label={t('documents.details.episode')}
          alignLabel="middle"
          value={
            <FormContainer>
              <Tooltip
                title={
                  postId ? t('documents.details.episodeDisabledByPostId') : ''
                }
              >
                <Form.Item noStyle name={['document', 'episodeId']}>
                  <EpisodePicker
                    memberId={memberIdFieldValue}
                    fallbackTitle={t('episodes.missingTitle')}
                    placeholder={t('documents.details.episodePlaceholder')}
                    disabled={!!postId || !memberIdFieldValue}
                  />
                </Form.Item>
              </Tooltip>
              {hasEpisodeLink && (
                <Link
                  to={episodeRoute}
                  target={deviceInfo.isElectron() ? undefined : '_blank'}
                  data-testid="episode-link"
                  css={`
                    && {
                      padding: 0;
                      white-space: normal;
                      text-align: left;
                    }
                  `}
                  onClick={stopPropagation} // Prevents current tab from navigating.
                >
                  <ExportOutlined />
                </Link>
              )}
            </FormContainer>
          }
        />
        <DetailLine
          label={t('documents.details.note')}
          alignLabel="middle"
          value={
            <FormContainer>
              <Form.Item noStyle name={['document', 'createdFromNoteId']}>
                <NoteDisplay memberId={memberId} noteId={createdFromNoteId} />
              </Form.Item>
              {hasNoteLink && (
                <Button
                  data-testid="note-link"
                  type="link"
                  size="small"
                  css={`
                    && {
                      padding: 0;
                      white-space: normal;
                      text-align: left;
                    }
                  `}
                  onClick={openNote}
                >
                  <ExportOutlined />
                </Button>
              )}
            </FormContainer>
          }
        />
      </InputSection>
      <Button
        data-testid="process-fax-btn"
        type="primary"
        loading={isLoading}
        onClick={onClickProcessFax}
        css={`
          margin-top: 24px;
          margin-bottom: 48px;
          float: right;
        `}
        disabled={processButtonDisabled}
      >
        {getProcessingText()}
      </Button>

      <PermissionsGuard permissions={[ER_LIST_TASKS]}>
        {enableTaskSystem && (
          <>
            <HeaderLine
              title={t('documents.sections.tasks')}
              extra={
                <TasksCreateNew
                  memberId={memberIdFieldValue}
                  episodeId={episodeId}
                  documentIds={[documentId]}
                />
              }
            />
            <TasksView
              documentId={documentId}
              noTasksLabel={t('tasks.document.noTasksForDocument')}
              noTasksDueLabel={t('tasks.document.noTasksDueForDocument')}
              data-cy="document-view-tasks"
            />
          </>
        )}
      </PermissionsGuard>
      <HeaderLine title={t('documents.sections.activity')} />
      <DetailLine
        label={t('documents.received')}
        value={moment(receivedAt).format(dateAndHoursFormat)}
      />
    </Form>
  )
}
