import { TemplatesApiFactory } from '@dialogue/notepad-client'
import { put, all, takeLatest, call } from 'typed-redux-saga/macro'

import { noteTemplatesActions } from 'app/redux/structured-notes/templates'

import { getConfig } from './utils'

export function* initNotepadTemplatesClient() {
  return TemplatesApiFactory(yield* call(getConfig))
}

export function* getTemplates() {
  try {
    const notepad = yield* call(initNotepadTemplatesClient)
    const { data } = yield* call(notepad.getTemplates)

    yield* put(noteTemplatesActions.getSuccess(data.data))
  } catch (e) {
    yield* put(noteTemplatesActions.getFailure(e))
  }
}

export default function* notesTemplates() {
  yield* all([takeLatest(noteTemplatesActions.get, getTemplates)])
}
