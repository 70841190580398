import type { Appointment as TkAppointment } from '@dialogue/timekeeper'
import { all, call, put, select } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import {
  getAppointments,
  getAppointmentsSuccess,
  getAppointmentsFailure,
  updateAppointmentStatus,
  updateAppointmentStatusSuccess,
  updateAppointmentStatusFailure,
  selectOffset,
} from 'app/redux/appointments'

import {
  initEmeraldClient,
  initEmeraldV2Client,
  takeKeyedLatest,
} from './utils'

const actionPatientId = (action: ReturnType<typeof getAppointments>) =>
  action.payload.patientId

export function* getPatientAppointments({
  payload: { patientId },
}: ActionType<typeof getAppointments>) {
  const limit = 100
  try {
    const offset = yield* select(selectOffset, patientId)
    const emerald = yield* call(initEmeraldClient)
    const appointmentDetails = yield* call(
      emerald.getAppointments,
      patientId,
      limit,
      offset,
    )
    const { data, meta } = appointmentDetails
    const { appointments, providers } = data

    yield* put(
      getAppointmentsSuccess({
        patientId,
        appointments,
        providers,
        metadata: meta,
      }),
    )
  } catch (error) {
    yield* put(getAppointmentsFailure({ patientId, error }))
  }
}

export function* updatePatientAppointmentStatus({
  payload: { appointmentId, patientId, updateAppointmentStatusAttributes },
}: ActionType<typeof updateAppointmentStatus>) {
  try {
    const emerald = yield* call(initEmeraldV2Client)
    const source = window.location.hash ? `cp:${window.location.hash}` : 'cp'
    const appointment = yield* call(
      emerald.updateAppointmentStatus,
      appointmentId,
      {
        ...updateAppointmentStatusAttributes,
        source,
      },
    )
    yield* put(
      updateAppointmentStatusSuccess({
        appointment,
        patientId,
        tkAppointment: appointment.tk_appointment as TkAppointment,
      }),
    )
  } catch (error) {
    yield* put(updateAppointmentStatusFailure({ appointmentId, error }))
  }
}

export default function* appointmentsSagas() {
  yield* all([
    takeKeyedLatest(getAppointments, actionPatientId, getPatientAppointments),
    takeKeyedLatest(
      updateAppointmentStatus,
      (action) => action.payload.appointmentId,
      updatePatientAppointmentStatus,
    ),
  ])
}
