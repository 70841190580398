// Disable global drag and drop, to prevent dropping a file in the browser from
// navigating away to open the file.
document.addEventListener(
  'dragover',
  function (event) {
    event.preventDefault()
    return false
  },
  false,
)

document.addEventListener(
  'drop',
  function (event) {
    event.preventDefault()
    return false
  },
  false,
)
