export enum TemplatesActionTypes {
  GET_TEMPLATES_REQUEST = '@@templates/GET_TEMPLATES_REQUEST',
  GET_TEMPLATES_SUCCESS = '@@templates/GET_TEMPLATES_SUCCESS',
  GET_TEMPLATES_FAILURE = '@@templates/GET_TEMPLATES_FAILURE',
}

// TODO: externalize
type TemplateLanguage = 'en' | 'fr' | 'de'

export type Template = Record<TemplateLanguage, string>

export interface TemplatesState {
  templates: Record<string, Template>
  fetching: boolean
  error: Error
}
