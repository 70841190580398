import type {
  DocumentContentUpdateReason,
  DocumentFormat,
  FilterMultiSelectResponse,
  MemberApiCreateDocumentRequest,
} from '@dialogue/document-center'
import type { MemberDocument } from '@dialogue/services/dist/emerald/v2/model'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
import type { FullFileInfo } from 'app/redux/chat/types'

import type { ViewerMemberDocument } from './viewer'

interface DocumentFilters {
  episode_ids: Array<string | null>
  formats: DocumentFormat[]
  creator_ids: number[]
}

interface MemberState {
  uploading: boolean
  errorUploading: Error | null
  filters: DocumentFilters | null
  fetchingFilters: boolean
  errorFetchingFilters: Error | null
  updatingDocument: boolean
  errorUpdating: Error | null
  updatingDocumentBytes: boolean
  errorUpdatingDocumentBytes: Error | null
  uploadingToIh: boolean
  errorUploadingToIh: {
    [documentId: string]: Error | null
  }
}

interface State {
  [memberId: number]: MemberState
}

export type MemberAction<T = {}> = PayloadAction<T & { memberId: number }>
export type MemberDocumentAction<T = {}> = PayloadAction<
  T & { memberId: number; documentId: string }
>

const INITIAL_STATE: State = {}

const getMemberState = (state: State, memberId: number) => {
  if (!state[memberId]) {
    state[memberId] = {
      uploading: false,
      errorUploading: null,
      filters: null,
      fetchingFilters: false,
      errorFetchingFilters: null,
      updatingDocument: false,
      errorUpdating: null,
      updatingDocumentBytes: false,
      errorUpdatingDocumentBytes: null,
      uploadingToIh: false,
      errorUploadingToIh: {},
    }
  }
  return state[memberId]
}

export const { actions: memberDocumentsActions, reducer } = createSlice({
  name: '@@documents/members',
  initialState: INITIAL_STATE,
  reducers: {
    // Save from Chat
    saveDocumentFromChat(
      state,
      action: MemberAction<{
        fileInfo: FullFileInfo
        episodeId: string
      }>,
    ) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.errorUploading = null
      memberState.uploading = true
    },
    uploadDocument(
      state,
      action: MemberAction<MemberApiCreateDocumentRequest>,
    ) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.errorUploading = null
      memberState.uploading = true
    },
    savedDocument(state, action: MemberAction<{ document: MemberDocument }>) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.uploading = false
    },
    erroredSavingDocument(state, action: MemberAction<{ error: Error }>) {
      const { memberId, error } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.errorUploading = error
      memberState.uploading = false
    },
    uploadDocumentFromUrl(
      state,
      action: MemberAction<{
        url: string
        name: string
        episodeId?: string
        createdFromNoteId?: number
      }>,
    ) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.errorUploading = null
      memberState.uploading = true
    },
    savedDocumentFromUrl(
      state,
      action: MemberAction<{ document: MemberDocument }>,
    ) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.uploading = false
    },
    erroredSavingDocumentFromUrl(
      state,
      action: MemberAction<{ error: Error }>,
    ) {
      const { memberId, error } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.errorUploading = error
      memberState.uploading = false
    },

    // Filters fetching
    getDocumentFilters(state, action: MemberAction) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.fetchingFilters = true
      memberState.errorFetchingFilters = null
    },
    receivedDocumentFilters(
      state,
      action: MemberAction<{ filters: FilterMultiSelectResponse }>,
    ) {
      const { memberId, filters } = action.payload
      const memberState = getMemberState(state, memberId)

      memberState.filters = filters
      memberState.fetchingFilters = false
    },
    erroredGettingDocumentFilters(
      state,
      action: MemberAction<{ error: Error }>,
    ) {
      const { memberId, error } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.errorFetchingFilters = error
      memberState.fetchingFilters = false
    },
    sendToEpisode(
      state,
      action: PayloadAction<{
        episodeId: string
        document: ViewerMemberDocument
        memberId: number
      }>,
    ) {},
    sendBlobToEpisode(
      state,
      action: PayloadAction<{
        episodeId: string
        document: ViewerMemberDocument
        fileBlob: Blob
      }>,
    ) {},
    updateDocument(
      state,
      action: MemberAction<{
        documentId: MemberDocument['id']
        documentProperties: {
          episode_id?: string
          created_from_note_id?: number | null
          name?: string
        }
      }>,
    ) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.updatingDocument = true
      memberState.errorUpdating = null
    },
    documentUpdated(state, action: MemberAction<{ document: MemberDocument }>) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.updatingDocument = false
      memberState.errorUpdating = null
    },
    errorUpdatingDocument(state, action: MemberAction<{ error: Error }>) {
      const { memberId, error } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.updatingDocument = false
      memberState.errorUpdating = error
    },
    updateDocumentBytes(
      state,
      action: MemberAction<{
        documentId: MemberDocument['id']
        document: File
        reason: DocumentContentUpdateReason
      }>,
    ) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.updatingDocumentBytes = true
      memberState.errorUpdatingDocumentBytes = null
    },
    documentBytesUpdated(
      state,
      action: MemberAction<{ document: MemberDocument }>,
    ) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.updatingDocumentBytes = false
      memberState.errorUpdatingDocumentBytes = null
    },
    errorUpdatingDocumentBytes(state, action: MemberAction<{ error: Error }>) {
      const { memberId, error } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.updatingDocumentBytes = false
      memberState.errorUpdatingDocumentBytes = error
    },
    resetUpdatingDocumentBytes(state, action: MemberAction) {
      const { memberId } = action.payload
      const memberState = getMemberState(state, memberId)
      memberState.updatingDocumentBytes = false
      memberState.errorUpdatingDocumentBytes = null
    },

    // Upload to IH
    uploadToIh(
      state,
      {
        payload,
      }: MemberAction<{
        documentId: MemberDocument['id']
        description?: string
      }>,
    ) {
      const { memberId, documentId } = payload
      const memberState = getMemberState(state, memberId)

      memberState.errorUploadingToIh[documentId] = null
      memberState.uploadingToIh = true
    },
    savedToIh(state, { payload }: MemberAction<{ document: MemberDocument }>) {
      const { memberId } = payload
      const memberState = getMemberState(state, memberId)
      memberState.uploadingToIh = false
    },
    erroredSavingToIh(
      state,
      {
        payload,
      }: MemberAction<{ error: Error; documentId: MemberDocument['id'] }>,
    ) {
      const { memberId, error, documentId } = payload
      const memberState = getMemberState(state, memberId)

      memberState.errorUploadingToIh[documentId] = error
      memberState.uploadingToIh = false
    },
  },
})

export default reducer

const selectMemberDocumentsState = (state: ReduxState, memberId: number) =>
  state.documents.members[memberId]

export const selectIsUploading = createSelector(
  selectMemberDocumentsState,
  (state) => state?.uploading,
)

export const selectFilterOptions = createSelector(
  selectMemberDocumentsState,
  (state) => state?.filters,
)

export const selectIsFetchingFilterOptions = createSelector(
  selectMemberDocumentsState,
  (state) => state?.fetchingFilters,
)

export const selectErrorGettingFilterOptions = createSelector(
  selectMemberDocumentsState,
  (state) => state?.errorFetchingFilters,
)

export const selectIsUploadingToIh = createSelector(
  selectMemberDocumentsState,
  (state) => state?.uploadingToIh,
)

export const selectErrorUploadingToIh = createSelector(
  selectMemberDocumentsState,
  (_state: ReduxState, _memberId: number, documentId: string) => documentId,
  (memberState, documentId) => memberState?.errorUploadingToIh?.[documentId],
)
