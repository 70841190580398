import { Provider } from 'react-redux'

import OfflineModal from 'app/containers/offline-modal'
import { store } from 'app/redux'
import { CpThemeProvider } from 'app/theme'

import useMediaDevices from './hooks/media-devices'
import AppRouter from './router'
import deviceInfo from './services/device-info'
import { useThemeSync } from './theme-sync'

const onDevicesChanged = (devices: any) => {
  if (deviceInfo.isElectron()) {
    window.electronApi?.sendDevices(devices)
  }
}

const App = () => {
  useThemeSync()
  useMediaDevices(onDevicesChanged)

  return (
    <>
      <OfflineModal />
      <AppRouter />
    </>
  )
}

export const AppWithProviders = () => {
  return (
    <Provider store={store}>
      <CpThemeProvider>
        <App />
      </CpThemeProvider>
    </Provider>
  )
}

export default AppWithProviders
