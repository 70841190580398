import type {
  ItemPatient,
  PatientApiGetPatientProfileRequest,
} from '@dialogue/coredata'

import { emergencyRoomApi, Tags } from './api'

/**
 * NOTE: this API was created for the faxing outbox component; all other components are
 * still using the legacy redux/sagas pattern for fetching a patient's profile.
 * src/app/redux/patients, src/app/sagas/patients.ts
 *
 * TODO: refactor to include other patient-related endpoints, and implement in components.
 */
export const patientsApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getPatientProfile: build.query({
      query: ({ patientId }: PatientApiGetPatientProfileRequest) => ({
        url: `/v1/patients/${patientId}`,
      }),
      transformResponse: (response: ItemPatient) => response.data,
      providesTags: (_result, _error, { patientId }) => [
        { type: Tags.Patients, id: patientId },
      ],
    }),
  }),
})

export const { useGetPatientProfileQuery } = patientsApi
