// @ts-strict-ignore
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

import type {
  ComponentInfo,
  Incident,
  PageInfo,
  StatusInfo,
  StatusPageState,
} from './types'

const INITIAL_STATE: StatusPageState = {
  fetching: false,
  error: null,
}

export const { reducer, actions: statuspageActions } = createSlice({
  name: '@@statuspage',
  initialState: INITIAL_STATE,
  reducers: {
    fetchStatus: (state) => {
      state.fetching = true
      state.error = null
    },
    fetchStatusSuccess: (
      state,
      action: PayloadAction<{
        status: StatusInfo
        components: ComponentInfo[]
        page: PageInfo
        incidents: Incident[]
      }>,
    ) => {
      const { payload } = action

      state.fetching = false
      state.components = payload.components
      state.incidents = payload.incidents
      state.pageInfo = payload.page
      state.status = payload.status
    },
    fetchStatusError: (state, action: PayloadAction<Error>) => {
      state.fetching = false
      state.error = action.payload
    },
  },
})

export default reducer

export const selectComponent = (state: ReduxState, componentId: string) =>
  state.statusPage.components?.find((component) => component.id === componentId)
