import { useEffect, useRef, useState } from 'react'

import type { Core } from '@pdftron/webviewer'
import { notification } from 'antd'

import config from 'app/config'
import i18n from 'app/i18n'

import { webviewerPath } from './viewer-config'

export interface OutgoingFaxAutofillFields {
  patient_dia_id?: string
  outgoing_fax_recipient_name: string
  outgoing_fax_recipient_fax_number: string
  todays_date: string
  outgoing_fax_page_count?: string
  outgoing_fax_template_text?: string
  provider_full_name?: string
}
declare global {
  interface Window {
    Core: typeof Core
  }
}

function loadApryse(path: string) {
  return new Promise((resolve) => {
    if (window && document) {
      const body = document.getElementsByTagName('body')[0]
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = path
      body.appendChild(script)
      script.addEventListener('load', () => {
        resolve(undefined)
      })
    }
  })
}

function withCleanup(target: Function, _: any) {
  return async function methodWithCleanup(this: any, ...args: any[]) {
    return this.getCore().PDFNet.runWithCleanup(
      () => target.apply(this, args),
      config.PDF_APRYSE_LICENSE,
    )
  }
}

interface SplitDocumentResult {
  splitDocuments: File[] | null
  pageNumbers: number[][]
}

export class Apryse {
  loaded: boolean

  constructor() {
    this.loaded = false
  }

  load = async (): Promise<void> => {
    // It is the consumer's responsibility to wait for this initialization
    // to resolve before using the library.
    if (this.loaded) {
      return
    }
    await loadApryse(webviewerPath + '/core/webviewer-core.min.js')
    await loadApryse(webviewerPath + '/core/pdf/PDFNetLean.js')
    await loadApryse(webviewerPath + '/core/pdf/PDFNet.js')
    this.getCore().setWorkerPath(webviewerPath + '/core')
    await this.getCore().PDFNet.initialize(config.PDF_APRYSE_LICENSE)
    this.loaded = true
  }

  getCore = () => window.Core

  static blobToBase64 = (blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  static autofillPdf = async (
    pdfDoc: Core.PDFNet.PDFDoc,
    autofillFields: OutgoingFaxAutofillFields,
  ): Promise<void> => {
    const fields = Object.entries(autofillFields)
    for (const [key, value] of fields) {
      const field = await pdfDoc.getField(key)
      if (await field.isValid()) {
        await field.setValueAsString(value)
        // refresh appearance so the field's rendering is updated
        await field.refreshAppearance()
      }
    }
  }

  @withCleanup
  async prepareFaxPackage(
    coverPageUrl: string,
    memberDocUrl: string,
    autofillFields?: OutgoingFaxAutofillFields,
  ): Promise<Blob> {
    let coverPageDoc =
      await this.getCore().PDFNet.PDFDoc.createFromURL(coverPageUrl)
    const memberDoc =
      await this.getCore().PDFNet.PDFDoc.createFromURL(memberDocUrl)

    const coverPageCount = await coverPageDoc.getPageCount()
    const memberDocPageCount = await memberDoc.getPageCount()

    if (autofillFields) {
      autofillFields.outgoing_fax_page_count = String(
        coverPageCount + memberDocPageCount,
      )
      await Apryse.autofillPdf(coverPageDoc, autofillFields)
    }

    // insert entire member doc after cover page
    await coverPageDoc.insertPages(
      coverPageCount + 1, // insert the pages at the end of the cover page
      memberDoc, // which pages to insert
      1, // start from the first page
      memberDocPageCount, // insert all pages
      this.getCore().PDFNet.PDFDoc.InsertFlag.e_none, // no special flags
    )

    await coverPageDoc.lock()

    // Important for correctly displaying multiline text fields.
    await coverPageDoc.flattenAnnotations()
    await memberDoc.flattenAnnotations()

    const data = await coverPageDoc.saveMemoryBuffer(
      this.getCore().PDFNet.SDFDoc.SaveOptions.e_linearized,
    )

    const arr = new Uint8Array(data)
    const blob = new Blob([arr], { type: 'application/pdf' })

    return blob
  }

  @withCleanup
  async flattenDocument(memberDocUrl: string) {
    let doc = await this.getCore().PDFNet.PDFDoc.createFromURL(memberDocUrl)

    doc.flattenAnnotations()
    const data = await doc.saveMemoryBuffer(
      this.getCore().PDFNet.SDFDoc.SaveOptions.e_linearized,
    )
    const arr = new Uint8Array(data)
    const blob = new Blob([arr], { type: 'application/pdf' })

    return blob
  }

  @withCleanup
  async splitDocument(
    pageNumberRanges: Array<number[]>,
    core: typeof Core,
  ): Promise<SplitDocumentResult> {
    const { documentViewer, annotationManager } = core

    const doc = documentViewer.getDocument()

    if (!doc) {
      notification.error({
        message: i18n.t('documents.errorSplittingUndefinedDocument'),
      })
      return { splitDocuments: null, pageNumbers: [] }
    }

    const extractedDocuments: File[] = []
    const pageNumbers: number[][] = []

    for (let index = 0; index < pageNumberRanges.length; index++) {
      const pageNumberRange = pageNumberRanges[index]
      const rangeContainsInvalidPageNumber = pageNumberRange.some(
        (pageNumber) => pageNumber < 1 || pageNumber > doc.getPageCount(),
      )

      if (rangeContainsInvalidPageNumber) {
        notification.error({
          message: i18n.t('documents.split.errors.invalidPageNumber', {
            pageCount: doc.getPageCount(),
          }),
        })
        return { splitDocuments: null, pageNumbers: [] }
      }

      // only include annotations on the pages to extract
      const annotList = annotationManager
        .getAnnotationsList()
        .filter((annot) => pageNumberRange.indexOf(annot.PageNumber) > -1)
      const xfdfString = await annotationManager.exportAnnotations({
        annotList,
      })
      const data = await doc.extractPages(pageNumberRange, xfdfString)
      const fileName = doc.getFilename().split('.pdf')[0]

      const arr = new Uint8Array(data)
      const blob = new Blob([arr], { type: 'application/pdf' })
      const file = new File([blob], `${fileName} - section ${index + 1}.pdf`)
      extractedDocuments.push(file)
      pageNumbers.push(pageNumberRange)
    }

    return { splitDocuments: extractedDocuments, pageNumbers }
  }
}

interface ApryseProjection {
  loaded: typeof Apryse.prototype.loaded
  blobToBase64: typeof Apryse.blobToBase64
  autofillPdf: typeof Apryse.autofillPdf
  prepareFaxPackage: typeof Apryse.prototype.prepareFaxPackage
  splitDocument: typeof Apryse.prototype.splitDocument
  flattenDocument: typeof Apryse.prototype.flattenDocument
}

export function useApryse() {
  const instance = useRef(new Apryse())?.current

  const project = (): ApryseProjection => ({
    // Here the methods we want to expose to be used in React are declared.
    // Note that instance methods need to be bound to the instance.
    loaded: instance.loaded,
    blobToBase64: Apryse.blobToBase64,
    autofillPdf: Apryse.autofillPdf,
    prepareFaxPackage: instance.prepareFaxPackage.bind(instance),
    splitDocument: instance.splitDocument.bind(instance),
    flattenDocument: instance.flattenDocument.bind(instance),
  })

  const [projection, setProjection] = useState(project())

  const setNewProjection = () => setProjection(project())

  useEffect(() => {
    instance.load().then(() => setNewProjection())
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Patch the methods to call setNewProjection after they are called
  // Keep the rest the same
  const patchedProjection = Object.entries(projection).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'function') {
        return {
          ...acc,
          [key]: async (...args: any[]) => {
            const res = await value(...args)
            setNewProjection()
            return res
          },
        }
      }
      return { ...acc, [key]: value }
    },
    projection,
  )

  return patchedProjection
}
