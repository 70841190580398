import { ApiRequestError } from '@dialogue/services'
import type {
  DraftAppointment,
  NewDraftAppointment,
} from '@dialogue/timekeeper'
import { call, put, select, all, takeLatest } from 'typed-redux-saga/macro'

import {
  selectCurrentDraftId,
  timekeeperDraftActions,
} from 'app/redux/timekeeper/drafts'

import { getDraftsApi } from './utils'

export function* createDraft(newDraft: NewDraftAppointment) {
  const api = yield* call(getDraftsApi)
  const {
    data: { data: draft },
  } = yield* call(api.create, newDraft)

  yield* put(timekeeperDraftActions.received(draft))
}

export function* updateDraft(
  draftId: DraftAppointment['id'],
  newDraft: NewDraftAppointment,
) {
  try {
    const api = yield* call(getDraftsApi)
    const {
      data: { data: draft },
    } = yield* call(api.update, draftId, newDraft)

    yield* put(timekeeperDraftActions.received(draft))
  } catch (e) {
    if (e instanceof ApiRequestError && e.code === 404) {
      yield* call(createDraft, newDraft)
    } else {
      throw e
    }
  }
}

export function* saveDraft({
  payload,
}: ReturnType<typeof timekeeperDraftActions.save>) {
  const existingDraftId = yield* select(selectCurrentDraftId)

  try {
    if (existingDraftId) {
      yield* call(updateDraft, existingDraftId, payload)
    } else {
      yield* call(createDraft, payload)
    }
  } catch (e) {
    console.warn('Failed to save draft', e)
  }
}

export function* clearDraft() {
  const existingDraftId = yield* select(selectCurrentDraftId)

  if (!existingDraftId) {
    return
  }

  try {
    const api = yield* call(getDraftsApi)
    yield* call(api.deleteDraft, existingDraftId)
    yield* put(timekeeperDraftActions.deleted(existingDraftId))
  } catch (e) {
    console.warn('Failed to delete draft', e)
  }
}

export default function* draftSagas() {
  yield* all([takeLatest(timekeeperDraftActions.save, saveDraft)])
  yield* all([takeLatest(timekeeperDraftActions.clear, clearDraft)])
}
