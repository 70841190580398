import { datadogRum } from '@datadog/browser-rum'
import { configureStore } from '@reduxjs/toolkit'
import { addGlobalContexts } from '@snowplow/browser-tracker'
/* eslint-disable import/no-duplicates */
import type LocalForageType from 'localforage'
// @ts-expect-error This explicit .js file has no typings, issues with how localforage
// isn't esm compatible. Details of source of solution:
// https://github.com/localForage/localForage/issues/831#issuecomment-1409594290
import localForageImport from 'localforage/dist/localforage'
import type { Middleware } from 'redux'
import { type PersistConfig, persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createSagaMiddleware from 'redux-saga'

import { apiMiddlewares } from 'app/redux/api'
import reducers from 'app/redux/reducers'
import appSagas from 'app/sagas'
import { getEventSchema } from 'app/services/snowplow-analytics'

import { selectUserProfile } from './authentification/selectors'

export const localforage: typeof LocalForageType = localForageImport

type State = ReturnType<typeof reducers>
const persistConfig: PersistConfig<State> = {
  key: 'root',
  storage: localforage,
  whitelist: ['dashboardLayout', 'theming', 'preferences'],
  stateReconciler: autoMergeLevel2,
  // enable if we start persisting seamless objects
  // transforms: [seamlessImmutableTransformCreator({})]
}

const middlewares: Middleware[] = [...apiMiddlewares]

/* ------------- Saga Middleware ------------- */
// create our new saga monitor
const sagaMiddleware = createSagaMiddleware({
  onError: (err, { sagaStack }) => datadogRum.addError(err, { sagaStack }),
})
middlewares.push(sagaMiddleware)

// Now we're ready to create the store

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      actionCreatorCheck: false,
    }).concat(middlewares),
})

// persist the store
export const persistor = persistStore(store)

// kick off root saga
sagaMiddleware.run(appSagas)

// hook in to state to inject tenant in analytics calls
const addTenantContextToAnalytics = () => {
  const tenantId = selectUserProfile(store.getState())?.tenant_id
  if (tenantId) {
    return {
      schema: getEventSchema('tenant', '1-0-0'),
      data: {
        tenant_id: tenantId,
      },
    }
  }
}

addGlobalContexts([addTenantContextToAnalytics])
