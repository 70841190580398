// @ts-strict-ignore
import type { Pharmacy } from '@dialogue/services/dist/emerald/v2/model'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
import { selectPreferredPharmacyId } from 'app/redux/input-health/reducer'

type Pharmacies = { [id: string]: Pharmacy }

interface State {
  fetching: boolean
  error: Error | null
  data: Pharmacies | null
}

export const INITIAL_STATE: State = {
  fetching: false,
  error: null,
  data: {},
}

export const { actions: pharmaciesActions, reducer } = createSlice({
  name: '@@medicalProfile/pharmacies',
  initialState: INITIAL_STATE,
  reducers: {
    get: (state, action: PayloadAction<{ pharmacyId: string }>) => {
      state.error = null
      state.fetching = true
    },
    getPreferred: (state, action: PayloadAction<{ patientId: string }>) => {
      state.error = null
      state.fetching = true
    },
    error: (state, { payload }: PayloadAction<Error>) => {
      state.fetching = false
      state.error = payload
    },
    receive: (state, { payload }: PayloadAction<Pharmacy>) => {
      state.fetching = false
      if (payload) {
        state.data[payload.id] = payload
      }
    },
  },
})

export const selectPharmacy = (state: ReduxState, pharmacyId: string) =>
  state.medicalProfile.pharmacies.data[pharmacyId]

export const selectPharmacyFetching = (state: ReduxState) =>
  state.medicalProfile.pharmacies.fetching

export const selectPharmacyError = (state: ReduxState) =>
  state.medicalProfile.pharmacies.error

export const selectPreferredPharmacy = (
  state: ReduxState,
  patientId: string | number,
) => {
  const id = selectPreferredPharmacyId(state, patientId)

  if (!id) return null

  return state.medicalProfile.pharmacies.data[id]
}

export default reducer
