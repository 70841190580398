/**
 * Theme color definitions for use throughout the app.
 * See https://material.io/design/material-theming/implementing-your-theme.html#color
 * for color naming guidelines.
 */

import { baseColors } from '@dialogue/basics'

import { themed } from './utils'

export const primary = themed({
  default: baseColors.azure,
  aubergine: baseColors.lilac,
  clementine: baseColors.alert,
})

export const primaryLight = themed({
  default: baseColors.malibuLight,
  aubergine: baseColors.lilacLight,
  clementine: baseColors.salmon,
})

export const primaryDark = themed({
  default: baseColors.marine,
  aubergine: baseColors.eggplant,
})

export const primaryDarkest = themed({
  default: '#114275',
})

export const onPrimary = themed({
  default: baseColors.white,
})

export const background = themed({
  default: baseColors.smokeLight,
  aubergine: baseColors.tofuLight,
  clementine: baseColors.aluminum,
})

export const backgroundDark = themed({
  default: baseColors.smoke,
  clementine: baseColors.silver,
  aubergine: '#E5E4ED', // tofu
})

// the default light grey background color of a page
export const appBackground = themed({
  default: 'rgb(240, 242, 245)',
})

/*
  TODO: fix the bug described below and use a themed color
  
  BUG: when using a color from themed in some contexts such as
  the box-shadow applied in task-notification-card.tsx, themed colors
  don't result in a valid output value.
*/
export const appBackgroundShadow = 'rgba(0,0,0,0.15)'

export const onBackground = themed({
  default: baseColors.night,
})

export const surface = themed({
  default: baseColors.white,
  clementine: baseColors.smokeLight,
  aubergine: '#F2F1F5', // tofu light
})

export const onSurface = themed({
  default: baseColors.night,
})

export const surfacePrimary = themed({
  default: baseColors.skyLight,
  aubergine: baseColors.amethystLight,
  clementine: baseColors.dustLight,
})

export const surfaceDark = themed({
  default: baseColors.smoke,
  aubergine: baseColors.tofu,
})

export const surfaceError = themed({
  default: '#E95D5D',
})

export const surfaceWarning = themed({
  default: '#FFA970',
})

export const surfaceSuccess = themed({
  default: baseColors.aqua,
})

export const error = themed({
  default: '#E95D5D',
})

export const errorDark = themed({
  default: '#E21616', // #blood
})

export const warningDark = themed({
  default: '#FF7D00', // #orange
})

export const errorLight = themed({
  default: '#EF8D8D',
})

export const warningLight = themed({
  default: '#FFC29A',
})

export const warning = themed({
  default: baseColors.salmon,
})

export const onError = themed({
  default: baseColors.smokeLight,
})

export const success = themed({
  default: baseColors.candy,
})

export const onSuccess = themed({
  default: baseColors.white,
})

export const textLight = themed({
  default: '#9DAAB3',
})

export const textDark = themed({
  default: '#485156',
})

export const textDisabled = themed({
  default: '#D7DDE0',
})

export const border = themed({
  default: '#D7DDE0',
})

export const textSuccess = themed({
  default: baseColors.silver,
})

export const onSurfaceLight = themed({
  default: baseColors.stone,
})

// FIXME: rename
export const accent = themed({
  default: baseColors.eggplantLight,
})

export const accentDark = themed({
  default: baseColors.eggplant,
})

export const accentLight = themed({
  default: '#E5E4ED', // tofu
  aubergine: '#CAC9D9', // amethyst light
})

export const surfaceWarningLight = themed({
  default: '#FDF7EE',
})

export const surfaceErrorLight = themed({
  default: '#FDF1EE',
})

export const lightBorder = themed({
  default: '#f0f0f0',
})

export const callCentreColor = themed({
  default: '#E95D5D',
})

export const ccqColor = themed({
  default: '#E08D11',
})
