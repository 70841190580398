import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { formatPhoneNumber } from 'app/lib/helpers'
import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'

import { HeaderLine, LineItem } from '../common'

export const FaxProperties = ({
  document,
}: {
  document: ViewerIncomingFaxDocument
}) => {
  const { t } = useTranslation()

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        gap: 8px;
      `}
    >
      <HeaderLine title={t('faxes.properties.title')} />
      <LineItem
        label={t('faxes.properties.receivedFrom')}
        value={formatPhoneNumber(document.sender_number)}
      />
      <LineItem
        label={t('faxes.properties.receivedOn')}
        value={format(new Date(document.received_at), 'MM-dd-yyyy hh:mm a')}
      />
    </div>
  )
}
