import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type * as actions from './actions'
import { type EpisodeQueuesState, EpisodeQueuesTypes } from './types'

export const INITIAL_STATE: EpisodeQueuesState = {
  teamsQueues: [],
  queuedEpisodeGroups: {},
}

export type EpisodeQueuesAction = ActionType<typeof actions>

export const reducer: Reducer<typeof INITIAL_STATE, EpisodeQueuesAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EpisodeQueuesTypes.SET_TEAM_EPISODE_GROUPS:
        draft.queuedEpisodeGroups[action.payload.teamId] =
          action.payload.episodeGroups
        break
      case EpisodeQueuesTypes.SET_TEAMS:
        draft.teamsQueues = action.payload.teams
        break
    }
  })

export default reducer
