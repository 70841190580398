import type {
  CollectionTemplateResponse,
  TemplatesApiGetTemplatesRequest,
} from '@dialogue/document-center'

import { documentCenterApi } from './api'

export const templatesApi = documentCenterApi.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: (params: TemplatesApiGetTemplatesRequest) => ({
        url: `/templates`,
        params,
      }),
      transformResponse: ({ data }: CollectionTemplateResponse) => data,
    }),
  }),
})

export const { useGetTemplatesQuery } = templatesApi
