import { forwardRef, useEffect, useMemo, useState } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import type { ScribeTypes } from '@dialogue/services'
import type { RefSelectProps } from 'antd/lib/select'
import debounce from 'lodash/debounce'
import Moment from 'moment'
import { useTranslation } from 'react-i18next'

import {
  ExtendedSelect,
  type ExtendedSelectProps,
  OptionContainer,
  OptionSubHeader,
} from 'app/components/extended-select'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  birthdateToAge,
  formatUserFullName,
  formatSearchTermWithDob,
} from 'app/lib/helpers'
import { patientSearchActions } from 'app/redux/patient-search'
import {
  selectIsFetching,
  selectResults,
} from 'app/redux/patient-search/selectors'

const DEBOUNCE_TIME = 500
const DOB_FORMAT = 'YYYY/MM/DD'

type SubInfoVariants = 'dobEmail'

type MemberInfoProps = {
  variant?: SubInfoVariants
  member: ScribeTypes.V2.User
  optionRender?: (member: MemberInfoProps['member']) => JSX.Element
}

type MemberPickerProps = {
  defaultId?: number
  variant?: SubInfoVariants
} & Partial<ExtendedSelectProps>

const MemberInfo = ({ member, variant }: MemberInfoProps) => {
  const { t } = useTranslation()
  let subOptionInfo

  if (variant === 'dobEmail') {
    subOptionInfo = (
      <OptionSubHeader
        leftText={
          member.date_of_birth && (
            <span>
              {birthdateToAge(member.date_of_birth)}Y&nbsp;(
              {Moment(member.date_of_birth).format(DOB_FORMAT)})
            </span>
          )
        }
        rightText={member.email}
      />
    )
  } else {
    subOptionInfo = (
      <OptionSubHeader
        split="|"
        leftText={member.id}
        rightText={member.email}
      />
    )
  }

  return (
    <OptionContainer
      header={
        <>
          {formatUserFullName(
            member.first_name,
            member.last_name,
            member.preferred_name,
          )}{' '}
          {Object.keys(member.eligible_services).length === 0 && (
            <small data-dd-privacy="allow">
              ({t('patientProfile.deactivated')})
            </small>
          )}
        </>
      }
      subHeader={subOptionInfo}
    />
  )
}

export const MemberPicker = forwardRef<RefSelectProps, MemberPickerProps>(
  ({ variant, defaultId, placeholder, disabled, ...rest }, ref) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [hidden, setHidden] = useState(!defaultId)

    const results = useAppSelector(selectResults)
    const fetching = useAppSelector(selectIsFetching)

    if (!placeholder) {
      placeholder = t('patientSearch.placeholder')
    }

    useEffect(() => {
      if (!!defaultId) {
        dispatch(patientSearchActions.searchPatient(String(defaultId)))
      }
    }, [dispatch, defaultId])

    const handleDebouncedSearch = debounce((value: string) => {
      if (value.length === 0) {
        dispatch(patientSearchActions.clearSearchResults())
        setHidden(true)
      } else if (value.length >= 2) {
        const searchTermWithDoB = formatSearchTermWithDob(value)
        dispatch(patientSearchActions.searchPatient(searchTermWithDoB))
        setHidden(false)
      }
    }, DEBOUNCE_TIME)

    const options = useMemo(
      () =>
        ((results || []) as ScribeTypes.V2.User[]).map((user) => {
          return {
            value: user.id,
            label: <MemberInfo member={user} variant={variant} />,
            'data-testid': 'member-picker-option',
            'data-dd-action-name': 'member-picker:select:option',
          }
        }),
      [results, variant],
    )

    return (
      <ExtendedSelect
        {...rest}
        ref={ref}
        showSearch
        filterOption={false}
        placeholder={placeholder}
        loading={fetching}
        defaultValue={defaultId}
        disabled={disabled}
        onSearch={handleDebouncedSearch}
        options={options}
        dropdownStyle={hidden ? { display: 'none' } : {}}
        optionLabelProp="label"
        data-testid="member-picker"
        data-dd-action-name="member-picker:open"
        suffixIcon={!disabled && <SearchOutlined />}
        data-private
      />
    )
  },
)
