import type { ResolvedTemplate, LocalizedValue } from '@dialogue/notepad-client'

import type { LocalizedNoteTemplate } from 'app/redux/structured-notes/types'

/**
 * Localizes an object by transforming all properties that are LocalizedValues
 * to simple strings.
 *
 * @example `{ prop: {en: "English", fr: "French"} }` becomes `{ prop: 'English' }`
 * @param template A note template object that contains various nested properties
 * @param locale a simple string referring to the locale used to index localizations (eg: 'en', 'fr')
 * @returns a copy of the template, where all localized values are normalized as strings
 */
export const localizeTemplate = (
  template: ResolvedTemplate,
  locale: string,
): LocalizedNoteTemplate => {
  return localizeDeep(template, locale)
}

export const isLocalizedValue = (value: unknown): value is LocalizedValue => {
  return (
    !!value && typeof value === 'object' && ('fr' in value || 'en' in value)
  )
}

export const isLocale = (value: string): value is keyof LocalizedValue =>
  ['en', 'fr'].includes(value)

/**
 * Returns a copy of the object with all LocalizedValues transormed into simple
 * strings according to the locale
 */
export const localizeDeep = (
  obj: unknown,
  locale: string,
  stack: string[] = [],
) => {
  const isObject = typeof obj === 'object' || Array.isArray(obj)

  if (!isObject || !obj) {
    return obj
  }

  if (isLocalizedValue(obj) && isLocale(locale)) {
    let localizedValue = obj[locale]

    if (localizedValue === undefined) {
      localizedValue = `${stack.join('.')}.${locale}`
      console.warn(`Missing localization in template at "${localizedValue}"`)
    }

    return localizedValue
  }

  return Object.entries(obj).reduce(
    (output: any, [key, value]) => {
      output[key] =
        typeof value !== 'object' && !Array.isArray(value)
          ? value
          : localizeDeep(value, locale, [...stack, key])
      return output
    },
    Array.isArray(obj) ? [] : {},
  )
}
