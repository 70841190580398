import { combineReducers } from 'redux'

import memberMedicalProfile from './member-medical-profile'
import memberNotes from './member-notes'
import pharmacies from './pharmacies'

export default combineReducers({
  memberNotes,
  pharmacies,
  memberMedicalProfile,
})
