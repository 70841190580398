import type { EnrichedEpisode, TeamQueue } from '@dialogue/coredata'

export type CustomGroupNames = 'bot' | 'unassigned'
export const BOT_EPISODE_GROUP_ID: CustomGroupNames = 'bot'
export const UNASSIGNED_GROUP_ID: CustomGroupNames = 'unassigned'

export enum EpisodeQueuesTypes {
  ENQUEUE_EPISODE = '@@episodeQueues/ENQUEUE_EPISODE',
  ENQUEUE_EPISODE_SUCCESS = '@@episodeQueues/ENQUEUE_EPISODE_SUCCESS',
  ENQUEUE_EPISODE_ERROR = '@@episodeQueues/ENQUEUE_EPISODE_ERROR',

  START_POLLING_TEAM_EPISODES = '@@episodeQueues/START_POLLING_TEAM_EPISODES',
  STOP_POLLING_TEAM_EPISODES = '@@episodeQueues/STOP_POLLING_TEAM_EPISODES',
  SET_TEAM_EPISODE_GROUPS = '@@episodeQueues/SET_TEAM_EPISODE_GROUPS',

  START_POLLING_TEAMS = '@@episodeQueues/START_POLLING_TEAMS',
  STOP_POLLING_TEAMS = '@@episodeQueues/STOP_POLLING_TEAMS',
  SET_TEAMS = '@@episodeQueues/SET_TEAMS',
}

export interface EpisodeAssignee {
  id: number | CustomGroupNames
  name?: string
  picture?: string | null
  licenses_admin_areas?: string[] | null
  main_specialization?: string | null
}

export interface TeamEpisodeGroup {
  assignee?: EpisodeAssignee
  episodes: EnrichedEpisode[]
  hasActiveFilters?: boolean
}

export interface EpisodeQueuesState {
  teamsQueues: TeamQueue[]
  queuedEpisodeGroups: Record<string, TeamEpisodeGroup[]>
}
