// @ts-strict-ignore
import { notification } from 'antd'
import ISO31661 from 'iso-3166-1'
import ISO6391 from 'iso-639-1'
import moment from 'moment'
import { all, call, put, takeLatest } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import { EMERALD_ENABLED } from 'app/config/features'
import { DATE_FORMAT } from 'app/config/utils'
import i18n from 'app/i18n'
import { createProfileActions } from 'app/redux/create-profile'
import type { CreateProfileFormValues } from 'app/redux/create-profile/types'
import routes from 'app/services/routes'

import {
  initEmeraldClient,
  initEmergencyRoomClient,
  initMultipassClient,
  initScribeV2Client,
} from './utils'

export function* multipassRequest(
  formValues: CreateProfileFormValues,
  brandId: string | null,
  organizationId: number | null,
  uniqueIdentifier: string,
) {
  const securityPin = formValues.securityPin?.replace(/\s/g, '') || null

  const requestProps = {
    email: formValues.email,
    pin: securityPin,
    communication_language: formValues.communicationLanguage,
    brand_id: brandId,
    enforce_eligibility: true,
    run_login_actions: true,
    access_code: formValues.accessCode,
    organization_id: organizationId,
    unique_identifier: uniqueIdentifier,
  }

  const client = yield* call(initMultipassClient)
  return yield* call(client.createUser, requestProps)
}

export function* emergencyRoomRequest(
  patientId: string | number,
  formValues: CreateProfileFormValues,
) {
  // Normalize input
  const date_of_birth = moment(formValues.birthdate, DATE_FORMAT).format(
    'YYYY-MM-DD',
  )
  const preferred_language = ISO6391.getCode(formValues.communicationLanguage)

  // Request
  const profile = {
    date_of_birth,
    family_name: formValues.lastName,
    given_name: formValues.firstName,
    has_consented: formValues.hasConsented,
    phone_number: formValues.phone,
    admin_area_iso_code: `${formValues.country}-${formValues.adminArea}`,
    country_iso_code: formValues.country,
    locality: formValues.city,
    postal_code: formValues.postalCode,
    street_number: formValues.streetAddress,
    street_number2: formValues.unit,
    preferred_language,
    preferred_name: formValues.preferredName,
    resides_in: formValues.adminArea,
  }

  const client = yield* call(initEmergencyRoomClient)
  yield* call(client.updatePatient, patientId, profile)
}

export function* emeraldLinkProfileRequest(
  patientId: string | number,
  formValues: CreateProfileFormValues,
) {
  // Normalize input
  const date_of_birth = moment(formValues.birthdate, DATE_FORMAT).format(
    'YYYY-MM-DD',
  )

  // Request
  const requestProps = {
    date_of_birth,
    dia_id: patientId,
    email: formValues.email,
    first_name: formValues.firstName,
    last_name: formValues.lastName,
  }

  const client = yield* call(initEmeraldClient)
  yield* call(client.linkOrCreatePatient, requestProps)
}

export function* emeraldUpdateProfileRequest(
  patientId: string | number,
  formValues: CreateProfileFormValues,
) {
  // Normalize input
  const country = ISO31661.whereAlpha2(formValues.country)?.country
  const date_of_birth = moment(formValues.birthdate, DATE_FORMAT).format(
    'YYYY-MM-DD',
  )

  const streetAddressWithUnit = [
    formValues.unit,
    formValues.streetAddress,
  ].join('-')

  const address = [
    streetAddressWithUnit,
    formValues.city,
    formValues.postalCode,
    formValues.adminArea,
    country,
  ]
    .filter(Boolean)
    .join(', ')

  // Request
  const requestProps = {
    city: formValues.city,
    country,
    date_of_birth,
    first_name: formValues.firstName,
    last_name: formValues.lastName,
    phone: formValues.phone,
    preferred_name: formValues.preferredName,
    region: formValues.adminArea,
    street_address: address,
    zipcode: formValues.postalCode,
  }

  const client = yield* call(initEmeraldClient)
  yield* call(client.updatePatient, patientId, requestProps)
}

export function* doCreateMemberProfile({
  payload: { formId, formValues, brandId, organizationId, uniqueIdentifier },
}: ActionType<typeof createProfileActions.request>) {
  let step = 'Init'
  try {
    step = 'Multipass'
    const {
      scribe_user: { id: patientId },
    } = yield* call(
      multipassRequest,
      formValues,
      brandId,
      organizationId,
      uniqueIdentifier,
    )

    if (EMERALD_ENABLED) {
      step = 'Emerald Link Profile'
      yield* call(emeraldLinkProfileRequest, patientId, formValues)

      step = 'Emerald Update Profile'
      yield* call(emeraldUpdateProfileRequest, patientId, formValues)
    }

    // This call is intentionally after the Emerald requests to delay its execution (race condition)
    // If the Emerald requests are removed, the bug will resurface. See DIA-48906
    step = 'Emergency room'
    yield* call(emergencyRoomRequest, patientId, formValues)

    yield* put(createProfileActions.success({ formId }))
    // If this could happen using extraReducers, that seems better but wasn't working for me
    yield* put(createProfileActions.clearAccessCodeState())
    yield* call(notifyOfSuccessAndNavigateToProfile, patientId)
  } catch (error) {
    notification.error({
      message: String(error),
      duration: null,
      description: i18n.t('patientProfile.error.creatingProfile', {
        id: formId,
        step,
      }),
    })
    yield* put(createProfileActions.failure({ formId, error }))
  }
}

export function notifyOfSuccessAndNavigateToProfile(
  patientId: string | number,
) {
  notification.success({
    message: i18n.t('memberProfile.profileCreateSuccess', { patientId }),
  })
  window.location.hash = '#' + routes.memberProfile(patientId)
}

export function* verifyAccessCode({
  payload: { accessCode },
}: ActionType<typeof createProfileActions.validateAccessCode>) {
  try {
    const scribe = yield* call(initScribeV2Client)
    const isAccessCodeValid = yield* call(
      scribe.validateOrganizationEnrolmentCode,
      accessCode,
    )

    if (isAccessCodeValid) {
      yield* put(createProfileActions.validateAccessCodeSuccess({ accessCode }))
    } else {
      yield* put(
        createProfileActions.validateAccessCodeFailure({
          accessCode,
          error: new Error('Access code is invalid'),
        }),
      )
    }
  } catch (error) {
    yield* put(
      createProfileActions.validateAccessCodeFailure({
        accessCode,
        error,
      }),
    )
  }
}

export default function* createMemberProfileSagas() {
  yield* all([
    takeLatest(createProfileActions.request, doCreateMemberProfile),
    takeLatest(createProfileActions.validateAccessCode, verifyAccessCode),
  ])
}
