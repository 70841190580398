import type { EmergencyRoomTypes } from '@dialogue/services'

export enum UserManagementTypes {
  ADD_OR_UPDATE_PRACTITIONER = '@@userManagement/ADD_OR_UPDATE_PRACTITIONER',
  ADD_OR_UPDATE_PRACTITIONER_SUCCESS = '@@userManagement/ADD_OR_UPDATE_PRACTITIONER_SUCCESS',
  ADD_OR_UPDATE_PRACTITIONER_FAILURE = '@@userManagement/ADD_OR_UPDATE_PRACTITIONER_FAILURE',

  GET_PRACTITIONER = '@@userManagement/GET_PRACTITIONER',
  GET_PRACTITIONER_SUCCESS = '@@userManagement/GET_PRACTITIONER_SUCCESS',
  GET_PRACTITIONER_FAILURE = '@@userManagement/GET_PRACTITIONER_FAILURE',

  GET_LICENSES = '@@userManagement/GET_LICENSES',
  GET_LICENSES_SUCCESS = '@@userManagement/GET_LICENSES_SUCCESS',
  GET_LICENSES_FAILURE = '@@userManagement/GET_LICENSES_FAILURE',

  CREATE_LICENSE = '@@userManagement/CREATE_LICENSE',
  UPDATE_LICENSE = '@@userManagement/UPDATE_LICENSE',
  DELETE_LICENSE = '@@userManagement/DELETE_LICENSE',

  CRUD_LICENSE_SUCCESS = '@@userManagement/CRUD_LICENSE_SUCCESS',
  CRUD_LICENSE_FAILURE = '@@userManagement/CRUD_LICENSE_FAILURE',

  GET_SPECIALIZATIONS = '@@userManagement/GET_SPECIALIZATIONS',
  GET_SPECIALIZATIONS_SUCCESS = '@@userManagement/GET_SPECIALIZATIONS_SUCCESS',
  GET_SPECIALIZATIONS_FAILURE = '@@userManagement/GET_SPECIALIZATIONS_FAILURE',

  GET_EMR_ID = '@@userManagement/GET_EMR_ID',
  GET_EMR_ID_SUCCESS = '@@userManagement/GET_EMR_ID_SUCCESS',
  GET_EMR_ID_FAILURE = '@@userManagement/GET_EMR_ID_FAILURE',
}

export interface Practitioner {
  fetchingLicenses?: boolean
  licensesError?: Record<string, any>
  fetching?: boolean
  errorFetching?: Record<string, any>
  updating?: boolean
  errorUpdating?: Record<string, any>
  requestLicense?: boolean
  licenseError?: Record<string, any>
  fetchingEmrId?: boolean
  errorFetchingEmrId?: Record<string, any>

  profile?: EmergencyRoomTypes.ExtendedPractitioner
  licenses?: Record<string, EmergencyRoomTypes.License>
  emrId?: string | number
}

export interface UserManagementState {
  practitioners: Record<string, Practitioner>
  specializations: EmergencyRoomTypes.MainSpecialization[]
  fetchSpecializationsError?: Error
  fetchingSpecializations: boolean
}
