import { Emerald } from '@dialogue/services'
import type { PatientLinkInfo } from '@dialogue/services/dist/emerald/model'
import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeLeading,
} from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import appConfig from 'app/config'
import { selectAccessToken } from 'app/redux/authentification/selectors'
import * as InputHealthActions from 'app/redux/input-health/actions'
import { InputHealthTypes } from 'app/redux/input-health/types'
import { selectPatientProfile } from 'app/redux/patients/selectors'

import { poll } from './utils'

// Poll every 4 mins as the IH S3 urls expire in ~5 mins
export const POLLING_INTERVAL_MS = 240_000

export function* getClient(config = appConfig) {
  const accessToken = yield* select(selectAccessToken)

  if (!config.EMERALD_API_URL) {
    throw new Error('Emerald config is not defined')
  }

  const emeraldClient = new Emerald(accessToken, config.EMERALD_API_URL)

  return emeraldClient
}

export function* verifyRecord(
  action: ActionType<typeof InputHealthActions.verifyRecord>,
) {
  const patientId = action.payload

  try {
    const client = yield* call(getClient)
    const res = yield* call(client.getPatient, patientId)
    yield* put(InputHealthActions.verifyRecordSuccess(patientId, res))
    return res
  } catch (error) {
    // We automatically link the record if it does not exist
    if (error.code === 404) {
      yield* call(() => linkRecord(patientId))
    } else {
      yield* put(InputHealthActions.verifyRecordError(patientId, error))
    }
  }
}

export function* linkRecord(patientId: string) {
  const profile = yield* select(selectPatientProfile, patientId)

  const patientLinkInfo: PatientLinkInfo = {
    dia_id: patientId,
    first_name: profile.first_name,
    last_name: profile.last_name,
    date_of_birth: profile.birthdate,
    email: profile.email,
    gender: profile.sex,
    phone: profile.phone_number,
    gender_identity: profile.gender_identity,
    gender_identity_description: profile.gender_identity_description,
    preferred_name: profile.preferred_name,
  }

  try {
    const client = yield* call(getClient)
    const res = yield* call(client.linkOrCreatePatient, patientLinkInfo)
    yield* put(InputHealthActions.verifyRecordSuccess(patientId, res))
    return res
  } catch (error) {
    yield* put(InputHealthActions.verifyRecordError(patientId, error))
  }
}

export function* getPatientDocuments(
  action: ActionType<
    | typeof InputHealthActions.getPatientDocuments
    | typeof InputHealthActions.startPollingPatientDocuments
  >,
) {
  try {
    const client = yield* call(getClient)
    const res = yield* call(client.getPatientDocuments, action.payload)

    yield* put(InputHealthActions.getPatientDocumentsSuccess(res))
    return res
  } catch (error) {
    yield* put(InputHealthActions.getPatientDocumentsFailure(error))
  }
}

export function* getPatientFormUrl(patientId: number, documentId: string) {
  const client = yield* call(getClient)

  return yield* call(client.getReferral, patientId, documentId)
}

export default function* inputHealthSagas() {
  yield* all([
    takeLeading(InputHealthTypes.VERIFY_RECORD, verifyRecord),
    takeLatest(InputHealthTypes.GET_PATIENT_DOCUMENTS, getPatientDocuments),
    poll(
      InputHealthTypes.START_POLLING_PATIENT_DOCUMENTS,
      InputHealthTypes.STOP_POLLING_PATIENT_DOCUMENTS,
      POLLING_INTERVAL_MS,
      getPatientDocuments,
    ),
  ])
}
