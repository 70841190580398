import type {
  EpisodeApiGetEpisodeRequest,
  ItemEnrichedEpisode,
} from '@dialogue/coredata'

import { emergencyRoomApi, Tags } from './api'

/**
 * NOTE: this API was created for the faxing outbox component; all other components are
 * still using the legacy redux/sagas pattern for fetching an episode's data.
 * src/app/redux/episodes, src/app/sagas/episodes.ts
 *
 * TODO: refactor to include other episode-related endpoints, and implement in components.
 */
export const episodesApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getEpisode: build.query({
      query: ({ episodeId }: EpisodeApiGetEpisodeRequest) => ({
        url: `/v1/episodes/${episodeId}`,
      }),
      transformResponse: (response: ItemEnrichedEpisode) => response.data,
      providesTags: (_result, _error, { episodeId }) => [
        { type: Tags.Episodes, id: episodeId },
      ],
    }),
  }),
})

export const { useGetEpisodeQuery } = episodesApi
