import { action } from 'typesafe-actions'

import { type Intent, RasaIntentsActionType } from './types'

export const getRasaIntents = () =>
  action(RasaIntentsActionType.GET_RASA_INTENTS)

export const getRasaIntentsSuccess = (intents: Intent[]) =>
  action(RasaIntentsActionType.GET_RASA_INTENTS_SUCCESS, { intents })

export const getRasaIntentsFailure = (error: Error) =>
  action(RasaIntentsActionType.GET_RASA_INTENTS_ERROR, { error })
