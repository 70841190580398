import type { EmergencyRoomTypes } from '@dialogue/services'
import { action } from 'typesafe-actions'

import { UserManagementTypes } from './types'

export const addOrUpdatePractitioner = (
  practitionerId: string,
  practitionerProfile: Partial<EmergencyRoomTypes.ExtendedPractitioner>,
) =>
  action(UserManagementTypes.ADD_OR_UPDATE_PRACTITIONER, {
    practitionerId,
    practitionerProfile,
  })

export const addOrUpdatePractitionerSuccess = (
  practitionerId: string,
  practitionerProfile: EmergencyRoomTypes.ExtendedPractitioner,
) =>
  action(UserManagementTypes.ADD_OR_UPDATE_PRACTITIONER_SUCCESS, {
    practitionerId,
    practitionerProfile,
  })

export const addOrUpdatePractitionerFailure = (
  practitionerId: string,
  error: Record<string, any>,
) =>
  action(
    UserManagementTypes.ADD_OR_UPDATE_PRACTITIONER_FAILURE,
    { practitionerId },
    null,
    error,
  )

export const getPractitioner = (practitionerId: string) =>
  action(UserManagementTypes.GET_PRACTITIONER, { practitionerId })

export const getPractitionerSuccess = (
  practitionerId: string,
  practitionerProfile: EmergencyRoomTypes.ExtendedPractitioner,
) =>
  action(UserManagementTypes.GET_PRACTITIONER_SUCCESS, {
    practitionerId,
    practitionerProfile,
  })

export const getPractitionerFailure = (
  practitionerId: string,
  error: Record<string, any>,
) =>
  action(
    UserManagementTypes.GET_PRACTITIONER_FAILURE,
    { practitionerId },
    null,
    error,
  )

export const getLicenses = (practitionerId: string) =>
  action(UserManagementTypes.GET_LICENSES, { practitionerId })

export const getLicensesSuccess = (
  practitionerId: string,
  licenses: EmergencyRoomTypes.License[],
) =>
  action(UserManagementTypes.GET_LICENSES_SUCCESS, { practitionerId, licenses })

export const getLicensesFailure = (
  practitionerId: string,
  error: Record<string, any>,
) =>
  action(
    UserManagementTypes.GET_LICENSES_FAILURE,
    { practitionerId },
    null,
    error,
  )

export const createLicense = (
  practitionerId: string,
  license: EmergencyRoomTypes.NewLicense,
) => action(UserManagementTypes.CREATE_LICENSE, { practitionerId, license })

export const updateLicense = (
  practitionerId: string,
  licenseId: number,
  license: EmergencyRoomTypes.NewLicense,
) =>
  action(UserManagementTypes.UPDATE_LICENSE, {
    practitionerId,
    licenseId,
    license,
  })

export const deleteLicense = (practitionerId: string, licenseId: number) =>
  action(UserManagementTypes.DELETE_LICENSE, { practitionerId, licenseId })

export const crudLicenseSuccess = (
  practitionerId: string,
  licenseId: number,
  license?: EmergencyRoomTypes.License,
) =>
  action(UserManagementTypes.CRUD_LICENSE_SUCCESS, {
    practitionerId,
    licenseId,
    license,
  })

export const crudLicenseFailure = (
  practitionerId: string,
  error: Record<string, any>,
) =>
  action(
    UserManagementTypes.CRUD_LICENSE_FAILURE,
    { practitionerId },
    null,
    error,
  )

export const getSpecializations = () =>
  action(UserManagementTypes.GET_SPECIALIZATIONS)

export const getSpecializationsSuccess = (
  specializations: EmergencyRoomTypes.MainSpecialization[],
) =>
  action(UserManagementTypes.GET_SPECIALIZATIONS_SUCCESS, { specializations })
export const getSpecializationsFailure = (error: Error) =>
  action(UserManagementTypes.GET_SPECIALIZATIONS_FAILURE, { error })

export const getEmrId = (practitionerId: string, email: string) =>
  action(UserManagementTypes.GET_EMR_ID, { practitionerId, email })

export const getEmrIdSuccess = (
  practitionerId: string,
  emrId: number | string,
) =>
  action(UserManagementTypes.GET_EMR_ID_SUCCESS, {
    practitionerId,
    emrId,
  })

export const getEmrIdFailure = (practitionerId: string, error: Error) =>
  action(
    UserManagementTypes.GET_EMR_ID_FAILURE,
    { practitionerId },
    null,
    error,
  )
