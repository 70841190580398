import { sprig } from '@sprig-technologies/sprig-browser'

import config from 'app/config'

let Sprig: null | WindowSprig = null

if (config.SPRIG_ENV_ID) {
  Sprig = sprig.configure({
    environmentId: config.SPRIG_ENV_ID,
  })
}

export { Sprig }
