import { type AnyAction, createAction, createReducer } from '@reduxjs/toolkit'

export interface HistoryCheckpoint {
  path: string
  title: string
}

export interface ActionReplayItem {
  replayKey: string
  action: AnyAction
  replayFrequency: number
}

export interface State {
  historyCheckpoints: HistoryCheckpoint[]
  replayWatches: ActionReplayItem[]
  replayActionLastRunAt: Record<string, number>
}

export const addPeriodicActionReplay = createAction<ActionReplayItem>(
  '@@app/addPeriodicActionReplay',
)

export const setPeriodicActionReplayLastRunAt = createAction<{
  replayKey: string
  date: number
}>('@@app/setPeriodicActionReplayLastRunAt')

export const removePeriodicActionReplay = createAction<{ replayKey: string }>(
  '@@app/removePeriodicActionReplay',
)

export const addHistoryCheckPoint = createAction<HistoryCheckpoint>(
  '@@app/addHistoryCheckPoint',
)

export const INITIAL_STATE: State = {
  replayWatches: [],
  replayActionLastRunAt: {},
  historyCheckpoints: [],
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(addPeriodicActionReplay, (state, { payload }) => {
    const replayItem = payload
    const replayWatches = state.replayWatches

    const existingReplayIndex = replayWatches.findIndex(
      (e) => e.replayKey === replayItem.replayKey,
    )

    if (existingReplayIndex !== -1) {
      replayWatches[existingReplayIndex] = replayItem
    } else {
      replayWatches.unshift(replayItem)
    }
  })

  builder.addCase(setPeriodicActionReplayLastRunAt, (state, { payload }) => {
    state.replayActionLastRunAt[payload.replayKey] = payload.date
  })

  builder.addCase(removePeriodicActionReplay, (state, { payload }) => {
    const { replayKey } = payload
    const replayIndex = state.replayWatches.findIndex(
      (e) => e.replayKey === replayKey,
    )

    if (replayIndex !== -1) {
      state.replayWatches.splice(replayIndex, 1)
    }
  })

  builder.addCase(addHistoryCheckPoint, (state, { payload }) => {
    const existingIndex = state.historyCheckpoints.findIndex(
      (e) => e.path === payload.path,
    )

    if (existingIndex !== -1) {
      state.historyCheckpoints.splice(existingIndex, 1)
    }

    state.historyCheckpoints.unshift(payload)
  })
})
