import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { defaultAdminArea, defaultCountry } from 'app/lib/data-models'
import type { ReduxState } from 'app/redux'

import type {
  CreateProfileState,
  CreateProfileFormValues,
  CreateProfileRequest,
  CreateProfileSuccess,
  CreateProfileFailure,
} from './types'

export const INITIAL_STATE: CreateProfileState = {
  requests: {},
  accessCodeRequest: {
    accessCode: null,
    fetching: false,
    success: null,
    error: null,
  },
}

export const INITIAL_FORM_VALUES: CreateProfileFormValues = {
  country: defaultCountry,
  adminArea: defaultAdminArea,
  hasConsented: true,
  communicationLanguage: 'english',
}

export const { reducer, actions: createProfileActions } = createSlice({
  name: '@@createProfile',
  initialState: INITIAL_STATE,
  reducers: {
    formStart: (state, action: PayloadAction<CreateProfileRequest>) => {
      const { requests } = state
      const {
        formId,
        formValues,
        brandId,
        enableAccessCode,
        organizationId,
        uniqueIdentifier,
        attributeSchemaCommunicationEmail,
        attributeSchemaSignupIdentifier,
      } = action.payload
      requests[formId] = {
        ...requests[formId],
        formValues: {
          ...INITIAL_FORM_VALUES,
          ...requests[formId]?.formValues,
          ...formValues,
        },
        formId,
        formState: 'filling',
        brandId,
        enableAccessCode,
        organizationId,
        uniqueIdentifier,
        attributeSchemaCommunicationEmail,
        attributeSchemaSignupIdentifier,
      }
    },
    formPause: (state, action: PayloadAction<CreateProfileRequest>) => {
      const { requests } = state
      const { formId, formValues } = action.payload
      requests[formId] = {
        ...requests[formId],
        formValues: {
          ...requests[formId]?.formValues,
          ...formValues,
        },
        formState: 'paused',
      }
    },
    request: (state, action: PayloadAction<CreateProfileRequest>) => {
      const { requests } = state
      const { formId, formValues, organizationId, uniqueIdentifier } =
        action.payload
      requests[formId] = {
        ...requests[formId],
        formValues: {
          ...requests[formId]?.formValues,
          ...formValues,
        },
        formState: 'submitting',
        organizationId,
        uniqueIdentifier,
      }
    },
    success: (state, action: PayloadAction<CreateProfileSuccess>) => {
      const { requests } = state
      const { formId } = action.payload
      requests[formId] = {
        ...requests[formId],
        formState: 'complete',
      }
    },
    failure: (state, action: PayloadAction<CreateProfileFailure>) => {
      const { requests } = state
      const { formId, error } = action.payload
      requests[formId] = {
        ...requests[formId],
        formState: 'error',
        error,
      }
    },
    validateAccessCode: (state, action) => {
      const { accessCode } = action.payload
      state.accessCodeRequest = {
        accessCode,
        fetching: true,
        success: null,
        error: null,
      }
    },
    validateAccessCodeSuccess: (state, action) => {
      const { accessCode } = action.payload
      state.accessCodeRequest = {
        accessCode,
        fetching: false,
        success: true,
        error: null,
      }
    },
    validateAccessCodeFailure: (state, action) => {
      const { accessCode, error } = action.payload
      state.accessCodeRequest = {
        accessCode,
        fetching: false,
        success: false,
        error,
      }
    },
    clearAccessCodeState: (state) => {
      state.accessCodeRequest = INITIAL_STATE.accessCodeRequest
    },
  },
})

export default reducer

export const selectFirstActiveForm = (state: ReduxState) => {
  const activeForm = Object.values(state.createProfile.requests).find(
    ({ formState }) =>
      formState !== 'paused' && formState !== 'complete' && formState !== null,
  )
  return activeForm || null
}

export const selectAccessCodeRequestState = (state: ReduxState) =>
  state?.createProfile?.accessCodeRequest
