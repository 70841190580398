// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type { chatActions } from '../chat'
import { ChatTypes } from '../chat/types'

import type * as actions from './actions'
import { type PractitionerState, PractitionerTypes } from './types'

export const INITIAL_STATE: PractitionerState = {
  profiles: {},
  mmIdMap: {},
  fetchingProfiles: false,
  errorFetchingProfiles: null,
}

export type PractitionersAction = ActionType<typeof actions>

export const reducer: Reducer<
  typeof INITIAL_STATE,
  PractitionersAction | ActionType<typeof chatActions.gotStatuses>
> = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case PractitionerTypes.REQUEST_PROFILES:
        draft.fetchingProfiles = true
        draft.errorFetchingProfiles = null
        break

      case PractitionerTypes.REQUEST_PROFILES_SUCCESS:
        draft.fetchingProfiles = false
        action.payload.practitioners.forEach((practitioner) => {
          draft.profiles[practitioner.props.app_id] = {
            id: practitioner.props.app_id,
            online: !!state.profiles[practitioner.props.app_id]?.online,
            mm_id: practitioner.id,
            first_name: practitioner.first_name,
            last_name: practitioner.last_name,
            nickname: practitioner.nickname,
            email: practitioner.email,
            locale: practitioner.locale,
            picture: practitioner.props.picture,
            username: practitioner.username,
          }
          draft.mmIdMap[practitioner.id] = practitioner.props.app_id
        })
        break

      case PractitionerTypes.REQUEST_PROFILES_FAILURE:
        draft.fetchingProfiles = false
        draft.errorFetchingProfiles = action.payload.error
        break

      case ChatTypes.GOT_STATUSES:
        const statuses = action.payload.statuses || {}
        Object.entries(state.profiles).forEach(([id, { mm_id }]) => {
          draft.profiles[id].online = statuses[mm_id] === 'online'
        })
        break
    }
  })

export default reducer
