import { useCallback, useEffect } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  useLocation,
  useMatch,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { DocumentLinkParam } from 'app/containers/documents/document-actions/types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useLazyGetReceivedFaxQuery } from 'app/redux/api/document-center/faxes'
import {
  DocumentKind,
  type DocumentSource,
  documentViewerActions,
  selectDocumentViewerState,
} from 'app/redux/documents/viewer'
import { selectEpisodeViewEpisode } from 'app/redux/episode-view/selectors'

/**
 * Intercept deeplinks, launch resolution flow, and clear parameters
 */
const useDocumentLinkResolver = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const { patientId: pathPatientId, episodeId } = useParams<
    'patientId' | 'episodeId'
  >()

  const episodePatientId = useAppSelector((state) => {
    const episode = selectEpisodeViewEpisode(state)

    // only resolve once the correct episode has been loaded upstream,
    // to handle deeplinking when on another episode
    if (!episode || episode.id !== episodeId) {
      return null
    }

    return episode.subject_id || episode.patient_id
  })

  const isMemberProfile = useMatch('/member/*')

  // member-profile: use path param; episode view: use episode subject
  const memberId = isMemberProfile ? pathPatientId : episodePatientId

  const documentId = searchParams.get(DocumentLinkParam.ID)
  const documentSource = searchParams.get(DocumentLinkParam.SOURCE)
  const documentKind = searchParams.get(DocumentLinkParam.KIND)

  const [getReceivedFax] = useLazyGetReceivedFaxQuery()

  const clearSearchParams = useCallback(() => {
    setSearchParams(
      (previous) => {
        previous.delete(DocumentLinkParam.ID)
        previous.delete(DocumentLinkParam.SOURCE)
        previous.delete(DocumentLinkParam.KIND)
        return previous
      },
      { replace: true },
    )
  }, [setSearchParams])

  useEffect(() => {
    // setSearchParams is async, so effect is needed
    switch (documentKind) {
      case DocumentKind.MEMBER_DOCUMENT:
        if (memberId && documentId && documentSource) {
          dispatch(
            documentViewerActions.resolveAndViewMemberDocument({
              memberId: Number(memberId),
              documentId,
              documentSource: documentSource as DocumentSource,
            }),
          )
          // clear params once we try to resolve
          clearSearchParams()
        }
        break

      case DocumentKind.INCOMING_FAX_DOCUMENT:
        const fetchReceivedFax = async () => {
          if (documentId) {
            try {
              const { data: receivedFax } = await getReceivedFax({
                documentId,
              }).unwrap()

              if (receivedFax) {
                dispatch(
                  documentViewerActions.viewIncomingFaxDocument({
                    document: receivedFax,
                  }),
                )
              }
            } catch (error) {
              datadogLogs.logger.error(
                'Failed to fetch received fax',
                {},
                error,
              )
              notification.error({
                message: t('faxes.error.receivedFax'),
              })
            }

            clearSearchParams()
          }
        }

        fetchReceivedFax()
        break

      default:
        break
    }
  }, [
    dispatch,
    memberId,
    documentId,
    documentSource,
    documentKind,
    clearSearchParams,
    getReceivedFax,
    t,
  ])
}

/**
 * Hook which exposes selected document and handler to close it.
 *
 * Use it to hook any details container to the view-document.
 * The hook also handles closing the document for you on navigation.
 *
 */
export const useDocumentDetails = () => {
  const dispatch = useAppDispatch()
  const document = useAppSelector(
    (state) => selectDocumentViewerState(state).selectedDocument,
  )

  useDocumentLinkResolver()

  const { pathname } = useLocation()

  const closeDocument = useCallback(() => {
    dispatch(documentViewerActions.close())
  }, [dispatch])

  useEffect(() => {
    return () => {
      // On navigate away from documents
      dispatch(documentViewerActions.close())
    }
  }, [pathname, dispatch])

  return { document, closeDocument }
}
