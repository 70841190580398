import { useVariation } from 'app/hooks'
import {
  DocumentKind,
  DocumentSource,
  type ViewerDocument,
} from 'app/redux/documents/viewer'
import { Flags } from 'app/services/feature-flags'

import { AnticipatedOutgoingFaxPanel } from './anticipated-outgoing-fax-panel'
import type { OutgoingFaxMetadata } from './common'
import { IncomingFaxPanel } from './incoming-fax-panel'
import { OldFaxDocumentPanel } from './incoming-fax-panel/old-incoming-fax-document-panel'
import { InputHealthPanel } from './input-health-panel'
import { MemberPanel } from './member-panel'
import { OutgoingFaxPanel } from './outgoing-fax-panel'

interface Props {
  document: ViewerDocument
  onOutgoingFaxDocumentCancel: () => void
  onConfirmSendFax: () => void
  onClose: () => void
  outgoingFaxMetadata: OutgoingFaxMetadata | null
}

export const DocumentInfoPanel = ({
  document,
  onOutgoingFaxDocumentCancel,
  onConfirmSendFax,
  onClose,
  outgoingFaxMetadata,
}: Props) => {
  const enableExplicitFaxTriage = useVariation(
    Flags.enableExplicitFaxTriage,
    true,
  )

  if (document.source === DocumentSource.INPUT_HEALTH) {
    return <InputHealthPanel document={document} />
  }

  switch (document.kind) {
    case DocumentKind.MEMBER_DOCUMENT:
      return <MemberPanel onClose={onClose} document={document} />

    case DocumentKind.INCOMING_FAX_DOCUMENT:
      // Keep the old way of triaging & processing faxes for now, until ops is ready to adopt
      return enableExplicitFaxTriage ? (
        <IncomingFaxPanel document={document} />
      ) : (
        <OldFaxDocumentPanel document={document} />
      )

    case DocumentKind.OUTGOING_FAX_DOCUMENT:
      return <OutgoingFaxPanel document={document} />

    case DocumentKind.ANTICIPATED_OUTGOING_FAX:
      return (
        <AnticipatedOutgoingFaxPanel
          onCancel={onOutgoingFaxDocumentCancel}
          onConfirm={onConfirmSendFax}
          outgoingFaxMetadata={outgoingFaxMetadata}
        />
      )

    default:
      return null
  }
}
