import type { SectionState } from './split-document-panel'

/**
 * A utility function for converting a string page range input into a numbers array.
 * Used for validating the split document logic.
 *
 * Accepted formats:
 * - Single page: `1`
 * - Page range: `1-3`
 * - Multiple pages: `1,2,3`
 */
export const convertStringPageRangeToNumbers = (value: string): number[] => {
  return value.split(',').flatMap((part) => {
    if (part.includes('-')) {
      const [start, end] = part.split('-').map(Number)
      return Array.from({ length: end - start + 1 }, (_, i) => start + i)
    }
    return [Number(part)]
  })
}

export const hasDuplicatePages = (sections: SectionState[]): boolean => {
  const allNumbers = sections.flatMap((section) => section.numbers)
  return allNumbers.length !== new Set(allNumbers).size
}
