import {
  /* eslint-disable no-restricted-imports */
  useSelector,
  useDispatch,
  /* eslint-enable no-restricted-imports */
  type TypedUseSelectorHook,
} from 'react-redux'

import type { AppDispatch, ReduxState } from 'app/redux'

export const useAppSelector: TypedUseSelectorHook<ReduxState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
