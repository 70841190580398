import { useCallback, useContext } from 'react'

import { LeftOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { colors } from 'app/theme'

import { TaskDrawerContext } from './task-drawer-context'

const { Text } = Typography

const BackText = styled(Text)`
  display: block;
  border-bottom: 1px solid ${colors.textDark};
  margin-top: -16px;
  margin-bottom: 16px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  .anticon {
    padding-right: 4px;
  }
`

type Props = {
  parentId?: string
}

export const TaskBackButton = ({ parentId }: Props) => {
  const { t } = useTranslation()
  const { setDrawerTask } = useContext(TaskDrawerContext)

  const handleBack = useCallback(() => {
    if (!parentId) return
    setDrawerTask(parentId)
  }, [setDrawerTask, parentId])

  return parentId ? (
    <BackText
      onClick={handleBack}
      data-dd-privacy="allow"
      data-dd-action-name="task-form:goback"
    >
      <LeftOutlined />
      {t('tasks.navigation.goToParentTask')}
    </BackText>
  ) : null
}
