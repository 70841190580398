import { useCallback, useContext } from 'react'

import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

import { TaskDrawerContext } from 'app/containers/tasks/task-drawer-context'

interface TasksCreateNewProps {
  memberId?: number
  episodeId?: string
  documentIds?: string[]
  loading?: boolean
}

const TasksCreateNew = ({
  memberId,
  episodeId,
  documentIds,
  loading,
}: TasksCreateNewProps) => {
  const { t } = useTranslation()

  const { setDrawerInitialData } = useContext(TaskDrawerContext)

  const handleClick = useCallback(() => {
    setDrawerInitialData({
      member_id: memberId,
      episode_id: episodeId,
      document_ids: documentIds,
    })
  }, [documentIds, episodeId, memberId, setDrawerInitialData])

  if (!memberId) return null

  return (
    <>
      <Button
        data-cy="create-task-button"
        type="link"
        onClick={handleClick}
        data-dd-privacy="allow"
        data-dd-action-name="create:task"
        css={`
          padding: 0;
          margin-bottom: 5px;
          height: 100%;
        `}
        // When used in the context of a disabled form, we want the link to remain clickable
        disabled={false}
        loading={loading}
      >
        {t('tasks.actions.create')}
      </Button>
    </>
  )
}

export default TasksCreateNew
