import { useCallback, useEffect, useRef, useState } from 'react'

import forage from 'localforage'
import debounce from 'lodash/debounce'

/**
 * Hook which behaves similarly to useState, but persists
 * the value using localForage, using the passed second argument
 * as a storage key.
 *
 * This hook also handles flushing the storage and restoring
 * drafts when the key changes.
 *
 * Since loading the value from storage is async (and slow), isValueLoaded
 * allows to know when the value is loaded and ready to be used.
 *
 * Returns a 4-item tuple: `[ state, setState, clear, isValueLoaded ]`
 *
 * Example:
 * ```tsx
 * const MyForm = () => {
 *    const [value, setValue, clearValue, isValueLoaded] =
 *      usePersistedValue(null, 'my-form')
 *
 *    const onSubmit = () => {
 *      console.log("submitted: " + value)
 *      clearValue()
 *    }
 *
 *    const onChange = (e) => {
 *      setValue(e.target.value)
 *    }
 *
 *    return (
 *      <div>
 *        <input onChange={onChange} value={value} />
 *        <button onClick={onSubmit}>Submit</button>
 *      </div>
 *    )
 * }
 * ```
 *
 * @param defaultValue default value you would normally pass to `useState`
 * @param storageKey key to persist and load drafts from
 */
export const usePersistedValue = <T>(defaultValue: T, storageKey: string) => {
  const [value, setValue] = useState(defaultValue)
  const [isValueLoaded, setIsValueLoaded] = useState(false)

  const persistValueRef = useRef<ReturnType<typeof debounce>>()

  useEffect(() => {
    setValue(defaultValue)
    setIsValueLoaded(false)
    forage.getItem<T>(storageKey).then((storedValue) => {
      if (storedValue) {
        setValue(storedValue)
      }
      setIsValueLoaded(true)
    })

    const persistValue = debounce((newValue: T) => {
      forage.setItem(storageKey, newValue)
    }, 400)

    persistValueRef.current = persistValue

    return () => {
      persistValue.flush()
    }
  }, [storageKey, defaultValue])

  const updateAndPersistValue = useCallback((newValue: T) => {
    setValue(newValue)
    persistValueRef.current?.(newValue)
  }, [])

  const clear = useCallback(() => {
    persistValueRef.current?.cancel()
    forage.removeItem(storageKey)
    setValue(defaultValue)
    setIsValueLoaded(false)
  }, [storageKey, defaultValue])

  return [value, updateAndPersistValue, clear, isValueLoaded] as const
}
