// @ts-strict-ignore
import type { EnrichedEpisode } from '@dialogue/coredata'
import type { EmergencyRoomTypes } from '@dialogue/services'
import type { Appointment, NewAppointment } from '@dialogue/timekeeper'
import {
  createSelector,
  createSlice,
  type PayloadAction,
  createEntityAdapter,
  isAnyOf,
} from '@reduxjs/toolkit'
import type { Moment } from 'moment'

import { alphaSort } from 'app/lib/sorters'
import type { ReduxState } from 'app/redux'
import * as emeraldAppointmentActions from 'app/redux/appointments'
import type { Episode } from 'app/redux/episode-view/types'
import { schedulingAppointmentActions } from 'app/redux/scheduling/appointments'

import { makeSetForRangeAndProviders } from './schedule/common'
import {
  filterGroupScheduleActions,
  selectFilterGroupProviderIds,
} from './schedule/filter-group-schedule'
import { providerScheduleActions } from './schedule/provider-schedule'

export interface FormData {
  episode_id: string
  provider_id: number
  appointment_type: string
  start_date: Moment
  duration: number
}

export type FormSubmitData = Omit<FormData, 'duration'> & {
  episode:
    | EmergencyRoomTypes.EnrichedEpisode
    | EnrichedEpisode
    | Partial<Episode>
  end_date: Moment
}

interface State {
  fetching: boolean
  errorFetching: Error | null
  creating: boolean
  errorCreating: Error | null
}

const appointmentAdapter = createEntityAdapter<Appointment>({
  selectId: (appointment) => appointment.id,
  sortComparer: (a, b) => alphaSort(a.start_at, b.start_at),
})

const INITIAL_STATE = appointmentAdapter.getInitialState<State>({
  fetching: false,
  errorFetching: null,
  creating: false,
  errorCreating: null,
})

const convertAppointmentFormData = (
  formData: FormSubmitData,
): NewAppointment => {
  return {
    start_at: formData.start_date.toISOString(),
    end_at: formData.end_date.toISOString(),
    provider_id: formData.provider_id,
    member_id: formData.episode.subject_id || formData.episode.patient_id,
    episode_owner_id: formData.episode.patient_id,
    episode_id: formData.episode_id,
    appointment_type: formData.appointment_type,
  }
}

const setForRangeAndProviders = makeSetForRangeAndProviders(appointmentAdapter)

export const { actions: timekeeperAppointmentsActions, reducer } = createSlice({
  name: '@@timekeeper/appointments',
  initialState: INITIAL_STATE,
  reducers: {
    getForProvider(
      state,
      {
        payload,
      }: PayloadAction<{
        providerId: number
        startDate: Date
        endDate: Date
      }>,
    ) {
      state.fetching = true
      state.errorFetching = null
    },
    receivedAll(
      state,
      { payload }: PayloadAction<{ appointments: Appointment[] }>,
    ) {
      state.fetching = false
      state.errorFetching = null

      appointmentAdapter.setAll(state, payload.appointments)
    },
    erroredReceiving(state, { payload }: PayloadAction<{ error: Error }>) {
      state.fetching = false
      state.errorFetching = payload.error
    },
    create: {
      reducer(
        state,
        { payload }: PayloadAction<{ newAppointment: NewAppointment }>,
      ) {
        state.creating = true
        state.errorCreating = null
      },
      prepare({
        appointmentFormData,
      }: {
        appointmentFormData: FormSubmitData
      }) {
        return {
          payload: {
            newAppointment: convertAppointmentFormData(appointmentFormData),
          },
        }
      },
    },
    created(state, { payload }: PayloadAction<{ appointment: Appointment }>) {
      state.creating = false
      appointmentAdapter.setOne(state, payload.appointment)
    },
    erroredCreating(state, { payload }: PayloadAction<Error>) {
      state.creating = false
      state.errorCreating = payload
    },
  },
  extraReducers(builder) {
    builder.addCase(providerScheduleActions.received, (state, action) => {
      setForRangeAndProviders(
        state,
        action.payload.schedule.appointments,
        [action.payload.schedule.provider_id],
        action.payload.range,
      )
    })
    builder.addCase(filterGroupScheduleActions.received, (state, action) => {
      const appointments = action.payload.schedules.flatMap(
        (schedule) => schedule.appointments,
      )
      const providerIds = action.payload.schedules.map(
        (schedule) => schedule.provider_id,
      )
      setForRangeAndProviders(
        state,
        appointments,
        providerIds,
        action.payload.range,
      )
    })
    builder.addMatcher(
      isAnyOf(
        emeraldAppointmentActions.updateAppointmentStatusSuccess,
        schedulingAppointmentActions.created,
        schedulingAppointmentActions.updated,
      ),
      (state, action) => {
        if (action.payload.tkAppointment) {
          appointmentAdapter.setOne(state, action.payload.tkAppointment)
        }
      },
    )
  },
})

const selectors = appointmentAdapter.getSelectors(
  (state: ReduxState) => state.timekeeper.appointments,
)

export const selectAppointmentsForProvider = createSelector(
  selectors.selectAll,
  (_state: ReduxState, providerId: number) => providerId,
  (appointmentDataMap, providerId): Appointment[] =>
    appointmentDataMap.filter((a) => a.provider_id === providerId),
)

export const selectAppointmentsForFilterGroup = createSelector(
  selectors.selectAll,
  selectFilterGroupProviderIds,
  (appointmentDataMap, providerIds) =>
    appointmentDataMap.filter((a) => providerIds.includes(a.provider_id)),
)

// Not used for now, will be needed when we create in TK directly
export const selectCreateAppointmentError = (state: ReduxState) =>
  state.timekeeper.appointments.errorCreating

export default reducer
