export const makeSlackMessage = (data: {
  name: string
  email: string
  cpUrl: string
}) => {
  const post = {
    text: `Hello, :wave: CP User "${data.name}" needs access into ${data.cpUrl}`,
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Hello, :wave: A CP User needs access into ${data.cpUrl}`,
        },
        fields: [
          {
            type: 'mrkdwn',
            text: `*Practitioner Name*`,
          },
          {
            type: 'mrkdwn',
            text: '*Practitioner Email*',
          },
          {
            type: 'plain_text',
            text: data.name,
          },
          {
            type: 'plain_text',
            text: data.email,
          },
        ],
      },
      {
        type: 'actions',
        elements: [
          {
            type: 'button',
            text: {
              type: 'plain_text',
              text: 'View Episode',
            },
            value: 'view_episode',
            url: data.cpUrl,
            action_id: 'button',
            style: 'primary',
          },
        ],
      },
    ],
  }
  return JSON.stringify(post)
}

export const modalConfig = {
  icon: null as React.ReactNode,
  centered: true,
  width: 624,
  okButtonProps: {
    style: {
      display: 'none',
    },
  },
} as const
