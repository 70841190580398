import { forwardRef, useCallback, useState } from 'react'

import { Button, Tag } from 'antd'
import moment from 'moment-timezone'
import type { BaseSelectRef } from 'rc-select'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  DatePicker,
  TimePicker,
  type DatePickerProps,
} from 'app/components/ant-design'
import i18n from 'app/i18n'

// Config
const MINUTE_INCREMENTS = 15
export const TIME_FORMAT = 'H:mm z'
export const DATE_FORMAT = 'YYYY-MM-DD H:mm z'

// Styling
const ShortcutsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .ant-tag {
    margin-top: 4px;
  }
`

const StyledDiv = styled.div`
  text-align: right;
  margin-top: 8px;
`

// Interfaces
interface DateTimeShortcut {
  label: string
  datetime: moment.Moment
}

type PickerProps = { showTime?: boolean } & Omit<DatePickerProps, 'picker'>

const localNow = () => moment.tz(moment.tz.guess())

// Helpers
// NOTE: Shortcuts are based on the current datetime, not the initial value
// from the form.
const dateTimeShortcuts = () => {
  const nextWeek = localNow().startOf('isoWeek').add(1, 'week')

  return [
    {
      label: i18n.t('reminders.shortcuts.now'),
      datetime: localNow(),
    },
    {
      label: i18n.t('reminders.shortcuts.inOneHour'),
      datetime: localNow().add(1, 'hours'),
    },
    {
      label: i18n.t('reminders.shortcuts.inTwoHours'),
      datetime: localNow().add(2, 'hours'),
    },
    {
      label: i18n.t('reminders.shortcuts.tomorrow'),
      datetime: localNow().add(1, 'days').startOf('day').add(9, 'hours'),
    },
    {
      label: i18n.t('reminders.shortcuts.inTwoDays'),
      datetime: localNow().add(2, 'days').startOf('day').add(9, 'hours'),
    },
    {
      label: i18n.t('reminders.shortcuts.inOneWeek'),
      datetime: localNow().add(1, 'week').startOf('day').add(9, 'hours'),
    },
    {
      label: i18n.t('reminders.shortcuts.inTwoWeeks'),
      datetime: localNow().add(2, 'week').startOf('day').add(9, 'hours'),
    },
    {
      label: i18n.t('reminders.shortcuts.nextMonday'),
      datetime: nextWeek.clone().startOf('day').add(9, 'hours'),
    },
    {
      label: i18n.t('reminders.shortcuts.nextFriday'),
      datetime: nextWeek.clone().add(4, 'day').startOf('day').add(9, 'hours'),
    },
  ]
}

// Components
export const DateTimePicker = forwardRef<BaseSelectRef, PickerProps>(
  ({ onChange, showTime = false, value, ...rest }) => {
    const { t } = useTranslation()
    const shortcuts = dateTimeShortcuts()

    const [datetime, setDatetime] = useState<moment.Moment | null | undefined>(
      value,
    )

    const [openTimePicker, setOpenTimePicker] = useState(false)

    const handleShortcutClick = useCallback(
      (shortcut: DateTimeShortcut) => {
        setDatetime(shortcut.datetime)
        onChange?.(shortcut.datetime, '')
        setOpenTimePicker(false)
      },
      [setDatetime, onChange, setOpenTimePicker],
    )

    const handleTimeChange = useCallback(
      (value: moment.Moment | null, _dateString: string) => {
        setDatetime((prevValue) => {
          let newDate: moment.Moment | null = value
          if (!!prevValue && !!value) {
            newDate = prevValue.clone().set({
              hour: value.get('hour'),
              minute: value.get('minute'),
            })
          }
          return newDate
        })
      },
      [setDatetime],
    )

    const handleDateChange = useCallback(
      (value: moment.Moment | null, _dateString: string) => {
        setDatetime((prevValue) => {
          let newDate: moment.Moment | null = value
          if (!!prevValue && !!value) {
            newDate = prevValue.clone().set({
              year: value.get('year'),
              month: value.get('month'),
              date: value.get('date'),
            })
          }
          return newDate
        })
      },
      [setDatetime],
    )

    const handleTimeModalOpen = useCallback(() => {
      setOpenTimePicker(true)
    }, [setOpenTimePicker])

    const handleTimeModalClose = useCallback(() => {
      setOpenTimePicker(false)
      onChange?.(datetime || null, '')
    }, [setOpenTimePicker, onChange, datetime])

    const renderShortcuts = useCallback(() => {
      return (
        <ShortcutsContainer>
          {shortcuts
            .filter((shortcut: DateTimeShortcut) => {
              return showTime
                ? true
                : !shortcut.datetime.isSame(moment(), 'day')
            })
            .map((shortcut: DateTimeShortcut, index) => {
              const isSelected = shortcut.datetime.isSame(datetime, 'minute')
              return (
                <Tag
                  color={isSelected ? 'blue' : 'default'}
                  key={index}
                  data-testid="date-time-shortcut"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={(e) => {
                    handleShortcutClick(shortcut)
                  }}
                >
                  {shortcut.label}
                </Tag>
              )
            })}
        </ShortcutsContainer>
      )
    }, [datetime, handleShortcutClick, shortcuts, showTime])

    const renderTimePickerAsFooter = useCallback(() => {
      return (
        <div>
          {showTime && (
            <TimePicker
              value={datetime}
              onChange={handleTimeChange}
              format={TIME_FORMAT}
              minuteStep={MINUTE_INCREMENTS}
              size="small"
              css={`
                width: 100%;
              `}
              allowClear={false}
              showNow={true}
              hideDisabledOptions
              inputReadOnly
              data-testid="task-due-on-time"
            />
          )}
          {renderShortcuts()}
          <StyledDiv>
            <Button
              type="primary"
              size="small"
              onClick={handleTimeModalClose}
              data-testid="task-due-on-time-submit"
            >
              {t('tasks.actions.ok')}
            </Button>
          </StyledDiv>
        </div>
      )
    }, [
      datetime,
      handleTimeChange,
      handleTimeModalClose,
      renderShortcuts,
      showTime,
      t,
    ])

    return (
      <DatePicker
        {...rest}
        picker={'date'}
        value={datetime}
        open={openTimePicker}
        onOpenChange={handleTimeModalOpen}
        format={DATE_FORMAT}
        showToday={false}
        onChange={handleDateChange}
        renderExtraFooter={renderTimePickerAsFooter}
        allowClear={false}
        inputReadOnly
      />
    )
  },
)
