import { notification } from 'antd'
import { call, put, all, takeLatest } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import { timekeeperAdminEventActions } from 'app/redux/timekeeper/admin-events'

import { getAdminEventsApi } from './utils'

export function* createAdminEvent({
  payload,
}: ReturnType<typeof timekeeperAdminEventActions.create>) {
  try {
    const api = yield* call(getAdminEventsApi)
    const {
      data: { data: adminEvent },
    } = yield* call(api.create, payload.newAdminEvent)

    yield* put(
      timekeeperAdminEventActions.created({
        adminEvent,
      }),
    )
    yield* call(notification.success, {
      message: i18n.t('schedule.createAdminEventSuccess'),
    })
  } catch (e) {
    yield* put(timekeeperAdminEventActions.erroredCreating(e))

    yield* call(notification.error, {
      message: i18n.t('schedule.error.createAdminEvent'),
      description: String(e),
    })
  }
}

export function* deleteAdminEvent({
  payload,
}: ReturnType<typeof timekeeperAdminEventActions.delete>) {
  try {
    const api = yield* call(getAdminEventsApi)

    yield* call(api.deleteAdminEvent, payload.adminEventId)

    yield* put(
      timekeeperAdminEventActions.deleted({
        adminEventId: payload.adminEventId,
      }),
    )
    yield* call(notification.success, {
      message: i18n.t('schedule.deleteAdminEventSuccess'),
    })
  } catch (e) {
    yield* put(timekeeperAdminEventActions.erroredDeleting(e))

    yield* call(notification.error, {
      message: i18n.t('schedule.error.deleteAdminEvent'),
      description: String(e),
    })
  }
}

export default function* adminEventSagas() {
  yield* all([
    takeLatest(timekeeperAdminEventActions.create, createAdminEvent),
    takeLatest(timekeeperAdminEventActions.delete, deleteAdminEvent),
  ])
}
