// @ts-strict-ignore
import delay from '@redux-saga/delay-p'
import type { Task } from 'redux-saga'
import {
  all,
  call,
  cancel,
  put,
  select,
  spawn,
  takeLatest,
} from 'typed-redux-saga/macro'

import type { ReduxState } from 'app/redux'
import { setPeriodicActionReplayLastRunAt } from 'app/redux/app'
import { loginSuccess, logoutSuccess } from 'app/redux/authentification'
import { chatActions } from 'app/redux/chat'
import { practitionerActions } from 'app/redux/practitioners'
import * as TemplatesActions from 'app/redux/templates/actions'
import * as UserManagementActions from 'app/redux/user-management/actions'

export const appState = (state: ReduxState) => state.app

const longPollSagas: Task[] = []
export function* startup() {
  yield* put(TemplatesActions.getTemplatesRequest())
  yield* put(practitionerActions.requestProfiles())
  longPollSagas.push(yield* spawn(periodicActionReplay))
  yield* put(UserManagementActions.getSpecializations())
}

export function* logout() {
  while (longPollSagas.length) {
    yield* cancel(longPollSagas.pop())
  }
  yield* put(chatActions.clearAll())
}

const MAX_REPLAY_THREADS = 3
export function* periodicActionReplay() {
  while (true) {
    const app = yield* select(appState)
    if (app.replayWatches.length > 0) {
      const now = Date.now()
      const replaysRequiringUpdate = app.replayWatches.filter(
        (actionReplay) => {
          const lastRunAt =
            app.replayActionLastRunAt[actionReplay.replayKey] || 0
          return lastRunAt + actionReplay.replayFrequency < now
        },
      )

      let i = 0
      let thread = 0
      while (i < replaysRequiringUpdate.length) {
        const replayAction = replaysRequiringUpdate[i]
        try {
          yield* put(
            setPeriodicActionReplayLastRunAt({
              replayKey: replayAction.replayKey,
              date: Date.now(),
            }),
          )
          yield* put({ ...replayAction.action })
        } catch (err) {
          console.error(
            'error processing action replay',
            err,
            replayAction.action,
          )
        }
        thread++
        if (thread >= MAX_REPLAY_THREADS) {
          thread = 0
          if (
            replaysRequiringUpdate[i + 1] &&
            app.replayActionLastRunAt[replaysRequiringUpdate[i + 1].replayKey]
          ) {
            yield* call(delay, 100)
          }
        }
        i++
      }
    }
    yield* call(delay, 200)
  }
}

export default function* appSagas() {
  yield* all([
    takeLatest(loginSuccess, startup),
    takeLatest(logoutSuccess, logout),
  ])
}
