import { all, call, put } from 'typed-redux-saga/macro'

import { eligibilityIntervalActions } from 'app/redux/member-eligibility-interval'

import { initScribeV2Client, SEARCH_LIMIT, takeKeyedLatest } from './utils'

export function* loadEligibilityIntervals({
  payload: memberId,
}: ReturnType<(typeof eligibilityIntervalActions)['get']>) {
  try {
    const scribe = yield* call(initScribeV2Client)
    const { data } = yield* call(scribe.getUserEligibleIntervals, memberId, {
      limit: SEARCH_LIMIT,
    })
    yield* put(
      eligibilityIntervalActions.getSuccess({ memberId, intervals: data }),
    )
  } catch (e) {
    console.warn('Failed to get eligibility intervals for member', e)
    yield* put(eligibilityIntervalActions.getFailure({ memberId, error: e }))
  }
}

export default function* memberEligibilityIntervalSagas() {
  yield* all([
    takeKeyedLatest(
      eligibilityIntervalActions.get,
      (action) => action.payload,
      loadEligibilityIntervals,
    ),
  ])
}
