// @ts-strict-ignore
import { datadogRum } from '@datadog/browser-rum'
import type { GetEpisodeResponse } from '@dialogue/services/dist/er/model'
import { all, call, put, select } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import {
  selectPractitionerIdFromMmId,
  selectUserHasRestrictedView,
} from 'app/redux/authentification/selectors'
import { selectUsername } from 'app/redux/chat/selectors'
import type { Post } from 'app/redux/chat/types'
import {
  type Mention,
  mentionsActions,
  MentionsTypes,
} from 'app/redux/mentions'
import { Flags, ldclient } from 'app/services/feature-flags'

import { shouldReportChatError } from './chat/utils'
import { initEmergencyRoomClient, initMMClientV4, poll } from './utils'

export let POLLING_INTERVAL_MS = 30000

if (ldclient.variation(Flags.useMentionsWithoutSearch, false)) {
  POLLING_INTERVAL_MS = 10000
}

function* computePatientIdDict(channelMemberships: { channel_id: string }[]) {
  // takes either post list, or membership list
  const er = yield* call(initEmergencyRoomClient)
  function* getEpisodeWithFailSafe(episodeId: string) {
    try {
      return yield* call(er.getEpisode, episodeId)
    } catch {
      return null
    }
  }
  const episodes: Record<string, GetEpisodeResponse> = yield* all(
    channelMemberships.reduce(
      (acc, { channel_id }) => ({
        ...acc,
        [channel_id]: call(getEpisodeWithFailSafe, channel_id),
      }),
      {},
    ),
  )
  return channelMemberships.reduce((acc, { channel_id }) => {
    const episode = episodes[channel_id]
    if (!episode) return acc
    return {
      ...acc,
      [channel_id]: episode.data.patient_id,
    }
  }, {}) as Record<string, number>
}

const setAppBadge = (count: number) => {
  navigator.setAppBadge?.(count)
}

export function* getMentions({
  payload: { teamId },
}: ActionType<typeof mentionsActions.startPolling>) {
  const userHasRestrictedView = yield* select(selectUserHasRestrictedView)
  if (userHasRestrictedView) {
    yield* put(mentionsActions.stopPolling())
    return
  }
  const mmv4 = yield* call(initMMClientV4)

  const username = yield* select(selectUsername)
  if (!username) return

  // Workaround mm (v3 & v4) post search bug wherein searchterm that includes a hyphen + dot
  // return no search results.
  const unHyphenatedUserName = username.includes('-')
    ? username.replace(/\.[a-z0-9]+$/, '')
    : username

  try {
    const {
      searchResults: { order, posts },
      channelMemberships,
    } = yield* all({
      searchResults: call(
        mmv4.searchPosts,
        teamId,
        `@${unHyphenatedUserName}`,
        false,
      ),
      channelMemberships: call(mmv4.getMyChannelMembers, teamId),
    })
    const patientIds = yield* computePatientIdDict(Object.values(posts))

    const channelsUnreadMentionCount: Record<string, number> = {}
    channelMemberships.forEach((membership) => {
      channelsUnreadMentionCount[membership.channel_id] =
        membership.mention_count
    })

    const mentions: Record<string, Mention> = {}

    for (const postId of order || []) {
      const post = posts[postId]

      // As we use a substring of the username (see unHyphenatedUserName)
      // in the posts search, we might get mentions unrelated to the user.
      // Ensure this user was actually mentioned in the post
      if (!post.message.includes(username.trim())) {
        continue
      }

      let unreadMentionCount: number = 0
      if (post.channel_id in channelsUnreadMentionCount) {
        unreadMentionCount = channelsUnreadMentionCount[post.channel_id]
        if (unreadMentionCount > 0) {
          channelsUnreadMentionCount[post.channel_id]--
        }
      }

      const unread = unreadMentionCount > 0

      try {
        const mention = yield* call(
          processMentionFromPost,
          post,
          unread,
          patientIds[post.channel_id],
        )
        mentions[postId] = mention
      } catch (e) {
        console.warn('failed to process mention from post', e)
      }
    }

    yield* put(mentionsActions.setMentions(mentions))

    setAppBadge(Object.keys(mentions).filter((k) => mentions[k].unread).length)
    return
  } catch (error) {
    if (shouldReportChatError(error)) {
      datadogRum.addError(error)
    }
  }
}

export function* getMentionsWithoutPostSearch({
  payload: { teamId },
}: ActionType<typeof mentionsActions.startPolling>) {
  const userHasRestrictedView = yield* select(selectUserHasRestrictedView)
  if (userHasRestrictedView) {
    yield* put(mentionsActions.stopPolling())
    return
  }
  const mmv4 = yield* call(initMMClientV4)

  const username = yield* select(selectUsername)
  if (!username) return

  try {
    const membershipsWithMentions = (yield* call(
      mmv4.getMyChannelMembers,
      teamId,
    )).filter((membership) => membership.mention_count > 0)
    const patientIds = yield* computePatientIdDict(membershipsWithMentions)
    const mentions: Record<string, Mention> = {}
    let channel_id: string

    membershipsWithMentions.forEach((membership) => {
      channel_id = membership.channel_id
      const mention: Mention = {
        message: 'You were mentioned in the episode',
        id: channel_id,
        channel_id,
        app_id: Number(patientIds[channel_id]),
        create_at: Date.now(),
        mentioned_by_id: null,
        unread: true,
      }

      mentions[channel_id] = mention
    })

    yield* put(mentionsActions.setMentions(mentions))
    setAppBadge(Object.keys(mentions).filter((k) => mentions[k].unread).length)
    return
  } catch (error) {
    datadogRum.addError(error)
    return
  }
}

/**
 * Processes a mention from a post
 *
 * @param post
 * @param unread
 */
export function* processMentionFromPost(
  post: Post,
  unread: boolean,
  patientId: number,
) {
  const { channel_id, create_at, id, user_id: mmId, message } = post

  const practitionerId = yield* select(selectPractitionerIdFromMmId, mmId)

  return {
    mentioned_by_id: Number(practitionerId),
    message,
    channel_id,
    create_at,
    app_id: patientId,
    unread,
    id,
  }
}

function* chooseMentionType(
  action: ActionType<typeof mentionsActions.startPolling>,
) {
  if (ldclient.variation(Flags.useMentionsWithoutSearch, false)) {
    yield* call(getMentionsWithoutPostSearch, action)
  } else {
    yield* call(getMentions, action)
  }
}

export default function* mentionsSagas() {
  yield* all([
    poll(
      MentionsTypes.START_POLLING,
      MentionsTypes.STOP_POLLING,
      POLLING_INTERVAL_MS,
      chooseMentionType,
    ),
  ])
}
