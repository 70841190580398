// @ts-strict-ignore
import produce from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'
import { v4 as uuid } from 'uuid'

import { memberDocumentsActions } from 'app/redux/documents/members'

import { type practitionerActions, PractitionerTypes } from '../practitioners'

import type * as actions from './actions'
import { type ChatState, ChatTypes } from './types'

export const INITIAL_STATE: ChatState = {
  channels: {},
  practitioners: [],
  memberships: {},
  user: null,
  statuses: {},
  sessionId: uuid(),
  failedPosts: {},
}

export type ChatAction = ActionType<typeof actions>

const reducer: Reducer<
  typeof INITIAL_STATE,
  | ChatAction
  | ActionType<(typeof practitionerActions)['requestProfilesSuccess']>
  | ReturnType<(typeof memberDocumentsActions)['sendBlobToEpisode']>
  | ReturnType<(typeof memberDocumentsActions)['sendToEpisode']>
> = (state = INITIAL_STATE, action) =>
  produce(state, (draft): typeof INITIAL_STATE | void => {
    switch (action.type) {
      case ChatTypes.LOAD_USER_SUCCESS:
        draft.user = action.payload.user
        break

      case ChatTypes.LOAD_MEMBERSHIPS_SUCCESS:
        draft.memberships = action.payload.memberships
        break

      case ChatTypes.GOT_STATUSES:
        draft.statuses = action.payload.statuses
        break

      case ChatTypes.JOIN_CHANNEL_SUCCESS:
        draft.memberships[action.payload.membership.channel_id] =
          action.payload.membership
        break

      case ChatTypes.LEAVE_CHANNEL_SUCCESS:
        delete draft.memberships[action.payload.channelId]
        break

      case PractitionerTypes.REQUEST_PROFILES_SUCCESS:
        // FIXME: practitioner state transforms these,
        // making them incompatible with speakeasy.
        // This is why this data is 'duplicated'.
        draft.practitioners = action.payload.practitioners
        break

      case ChatTypes.INIT_CHANNEL:
        draft.channels[action.payload.channelId] = {
          order: [],
          posts: {},
          loading: true,
          error: null,
          fileError: null,
          fileMetadata: {},
        }
        break

      case ChatTypes.INIT_CHANNEL_SUCCESS:
        draft.channels[action.payload.channelId].loading = false
        break

      case ChatTypes.INIT_CHANNEL_FAILURE:
        draft.channels[action.payload.channelId].loading = false
        draft.channels[action.payload.channelId].error = action.payload.error
        break

      case ChatTypes.CLEAR_CHANNEL:
        delete draft.channels[action.payload.channelId]
        break

      case ChatTypes.LOAD_CHANNEL_SUCCESS:
        draft.channels[action.payload.channelId].channel =
          action.payload.channel
        draft.channels[action.payload.channelId].members =
          action.payload.members
        draft.channels[action.payload.channelId].patientMmId =
          action.payload.patientMmId
        draft.channels[action.payload.channelId].patientAppId =
          action.payload.patientAppId
        draft.channels[action.payload.channelId].patientLastViewedAt =
          action.payload.patientLastViewedAt
        break

      case ChatTypes.UPDATE_PATIENT_LAST_VIEWED_AT:
        draft.channels[action.payload.channelId].patientLastViewedAt =
          action.payload.patientLastViewedAt
        break

      case ChatTypes.LOAD_POSTS_SUCCESS:
        const { order, posts } = action.payload.postsResponse

        const filteredOrder = order.filter((postId) => {
          if (posts[postId]?.props.practitioner_visible === false) {
            delete posts[postId]
            return false
          }
          return true
        })

        // append failed posts to loaded posts
        for (const postId of Object.keys(draft.failedPosts)) {
          const failedPost = draft.failedPosts[postId]
          if (failedPost.channel_id === action.payload.channelId) {
            filteredOrder.unshift(postId)
            posts[postId] = failedPost
          }
        }

        draft.channels[action.payload.channelId].order = filteredOrder
        draft.channels[action.payload.channelId].posts = posts

        break

      case ChatTypes.ADD_POST:
        const { channelId, post } = action.payload

        if (post.props.practitioner_visible === false) {
          break
        }

        // FIXME: split up into send post success/failure
        // retry for failed post [1/2]
        if (post.retryForId) {
          delete draft.failedPosts[post.retryForId]
        }

        // store failed posts
        if (post.props.failed) {
          draft.failedPosts[post.id] = post
        }

        const channel = draft.channels[channelId]

        if (!channel) {
          // posts may be sent while not being
          // actively on a channel (e.g. retry)
          break
        }

        channel.posts[post.id] = post

        // retry for failed post [2/2]
        if (post.retryForId) {
          const prevIndex = channel.order.indexOf(post.retryForId)
          if (prevIndex !== -1) {
            channel.order.splice(prevIndex, 1)
            delete channel.posts[post.localId]
          }
        }

        // replace draft with real version
        if (post.localId) {
          const prevIndex = channel.order.indexOf(post.localId)
          if (prevIndex !== -1) {
            channel.order[prevIndex] = post.id
            delete channel.posts[post.localId]
          }
        }

        if (channel.order.indexOf(post.id) === -1) {
          channel.order.unshift(post.id)
        }

        break

      case ChatTypes.UPDATE_POST:
        const oldPost =
          draft.channels[action.payload.channelId]?.posts?.[
            action.payload.post.id
          ]
        if (oldPost) {
          oldPost.props = action.payload.post.props
          // forces re-render
          oldPost.update_at = Date.now()
        }
        break

      case ChatTypes.USER_TYPING:
        if (action.payload.channelId in draft.channels) {
          draft.channels[action.payload.channelId].userTyping =
            action.payload.userId
        }
        break
      case ChatTypes.UPLOAD_FILE:
        draft.channels[action.payload.channelId].fileError = null
        draft.channels[action.payload.channelId].uploadingFile = true
        draft.channels[action.payload.channelId].uploadedFile = {
          name: action.payload.file.name,
          type: action.payload.file.type,
        }
        break
      case ChatTypes.UPLOAD_IH_FILE:
        draft.channels[action.payload.channelId].fileError = null
        draft.channels[action.payload.channelId].uploadingFile = true
        draft.channels[action.payload.channelId].uploadedFile = {
          name: action.payload.file.name,
          type: action.payload.file.type,
          additionalPostProps: { ih_file_type: action.payload.file.type },
        }
        break
      case memberDocumentsActions.sendBlobToEpisode.type:
      case memberDocumentsActions.sendToEpisode.type:
        draft.channels[action.payload.episodeId].fileError = null
        draft.channels[action.payload.episodeId].uploadingFile = true
        draft.channels[action.payload.episodeId].uploadedFile = {
          name: action.payload.document.name,
          type: action.payload.document.format,
          additionalPostProps: {
            document_center_id: action.payload.document.id,
          },
        }
        break
      case ChatTypes.UPLOAD_FILE_SUCCESS:
        draft.channels[action.payload.channelId].fileError = null
        draft.channels[action.payload.channelId].uploadingFile = false
        draft.channels[action.payload.channelId].uploadedFile = {
          ...draft.channels[action.payload.channelId].uploadedFile,
          id: action.payload.fileId,
        }
        break
      case ChatTypes.UPLOAD_FILE_FAILURE:
        draft.channels[action.payload.channelId].uploadingFile = true
        draft.channels[action.payload.channelId].fileError =
          action.payload.fileError
        break
      case ChatTypes.CANCEL_UPLOADED_FILE:
        draft.channels[action.payload.channelId].uploadingFile = false
        delete draft.channels[action.payload.channelId].uploadedFile
        break
      case ChatTypes.SEND_FILE:
        draft.channels[action.payload.channelId].uploadingFile = false
        delete draft.channels[action.payload.channelId].uploadedFile
        break
      case ChatTypes.RECEIVED_FILES_METADATA:
        draft.channels[action.payload.channelId].fileMetadata[
          action.payload.postId
        ] = action.payload.fileMetadata
        break
      // FIXME: use logout action instead once that's in typescript
      case ChatTypes.CLEAR_ALL:
        return INITIAL_STATE
    }
  })

export default reducer
