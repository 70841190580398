// @ts-strict-ignore
import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from '@snowplow/browser-plugin-link-click-tracking'
import { newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker'

import config from 'app/config'

import { deviceInfo } from './device-info'

if (config.SNOWPLOW_COLLECTION_REL_URL) {
  newTracker(config.SNOWPLOW_APP_STAGE, config.SNOWPLOW_COLLECTION_REL_URL, {
    appId: config.SNOWPLOW_APP_ID,
    plugins: [LinkClickTrackingPlugin()],
  })
  enableLinkClickTracking()
}

export function getEventSchema(eventName: string, version: string) {
  return `iglu:co.dialogue/${eventName.toLowerCase()}/jsonschema/${version}`
}

export const additionalContext: Record<string, any> = {
  app_version: deviceInfo.getVersion(),
}

export function track(
  eventName: string,
  data?: Record<string, unknown>,
  version: string = '1-0-0',
) {
  const payload = {
    ...additionalContext,
    ...data,
  }
  return trackSelfDescribingEvent({
    event: {
      schema: getEventSchema(eventName, version),
      data: payload,
    },
  })
}

function registerIPCAnalytics() {
  if (deviceInfo.isElectron()) {
    window.electronApi.onAnalyticsTrack(
      (event: any, name: string, props = {}) => {
        if (name && typeof name === 'string') {
          track(name, props)
          event.returnValue = true
        } else {
          console.error(
            'Invalid analytics tracking arguments provided. Expected {eventName: string, args?: object}',
          )
          event.returnValue = false
        }
      },
    )
  }
}

registerIPCAnalytics()
