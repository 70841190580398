import type { Task } from '@dialogue/coredata'
import { Typography } from 'antd'
import styled from 'styled-components'

import { TaskPriorityTag } from 'app/components/tasks/task-priority-tag'

type TaskTitleProps = Pick<Task, 'title' | 'priority'>

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const TaskTitle = ({ title, priority }: TaskTitleProps) => {
  return (
    <TitleContainer className="task-row-title">
      <TaskPriorityTag priority={priority} showTooltip />
      <Typography.Text ellipsis={{ tooltip: title }}>{title}</Typography.Text>
    </TitleContainer>
  )
}

export default TaskTitle
