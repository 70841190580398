import {
  useCallback,
  type FocusEvent,
  type KeyboardEvent,
  useState,
  type ChangeEventHandler,
} from 'react'

import { Input } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import type { NamePath } from 'antd/lib/form/interface'

import { useViewerInstance } from '../viewer-context'

interface Props {
  formItemName: NamePath
  onChangeName: (title: string) => void
  form: FormInstance
}

export const DocumentName = ({ formItemName, onChangeName, form }: Props) => {
  // Should be a string, but for safety this is checked in the handler
  const [value, setValue] = useState<any | undefined>(
    form.getFieldValue(formItemName),
  )

  const { viewerInstance } = useViewerInstance()

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      setValue(e.target.value)
    },
    [],
  )

  const handleDocumentNameChange = useCallback(
    (
      event:
        | KeyboardEvent<HTMLTextAreaElement>
        | FocusEvent<HTMLTextAreaElement>,
    ) => {
      if (typeof value !== 'string') {
        return
      }

      if ('key' in event && event.key === 'Enter') {
        // prevent 'Enter' from creating a new line
        event.preventDefault()
      }

      // trim trailing/leading white space, and remove line breaks
      let newDocumentName = value?.trim().replace(/(\r\n|\n|\r)/gm, '')

      // reset to original value if empty
      if (!newDocumentName) {
        form.resetFields([formItemName])
        return
      } else {
        onChangeName(newDocumentName)
        // Pass the title update to the viewer instance
        // https://docs.apryse.com/api/web/Core.Document.html#setFilename__anchor.
        // FIXME: will do nothing if the name changes before the document loads into the viewer
        viewerInstance?.Core.documentViewer
          .getDocument()
          ?.setFilename(newDocumentName)
      }
    },
    [
      form,
      formItemName,
      onChangeName,
      value,
      viewerInstance?.Core.documentViewer,
    ],
  )

  return (
    <Input.TextArea
      value={value}
      autoSize
      onChange={handleChange}
      onBlur={handleDocumentNameChange}
      onPressEnter={handleDocumentNameChange}
      data-testid="document-name-input"
      size="large"
      css={`
        && {
          min-height: 40px;
        }
      `}
    />
  )
}
