import config from 'app/config'
import i18n from 'app/i18n'

const models = {
  adminAreas: {
    CA: [
      { name: 'adminAreas.CA-QC', value: 'CA-QC' },
      { name: 'adminAreas.CA-ON', value: 'CA-ON' },
      { name: 'adminAreas.CA-BC', value: 'CA-BC' },
      { name: 'adminAreas.CA-AB', value: 'CA-AB' },
      { name: 'adminAreas.CA-MB', value: 'CA-MB' },
      { name: 'adminAreas.CA-SK', value: 'CA-SK' },
      { name: 'adminAreas.CA-NS', value: 'CA-NS' },
      { name: 'adminAreas.CA-NB', value: 'CA-NB' },
      { name: 'adminAreas.CA-NL', value: 'CA-NL' },
      { name: 'adminAreas.CA-PE', value: 'CA-PE' },
      { name: 'adminAreas.CA-YT', value: 'CA-YT' },
      { name: 'adminAreas.CA-NT', value: 'CA-NT', disabled: true },
      { name: 'adminAreas.CA-NU', value: 'CA-NU', disabled: true },
    ],
    US: [
      { name: 'adminAreas.US-AL', value: 'US-AL' },
      { name: 'adminAreas.US-AK', value: 'US-AK' },
      { name: 'adminAreas.US-AZ', value: 'US-AZ' },
      { name: 'adminAreas.US-AR', value: 'US-AR' },
      { name: 'adminAreas.US-CA', value: 'US-CA' },
      { name: 'adminAreas.US-CO', value: 'US-CO' },
      { name: 'adminAreas.US-CT', value: 'US-CT' },
      { name: 'adminAreas.US-DE', value: 'US-DE' },
      { name: 'adminAreas.US-FL', value: 'US-FL' },
      { name: 'adminAreas.US-GA', value: 'US-GA' },
      { name: 'adminAreas.US-HI', value: 'US-HI' },
      { name: 'adminAreas.US-ID', value: 'US-ID' },
      { name: 'adminAreas.US-IL', value: 'US-IL' },
      { name: 'adminAreas.US-IN', value: 'US-IN' },
      { name: 'adminAreas.US-IA', value: 'US-IA' },
      { name: 'adminAreas.US-KS', value: 'US-KS' },
      { name: 'adminAreas.US-KY', value: 'US-KY' },
      { name: 'adminAreas.US-LA', value: 'US-LA' },
      { name: 'adminAreas.US-ME', value: 'US-ME' },
      { name: 'adminAreas.US-MD', value: 'US-MD' },
      { name: 'adminAreas.US-MA', value: 'US-MA' },
      { name: 'adminAreas.US-MI', value: 'US-MI' },
      { name: 'adminAreas.US-MN', value: 'US-MN' },
      { name: 'adminAreas.US-MS', value: 'US-MS' },
      { name: 'adminAreas.US-MO', value: 'US-MO' },
      { name: 'adminAreas.US-MT', value: 'US-MT' },
      { name: 'adminAreas.US-NE', value: 'US-NE' },
      { name: 'adminAreas.US-NV', value: 'US-NV' },
      { name: 'adminAreas.US-NH', value: 'US-NH' },
      { name: 'adminAreas.US-NJ', value: 'US-NJ' },
      { name: 'adminAreas.US-NM', value: 'US-NM' },
      { name: 'adminAreas.US-NY', value: 'US-NY' },
      { name: 'adminAreas.US-NC', value: 'US-NC' },
      { name: 'adminAreas.US-ND', value: 'US-ND' },
      { name: 'adminAreas.US-OH', value: 'US-OH' },
      { name: 'adminAreas.US-OK', value: 'US-OK' },
      { name: 'adminAreas.US-OR', value: 'US-OR' },
      { name: 'adminAreas.US-PA', value: 'US-PA' },
      { name: 'adminAreas.US-RI', value: 'US-RI' },
      { name: 'adminAreas.US-SC', value: 'US-SC' },
      { name: 'adminAreas.US-SD', value: 'US-SD' },
      { name: 'adminAreas.US-TN', value: 'US-TN' },
      { name: 'adminAreas.US-TX', value: 'US-TX' },
      { name: 'adminAreas.US-UT', value: 'US-UT' },
      { name: 'adminAreas.US-VT', value: 'US-VT' },
      { name: 'adminAreas.US-VA', value: 'US-VA' },
      { name: 'adminAreas.US-WA', value: 'US-WA' },
      { name: 'adminAreas.US-WV', value: 'US-WV' },
      { name: 'adminAreas.US-WI', value: 'US-WI' },
      { name: 'adminAreas.US-WY', value: 'US-WY' },
      { name: 'adminAreas.US-DC', value: 'US-DC' },
      { name: 'adminAreas.US-AS', value: 'US-AS' },
      { name: 'adminAreas.US-GU', value: 'US-GU' },
      { name: 'adminAreas.US-MP', value: 'US-MP' },
      { name: 'adminAreas.US-PR', value: 'US-PR' },
      { name: 'adminAreas.US-UM', value: 'US-UM' },
      { name: 'adminAreas.US-VI', value: 'US-VI' },
    ],
    OUTSIDE_CA: [
      { name: 'adminAreas.OUTSIDE_CA', value: 'OUTSIDE_CA-OUTSIDE_CA' },
    ],
    DE: [
      { name: 'adminAreas.DE-BW', value: 'DE-BW', disabled: true },
      { name: 'adminAreas.DE-BY', value: 'DE-BY', disabled: true },
      { name: 'adminAreas.DE-BE', value: 'DE-BE', disabled: true },
      { name: 'adminAreas.DE-BB', value: 'DE-BB', disabled: true },
      { name: 'adminAreas.DE-HB', value: 'DE-HB', disabled: true },
      { name: 'adminAreas.DE-HH', value: 'DE-HH', disabled: true },
      { name: 'adminAreas.DE-HE', value: 'DE-HE', disabled: true },
      { name: 'adminAreas.DE-NI', value: 'DE-NI', disabled: true },
      { name: 'adminAreas.DE-MV', value: 'DE-MV', disabled: true },
      { name: 'adminAreas.DE-NW', value: 'DE-NW', disabled: true },
      { name: 'adminAreas.DE-RP', value: 'DE-RP', disabled: true },
      { name: 'adminAreas.DE-SL', value: 'DE-SL', disabled: true },
      { name: 'adminAreas.DE-SN', value: 'DE-SN', disabled: true },
      { name: 'adminAreas.DE-ST', value: 'DE-ST', disabled: true },
      { name: 'adminAreas.DE-SH', value: 'DE-SH', disabled: true },
      { name: 'adminAreas.DE-TH', value: 'DE-TH', disabled: true },
    ],
  },
  languages: [
    { value: 'en', label: 'languages.english' },
    { value: 'fr', label: 'languages.french' },
  ],
  sex: [
    { name: 'profile.male', value: 'M' },
    { name: 'profile.female', value: 'F' },
  ],
  positions: [
    'Care Coordinator',
    'Family Physician',
    'Nurse',
    'Nurse Practitioner',
    'Nutritionist',
    'Occupational Therapist',
    'Psychologist',
    'Psychotherapist',
    'Registered Dietitian',
    'Surgeon',
  ],
} as const

/**
 * @param {boolean} Sort
 *
 * @return A flat array of all admin areas for each region defined in the config
 **/
function getAdminAreas(sort = true) {
  const adminAreas = config.REGIONS.map(
    (region) => models.adminAreas[region],
  ).flat()

  if (sort) {
    adminAreas.sort((a, b) => i18n.t(a.name).localeCompare(i18n.t(b.name)))
  }

  return adminAreas
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

/**
 * @return An array of all languages defined in the config
 **/
function getLanguages() {
  return config.REGION_LANGUAGES.map((language) =>
    models.languages.find((lang) => lang.value === language.toLowerCase()),
  )
    .filter(notEmpty)
    .sort((a, b) => a.value.localeCompare(b.value))
}

export const adminAreas = getAdminAreas()

function getDefaultAdminAreaAndCountry() {
  const splitAdminArea = adminAreas[0].value.split('-')
  return {
    defaultCountry: splitAdminArea[0],
    defaultAdminArea: splitAdminArea[1],
  }
}

export const { defaultCountry, defaultAdminArea } =
  getDefaultAdminAreaAndCountry()

export const languages = getLanguages()

export default models
