import type { ScribeTypes } from '@dialogue/services'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface EligibilitySearchState {
  results: ScribeTypes.V2.EligibilityRecord[]
  fetching: boolean
  error: Error | null
  query?: string | null
}

export interface EligibilitySearchRequest {
  query: string
}

export interface EligibilitySearchSuccess {
  results: ScribeTypes.V2.EligibilityRecord[]
}

export interface EligibilitySearchFailure {
  error: Error
}

export const INITIAL_STATE: EligibilitySearchState = {
  fetching: false,
  results: [],
  error: null,
  query: null,
}

export const { reducer, actions: eligibilitySearchActions } = createSlice({
  name: '@@eligibilitySearch',
  initialState: INITIAL_STATE,
  reducers: {
    request: (state, _: PayloadAction<EligibilitySearchRequest>) => {
      state.fetching = true
      state.results = []
    },
    success: (state, action: PayloadAction<EligibilitySearchSuccess>) => {
      const { results } = action.payload
      state.fetching = false
      state.results = results
    },
    failure: (state, action: PayloadAction<EligibilitySearchFailure>) => {
      const { error } = action.payload
      state.fetching = false
      state.error = error
    },
    clear: (state) => {
      state.fetching = false
      state.error = null
      state.results = []
    },
  },
})

export default reducer
