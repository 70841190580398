import { useMemo } from 'react'

import type { Task } from '@dialogue/coredata'
import { Empty, Spin, Timeline, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'app/hooks'
import {
  useGetTaskActivitiesQuery,
  useGetTaskQuery,
} from 'app/redux/api/emergency-room/tasks'
import { selectUserIdToNickname } from 'app/redux/practitioners/selectors'

import {
  ActivitiesContainer,
  ActivityTimelineBlock,
} from '../documents/document-details/info-panel/common'

interface TaskActivitiesProps {
  taskId: string
}

type TaskIdtoTitle = { [key: string]: string }

export const Activities = ({ taskId = '' }: Partial<TaskActivitiesProps>) => {
  const { t } = useTranslation()

  const { currentData: activities = [], isLoading } = useGetTaskActivitiesQuery(
    { taskId, includeSubtasks: true },
    { skip: !taskId },
  )

  const { currentData: task, isLoading: isLoadingTask } = useGetTaskQuery(
    { taskId },
    { skip: !taskId },
  )

  // Pulling out subtasks to get around shallow equality check during memoization
  const subtasks = task?.subtasks
  const taskIdToTitleMap: TaskIdtoTitle = useMemo(() => {
    if (!task || !subtasks) return {}
    return {
      [task.id]: task.title,
      ...subtasks.reduce((acc: TaskIdtoTitle, subtask: Task) => {
        acc[subtask.id] = subtask.title
        return acc
      }, {}),
    }
  }, [task, subtasks])

  const userIds = useMemo(() => activities.map((a) => a.user_id), [activities])

  const userIdToNickname = useAppSelector((state) =>
    selectUserIdToNickname(state, userIds),
  )

  if (isLoading || isLoadingTask) {
    return (
      <ActivitiesContainer>
        <Spin />
      </ActivitiesContainer>
    )
  }

  if (activities && activities.length > 0) {
    return (
      <ActivitiesContainer>
        <Typography.Text type="secondary" data-dd-privacy="allow">
          {t('tasks.sortOrderText')}
        </Typography.Text>
        <Timeline>
          {activities.map((activity) => {
            const personName = userIdToNickname[activity.user_id]
            const resourceTitle = taskIdToTitleMap[activity.task_id]
            const taskType =
              activity.task_id === taskId
                ? t('tasks.type.task')
                : t('tasks.type.subtask')
            const extendedActivityType = t(
              `tasks.activityType.${activity.activity_type}`,
              activity.activity_type,
              { type: taskType },
            )
            const tooltipMessages: string[] = Object.keys(
              activity.modified_values || {},
            )?.map((key) => t(`tasks.tooltipMessages.${key}`, key))

            return (
              <Timeline.Item key={activity.id}>
                <ActivityTimelineBlock
                  activityType={extendedActivityType}
                  timestamp={activity.timestamp}
                  resourceTitle={resourceTitle}
                  personName={personName}
                  tooltipMessages={tooltipMessages}
                />
              </Timeline.Item>
            )
          })}
        </Timeline>
      </ActivitiesContainer>
    )
  }

  return (
    <ActivitiesContainer>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    </ActivitiesContainer>
  )
}
