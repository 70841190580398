import {
  AdminEventsApiFactory,
  AppointmentsApiFactory,
  Configuration,
  DraftAppointmentsApiFactory,
  ProvidersApiFactory,
} from '@dialogue/timekeeper'
import { select, call } from 'typed-redux-saga/macro'

import appConfig from 'app/config'
import { selectAccessToken } from 'app/redux/authentification/selectors'
import {
  MissingAccessTokenError,
  MissingConfigURLError,
} from 'app/sagas/utils/exceptions'

export function* getClientConfig(config = appConfig) {
  const accessToken = yield* select(selectAccessToken)

  if (!accessToken) {
    throw new MissingAccessTokenError()
  }

  if (!config.TIMEKEEPER_URL) {
    throw new MissingConfigURLError()
  }

  return new Configuration({
    accessToken,
    basePath: config.TIMEKEEPER_URL,
  })
}

export function* getProvidersApi() {
  return ProvidersApiFactory(yield* call(getClientConfig))
}

export function* getAppointmentsApi() {
  return AppointmentsApiFactory(yield* call(getClientConfig))
}

export function* getDraftsApi() {
  return DraftAppointmentsApiFactory(yield* call(getClientConfig))
}

export function* getAdminEventsApi() {
  return AdminEventsApiFactory(yield* call(getClientConfig))
}
