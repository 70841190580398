import type { EnrichedEpisode } from '@dialogue/coredata'
import { notification } from 'antd'
import { all, call, put, takeEvery, takeLeading } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import i18n from 'app/i18n'
import { episodesApi } from 'app/redux/api/emergency-room/episodes'
import { practitionerActions, PractitionerTypes } from 'app/redux/practitioners'
import { track } from 'app/services/snowplow-analytics'

import { initEmergencyRoomClient, initUsherClient } from './utils'

export function* requestProfiles() {
  try {
    const usher = yield* call(initUsherClient)
    const profilesResponse = yield* call(usher.getPractitioners)
    yield* put(
      practitionerActions.requestProfilesSuccess(profilesResponse.data),
    )
  } catch (error) {
    yield* put(practitionerActions.requestProfilesFailure(error))
  }
}

export function* assignEpisode({
  payload,
}: ActionType<typeof practitionerActions.assignEpisode>) {
  const { channelId, practitionerId } = payload

  const emergencyRoom = yield* call(initEmergencyRoomClient)
  try {
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const episode: EnrichedEpisode = yield* call(
      emergencyRoom.assignEpisode,
      channelId,
      Number(practitionerId),
    )

    yield* put(practitionerActions.assignEpisodeSuccess(episode))

    // Ensure we update the RTKq cache also
    yield* put<ReturnType<typeof episodesApi.util.upsertQueryData>>(
      episodesApi.util.upsertQueryData(
        'getEpisode',
        { episodeId: episode.id },
        episode,
      ),
    )
  } catch (error) {
    yield* put(practitionerActions.assignEpisodeFailure(error))
  }
}

export function* batchAssignEpisodes({
  payload,
}: ActionType<typeof practitionerActions.batchAssignEpisodes>) {
  const { episodeIds, practitionerId } = payload
  const emergencyRoom = yield* call(initEmergencyRoomClient)

  try {
    yield* all(
      episodeIds.map((episodeId) =>
        call(emergencyRoom.assignEpisode, episodeId, practitionerId),
      ),
    )
    yield* call(notification.info, {
      message: i18n.t('practitioner.actions.batchAssignSuccess'),
    })

    yield* call(track, 'batch_followups_assigned', {
      count: episodeIds.length,
    })
  } catch (e) {
    yield* call(notification.error, {
      message: i18n.t('practitioner.actions.batchAssignError'),
    })
  }
}

export function* unassignEpisode({
  payload,
}: ActionType<typeof practitionerActions.unassignEpisode>) {
  const { channelId } = payload

  const emergencyRoom = yield* call(initEmergencyRoomClient)
  try {
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const episode: EnrichedEpisode = yield* call(
      emergencyRoom.unassignEpisode,
      channelId,
    )

    yield* put(practitionerActions.unassignEpisodeSuccess(episode))

    // Ensure we update the RTKq cache also
    yield* put<ReturnType<typeof episodesApi.util.upsertQueryData>>(
      episodesApi.util.upsertQueryData(
        'getEpisode',
        { episodeId: episode.id },
        episode,
      ),
    )
  } catch (error) {
    yield* put(practitionerActions.unassignEpisodeFailure(error))
  }
}

export default function* practitionerSagas() {
  yield* all([takeLeading(PractitionerTypes.REQUEST_PROFILES, requestProfiles)])
  yield* all([takeEvery(PractitionerTypes.ASSIGN_EPISODE, assignEpisode)])
  yield* all([
    takeEvery(PractitionerTypes.BATCH_ASSIGN_EPISODES, batchAssignEpisodes),
  ])
  yield* all([takeEvery(PractitionerTypes.UNASSIGN_EPISODE, unassignEpisode)])
}
