import type {
  CollectionTaskActivity,
  CollectionTaskComment,
  CollectionTaskTemplate,
  CollectionTeam,
  ItemInt,
  ItemTask,
  ItemTaskComment,
  ItemTaskSubscription,
  ItemTaskWithLinkedTasks,
  NewTask,
  NewTaskComment,
  PageTaskListItem,
  PageTaskSubscriptionWithTask,
  TasksApiGetCommentSubscriptionsRequest,
  TasksApiGetTaskActivityRequest,
  TasksApiGetTaskCommentsRequest,
  TasksApiGetTaskCommentsSubscriptionsRequest,
  TasksApiGetTaskRequest,
  TasksApiGetTasksRequest,
  TaskSubscription,
  TaskSubscriptionUpdateRequest,
  TaskSubscriptionUpsertRequest,
  TaskSubscriptionWithTask,
  TaskWithLinkedTasks,
  UpdateTask,
  UpdateTaskStatusRequest,
} from '@dialogue/coredata'

import {
  handleCreateTaskNotification,
  handleUpdateTaskNotification,
} from 'app/components/tasks/utils'

import { emergencyRoomApi, Tags } from './api'

export const tasksApi = emergencyRoomApi.injectEndpoints({
  endpoints: (build) => ({
    getTask: build.query<TaskWithLinkedTasks, TasksApiGetTaskRequest>({
      query: ({ taskId }) => ({
        url: `/v1/tasks/${taskId}`,
      }),
      providesTags: (_res, _error, { taskId }) => [
        { type: Tags.Task, id: taskId },
      ],
      transformResponse: (response: ItemTaskWithLinkedTasks) => response.data,
    }),
    getTasks: build.query<PageTaskListItem, TasksApiGetTasksRequest>({
      query: ({ episodeId, parentId, documentId, limit, ...rest }) => ({
        url: `/v1/tasks/`,
        params: {
          episode_id: episodeId,
          parent_id: parentId,
          document_id: documentId,
          // Use page size of 100 by default
          // for unpaginated components
          limit: limit ?? 100,
          ...rest,
        },
      }),
      providesTags: (res) => [
        { type: Tags.Task, id: 'LIST' },
        ...(res?.data || []).map((task) => ({
          type: Tags.Task,
          id: task.id,
        })),
      ],
    }),
    createTask: build.mutation<ItemTask, NewTask>({
      query: (body) => ({
        url: `/v1/tasks/`,
        method: 'POST',
        body,
      }),
      onQueryStarted: handleCreateTaskNotification,
      invalidatesTags: (res) => {
        const tags = [{ type: Tags.Task, id: 'LIST' }]
        if (res?.data?.parent_id) {
          tags.push(
            { type: Tags.Task, id: res.data.parent_id },
            {
              type: Tags.TaskActivity,
              id: `LIST_FOR_TASK:${res.data.parent_id}`,
            },
          )
        }

        return tags
      },
    }),
    updateTask: build.mutation<ItemTask, { taskId: string } & UpdateTask>({
      query: ({ taskId, ...body }) => ({
        url: `/v1/tasks/${taskId}`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: handleUpdateTaskNotification,
      invalidatesTags: (res, _error, { taskId }) => {
        const tags = [
          { type: Tags.Task, id: 'LIST' },
          { type: Tags.Task, id: taskId },
          { type: Tags.TaskActivity, id: `LIST_FOR_TASK:${taskId}` },
        ]

        if (res?.data?.parent_id) {
          tags.push(
            {
              type: Tags.TaskActivity,
              id: `LIST_FOR_TASK:${res.data.parent_id}`,
            },
            { type: Tags.Task, id: res.data.parent_id },
          )
        }

        return tags
      },
    }),
    updateTaskStatus: build.mutation<
      ItemTask,
      { taskId: string } & UpdateTaskStatusRequest
    >({
      query: ({ taskId, ...body }) => ({
        url: `/v1/tasks/${taskId}/status`,
        method: 'POST',
        body,
      }),
      onQueryStarted: handleUpdateTaskNotification,
      invalidatesTags: (res, _error, { taskId }) => {
        const tags = [
          { type: Tags.Task, id: 'LIST' },
          { type: Tags.Task, id: taskId },
          { type: Tags.TaskActivity, id: `LIST_FOR_TASK:${taskId}` },
        ]

        if (res?.data?.parent_id) {
          tags.push({
            type: Tags.TaskActivity,
            id: `LIST_FOR_TASK:${res.data.parent_id}`,
          })
        }

        return tags
      },
    }),
    getTaskTeams: build.query({
      query: (_: void) => ({
        url: `/v1/tasks/teams`,
      }),
      transformResponse: ({ data = [] }: CollectionTeam) => data,
      providesTags: () => [{ type: Tags.TaskTeam, id: 'LIST' }],
    }),
    getTaskTemplates: build.query({
      query: (_: void) => ({
        url: `/v1/tasks/templates`,
      }),
      transformResponse: ({ data = [] }: CollectionTaskTemplate) => data,
      providesTags: () => [{ type: Tags.TaskTemplate, id: 'TaskTemplate' }],
    }),
    getTaskActivities: build.query({
      query: ({ taskId, ...params }: TasksApiGetTaskActivityRequest) => ({
        url: `/v1/tasks/${taskId}/activities`,
        params,
      }),
      transformResponse: ({ data = [] }: CollectionTaskActivity) => data,
      providesTags: (_res, _error, { taskId }) => [
        { type: Tags.TaskActivity, id: `LIST_FOR_TASK:${taskId}` },
      ],
    }),
    getTaskComments: build.query({
      query: ({ taskId }: TasksApiGetTaskCommentsRequest) => ({
        url: `/v1/tasks/${taskId}/comments`,
      }),
      transformResponse: (response: CollectionTaskComment) => response.data,
      providesTags: (comments = [], _error, { taskId }) => [
        {
          type: Tags.TaskComment,
          id: `LIST_FOR_TASK:${taskId}`,
        },
        ...comments.map((comment) => ({
          type: Tags.TaskComment,
          id: comment.id,
        })),
      ],
    }),
    addTaskComment: build.mutation({
      query: ({ taskId, ...body }: { taskId: string } & NewTaskComment) => ({
        url: `/v1/tasks/${taskId}/comments`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ItemTaskComment) => response.data,
      invalidatesTags: (_comment, _error, { taskId }) => [
        { type: Tags.Task, id: taskId },
        {
          type: Tags.TaskComment,
          id: `LIST_FOR_TASK:${taskId}`,
        },
        {
          type: Tags.TaskCommentSubscription,
          id: taskId,
        },
      ],
    }),
    getTaskSubscription: build.query<
      TaskSubscription,
      TasksApiGetTaskCommentsSubscriptionsRequest
    >({
      query: ({ taskId }) => ({
        url: `/v1/tasks/${taskId}/comments/subscriptions`,
      }),
      transformResponse: (response: ItemTaskSubscription) => response.data,
      providesTags: (_data, _error, { taskId }) => {
        return [
          {
            type: Tags.TaskCommentSubscription,
            id: taskId,
          },
        ]
      },
    }),
    getTaskSubscriptions: build.query<
      PageTaskSubscriptionWithTask,
      TasksApiGetCommentSubscriptionsRequest
    >({
      query: ({ limit, ...rest }) => ({
        url: `/v1/tasks/comments/subscriptions`,
        params: {
          ...rest,
          // Use page size of 100 by default
          // for unpaginated components
          limit: limit ?? 100,
        },
      }),
      providesTags: (res) => [
        {
          type: Tags.TaskCommentSubscription,
          id: 'LIST',
        },
        ...(res?.data || []).map((subscription) => ({
          type: Tags.TaskCommentSubscription,
          id: subscription.task_id,
        })),
      ],
    }),
    updateTaskSubscription: build.mutation({
      query: ({
        taskId,
        ...body
      }: { taskId: string } & TaskSubscriptionUpsertRequest) => ({
        url: `/v1/tasks/${taskId}/comments/subscriptions`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: ItemTaskSubscription) => response.data,
      invalidatesTags: (_data, _error, { taskId }) => {
        return [
          {
            type: Tags.TaskCommentSubscription,
            id: taskId,
          },
        ]
      },
    }),
    patchTaskSubscription: build.mutation<
      TaskSubscriptionWithTask,
      TaskSubscriptionUpdateRequest & { taskId: string }
    >({
      query: ({ taskId, ...body }) => ({
        url: `/v1/tasks/${taskId}/comments/subscriptions`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (_res, _error, { taskId }) => [
        { type: Tags.TaskCommentSubscription, id: 'COUNT' },
        {
          type: Tags.TaskCommentSubscription,
          id: `LIST_FOR_TASK:${taskId}`,
        },
      ],
    }),
    getUnreadCommentCount: build.query<
      number,
      TasksApiGetCommentSubscriptionsRequest
    >({
      query: () => ({
        url: `/v1/tasks/comments/subscriptions/unread_count`,
      }),
      transformResponse: (response: ItemInt) => response.data,
      providesTags: () => [{ type: Tags.TaskCommentSubscription, id: 'COUNT' }],
    }),
  }),
})

export const {
  useGetTasksQuery,
  useGetTaskQuery,
  useLazyGetTaskQuery,
  useCreateTaskMutation,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
  useGetTaskTeamsQuery,
  useGetTaskTemplatesQuery,
  useGetTaskActivitiesQuery,
  useGetTaskCommentsQuery,
  useAddTaskCommentMutation,
  useGetTaskSubscriptionQuery,
  useGetTaskSubscriptionsQuery,
  useUpdateTaskSubscriptionMutation,
  usePatchTaskSubscriptionMutation,
  useGetUnreadCommentCountQuery,
} = tasksApi
