import { useCallback } from 'react'

import {
  type BodyFaxesLinkReceivedFaxToMember,
  IncomingFaxDocumentStatus,
  type PatchIncomingFaxDocRequest,
  type PatchMemberDocRequest,
  IncomingFaxDocumentPriority,
} from '@dialogue/document-center'
import { Form, notification } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import type { Camelized } from 'humps'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import type { Owner } from 'app/components/tasks/task-owner-select'
import { useAppDispatch } from 'app/hooks'
import {
  useAssignMemberToFaxDocumentMutation,
  useUnassignMemberToFaxDocumentMutation,
  useUpdateIncomingFaxMutation,
} from 'app/redux/api/document-center/faxes'
import { useUpdateMemberDocumentMutation } from 'app/redux/api/document-center/member-documents'
import {
  documentViewerActions,
  type ViewerIncomingFaxDocument,
} from 'app/redux/documents/viewer'

import { useViewerInstance } from '../viewer-context'

import { convertUndefinedToNull, parseDocumentName } from './helpers'

interface FormValues {
  shared?: Camelized<BodyFaxesLinkReceivedFaxToMember>
  fax?: Camelized<PatchIncomingFaxDocRequest>
  owner?: Owner
  document?: Camelized<PatchMemberDocRequest>
}

/**
 * A hook that returns handlers for updating, triaging, or processing
 * a fax document depending on its current status.
 *
 * **Updating**:
 * - Updates the fax name, priority, and/or assignees
 * - Happens immediately when the values change
 *
 * **Triaging**:
 * - Assigns the fax to a member
 * - Updates the fax document status to `triaged`
 *
 * **Processing**:
 * - Assigns a document type.
 * - Updates the fax document status to `processed`
 * - (*Optionally*) Assigns an episode
 */
export function useModifyFax(
  document: ViewerIncomingFaxDocument,
  form: FormInstance<FormValues>,
) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { clearCurrentDocument } = useViewerInstance()
  const [
    updateIncomingFax,
    { data: processedFax, isLoading: isProcessLoading },
  ] = useUpdateIncomingFaxMutation()
  const [
    assignMemberToFaxDocument,
    { data: triagedFax, isLoading: isTriageLoading },
  ] = useAssignMemberToFaxDocumentMutation()
  const [updateMemberDocument] = useUpdateMemberDocumentMutation()
  const [unassignMember] = useUnassignMemberToFaxDocumentMutation()

  const nameFieldValue = Form.useWatch(['fax', 'name'], form)

  // Some fax properties can be updated immediately, without waiting for the form to submit
  const handleFaxValueChange = useCallback(
    (changedValues: FormValues) => {
      if (changedValues.fax) {
        updateIncomingFax({
          documentId: document.id,
          patchIncomingFaxDocRequest: convertUndefinedToNull(changedValues.fax),
        })
      }

      // owner needs to be split into asignee and/or teams first
      if (changedValues.owner) {
        const { assignee, teams } = changedValues.owner

        updateIncomingFax({
          documentId: document.id,
          patchIncomingFaxDocRequest: {
            assigned_to: assignee ? Number(assignee) : null,
            team_ids: teams || [],
          },
        })
      }
    },
    [updateIncomingFax, document.id],
  )

  // Document name is handled separately because it has its own internal rules
  const handleFaxNameChange = useCallback(
    (newDocumentName: string) => {
      const [, documentExtension] = parseDocumentName(document.name)

      if (newDocumentName !== nameFieldValue) {
        updateIncomingFax({
          documentId: document.id,
          patchIncomingFaxDocRequest: {
            name: documentExtension
              ? `${newDocumentName}.${documentExtension}`
              : newDocumentName,
          },
        })
        // Update the form value so that nameFieldValue reflects the change
        form.setFieldValue(['fax', 'name'], newDocumentName)
      }
    },
    [document.id, document.name, form, nameFieldValue, updateIncomingFax],
  )

  const handleUnassignMember = useCallback(
    async (documentId: string) => {
      try {
        const { data: receivedFaxDoc } = await unassignMember({
          documentId,
        }).unwrap()

        clearCurrentDocument()
        dispatch(
          documentViewerActions.viewIncomingFaxDocument({
            document: receivedFaxDoc,
          }),
        )
        notification.success({
          message: t('faxes.success.unassigning'),
        })

        // Reset local state of the form inputs
        form.setFieldValue(
          ['fax', 'priority'],
          IncomingFaxDocumentPriority.NONE,
        )
        form.setFieldValue(['owner'], null)
        form.setFieldValue(['shared', 'memberId'], null)
      } catch (error) {
        notification.error({
          message: t('faxes.error.unassigning'),
        })
      }
    },
    [clearCurrentDocument, dispatch, form, t, unassignMember],
  )

  const triageFax = useCallback(
    async (values: FormValues) => {
      // Triage the fax by assigning it to a member.
      if (
        values.shared?.memberId &&
        values.shared.memberId !== document.member_document?.member_id
      ) {
        try {
          const { data: assignedFaxDoc } = await assignMemberToFaxDocument({
            documentId: document.id,
            bodyFaxesLinkReceivedFaxToMember: {
              member_id: values.shared?.memberId,
            },
          }).unwrap()

          clearCurrentDocument()
          dispatch(
            documentViewerActions.viewIncomingFaxDocument({
              document: assignedFaxDoc,
            }),
          )
          notification.success({
            message: t('faxes.success.triaging'),
          })
          form.setFieldValue(['document', 'episodeId'], null)
        } catch (error) {
          notification.error({
            message: t('faxes.error.triaging'),
          })
        }
      }
    },
    [
      assignMemberToFaxDocument,
      clearCurrentDocument,
      dispatch,
      document.id,
      document.member_document?.member_id,
      form,
      t,
    ],
  )

  const processFax = useCallback(
    async (values: FormValues) => {
      try {
        if (!values.shared?.memberId) {
          return
        }

        updateMemberDocument({
          documentId: document.id,
          memberId: values.shared?.memberId,
          patchMemberDocRequest: {
            document_date: values.document?.documentDate
              ? moment(values.document.documentDate).toISOString()
              : undefined,
            type: values.document?.type,
            episode_id: values.document?.episodeId,
          },
        })

        const { data: updatedFax } = await updateIncomingFax({
          documentId: document.id,
          patchIncomingFaxDocRequest: {
            status: IncomingFaxDocumentStatus.processed,
          },
        }).unwrap()
        clearCurrentDocument()
        dispatch(
          documentViewerActions.viewIncomingFaxDocument({
            document: updatedFax,
          }),
        )
        notification.success({
          message: t('faxes.success.processing'),
        })
      } catch (error) {
        notification.error({
          message: t('faxes.error.processing'),
        })
      }
    },
    [
      clearCurrentDocument,
      dispatch,
      document.id,
      t,
      updateIncomingFax,
      updateMemberDocument,
    ],
  )

  const handleFaxAction = useCallback(
    (values: FormValues) => {
      switch (document.status) {
        case IncomingFaxDocumentStatus.received:
          triageFax(values)
          break

        case IncomingFaxDocumentStatus.triaged:
          processFax(values)
          break

        default:
          throw new Error('Invalid fax status')
      }
    },
    [document.status, processFax, triageFax],
  )

  const memberIdFieldValue = Form.useWatch(['shared', 'memberId'], form)
  const docTypeValue = Form.useWatch(['document', 'type'], form)

  const areFaxActionsDisabled = useCallback(
    (status: IncomingFaxDocumentStatus) => {
      switch (status) {
        case IncomingFaxDocumentStatus.received: {
          return (
            triagedFax?.data?.status === IncomingFaxDocumentStatus.triaged ||
            isTriageLoading ||
            !memberIdFieldValue
          )
        }

        case IncomingFaxDocumentStatus.triaged: {
          return (
            processedFax?.data?.status ===
              IncomingFaxDocumentStatus.processed ||
            isProcessLoading ||
            !memberIdFieldValue ||
            !docTypeValue
          )
        }

        case IncomingFaxDocumentStatus.processed:
          return true

        default:
          return true
      }
    },
    [
      docTypeValue,
      isProcessLoading,
      isTriageLoading,
      memberIdFieldValue,
      processedFax?.data?.status,
      triagedFax?.data?.status,
    ],
  )

  return {
    handleFaxValueChange,
    handleFaxNameChange,
    handleUnassignMember,
    handleFaxAction,
    isTriageLoading,
    isProcessLoading,
    areFaxActionsDisabled,
  }
}
