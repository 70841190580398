import { useCallback, useMemo, useState } from 'react'

import { Avatar, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { getInitials } from 'app/components/task-comments/helpers'
import { colors } from 'app/theme'

interface CommentProps {
  content: string
  providerName: string
  providerPicture?: string
  date: string
  showAuthorInfo?: boolean
  hideBackground?: boolean
}

const CommentContainer = styled.div<{
  $showAuthorInfo: CommentProps['showAuthorInfo']
}>`
  width: 100%;
  display: flex;
  gap: 8px;
  ${({ $showAuthorInfo }) => $showAuthorInfo && 'margin-top: 16px;'}
`

const AvatarContainer = styled.div`
  flex-shrink: 0;
  width: 32px;
  margin-top: 3px;
`

const MessageContainer = styled.div<{
  $hideBackground: CommentProps['hideBackground']
}>`
  background: ${({ $hideBackground }) => !$hideBackground && colors.background};
  border: 1px solid ${colors.backgroundDark};
  border-radius: 2px;
  margin: 2px;
  padding: 4px 8px;
`

const ToggleableTimestamp = styled(Typography.Text)<{
  $timestampVisible: boolean
}>`
  display: block;
  color: ${colors.textSuccess};
  font-size: 10px;
  transition: all 0.3s ease;
  opacity: ${({ $timestampVisible }) => ($timestampVisible ? 1 : 0)};
  max-height: ${({ $timestampVisible }) =>
    $timestampVisible
      ? '100px' // 100px or anything larger than the element's height
      : '0'};
  overflow: hidden;
`

const dateFormatter = Intl.DateTimeFormat('default', {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
})

export const Comment = ({
  content,
  providerName,
  providerPicture,
  date,
  showAuthorInfo = true,
  hideBackground = false,
}: CommentProps) => {
  const { t } = useTranslation()
  const [timestampVisible, setTimestampVisible] = useState(false)

  const formattedDateAndTime = useMemo(
    () => dateFormatter.format(new Date(date)).replace(',', ' •'),
    [date],
  )

  const handleToggleTimestampVisibility = useCallback(() => {
    // timestamp is already included with author info
    if (showAuthorInfo) {
      return
    }

    setTimestampVisible((timestampVisible) => !timestampVisible)
  }, [showAuthorInfo])

  return (
    <CommentContainer $showAuthorInfo={showAuthorInfo}>
      <AvatarContainer>
        {showAuthorInfo && (
          <Avatar
            src={providerPicture}
            css={`
              background-color: white;
              color: ${colors.textDark};
              border: 1px solid ${colors.textLight};
            `}
          >
            {providerName && getInitials(providerName)}
          </Avatar>
        )}
      </AvatarContainer>
      <Space
        direction="vertical"
        size={0}
        css={`
          flex-grow: 1;
        `}
      >
        {showAuthorInfo && (
          <Space size="small">
            <Typography.Text
              css={`
                margin-left: 3px;
                color: ${colors.onSurfaceLight};
              `}
            >
              {providerName}
            </Typography.Text>
            <Typography.Text
              css={`
                color: ${colors.textSuccess};
                font-size: 12px;
              `}
            >
              {formattedDateAndTime}
            </Typography.Text>
          </Space>
        )}

        <MessageContainer
          data-testid="task-comment-content"
          onClick={handleToggleTimestampVisibility}
          $hideBackground={hideBackground}
        >
          <Typography.Text
            css={`
              /* pre-line to respect the white space typed at input */
              white-space: pre-line;
            `}
            data-testid="comment-content"
          >
            {content}
          </Typography.Text>
        </MessageContainer>
        <ToggleableTimestamp $timestampVisible={timestampVisible}>
          {t('taskComments.sentAt', {
            // Show only the time
            time: formattedDateAndTime.split(' • ')[1],
          })}
        </ToggleableTimestamp>
      </Space>
    </CommentContainer>
  )
}
