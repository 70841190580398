import React, { useCallback, useState } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import config from 'app/config'
import { useClipboard } from 'app/hooks'
import { Icons } from 'app/images'
import { colors, CpThemeProvider } from 'app/theme'

const Container = styled.div`
  height: 100%;
  text-align: center;
`

const Title = styled.div`
  font-weight: 400;
  font-size: 24px;
  padding: 16px;
`

const CopyTextbox = styled.div`
  border: 2px dashed ${colors.border};
  padding: 28px;
  white-space: pre-line;
  text-align: center;
  margin: 24px 0 24px 0;
  cursor: copy;
`

const Description = styled.div`
  margin: 0 0 18px 0;
  font-size: 16px;
  color: ${colors.textLight};
`

const DividerText = styled.span`
  font-size: 16px;
  color: ${colors.textLight};
`

const CloseButton = styled(Button)`
  color: ${colors.textLight};
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    color: ${colors.textDark};
  }
`

export interface Props {
  closeModal: () => void
  url: string
  sendRequestAccess: () => void
}

export const RequestAccessModal: React.FC<Props> = ({
  url,
  closeModal,
  sendRequestAccess,
}) => {
  const { t } = useTranslation()

  const text = t('requestAccess.message', {
    url: `${config.APP_SCHEME}${url}`,
    interpolation: { escapeValue: false },
  })
  const requestedText = t('requestAccess.sent', {
    channel: `${config.REQUEST_ACCESS_CHANNEL}`,
  })

  const [hasCopied, setHasCopied] = useState(false)
  const [hasSent, setHasSent] = useState(false)

  const copyValue = useClipboard(text)

  const handleCopy = useCallback(() => {
    copyValue()
    setHasCopied(true)
    setTimeout(() => {
      setHasCopied(false)
    }, 5000)
  }, [copyValue])

  const handleSend = useCallback(() => {
    sendRequestAccess()
    setHasSent(true)

    setTimeout(() => {
      setHasSent(false)
    }, 5000)
  }, [sendRequestAccess])

  return (
    <CpThemeProvider>
      <Container data-cy="request-access-modal">
        <CloseButton
          type="link"
          icon={<CloseOutlined />}
          onClick={closeModal}
        />
        <Icons.Lock />
        <Title>{t('requestAccess.title')}</Title>
        {config.REQUEST_ACCESS_CHANNEL_URL && (
          <>
            <Description>
              {t('requestAccess.click')}{' '}
              <b>{t('requestAccess.requestAccess')}</b>{' '}
              {t('requestAccess.grant')}
              <br />
              {t('requestAccess.reload')}
            </Description>
            {/*
            FIXME:
              The button text should reflect if there was an error making the request.
              Requires connecting this component to redux to select error status.
        */}
            <Button type="primary" size="large" onClick={handleSend}>
              {hasSent ? requestedText : t('requestAccess.send')}
            </Button>
            <Divider>
              <DividerText>
                <b>OR</b>
              </DividerText>
            </Divider>
            <Description>
              {t('requestAccess.copyAndPaste')} <br />{' '}
              <b>
                <a
                  href={config.REQUEST_ACCESS_CHANNEL_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {config.REQUEST_ACCESS_CHANNEL}
                </a>
              </b>{' '}
              {t('requestAccess.grant')}
            </Description>
            <CopyTextbox onClick={handleCopy}>{text}</CopyTextbox>
            <Button type="default" size="large" onClick={handleCopy}>
              {hasCopied ? t('requestAccess.copied') : t('requestAccess.copy')}
            </Button>
          </>
        )}
      </Container>
    </CpThemeProvider>
  )
}
