import moment from 'moment'
import { all, call, put } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import {
  getPractitionerAvailabilities,
  getPractitionerAvailabilitiesSuccess,
  getPractitionerAvailabilitiesFailure,
} from 'app/redux/availabilities'

import { initEmeraldClient, takeKeyedLatest } from './utils'

const actionProviderId = (
  action: ReturnType<typeof getPractitionerAvailabilities>,
) => action.payload.practitionerId

export function* getAvailabilities({
  payload: {
    practitionerId,
    appointmentType,
    provinceCode,
    languageCode,
    startDate,
    lookaheadHours,
  },
}: ActionType<typeof getPractitionerAvailabilities>) {
  try {
    const emerald = yield* call(initEmeraldClient)

    const startDt = moment(startDate).startOf('day').format()

    const { data } = yield* call(emerald.getAvailableAppointments, {
      practitioner_ids: [practitionerId],
      type: appointmentType,
      location_code: `CA-${provinceCode}`,
      language_code: languageCode,
      start_at: startDt,
      lookahead_hours: lookaheadHours,
    })

    yield* put(
      getPractitionerAvailabilitiesSuccess({
        hasAvailabilities: !!data.length,
      }),
    )
  } catch (error) {
    yield* put(getPractitionerAvailabilitiesFailure({ error }))
  }
}

export default function* availabilitiesSagas() {
  yield* all([
    takeKeyedLatest(
      getPractitionerAvailabilities,
      actionProviderId,
      getAvailabilities,
    ),
  ])
}
