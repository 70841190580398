import React from 'react'

import isPropValid from '@emotion/is-prop-valid'
import {
  StyleSheetManager,
  ThemeProvider,
  type ShouldForwardProp,
} from 'styled-components'

import { DEFAULT_THEME, type Theme } from './utils'

// FIXME: remove once we convert styling props to transient props
const shouldForwardProp: ShouldForwardProp<'web'> = (prop, element) => {
  if (typeof element === 'string') {
    return isPropValid(prop)
  }
  return true
}

export const CpThemeProvider: React.FC<{
  theme?: Theme
  children: React.ReactChild
}> = ({ children, theme }) => (
  <StyleSheetManager shouldForwardProp={shouldForwardProp}>
    <ThemeProvider theme={theme || DEFAULT_THEME}>{children}</ThemeProvider>
  </StyleSheetManager>
)
