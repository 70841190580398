import {
  type ItemTask,
  TaskStatus,
  type TaskWithLinkedTasks,
  type UpdateTaskStatusRequest,
} from '@dialogue/coredata'
import type { MutationExtraOptions } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import { notification } from 'antd'

import i18n from 'app/i18n'

const { t } = i18n

export const sortTasks = (tasks: TaskWithLinkedTasks[]) => {
  const { completedTasks, incompleteTasks } = tasks.reduce(
    (
      acc: {
        completedTasks: TaskWithLinkedTasks[]
        incompleteTasks: TaskWithLinkedTasks[]
      },
      task: TaskWithLinkedTasks,
    ) => {
      if (task.status !== TaskStatus.DONE) {
        acc.incompleteTasks.push(task)
      } else {
        acc.completedTasks.push(task)
      }
      return acc
    },
    { completedTasks: [], incompleteTasks: [] },
  )
  return { completedTasks, incompleteTasks }
}

type HandlerType = MutationExtraOptions<
  string,
  ItemTask, // ResultType
  any & Partial<UpdateTaskStatusRequest>, // QueryArg
  BaseQueryFn // BaseQuery
>['onQueryStarted']

export const handleCreateTaskNotification: HandlerType = (
  _,
  { queryFulfilled },
) => {
  queryFulfilled
    .then(() => {
      notification.success({
        message: i18n.t('tasks.success.creating'),
        props: {
          'data-testid': 'task-create-success',
        },
      })
    })
    .catch(({ error }) => {
      const message =
        error.status === 409
          ? t('tasks.error.conflictOnCreating')
          : t('tasks.error.creating')
      notification.error({ message })
    })
}

export const handleUpdateTaskNotification: HandlerType = (
  { status },
  { queryFulfilled },
) => {
  return queryFulfilled
    .then(() => {
      notification.success({
        message: t('tasks.success.updating'),
        props: {
          'data-testid': 'task-update-success',
        },
      })
    })
    .catch(({ error }) => {
      let message = t('tasks.error.updating')
      if (error.status === 409) {
        if (status === TaskStatus.TO_DO) {
          message = t('tasks.error.conflictOnUpdatingToTodo')
        } else {
          message = t('tasks.error.conflictOnUpdatingToDone')
        }
      }
      notification.error({ message })
    })
}
