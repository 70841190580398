import { forwardRef, useCallback, useState } from 'react'

import { Select } from 'antd'
import type { RefSelectProps, SelectProps } from 'antd/lib/select'
import styled, { css } from 'styled-components'

import { colors } from 'app/theme'

const extendedInfoCss = css`
  &.ant-select {
    .ant-select-selector {
      height: 50px;
      padding: 5px 10px;
      .ant-typography {
        line-height: 18px;
      }
      .ant-select-selection-item {
        display: flex;
        flex-direction: column;
      }
    }
    .ant-select-arrow {
      color: ${colors.textDark};
    }
  }
`
export type ExtendedSelectProps = {
  $extendedInfo?: boolean
} & Partial<SelectProps<number>>

export const StyledSelect = styled(Select)<ExtendedSelectProps>`
  width: 100%;
  ${({ $extendedInfo }) => $extendedInfo && extendedInfoCss}
  &.ant-select-disabled {
    .ant-select-selection-item {
      color: ${colors.onSurface};
    }
  }
`

export const ExtendedSelect = forwardRef<RefSelectProps, ExtendedSelectProps>(
  (props, ref) => {
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false)

    const onDropdownVisibleChange = useCallback(
      (visible: boolean) => {
        setDropdownVisible(visible)
      },
      [setDropdownVisible],
    )
    return (
      <StyledSelect
        ref={ref}
        onDropdownVisibleChange={onDropdownVisibleChange}
        {...props}
        $extendedInfo={
          !!props.$extendedInfo && !!props.value && !dropdownVisible
        }
      />
    )
  },
)
