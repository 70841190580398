import type { EnrichedEpisode } from '@dialogue/coredata'
import type { UsherTypes } from '@dialogue/services'
import { action } from 'typesafe-actions'

import { PractitionerTypes } from './types'

export const requestProfiles = () => action(PractitionerTypes.REQUEST_PROFILES)

export const requestProfilesSuccess = (practitioners: UsherTypes.Physician[]) =>
  action(PractitionerTypes.REQUEST_PROFILES_SUCCESS, { practitioners })

export const requestProfilesFailure = (error: Error) =>
  action(PractitionerTypes.REQUEST_PROFILES_FAILURE, { error })

export const assignEpisode = (channelId: string, practitionerId: string) =>
  action(PractitionerTypes.ASSIGN_EPISODE, { channelId, practitionerId })

export const batchAssignEpisodes = (
  episodeIds: string[],
  practitionerId: number,
) =>
  action(PractitionerTypes.BATCH_ASSIGN_EPISODES, {
    episodeIds,
    practitionerId,
  })

export const assignEpisodeSuccess = (channel: EnrichedEpisode) =>
  action(PractitionerTypes.ASSIGN_EPISODE_SUCCESS, { channel })

export const assignEpisodeFailure = (error: Error) =>
  action(PractitionerTypes.ASSIGN_EPISODE_FAILURE, { error })

export const unassignEpisode = (channelId: string) =>
  action(PractitionerTypes.UNASSIGN_EPISODE, { channelId })

export const unassignEpisodeSuccess = (channel: EnrichedEpisode) =>
  action(PractitionerTypes.UNASSIGN_EPISODE_SUCCESS, { channel })

export const unassignEpisodeFailure = (error: Error) =>
  action(PractitionerTypes.UNASSIGN_EPISODE_FAILURE, { error })
