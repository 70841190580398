import { all, call, put, takeLatest } from 'typed-redux-saga/macro'

import {
  requestServices,
  requestServicesFailure,
  requestServicesSuccess,
} from 'app/redux/members-services'

import { initScribeV2Client } from './utils'

export function* loadServices() {
  try {
    const scribeV2 = yield* call(initScribeV2Client)
    const { data: services } = yield* call(scribeV2.getServices)
    yield* put(requestServicesSuccess(services))
  } catch (error: any) {
    yield* put(requestServicesFailure(error))
  }
}

export default function* servicesSagas() {
  yield* all([takeLatest(requestServices, loadServices)])
}
