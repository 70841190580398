import type { EntityState, EntityStateAdapter } from '@reduxjs/toolkit'
import moment, { type MomentInput } from 'moment'

export interface ScheduleRange {
  start: Date
  end: Date
}

/**
 * State for a fetching and paging of a single entity's schedule.
 *
 * Here, an entity could represent either a _provider_,
 * or a combination of _filters_ (i.e. _group_).
 */
export interface ScheduleState {
  /**
   * For internal use -- whether the entity schedule state was created
   * but no request was made yet
   */
  isUninitialized: boolean
  /**
   * Whether we are doing the initial fetch for a entity's schedule
   */
  isLoading: boolean
  /**
   * Whether we are fetching a date range that was not previously fetched
   * for the entity
   */
  isLoadingNewRange: boolean
  /**
   * Whether we are fetching the schedule for the entity, could be a
   * refresh or a new range
   */
  isFetching: boolean
  /**
   * The range that was last successfully fetched for the entity
   */
  currentRange: ScheduleRange | null
  /**
   * Any error that occurred while fetching the schedule for a range. Might not match current range.
   */
  fetchingError: Error | null
}

interface MakeScheduleState {
  (): ScheduleState
  <State extends object>(additionalStateProps: State): ScheduleState & State
}

export const makeScheduleState: MakeScheduleState = <T>(
  additionalStateProps?: T,
) => {
  const initialState: ScheduleState = {
    isUninitialized: true,
    isLoading: false,
    isLoadingNewRange: false,
    isFetching: false,
    currentRange: null,
    fetchingError: null,
  }
  if (additionalStateProps) {
    return { ...initialState, ...additionalStateProps }
  } else {
    return initialState
  }
}

/**
 * Determine whether input range overlaps with the target range.
 *
 * This is used to determine if an appointment or PAC would have been
 * included in a ranged schedule request, so we can replace them without
 * removing out-of-range pre-fetched appointments.
 *
 * Returns true if the input starts before the target ends and ends after the target starts.
 */
export const isIncludedInRange = (
  input: { start: MomentInput; end: MomentInput },
  target: ScheduleRange,
): boolean => {
  return (
    moment(input.start).isBefore(target.end) &&
    moment(input.end).isAfter(target.start)
  )
}

export const scheduleStateOps = {
  get(scheduleState: ScheduleState, range: ScheduleRange) {
    if (scheduleState.isUninitialized) {
      scheduleState.isUninitialized = false
      scheduleState.isLoading = true
    }

    // a range within the currently loaded range is not counted
    // as a new range
    const isInCurrentRange =
      scheduleState.currentRange &&
      moment(range.start).isSameOrAfter(scheduleState.currentRange.start) &&
      moment(range.end).isSameOrBefore(scheduleState.currentRange.end)

    if (!isInCurrentRange) {
      scheduleState.isLoadingNewRange = true
    }

    scheduleState.isFetching = true
    scheduleState.fetchingError = null
  },
  received(scheduleState: ScheduleState | undefined, range: ScheduleRange) {
    if (!scheduleState) {
      // no-op, shouldn't happen
      return
    }

    // we fetched -> all flags off
    scheduleState.isLoading = false
    scheduleState.isLoadingNewRange = false
    scheduleState.isFetching = false

    scheduleState.currentRange = range
    scheduleState.fetchingError = null
  },
  erroredFetching(scheduleState: ScheduleState | undefined, error: Error) {
    if (!scheduleState) {
      // no-op, shouldn't happen
      return
    }

    scheduleState.isLoading = false
    scheduleState.isLoadingNewRange = false
    scheduleState.isFetching = false
    scheduleState.fetchingError = error
  },
} as const

/**
 * Makes a function, `setForRangeAndProviders`, that works similar to entityAdapter.setMany,
 * but additionally will clean the state of any lingering appointments that would have been
 * fetched for the provided range and provider.
 *
 * @param {EntityStateAdapter<TEntity>} adapter - An EntityAdapter from Redux Toolkit.
 */
export const makeSetForRangeAndProviders =
  <
    TEntity extends {
      start_at: MomentInput
      end_at: MomentInput
      provider_id: number
    },
  >(
    adapter: EntityStateAdapter<TEntity>,
  ) =>
  /**
   * A function that works similar to entityAdapter.setMany, but additionally will clean the
   * state of any lingering appointments that would have been fetched for the provided range
   * and provider.
   *
   * This prevents orphan appointments when fetching a time range (e.g. appointment that was
   * initially fetched for a provider but was subsequently deleted or moved out of range).
   *
   * @param {EntityState<TEntity>} state - Instance of an EntityState, created by adapter.getInitialState.
   * @param {TEntity[]} newEntities - Array of entites to add.
   * @param {number[]} providerIds - Array of the provider IDs to update entities for.
   * @param {ScheduleRange} range - The ScheduleRange that was fetched. Entities outside this range
   *     (that match the provider) will be removed.
   */
  (
    state: EntityState<TEntity>,
    newEntities: TEntity[],
    providerIds: number[],
    range: ScheduleRange,
  ) => {
    const invalidatedIds = state.ids.filter((id) => {
      const entity = state.entities[id]

      return (
        entity &&
        providerIds.includes(entity.provider_id) &&
        isIncludedInRange({ start: entity.start_at, end: entity.end_at }, range)
      )
    })

    adapter.removeMany(state, invalidatedIds)
    adapter.setMany(state, newEntities)
  }
