import { createAction, createReducer } from '@reduxjs/toolkit'

export interface PatientAccess {
  hasAccess: boolean | null
  fetching: boolean
  error: Error | null
}

export const requestAccess = createAction<{
  patientId: string | number
  urlPath: string
}>('@@rlac/requestAccess')

export const requestAccessSuccess = createAction<{
  patientId: string | number
  hasAccess: boolean
}>('@@rlac/requestAccessSuccess')

export const requestAccessFailure = createAction<{
  patientId: string | number
  error: Error
}>('@@rlac/requestAccessFailure')

export const INITIAL_STATE: Record<string, PatientAccess> = {}

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(requestAccess, (state, { payload: { patientId } }) => {
    state[patientId] = { fetching: true, hasAccess: null, error: null }
  })
  builder.addCase(
    requestAccessSuccess,
    (state, { payload: { patientId, hasAccess } }) => {
      state[patientId].fetching = false
      state[patientId].hasAccess = hasAccess
    },
  )
  builder.addCase(
    requestAccessFailure,
    (state, { payload: { patientId, error } }) => {
      state[patientId].fetching = false
      state[patientId].error = error
    },
  )
})
