import {
  forwardRef,
  useEffect,
  useMemo,
  type ComponentProps,
  type ReactElement,
} from 'react'

import type { EnrichedEpisode } from '@dialogue/coredata'
import { Select, Typography } from 'antd'
import type { RefSelectProps } from 'antd/lib/select'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useWindowPortalContext } from 'app/components/window-portal/context'
import { formatDocumentDate } from 'app/containers/documents/helpers'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { normalizeString } from 'app/lib/helpers'
import { selectEpisodeViewEpisode } from 'app/redux/episode-view/selectors'
import type { Episode } from 'app/redux/episode-view/types'
import { patientsActions } from 'app/redux/patients'
import {
  selectErrorFetchingMostRecentEpisodes,
  selectFetchingMostRecentEpisodes,
  selectMostRecentEpisodeIds,
  selectPatientEpisodesByIds,
  selectPatientEpisodesData,
} from 'app/redux/patients/selectors'
import { colors } from 'app/theme'

interface OptionType {
  value?: string
  label: ReactElement<ComponentProps<typeof EpisodeOptionLabel>>
  [k: `data-${string}`]: string
}

const EpisodeSelect = styled(Select<string, OptionType>)`
  width: 100%;
  &.ant-select-disabled {
    .ant-select-selection-item {
      color: ${colors.onSurface};
    }
  }
`
type EpisodeSelectProps = ComponentProps<typeof EpisodeSelect>

type Props = {
  memberId: number
  defaultId?: string
  fallbackTitle?: string
} & Partial<EpisodeSelectProps>

interface EpisodeOptionLabelProps {
  title: string
  date: string
}

const EpisodeOptionLabel = ({ title, date }: EpisodeOptionLabelProps) => {
  return (
    <div
      css={`
        display: flex;
        justify-content: space-between;
        white-space: normal;
      `}
    >
      <span
        css={`
          padding-right: 10px;
        `}
      >
        {title}
      </span>
      <span data-dd-privacy="allow">({date})</span>
    </div>
  )
}

const handleEpisodeFilterOption: NonNullable<
  EpisodeSelectProps['filterOption']
> = (input, option) => {
  const label = option?.label.props.title || ''
  return normalizeString(label).includes(normalizeString(input))
}

const ErrorMessage = () => {
  const { t } = useTranslation()
  return (
    <Typography.Text type="danger" data-dd-privacy="allow">
      {t('documents.errorLoadingEpisodePicker')}
    </Typography.Text>
  )
}

export const EpisodePicker = forwardRef<RefSelectProps, Props>(
  ({ memberId, defaultId, fallbackTitle, ...rest }, ref) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const popoutWindow = useWindowPortalContext()
    const isInPopout = !!popoutWindow

    const isFetchingMostRecentEpisodes = useAppSelector((state) =>
      selectFetchingMostRecentEpisodes(state, memberId),
    )
    const errorFetchingMostRecentEpisodes = useAppSelector((state) =>
      selectErrorFetchingMostRecentEpisodes(state, memberId),
    )
    const episodesData = useAppSelector((state) =>
      selectPatientEpisodesData(state, memberId),
    )
    const mostRecentEpisodeIds = useAppSelector((state) =>
      selectMostRecentEpisodeIds(state, memberId),
    )
    const mostRecentEpisodes = useAppSelector((state) =>
      selectPatientEpisodesByIds(state, memberId, mostRecentEpisodeIds || []),
    )

    useEffect(() => {
      if (!!memberId && !mostRecentEpisodeIds) {
        dispatch(patientsActions.requestMostRecentEpisodes(memberId))
      }
    }, [dispatch, mostRecentEpisodeIds, memberId])

    const currentEpisode = useAppSelector(selectEpisodeViewEpisode)

    const episodeOptions = useMemo<EpisodeSelectProps['options']>(() => {
      if (!mostRecentEpisodes) {
        return []
      }

      let episodesToUse: Record<string, EnrichedEpisode | Partial<Episode>> = {}

      if (currentEpisode.id && !mostRecentEpisodes[currentEpisode.id]) {
        episodesToUse[currentEpisode.id] = currentEpisode
      }
      if (
        defaultId &&
        !mostRecentEpisodes[defaultId] &&
        episodesData?.[defaultId]
      ) {
        episodesToUse[defaultId] = episodesData[defaultId]
      }

      episodesToUse = { ...episodesToUse, ...mostRecentEpisodes }
      const options = []
      for (const epId in episodesToUse) {
        const formattedDate = formatDocumentDate({
          t,
          date: episodesToUse[epId].created_at,
        })
        let episodeTitle = episodesToUse[epId].title || fallbackTitle || epId
        if (!isInPopout && episodesToUse[epId].id === currentEpisode.id) {
          episodeTitle += ` (${t('documents.thisEpisode').toLowerCase()})`
        }

        options.push({
          label: (
            <EpisodeOptionLabel title={episodeTitle} date={formattedDate} />
          ),
          value: episodesToUse[epId].id,
          'data-dd-action-name': 'episode-picker:select:option',
          'data-testid': 'episode-picker-option',
        })
      }

      return options
    }, [
      t,
      episodesData,
      mostRecentEpisodes,
      currentEpisode,
      defaultId,
      isInPopout,
      fallbackTitle,
    ])

    return (
      <>
        <EpisodeSelect
          {...rest}
          ref={ref}
          loading={isFetchingMostRecentEpisodes}
          showSearch
          allowClear
          filterOption={handleEpisodeFilterOption}
          options={episodeOptions}
          key={defaultId}
          defaultValue={defaultId}
          dropdownMatchSelectWidth={false}
          status={errorFetchingMostRecentEpisodes && 'error'}
          optionLabelProp="label"
          data-testid="episode-picker"
          data-dd-action-name="episode-picker:open"
          data-private
        />
        {errorFetchingMostRecentEpisodes && <ErrorMessage />}
      </>
    )
  },
)
