import { useCallback, useMemo, useState, type ChangeEventHandler } from 'react'

import { Input } from 'antd'

import {
  ListSelect,
  type BaseOption,
  type ListSelectProps,
} from 'app/components/list-select/list-select'
import { normalizeString } from 'app/lib/helpers'

export interface SearchableOption extends BaseOption {
  filterValue: string
}

interface SearchableListSelectProps<T> extends ListSelectProps<T> {
  placeholder?: string
}

export const SearchableListSelect = <T extends SearchableOption>({
  options,
  onSelect,
  loading,
  hasError,
  errorMessage,
  placeholder,
  emptyText,
  listContainerRender,
}: SearchableListSelectProps<T>) => {
  const [filter, setFilter] = useState('')

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setFilter(e.target.value)
    },
    [],
  )

  const filteredOptions = useMemo(() => {
    return options?.filter((option) => {
      const filterString = option.filterValue
      return normalizeString(filterString).includes(normalizeString(filter))
    })
  }, [options, filter])

  return (
    <>
      <Input onChange={handleSearchChange} placeholder={placeholder} />
      <ListSelect
        options={filteredOptions}
        onSelect={onSelect}
        loading={loading}
        errorMessage={errorMessage}
        hasError={hasError}
        emptyText={emptyText}
        listContainerRender={listContainerRender}
      />
    </>
  )
}
