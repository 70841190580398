// @ts-strict-ignore
import type { EmeraldTypes } from '@dialogue/services'
import type { UpdateAppointmentStatusAttributes } from '@dialogue/services/dist/emerald/v2/model'
import type { Appointment as TkAppointment } from '@dialogue/timekeeper'
import { createAction, createReducer } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
import { schedulingAppointmentActions } from 'app/redux/scheduling/appointments'

import type { AppointmentMetadata, AppointmentState } from './types'

export const INITIAL_STATE: AppointmentState = {
  patients: {},
  appointmentStatusUpdate: {},
}

export const getAppointments = createAction<{
  patientId: number | string
}>('@@appointments/GET_APPOINTMENTS')

export const getAppointmentsSuccess = createAction<{
  patientId: number | string
  appointments: EmeraldTypes.Appointment[]
  providers: EmeraldTypes.Provider[]
  metadata: AppointmentMetadata
}>('@@appointments/GET_APPOINTMENTS_SUCCESS')

export const getAppointmentsFailure = createAction<{
  patientId: number | string
  error: Error
}>('@@appointments/GET_APPOINTMENTS_FAILURE')

export const updateAppointmentStatus = createAction<{
  appointmentId: number | string
  patientId: number | string
  updateAppointmentStatusAttributes: Omit<
    UpdateAppointmentStatusAttributes,
    'source'
  >
}>('@@appointments/UPDATE_APPOINTMENT_STATUS')

export const updateAppointmentStatusSuccess = createAction<{
  appointment: EmeraldTypes.V2.Appointment
  patientId: number | string
  tkAppointment: TkAppointment | null
}>('@@appointments/UPDATE_APPOINTMENT_STATUS_SUCCESS')

export const updateAppointmentStatusFailure = createAction<{
  appointmentId: number | string
  error: Error
}>('@@appointments/UPDATE_APPOINTMENT_STATUS_FAILURE')

export const clearAppointments = createAction<{
  patientId: number | string
}>('@@appointments/CLEAR_APPOINTMENTS')

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(getAppointments, (state, action) => {
    state.patients[action.payload.patientId] = {
      fetching: true,
      error: null,
      appointments: state.patients[action.payload.patientId]?.appointments,
      providerMap: state.patients[action.payload.patientId]?.providerMap,
      metadata: state.patients[action.payload.patientId]?.metadata,
    }
  })

  builder.addCase(getAppointmentsSuccess, (state, action) => {
    const patientState = state.patients[action.payload.patientId]

    if (!patientState) {
      return
    }

    patientState.appointments = patientState.appointments || []
    patientState.providerMap = patientState.providerMap || {}

    // Avoid duplicate appointments
    action.payload.appointments.forEach((appointment) => {
      const existingIndex = patientState.appointments.findIndex(
        (existing) => existing.id === appointment.id,
      )
      if (existingIndex === -1) {
        patientState.appointments.push(appointment)
      } else {
        patientState.appointments[existingIndex] = appointment
      }
    })

    action.payload.providers.forEach((item) => {
      patientState.providerMap[item.id] = item
    })

    patientState.fetching = false
    patientState.metadata = action.payload.metadata
  })

  builder.addCase(getAppointmentsFailure, (state, action) => {
    const patientState = state.patients[action.payload.patientId]
    if (patientState) {
      patientState.fetching = false
      patientState.error = action.payload.error
    }
  })

  builder.addCase(clearAppointments, (state, action) => {
    delete state.patients[action.payload.patientId]
  })

  builder.addCase(updateAppointmentStatus, (state, action) => {
    const appointmentId = action.payload.appointmentId

    state.appointmentStatusUpdate[appointmentId] = {
      fetching: true,
      error: null,
    }
  })

  builder.addCase(updateAppointmentStatusSuccess, (state, action) => {
    const appointment = action.payload.appointment

    state.appointmentStatusUpdate[appointment.id] = {
      fetching: false,
      error: null,
    }

    const patientState = state.patients[appointment.member.id]

    if (!patientState) {
      return
    }

    // find changed appointment and change the status
    const existingIndex = patientState.appointments.findIndex(
      (existing) => existing.id === appointment.id,
    )
    patientState.appointments[existingIndex].status = appointment.status
  })

  builder.addCase(updateAppointmentStatusFailure, (state, action) => {
    const appointmentId = action.payload.appointmentId
    state.appointmentStatusUpdate[appointmentId] = {
      fetching: false,
      error: action.payload.error,
    }
  })

  builder.addCase(schedulingAppointmentActions.created, (state, action) => {
    const appointment = action.payload.appointment
    const patientState = state.patients[appointment.member.id]

    if (!patientState) {
      return
    }

    // add to top even if not latest, for better feedback
    patientState.appointments.unshift({
      ...appointment,
      // convert from V1 -> V2
      until_at: appointment.end_at,
      provider_user_id: appointment.practitioner_emr_id,
    })
  })

  builder.addCase(schedulingAppointmentActions.updated, (state, action) => {
    const { appointment } = action.payload
    const patientState = state.patients[appointment.member.id]

    if (!patientState) {
      return
    }

    // update or add appointment
    const existingIndex = patientState.appointments.findIndex(
      (existingAppt) => existingAppt.id === appointment.id,
    )

    if (existingIndex === -1) {
      patientState.appointments.unshift({
        ...appointment,
        // convert from V1 -> V2
        until_at: appointment.end_at,
        provider_user_id: appointment.practitioner_emr_id,
      })
    } else {
      patientState.appointments[existingIndex] = {
        ...appointment,
        // convert from V1 -> V2
        until_at: appointment.end_at,
        provider_user_id: appointment.practitioner_emr_id,
      }
    }
  })
})

export const selectOffset = (state: ReduxState, patientId: string | number) =>
  state.appointments.patients[patientId]?.metadata?.offset || 0

export const selectPatientAppointments = (
  state: ReduxState,
  patientId: string | number,
) => {
  return state.appointments.patients[patientId]?.appointments
}

export const selectProviders = (
  state: ReduxState,
  patientId: string | number,
) => {
  return state.appointments.patients[patientId]?.providerMap
}

export const selectAppointmentStatusUpdate = (
  state: ReduxState,
  appointmentId: string | number,
) => {
  return state.appointments.appointmentStatusUpdate?.[appointmentId]
}

export const selectPatientAppointmentsData = (
  state: ReduxState,
  patientId: string | number,
) => {
  return state.appointments.patients[patientId]
}

export const selectPatientAppointmentDetails = (
  state: ReduxState,
  patientId: string | number,
) => {
  const appointments = selectPatientAppointments(state, patientId)
  const providers = selectProviders(state, patientId)

  if (!appointments) {
    return null
  }

  return appointments.map((appt: EmeraldTypes.Appointment) => {
    const provider: EmeraldTypes.Provider = providers[appt.provider_user_id]
    return {
      ...appt,
      provider_full_name: provider?.full_name,
      provider_title: provider?.title,
    }
  })
}
