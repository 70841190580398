// @ts-strict-ignore
import { all, call, put } from 'typed-redux-saga/macro'

import * as inputHealthActions from 'app/redux/input-health/actions'
import { pharmaciesActions } from 'app/redux/medical-profile/pharmacies'
import { verifyRecord } from 'app/sagas/input-health'
import { initEmeraldV2Client, takeKeyedLatest } from 'app/sagas/utils'

export function* getPharmacy({
  payload,
}: ReturnType<typeof pharmaciesActions.get>) {
  try {
    const emerald = yield* call(initEmeraldV2Client)
    const pharmacy = yield* call(emerald.getPharmacy, payload.pharmacyId)
    yield* put(pharmaciesActions.receive(pharmacy))
  } catch (e) {
    yield* put(pharmaciesActions.error(e))
  }
}

export function* getPreferredPharmacy({
  payload,
}: ReturnType<typeof pharmaciesActions.getPreferred>) {
  let pharmacyId: string

  try {
    const { data } = yield* call(
      verifyRecord,
      inputHealthActions.verifyRecord(payload.patientId),
    )

    pharmacyId = data.preferred_pharmacy_id
  } catch (error) {
    yield* put(pharmaciesActions.error(error))
  }

  if (pharmacyId) {
    yield* call(getPharmacy, pharmaciesActions.get({ pharmacyId }))
  } else {
    yield* put(pharmaciesActions.receive(null))
  }
}

export default function* pharmaciesSagas() {
  yield* all([
    takeKeyedLatest(
      pharmaciesActions.get,
      ({ payload }) => payload.pharmacyId,
      getPharmacy,
    ),
    takeKeyedLatest(
      pharmaciesActions.getPreferred,
      (action) => action.payload.patientId,
      getPreferredPharmacy,
    ),
  ])
}
