// @ts-strict-ignore
import { ApiRequestError } from '@dialogue/services'
import { ClientError } from '@mattermost/client'

/**
 * Whether a status code is a 4xx that isn't authentication related (not 401/403)
 * @param code
 */
const isClientError = (code: number | null) => {
  code = code ?? 0
  return code >= 400 && code < 500 && code !== 403 && code !== 401
}

/**
 * Whether an error should be forwarded to Datadog
 * TODO: revisit if this is still needed
 *
 * Returns true if the error is
 * - not a request (HTTP) error, or
 * - a request (HTTP) client error which is not authentication related,
 */
export const shouldReportChatError = (error: unknown) => {
  if (error instanceof ApiRequestError) {
    return isClientError(error.code)
  } else if (error instanceof ClientError) {
    return isClientError(error.status_code)
  } else {
    return true
  }
}
