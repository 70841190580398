import {
  useCallback,
  useMemo,
  type MouseEventHandler,
  type ReactNode,
} from 'react'

import {
  CloseOutlined,
  FileOutlined,
  FileUnknownOutlined,
} from '@ant-design/icons'
import {
  ActivityTypeEnum,
  type MemberDocument,
} from '@dialogue/document-center'
import { Button, Card, List, Tooltip } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAppSelector } from 'app/hooks'
import { CheckedDocument } from 'app/images/icons'
import { selectUserIdToNickname } from 'app/redux/practitioners/selectors'
import { colors } from 'app/theme'

const StyledCard = styled(Card)`
  && .ant-card-meta-title {
    margin-right: 12px;
    margin-bottom: 0;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
`

const StyledCloseIcon = styled(CloseOutlined)`
  svg {
    color: ${colors.textLight};
    height: 12px;
  }
`

const StyledMeta = styled(Card.Meta)`
  .ant-card-meta-avatar {
    padding-right: 8px;
    padding-top: 2px;
  }
`

const StyledItem = styled(List.Item)`
  &&& {
    margin-bottom: 8px;
    cursor: pointer;
  }
`

interface MultiSelectListProps extends React.ComponentProps<typeof List> {
  onCancel: (id: string) => void
  documents: MemberDocument[]
  onClick?: (id: string, document: MemberDocument) => void
  emptyText?: ReactNode
  allowEdit?: boolean
}

interface DocumentItemProps {
  name: string
  onRemoveDocument: MouseEventHandler
  onClick?: MouseEventHandler
  createdAt: string
  reviewers: string[]
}

export const MultiSelectList = ({
  documents,
  loading,
  onCancel,
  onClick = () => {},
  emptyText,
  allowEdit = true,
  ...rest
}: MultiSelectListProps) => {
  const { t } = useTranslation()

  const userIds = useMemo(
    () =>
      documents
        .map(({ activities }) =>
          activities
            .map(({ user_id }) => user_id)
            .filter((id): id is number => id !== undefined),
        )
        .flat(),
    [documents],
  )

  // Load a mapper function to get the nickname of a practitioner from their id
  const userIdToNickname = useAppSelector((state) =>
    selectUserIdToNickname(state, userIds),
  )

  const documentItems = useMemo<DocumentItemProps[]>(
    () =>
      documents?.map((document) => {
        const { name, created_at, id } = document || {}
        const nicknamesOfReviewers = document.activities
          .filter((a) => a.activity_type === ActivityTypeEnum.review)
          .map((a) => userIdToNickname[a.user_id ?? ''])

        return {
          name,
          onRemoveDocument: (e) => {
            e.stopPropagation()
            onCancel(id)
          },
          reviewers: [...new Set(nicknamesOfReviewers)],
          onClick: (e) => {
            e.stopPropagation()
            onClick(id, document)
          },
          createdAt: created_at,
        }
      }),
    [documents, onCancel, onClick, userIdToNickname],
  )

  const renderItem = useCallback(
    ({
      name,
      reviewers,
      createdAt,
      onRemoveDocument,
      onClick,
    }: DocumentItemProps) => {
      return (
        <StyledItem>
          <StyledCard
            size="small"
            onClick={onClick}
            data-testid={'document-select-card'}
          >
            <StyledMeta
              title={name || t('documents.unknownDetails.name')}
              avatar={
                name ? (
                  reviewers?.length ? (
                    <Tooltip
                      title={t('documents.activity.reviewedTooltip', {
                        reviewers: reviewers.join('\n'),
                      })}
                      placement="leftTop"
                      // Allow to render the tooltip outside the container:
                      getPopupContainer={() => document.body} // eslint-disable-line react/jsx-no-bind
                      // Allow to render the tooltip with line breaks:
                      overlayStyle={{ whiteSpace: 'pre-line' }}
                    >
                      <CheckedDocument />
                    </Tooltip>
                  ) : (
                    <FileOutlined
                      css={`
                        color: ${colors.textLight};
                      `}
                    />
                  )
                ) : (
                  <FileUnknownOutlined
                    css={`
                      color: ${colors.errorLight};
                    `}
                  />
                )
              }
              description={
                <>
                  {t('documents.created')}
                  {': '}
                  {createdAt
                    ? moment(createdAt).format('YYYY/MM/DD')
                    : t('documents.unknownDetails.createdAt')}
                </>
              }
            />
            {allowEdit && (
              <StyledButton
                onClick={onRemoveDocument}
                type="text"
                icon={<StyledCloseIcon />}
                data-testid={'document-select-remove-button'}
              />
            )}
          </StyledCard>
        </StyledItem>
      )
    },
    [allowEdit, t],
  )

  return (
    <List
      {...rest}
      loading={loading}
      grid={{ gutter: 16, column: 1 }}
      dataSource={documentItems}
      renderItem={renderItem}
      locale={{ emptyText: emptyText ?? t('documents.emptyText') }}
    />
  )
}
