// @ts-strict-ignore
import type { EmeraldTypes } from '@dialogue/services'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

import { updateAppointmentStatusSuccess } from '../appointments'

import { schedulingAppointmentActions } from './appointments'

interface State {
  fetching: boolean
  error: Error | null
  practitionerId: number | null
  selectedDate: string | Date | null
  schedule: EmeraldTypes.V2.PractitionerSchedule | null
}

const INITIAL_STATE: State = {
  fetching: false,
  error: null,
  practitionerId: null,
  selectedDate: null,
  schedule: null,
}

export const { actions: schedulingPractitionerActions, reducer } = createSlice({
  name: '@@scheduling/practitioner',
  initialState: INITIAL_STATE,
  reducers: {
    get(
      state,
      {
        payload,
      }: PayloadAction<{ practitionerId: number; selectedDate: string | Date }>,
    ) {
      if (payload.practitionerId !== state.practitionerId) {
        state.practitionerId = payload.practitionerId
        state.error = null
        delete state.schedule
      } else if (state.selectedDate !== payload.selectedDate) {
        delete state.schedule
      }

      state.selectedDate = payload.selectedDate
      state.fetching = true
    },
    getSuccess(
      state,
      { payload }: PayloadAction<EmeraldTypes.V2.PractitionerSchedule>,
    ) {
      state.fetching = false
      state.error = null
      state.schedule = payload
    },
    getFailure(state, { payload }: PayloadAction<Error>) {
      state.fetching = false
      state.error = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      schedulingAppointmentActions.created,
      (state, { payload }) => {
        if (payload.providerId === state.practitionerId) {
          const existingAppointmentIndex =
            state.schedule?.booked_appointments?.findIndex(
              (bookedAppointment) =>
                bookedAppointment.id === payload.appointment?.id,
            )
          // push the appointment into booked_appointments, replace if already there
          existingAppointmentIndex !== -1
            ? (state.schedule.booked_appointments[existingAppointmentIndex] =
                payload.appointment)
            : state.schedule?.booked_appointments?.push(payload.appointment)
        }
      },
    )

    builder.addCase(updateAppointmentStatusSuccess, (state, action) => {
      const { appointment } = action.payload

      if (!state.schedule?.booked_appointments) return

      const existingAppointmentIndex =
        state.schedule.booked_appointments.findIndex(
          (bookedAppointment) => bookedAppointment.id === appointment?.id,
        )

      if (existingAppointmentIndex !== -1) {
        state.schedule.booked_appointments[existingAppointmentIndex] =
          appointment
      }
    })

    builder.addCase(schedulingAppointmentActions.updated, (state, action) => {
      const { appointment, providerId } = action.payload

      if (!state.schedule?.booked_appointments) return

      const existingAppointmentIndex =
        state.schedule.booked_appointments.findIndex(
          (bookedAppointment) => bookedAppointment.id === appointment.id,
        )

      if (existingAppointmentIndex !== -1) {
        // update if provider is same, remove otherwise
        if (providerId === state.practitionerId) {
          state.schedule.booked_appointments[existingAppointmentIndex] =
            appointment
        } else {
          state.schedule.booked_appointments.splice(existingAppointmentIndex, 1)
        }
      } else if (providerId === state.practitionerId) {
        // add to schedule if moved to current provider
        state.schedule.booked_appointments.push(appointment)
      }
    })
  },
})

export const selectPractitionerScheduleFetching = (state: ReduxState) =>
  state.scheduling.practitioner.fetching

export const selectSchedulingPractitioner = (state: ReduxState) =>
  state.scheduling.practitioner

export const selectPractitionerSchedule = createSelector(
  selectSchedulingPractitioner,
  (practitioner): EmeraldTypes.V2.PractitionerSchedule => practitioner.schedule,
)

export default reducer
