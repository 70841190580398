import type { FormInstance } from 'antd'

import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'

import { FaxProperties } from './fax-properties'
import { MemberProperties } from './member-properties'

export const ReceivedPanel = ({
  document,
  form,
}: {
  document: ViewerIncomingFaxDocument
  form: FormInstance
}) => {
  return (
    <>
      <MemberProperties document={document} form={form} />
      <FaxProperties document={document} />
    </>
  )
}
