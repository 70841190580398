import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

export enum BackgroundFilterType {
  NONE = 'none',
  BLUR_LOW = 'blur-low',
  BLUR_HIGH = 'blur-high',
  IMAGE = 'image',
}

// AB testing - revert when done
export enum BackgroundImage {
  GENERIC = 'video-background.jpg',
  PC = 'video-background-pc.jpg',
  MH = 'video-background-mh.jpg',
}

export interface PreferencesState {
  videoCallfilterType: BackgroundFilterType | null
  videoCallBackgroundImage: BackgroundImage | null
  splitLayouts: Record<string, [number, number]>
}

export const INITIAL_STATE: PreferencesState = {
  videoCallfilterType: null,
  videoCallBackgroundImage: null,
  splitLayouts: {},
}

export const { reducer, actions: preferencesActions } = createSlice({
  name: '@@preferences',
  initialState: INITIAL_STATE,
  reducers: {
    setVideoCallBackgroundImage: (
      state,
      action: PayloadAction<BackgroundImage>,
    ) => {
      state.videoCallBackgroundImage = action.payload
    },
    setVideoCallFilter: (
      state,
      action: PayloadAction<BackgroundFilterType>,
    ) => {
      state.videoCallfilterType = action.payload
    },
    setSplitLayout: (
      state,
      action: PayloadAction<{ key: string; value: [number, number] }>,
    ) => {
      state.splitLayouts[action.payload.key] = action.payload.value
    },
  },
})

export default reducer

export const selectVideoFilterType = (state: ReduxState) => {
  return state.preferences?.videoCallfilterType || BackgroundFilterType.NONE
}

export const selectVideoBackgroundImage = (state: ReduxState) => {
  return state.preferences?.videoCallBackgroundImage || BackgroundImage.GENERIC
}

export const selectSplitLayout = (state: ReduxState, key: string) => {
  return state.preferences?.splitLayouts?.[key]
}
