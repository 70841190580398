// @ts-strict-ignore
import { notification } from 'antd'
import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import {
  DocumentSource,
  documentViewerActions,
} from 'app/redux/documents/viewer'
import * as inputHealthActions from 'app/redux/input-health/actions'
import { selectPatientDocumentById as selectIhDocument } from 'app/redux/input-health/reducer'
import { getPatientDocuments as getIhDocuments } from 'app/sagas/input-health'

import { getDocumentById } from './members'

function* resolveAndViewDcMemberDocument(memberId: number, documentId: string) {
  const memberDocument = yield* call(getDocumentById, memberId, documentId)

  yield* put(
    documentViewerActions.viewMemberDocument({ document: memberDocument }),
  )
}

function* resolveAndViewIhMemberDocument(memberId: number, documentId: string) {
  let ihDocument = yield* select(selectIhDocument, documentId)

  if (!ihDocument) {
    const ihDocuments = yield* call(
      getIhDocuments,
      inputHealthActions.getPatientDocuments(memberId),
    )
    ihDocument = ihDocuments.find((doc) => String(doc.id) === documentId)
  }
  if (!ihDocument) {
    throw new Error('Could not find document')
  }

  yield* put(
    documentViewerActions.viewIHDocument({ document: ihDocument, memberId }),
  )
}

export function* resolveAndViewMemberDocument({
  payload: { documentId, documentSource, memberId },
}: ReturnType<typeof documentViewerActions.resolveAndViewMemberDocument>) {
  try {
    switch (documentSource) {
      case DocumentSource.DOCUMENT_CENTER:
        yield* call(resolveAndViewDcMemberDocument, memberId, documentId)
        break
      case DocumentSource.INPUT_HEALTH:
        yield* call(resolveAndViewIhMemberDocument, memberId, documentId)
        break
    }
  } catch (e) {
    notification.error({
      message: i18n.t('documents.errorResolvingDocument'),
    })
    console.warn('could not resolve file', e)
  }
}

export default function* documentViewerSagas() {
  yield* all([
    takeLatest(
      documentViewerActions.resolveAndViewMemberDocument,
      resolveAndViewMemberDocument,
    ),
  ])
}
