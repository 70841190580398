import { EmergencyRoomTypes } from '@dialogue/services'
import { action, type ActionType } from 'typesafe-actions'

import { EpisodeViewTypes } from './types'

export const clearEpisodeView = () =>
  action(EpisodeViewTypes.CLEAR_EPISODE_VIEW)

export const resolveEpisode = (episode_id: string, outcome_id: string) =>
  action(EpisodeViewTypes.RESOLVE_EPISODE, {
    episode_id,
    state_id: EmergencyRoomTypes.EpisodeState.RESOLVED,
    outcome_id,
  } as const)

export const reOpenEpisode = (episode_id: string) =>
  action(EpisodeViewTypes.REOPEN_EPISODE, {
    episode_id,
    state_id: EmergencyRoomTypes.EpisodeState.ACTIVE,
  } as const)

export const setEpisodeToPending = (episode_id: string) =>
  action(EpisodeViewTypes.SET_EPISODE_TO_PENDING, {
    episode_id,
    state_id: EmergencyRoomTypes.EpisodeState.PENDING,
  } as const)

export const updateEpisodeStateSuccess = (
  episode_id: string,
  state_id: EmergencyRoomTypes.EpisodeState,
) =>
  action(EpisodeViewTypes.UPDATE_EPISODE_STATE_SUCCESS, {
    episode_id,
    state_id,
  })

export const updateEpisodeStateFailure = (episode_id: string, error: Error) =>
  action(EpisodeViewTypes.UPDATE_EPISODE_STATE_FAILURE, {
    episode_id,
    error,
  })

export type UpdateEpisodeStateAction = ActionType<
  typeof resolveEpisode | typeof reOpenEpisode | typeof setEpisodeToPending
>
