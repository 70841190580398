import { useEffect, useState } from 'react'

import { ldclient, type FlagsValues } from 'app/services/feature-flags'

/**
 * Hook which provides live value of a LaunchDarkly variation
 *
 * @param flagKey the launchdarkly flag name
 * @param defaultValue default value to set if flag doesn't exist / isn't loaded
 */
export const useVariation = <T>(flagKey: FlagsValues, defaultValue: T): T => {
  const [flagValue, setFlagValue] = useState<T>(() =>
    ldclient.variation(flagKey, defaultValue),
  )

  useEffect(() => {
    const initialValue = ldclient.variation(flagKey, defaultValue)
    setFlagValue(initialValue)

    const eventKey = `change:${flagKey}`
    const handler = (newValue: T) => {
      setFlagValue(newValue)
    }

    ldclient.on(eventKey, handler)

    return () => {
      ldclient.off(eventKey, handler)
    }
  }, [flagKey, defaultValue])

  return flagValue
}
