import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  type Context,
  type ReactNode,
} from 'react'

import type { NewTask, TaskWithLinkedTasks } from '@dialogue/coredata'

import { DrawerManagementContext } from 'app/components/drawer-manager/context'
import { useAppSelector } from 'app/hooks'
import {
  useGetTaskQuery,
  useGetUnreadCommentCountQuery,
} from 'app/redux/api/emergency-room/tasks'
import { selectUserId } from 'app/redux/authentification/selectors'
import { track } from 'app/services/snowplow-analytics'

type ContextValues = {
  task?: TaskWithLinkedTasks
  parentTask?: TaskWithLinkedTasks
  setDrawerTask: (taskId: string | null) => void
  initialData?: Partial<NewTask>
  setDrawerInitialData: (data: Partial<NewTask>) => void
  unreadSubCount: number
  isLoading: boolean
}

export const placeholderContext: ContextValues = {
  task: undefined,
  parentTask: undefined,
  setDrawerTask: (taskId: string | null) => {},
  initialData: undefined,
  setDrawerInitialData: (data: Partial<NewTask>) => {},
  unreadSubCount: 0,
  isLoading: false,
}

export const TaskDrawerContext: Context<ContextValues> =
  createContext(placeholderContext)

export const TASK_DRAWER_ID = 'task-drawer'

export const TaskDrawerProvider = ({ children }: { children: ReactNode }) => {
  const { setActiveDrawer, closeDrawer } = useContext(DrawerManagementContext)

  const [taskId, setTaskId] = useState<string | null>(null)

  const [initialData, setInitialData] = useState<Partial<NewTask> | undefined>()

  const { currentData: task, isLoading: isLoadingTask } = useGetTaskQuery(
    { taskId: taskId || '' },
    { skip: !taskId },
  )

  const { currentData: parentTask, isLoading: isLoadingParent } =
    useGetTaskQuery(
      { taskId: task?.parent_id || initialData?.parent_id || '' },
      { skip: !task?.parent_id && !initialData?.parent_id },
    )

  const { data: unreadSubCount = 0 } = useGetUnreadCommentCountQuery(
    {},
    { pollingInterval: 10000 },
  )

  const currentUserId = useAppSelector((state) => selectUserId(state))

  useEffect(() => {
    if (!task) return
    track(
      'opened_task_details_view',
      {
        practitioner_id: currentUserId,
        task_id: task.id,
        task_tenant_id: task.tenant_id,
        task_assignee_id: task.assignee_id,
        task_teams: task.teams,
      },
      '1-0-1',
    )
  }, [currentUserId, task])

  const setDrawerTask = useCallback(
    (taskId: string | null) => {
      setTaskId(taskId)
      setInitialData(undefined)
      if (taskId) {
        setActiveDrawer(TASK_DRAWER_ID)
      } else {
        closeDrawer()
      }
    },
    [closeDrawer, setActiveDrawer],
  )

  const setDrawerInitialData = useCallback(
    (data: Partial<NewTask>) => {
      setTaskId(null)
      setInitialData(data)
      setActiveDrawer(TASK_DRAWER_ID)
    },
    [setActiveDrawer],
  )

  return (
    <TaskDrawerContext.Provider
      value={{
        task,
        parentTask,
        initialData,
        setDrawerTask,
        setDrawerInitialData,
        unreadSubCount,
        isLoading: isLoadingParent || isLoadingTask,
      }}
    >
      {children}
    </TaskDrawerContext.Provider>
  )
}
