import { call, put, all } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import { memberChargesActions } from 'app/redux/member-charges'

import { initScribeClient, takeKeyedLatest } from './utils'

export function* fetchCharges({
  payload: patientId,
}: ActionType<typeof memberChargesActions.get>) {
  try {
    const scribeV1 = yield* call(initScribeClient)
    const charges = yield* call(scribeV1.getUserCharges, patientId)

    yield* put(memberChargesActions.getSuccess({ charges, patientId }))
  } catch (error: any) {
    yield* put(memberChargesActions.getFailure({ error, patientId }))
  }
}

export function* cancelCharge({
  payload,
}: ActionType<typeof memberChargesActions.cancel>) {
  const { patientId, chargeId, reason } = payload

  try {
    const scribeV1 = yield* call(initScribeClient)
    const cancelledCharge = yield* call(
      scribeV1.deleteUserCharge,
      chargeId,
      reason,
    )

    yield* put(
      memberChargesActions.cancelSuccess({
        charge: cancelledCharge,
        patientId,
      }),
    )
  } catch (error: any) {
    console.warn('Could not cancel the charge', error)
    yield* put(memberChargesActions.cancelFailure({ error, patientId }))
  }
}

const actionPatientId = (action: ReturnType<typeof memberChargesActions.get>) =>
  action.payload

const actionChargeId = (
  action: ReturnType<typeof memberChargesActions.cancel>,
) => action.payload.chargeId

export default function* userChargesSagas() {
  yield* all([
    takeKeyedLatest(memberChargesActions.get, actionPatientId, fetchCharges),
    takeKeyedLatest(memberChargesActions.cancel, actionChargeId, cancelCharge),
  ])
}
