// @ts-strict-ignore
import { useCallback, useMemo } from 'react'

import {
  CaretDownOutlined,
  SendOutlined,
  WarningFilled,
} from '@ant-design/icons'
import { DocumentFormat } from '@dialogue/document-center'
import { Button, Dropdown, notification, Popover } from 'antd'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useWindowPortalContext } from 'app/components/window-portal/context'
import { useApryse } from 'app/containers/documents/document-details/utils'
import { useViewerInstance } from 'app/containers/documents/document-details/viewer-context'
import { useAppDispatch, useAppSelector, useVariation } from 'app/hooks'
import { useLazyGetMemberDocumentDownloadUrlQuery } from 'app/redux/api/document-center/member-documents'
import { selectHasPermissions } from 'app/redux/authentification/selectors'
import {
  memberDocumentsActions,
  selectErrorUploadingToIh,
  selectIsUploadingToIh,
} from 'app/redux/documents/members'
import {
  DocumentSource,
  isDocumentCenterDocument,
  type ViewerDocument,
} from 'app/redux/documents/viewer'
import { DOCCENTER_SEND_FAX } from 'app/scopes'
import { Flags } from 'app/services/feature-flags'
import { track } from 'app/services/snowplow-analytics'
import { colors } from 'app/theme'

interface Props {
  document: ViewerDocument
  /**
   * Gets called when the parent should close the panel (if possible)
   */
  onClose?: () => void
  onSendFaxClick: () => void | null
  disableSendFax: boolean
}

export const SendDocumentActions = ({
  document,
  onClose,
  onSendFaxClick,
  disableSendFax,
}: Props) => {
  const { t } = useTranslation()
  const apryse = useApryse()
  const dispatch = useAppDispatch()

  const { loadedDocumentId } = useViewerInstance()
  const [fetchDocumentDownloadUrl] = useLazyGetMemberDocumentDownloadUrlQuery()

  const enableFaxing = useVariation<boolean>(Flags.enableFaxing, true)
  const hasSendFaxScope = useAppSelector((state) =>
    selectHasPermissions(state, [DOCCENTER_SEND_FAX]),
  )

  const isUploadingToIh = useAppSelector(
    (state) => document && selectIsUploadingToIh(state, document?.member_id),
  )
  const errorUploadingToIh = useAppSelector(
    (state) =>
      document &&
      selectErrorUploadingToIh(state, document?.member_id, document?.id),
  )

  const { episodeId } = useParams()
  const format = isDocumentCenterDocument(document) && document?.format

  const popoutWindow = useWindowPortalContext()
  const isInPopout = !!popoutWindow

  const handleSendFileToChatClick = useCallback(async () => {
    if (
      episodeId &&
      document.source === DocumentSource.DOCUMENT_CENTER &&
      // don't allow sending docs already saved from chat
      !document.post_id
    ) {
      if (format === DocumentFormat.doc) {
        const documentUrl = await fetchDocumentDownloadUrl({
          documentId: document?.id,
          memberId: document?.member_id,
        })
          .unwrap()
          .catch(() =>
            notification.error({
              message: t('documents.actions.sendToEpisodeError'),
            }),
          )
        if (documentUrl) {
          const pdfBlob = await apryse.flattenDocument(documentUrl)
          dispatch(
            memberDocumentsActions.sendBlobToEpisode({
              document,
              episodeId,
              fileBlob: pdfBlob,
            }),
          )
        }
      } else {
        dispatch(
          memberDocumentsActions.sendToEpisode({
            document,
            episodeId,
            memberId: document.member_id,
          }),
        )
      }
      track('doc_center_file_viewer_actions', {
        action_name: 'Send to chat',
        document_id: document.id,
      })
      onClose?.()
    }
  }, [
    apryse,
    dispatch,
    document,
    episodeId,
    fetchDocumentDownloadUrl,
    format,
    onClose,
    t,
  ])

  const handleSendFileToIh = useCallback(() => {
    if (document) {
      dispatch(
        memberDocumentsActions.uploadToIh({
          memberId: document.member_id,
          documentId: document.id,
        }),
      )
      track('doc_center_file_viewer_actions', {
        action_name: 'Send to IH',
        document_id: document.id,
      })
    }
  }, [dispatch, document])

  const sendButtonItems = useMemo(() => {
    const items: ItemType[] = [
      {
        key: 'send-to-episode',
        label: t('documents.actions.sendToEpisode'),
        onClick: handleSendFileToChatClick,
        disabled: !episodeId && !!isInPopout,
      },
    ]

    if (document?.source === DocumentSource.DOCUMENT_CENTER) {
      items.push({
        key: 'send-to-ih',
        label: t('documents.actions.sendToIh'),
        onClick: handleSendFileToIh,
      })

      if (enableFaxing && hasSendFaxScope) {
        items.push({
          key: 'send-to-fax',
          label: t('documents.actions.sendFax'),
          onClick: onSendFaxClick,
          disabled: disableSendFax,
        })
      }
    }

    return items
  }, [
    document,
    disableSendFax,
    enableFaxing,
    episodeId,
    handleSendFileToChatClick,
    handleSendFileToIh,
    hasSendFaxScope,
    isInPopout,
    onSendFaxClick,
    t,
  ])

  return (
    <>
      <Dropdown
        disabled={!loadedDocumentId}
        placement="bottomRight"
        menu={{
          items: sendButtonItems,
        }}
      >
        <Button
          size="small"
          type="primary"
          ghost
          icon={<SendOutlined />}
          loading={isUploadingToIh}
          data-testid="send-document-dropdown"
        >
          {t('documents.actions.sendDropdown')} <CaretDownOutlined />
        </Button>
      </Dropdown>
      {errorUploadingToIh && (
        <Popover
          trigger="hover"
          content={t('documents.actions.errorUploadingToIh')}
          arrowPointAtCenter
          placement="bottomRight"
        >
          <WarningFilled
            css={`
              color: ${colors.error};
            `}
            data-testid="error"
          />
        </Popover>
      )}
    </>
  )
}
