import { call, put, all, takeLeading } from 'typed-redux-saga/macro'

import { timekeeperAppointmentTypeActions } from 'app/redux/timekeeper/appointment-types'
import { takeKeyedLatest } from 'app/sagas/utils'

import { getAppointmentsApi } from './utils'

export function* getAppointmentTypes({
  payload,
}:
  | ReturnType<typeof timekeeperAppointmentTypeActions.getAll>
  | ReturnType<typeof timekeeperAppointmentTypeActions.getForProvider>) {
  try {
    const api = yield* call(getAppointmentsApi)
    const {
      data: { data },
    } = yield* call(api.getAppointmentTypes, payload?.providerId)

    if (!!payload && 'providerId' in payload) {
      yield* put(
        timekeeperAppointmentTypeActions.receivedForProvider({
          types: data,
          providerId: payload.providerId,
        }),
      )
    } else {
      yield* put(
        timekeeperAppointmentTypeActions.receivedAll({
          types: data,
        }),
      )
    }
  } catch (error) {
    yield* put(timekeeperAppointmentTypeActions.erroredReceivingTypes(error))
  }
}

export default function* appointmentTypesSagas() {
  yield* all([
    takeLeading(timekeeperAppointmentTypeActions.getAll, getAppointmentTypes),
    takeKeyedLatest(
      timekeeperAppointmentTypeActions.getForProvider,
      ({ payload }) => payload.providerId,
      getAppointmentTypes,
    ),
  ])
}
