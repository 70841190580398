import { Tooltip, type TooltipProps } from 'antd'

export interface WithTooltipProps {
  tooltip?: TooltipProps['title']
  tooltipProps?: Omit<TooltipProps, 'title'>
}

const withTooltip =
  <P extends object>(Component: React.ComponentType<P>) =>
  ({ tooltip, tooltipProps, ...rest }: WithTooltipProps & P) => {
    const componentProps = rest as P
    return tooltip ? (
      <Tooltip title={tooltip} {...tooltipProps}>
        <Component {...componentProps} />
      </Tooltip>
    ) : (
      <Component {...componentProps} />
    )
  }

export default withTooltip
