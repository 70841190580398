import { useEffect } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { Button, Result, Typography } from 'antd'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

const reloadPage = () => {
  window.location.reload()
}

export const ErrorDisplay = ({ error }: { error: unknown }) => {
  const message = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : error instanceof Error
      ? error.message
      : JSON.stringify(error)
  const stack = error instanceof Error ? error.stack : null

  useEffect(() => {
    if (!!error) datadogRum.addError(error)
  }, [error])

  // note: no i18n here, to prevent i18next from crashing the crashpad
  return (
    <Result
      style={{ userSelect: 'text' }}
      status="error"
      title="An unexpected error occurred"
      subTitle="Please try to reload the CP, and send #support a screenshot of this screen if the error persists."
      extra={[
        <Button key="reload" type="primary" onClick={reloadPage}>
          Reload
        </Button>,
      ]}
    >
      <Typography.Title level={3}>{message}</Typography.Title>
      <Typography.Paragraph>
        <pre>{stack}</pre>
      </Typography.Paragraph>
    </Result>
  )
}

/**
 * Top level "error boundary / crashpad" for the router,
 * which will catch, display, and report to Datadog
 * any uncaught errors in the app's React tree.
 *
 * Heavily inspired from
 * https://github.com/remix-run/react-router/blob/24f80acb846299b4ef6b9176583d836009a494db/packages/react-router/lib/hooks.tsx#L457
 */
export const RouterErrorScreen = () => {
  const error = useRouteError()
  return <ErrorDisplay error={error} />
}
