import type { Task, TaskActivity } from '@dialogue/coredata'
import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'

import type { HistoryItem } from 'app/components/history/text-area-history'
import i18n from 'app/i18n'
import { dateAndHoursFormat, formatPractitionerName } from 'app/lib/helpers'
import type { Practitioner } from 'app/redux/practitioners'

interface TaskActivityWithValues extends TaskActivity {
  modified_values?: Partial<Task>
}

export const selectDescriptionActivities = (currentData: TaskActivity[]) =>
  currentData.filter(
    (activity) =>
      activity.modified_values && 'description' in activity.modified_values,
  )

// TODO: there could value in making this method reusable across the app.
const getFormattedProviderName = (
  providers: Record<string, Practitioner>,
  providerId: number,
) => {
  const providerProfile = providers?.[providerId]
  const providerIdString = providerId.toString()
  return formatPractitionerName(
    providerProfile,
    i18n.t('practitionerProfile.unknownProvider', {
      providerId: providerIdString,
    }),
  )
}

const getHistoricalValue = (
  activity: TaskActivityWithValues,
  key: keyof Task,
) => {
  const value = activity.modified_values?.[key]
  if (typeof value === 'string') {
    return value
  }

  return ''
}

// Passthrough selector for providers argument
const selectProviders = (
  _state: unknown,
  providers: Record<string, Practitioner>,
) => providers

export const selectDescriptionsAsHistoryItems = createSelector(
  selectDescriptionActivities,
  selectProviders,
  (activities, providers) => {
    const historyItems = activities.map<HistoryItem>((activity) => ({
      id: `${activity?.id}`,
      value: getHistoricalValue(activity, 'description'),
      metadata: i18n.t('tasks.values.activityMetadata', {
        providerName: getFormattedProviderName(providers, activity?.user_id),
        timestamp: moment(activity?.timestamp).format(dateAndHoursFormat),
      }),
    }))

    return historyItems
  },
)
