import { datadogRum } from '@datadog/browser-rum'
import { all, call } from 'typed-redux-saga/macro'

// Sagas
import addDependentSagas from './add-dependent'
import appSagas from './app'
import appointmentSagas from './appointments'
import authentificationSagas from './authentification'
import availabilities from './availabilities'
import chatSagas from './chat'
import createProfileSagas from './create-profile'
import documentsSagas from './documents'
import eligibilitySearchSagas from './eligibility-search'
import episodeMetaSagas from './episode-meta'
import episodeQueuesSagas from './episode-queues'
import episodeViewSagas from './episode-view'
import episodesSagas from './episodes'
import icbtSagas from './icbt'
import inputHealthSagas from './input-health'
import locationsSagas from './locations'
import medicalProfileSagas from './medical-profile'
import memberEligibilityIntervalSagas from './member-eligibility-interval'
import membersServicesSagas from './membersServices'
import mentionsSagas from './mentions'
import multipassSagas from './multipass'
import patientSearchSagas from './patient-search'
import patientsSagas from './patients'
import practitionersSagas from './practitioners'
import questionnairesSagas from './questionnaires'
import rasaIntentsSagas from './rasa-intents'
import remindersSagas from './reminders'
import rlacSagas from './rlac'
import schedulingSagas from './scheduling'
import statusPageSagas from './statuspage'
import structuredNotesSagas from './structured-notes'
import templatesSagas from './templates'
import timekeeperSagas from './timekeeper'
import userChargesSagas from './user-charges'
import userManagementSagas from './user-management'

/**
 * Lazy load sagas.
 *
 * Those sagas will be asynchronously loaded as part of the boot sequence.
 * Ensure you only lazily load sagas that are not needed as part of the app's boot sequence.
 *
 */
const lazySaga = <T extends () => Generator>(
  loader: () => Promise<{ default: T }>,
) =>
  function* sagaLoader() {
    const module = yield* call(loader)

    return yield* call(module.default)
  }

const videoCallSagas = lazySaga(() => import('./video-call'))
const telephoneSagas = lazySaga(() => import('./telephone'))

export default function* rootSagas() {
  try {
    yield* all([
      addDependentSagas(),
      appSagas(),
      appointmentSagas(),
      authentificationSagas(),
      availabilities(),
      chatSagas(),
      createProfileSagas(),
      documentsSagas(),
      eligibilitySearchSagas(),
      episodeMetaSagas(),
      episodeViewSagas(),
      episodeQueuesSagas(),
      episodesSagas(),
      icbtSagas(),
      inputHealthSagas(),
      locationsSagas(),
      medicalProfileSagas(),
      questionnairesSagas(),
      memberEligibilityIntervalSagas(),
      membersServicesSagas(),
      mentionsSagas(),
      multipassSagas(),
      patientsSagas(),
      patientSearchSagas(),
      practitionersSagas(),
      rasaIntentsSagas(),
      remindersSagas(),
      rlacSagas(),
      statusPageSagas(),
      schedulingSagas(),
      structuredNotesSagas(),
      telephoneSagas(),
      templatesSagas(),
      timekeeperSagas(),
      userChargesSagas(),
      userManagementSagas(),
      videoCallSagas(),
    ])
  } catch (error) {
    datadogRum.addError(error)
  }
}
