import { memo, type FC } from 'react'

import { Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import { EllipsizedText } from 'app/components/extended-select'
import { formatDocumentDate } from 'app/containers/documents/helpers'
import { useGetPatientNoteQuery } from 'app/redux/api/notepadApi'

type Props = {
  memberId?: number
  noteId?: number
}

const NoteOptionLabel = memo(
  ({ title, date }: { title: string; date: string }) => {
    const { t } = useTranslation()
    const formattedDate = formatDocumentDate({ t, date })

    return <EllipsizedText leftText={formattedDate} rightText={title} />
  },
)

const ErrorMessage = ({ noteId }: { noteId?: number }) => {
  const { t } = useTranslation()
  return (
    <Typography.Text type="danger">
      {t('documents.errorLoadingNoteDisplay', { noteId })}
    </Typography.Text>
  )
}

export const NoteDisplay: FC<Props> = ({ memberId, noteId }) => {
  const { t } = useTranslation()
  const {
    data: note,
    isFetching,
    isError,
  } = useGetPatientNoteQuery(
    {
      patientId: memberId || 0,
      noteId: noteId || 0,
    },
    { skip: !noteId || !memberId },
  )

  return (
    <Spin spinning={isFetching} size="small">
      {!noteId && t('documents.details.noNote')}
      {note && (
        <NoteOptionLabel
          title={note.template.title[note.language]}
          date={note.completed_at || note.updated_at}
        />
      )}
      {isError && <ErrorMessage noteId={noteId} />}
    </Spin>
  )
}
