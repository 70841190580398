import { useAppSelector } from 'app/hooks'
import { useGetTaskActivitiesQuery } from 'app/redux/api/emergency-room/tasks'
import { selectDescriptionsAsHistoryItems } from 'app/redux/api/emergency-room/tasks.selectors'
import { selectProviderProfiles } from 'app/redux/practitioners/selectors'

export const useTaskDescriptionHistory = (taskId: string) => {
  const providers = useAppSelector(selectProviderProfiles)

  return useGetTaskActivitiesQuery(
    { taskId, includeSubtasks: false },
    {
      skip: !taskId,
      selectFromResult: ({ currentData = [], ...rest }) => {
        return {
          ...rest,
          descriptionHistory: selectDescriptionsAsHistoryItems(
            currentData,
            providers,
          ).slice(1),
        }
      },
    },
  )
}
