import { useCallback, useContext, useEffect, useState } from 'react'

import {
  AlertOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
} from '@ant-design/icons'
import {
  CommentingStatus,
  SubscriptionStatus,
  type CommentMention,
} from '@dialogue/coredata'
import { Button, List, Space } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CommentInput } from 'app/components/task-comments/comment-input'
import { CommentList } from 'app/components/task-comments/comment-list'
import { useVariation } from 'app/hooks'
import {
  useAddTaskCommentMutation,
  useGetTaskCommentsQuery,
  useGetTaskSubscriptionQuery,
  usePatchTaskSubscriptionMutation,
  useUpdateTaskMutation,
  useUpdateTaskSubscriptionMutation,
} from 'app/redux/api/emergency-room/tasks'
import { Flags } from 'app/services/feature-flags'

import { TaskDrawerContext } from './task-drawer-context'

const CommentContainer = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 16px;
`

interface TaskCommentProps {
  taskId: string
}

const EmptyComments = () => {
  return (
    <CommentContainer>
      <List
        css={`
          width: 100%;
          height: 100%;
          overflow: auto;
          flex-grow: 1;
        `}
      />
      <CommentInput disabled />
    </CommentContainer>
  )
}

const TaskComments = ({ taskId }: TaskCommentProps) => {
  const { t } = useTranslation()

  const pollingInterval = useVariation(Flags.taskCommentsPollingInterval, 3000)

  const { data: taskComments = [], isLoading: taskCommentsFetching } =
    useGetTaskCommentsQuery({ taskId }, { pollingInterval })
  const { data: taskSubscription } = useGetTaskSubscriptionQuery({ taskId })
  const { task } = useContext(TaskDrawerContext)

  const [addTaskComment] = useAddTaskCommentMutation()
  const [updateSubscription] = useUpdateTaskSubscriptionMutation()
  const [updateTask] = useUpdateTaskMutation()
  const [patchTaskSubscription] = usePatchTaskSubscriptionMutation()
  const [commentCount, setCommentCount] = useState(0)

  useEffect(() => {
    // as soon as the number of comment increases, update the last_read_at timestamp
    if (taskComments.length <= commentCount) return
    setCommentCount(taskComments.length)
    patchTaskSubscription({ taskId, last_read_at: moment().format() })
  }, [commentCount, taskComments, taskId, patchTaskSubscription])

  const isCommentingClosed = task?.commenting_status === CommentingStatus.closed
  const isSubscriptionMuted =
    taskSubscription?.status === SubscriptionStatus.unwatched

  const handleToggleDoneStatus = useCallback(() => {
    const newStatus = isCommentingClosed ? 'open' : 'closed'
    updateTask({ taskId, commenting_status: newStatus })
  }, [isCommentingClosed, updateTask, taskId])

  const handleToggleMuteStatus = useCallback(() => {
    // if no subscription exists, or is unwatched, subscribe to it with status watched
    const toggledStatus =
      !taskSubscription || isSubscriptionMuted
        ? SubscriptionStatus.watched
        : SubscriptionStatus.unwatched

    updateSubscription({ taskId, status: toggledStatus })
  }, [updateSubscription, taskSubscription, isSubscriptionMuted, taskId])

  const onCommentSubmit = useCallback(
    (text: string, mentions: CommentMention[]) => {
      addTaskComment({ taskId, text, mentions })
    },
    [taskId, addTaskComment],
  )
  return (
    <>
      {taskComments?.length > 0 && (
        <div css={'display: flex; justify-content: flex-end;'}>
          <Space>
            <Button
              type={!isCommentingClosed ? 'default' : 'primary'}
              ghost={!isCommentingClosed}
              onClick={handleToggleDoneStatus}
              icon={
                isCommentingClosed ? (
                  <CheckCircleFilled />
                ) : (
                  <CheckCircleOutlined />
                )
              }
              data-testid="task-comments-close-button"
              data-dd-privacy="allow"
              data-dd-action-name="task-comments:mark-done"
            >
              {t('tasks.actions.markTaskCommentStatusDone')}
            </Button>
            <Button
              ghost={!isSubscriptionMuted}
              type={!isSubscriptionMuted ? 'default' : 'primary'}
              onClick={handleToggleMuteStatus}
              icon={<AlertOutlined />}
              data-testid="task-comments-mute-button"
              data-dd-privacy="allow"
              data-dd-action-name="task-comments:toggle:mute-status"
            >
              {isSubscriptionMuted
                ? t('tasks.actions.muted')
                : t('tasks.actions.mute')}
            </Button>
          </Space>
        </div>
      )}
      <CommentContainer>
        <CommentList comments={taskComments} loading={taskCommentsFetching} />
        <CommentInput
          disabled={taskCommentsFetching}
          onSubmit={onCommentSubmit}
        />
      </CommentContainer>
    </>
  )
}

export const Comments = ({ taskId }: Partial<TaskCommentProps>) => {
  // if no taskId is provided, show an empty comments component.
  // Used for placeholders (e.g. new task form)
  if (!taskId) return <EmptyComments />

  return <TaskComments taskId={taskId} />
}
