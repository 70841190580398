// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import {
  type EpisodeQueuesAction,
  EpisodeQueuesTypes,
} from 'app/redux/episode-queues'
import { type EpisodesAction, EpisodesTypes } from 'app/redux/episodes'
import {
  type PractitionersAction,
  PractitionerTypes,
} from 'app/redux/practitioners'

import type * as actions from './actions'
import { type EpisodeViewState, EpisodeViewTypes } from './types'

export const INITIAL_STATE: EpisodeViewState = {
  episode: {},
  updatingState: false,
  updatingStateError: null,
}

export type EpisodeViewAction = ActionType<typeof actions>

export const reducer: Reducer<
  typeof INITIAL_STATE,
  EpisodeViewAction | EpisodesAction | EpisodeQueuesAction | PractitionersAction
> = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EpisodeViewTypes.CLEAR_EPISODE_VIEW:
        draft.episode = INITIAL_STATE.episode
        break
      case EpisodeQueuesTypes.ENQUEUE_EPISODE:
        draft.episode.queue = action.payload.selectedQueue
        draft.episode.teamAction = action.payload.selectedAction
        draft.episode.queueUpdating = true
        draft.episode.queueError = null
        draft.episode.fetchingEpisode = true
        break
      case EpisodeQueuesTypes.ENQUEUE_EPISODE_SUCCESS:
        draft.episode.queue = action.payload.selectedQueue
        draft.episode.teamAction = action.payload.selectedAction
        draft.episode.queueUpdating = false
        break
      case EpisodeQueuesTypes.ENQUEUE_EPISODE_ERROR:
        draft.episode.queueUpdating = false
        draft.episode.queueError = action.payload.error
        break
      case EpisodesTypes.INIT_POLLING_EPISODE:
      case EpisodesTypes.START_POLLING_EPISODE:
        draft.episode.fetchingEpisode = true
        draft.episode.teamAction = null
        break
      case EpisodesTypes.GET_EPISODE_SUCCESS:
        // FIXME: this is necessary to prevent flickering of TeamPicker's selected team when ER has not yet been updated by Usher
        // Remove once queue is set directly in ER
        if (state.episode.queueUpdating) {
          break
        }
        draft.episode.fetchingEpisode = false
        draft.episode.assignee_id = action.payload.episode.assignee_id
        draft.episode.queue = action.payload.episode.queue_id
        draft.episode.teamAction = action.payload.episode.team_action_id
        draft.episode.state = action.payload.episode.state_id
        draft.episode.id = action.payload.episode.id
        draft.episode.patient_id = action.payload.episode.patient_id
        draft.episode.created_at = action.payload.episode.created_at
        draft.episode.title = action.payload.episode.title
        draft.episode.health_issue_type_id =
          action.payload.episode.health_issue_type_id
        draft.episode.health_issue_type_name =
          action.payload.episode.health_issue_type_name
        draft.episode.priority_id = action.payload.episode.priority_id
        draft.episode.outcome_id = action.payload.episode.outcome_id
        draft.episode.subject_id = action.payload.episode.subject_id
        draft.episode.bot_status = action.payload.episode.bot_status
        draft.episode.brand_id = action.payload.episode.brand_id
        draft.episode.patient_first_name =
          action.payload.episode.patient_first_name
        draft.episode.patient_last_name =
          action.payload.episode.patient_last_name
        draft.episode.patient_preferred_name =
          action.payload.episode.patient_preferred_name
        draft.episode.communication_channel_id =
          action.payload.episode.communication_channel_id
        draft.episode.communication_methods =
          action.payload.episode.communication_methods
        break
      case EpisodesTypes.GET_EPISODE_ERROR:
        draft.episode.queueError = action.payload.error
        draft.episode.fetchingEpisode = false
        break
      case PractitionerTypes.ASSIGN_EPISODE:
        draft.episode.updatingAssignee = true
        draft.episode.updatingAssigneeError = null
        break
      case PractitionerTypes.ASSIGN_EPISODE_SUCCESS:
        draft.episode.assignee_id = action.payload.channel.assignee_id
        draft.episode.updatingAssignee = false
        break
      case PractitionerTypes.ASSIGN_EPISODE_FAILURE:
        draft.episode.updatingAssignee = false
        draft.episode.updatingAssigneeError = null
        break
      case PractitionerTypes.UNASSIGN_EPISODE:
        draft.episode.updatingAssignee = true
        draft.episode.updatingAssigneeError = null
        break
      case PractitionerTypes.UNASSIGN_EPISODE_SUCCESS:
        draft.episode.updatingAssignee = false
        break
      case PractitionerTypes.UNASSIGN_EPISODE_FAILURE:
        draft.episode.updatingAssignee = false
        draft.episode.updatingAssigneeError = null
        break
      case EpisodeViewTypes.RESOLVE_EPISODE:
      case EpisodeViewTypes.REOPEN_EPISODE:
      case EpisodeViewTypes.SET_EPISODE_TO_PENDING:
        draft.updatingState = true
        draft.updatingStateError = null
        break
      case EpisodeViewTypes.UPDATE_EPISODE_STATE_SUCCESS:
        draft.episode.state = action.payload.state_id
        draft.updatingState = false
        break
      case EpisodeViewTypes.UPDATE_EPISODE_STATE_FAILURE:
        draft.updatingStateError = action.payload.error
        draft.updatingState = false
        break
    }
  })

export default reducer
