import type { EnrichedEpisode } from '@dialogue/coredata'
import type { EmergencyRoomTypes } from '@dialogue/services'
import { action } from 'typesafe-actions'

import { EpisodesTypes, type EpisodeChannel } from './types'

export const requestEpisodesFromState = (
  episodesState: EmergencyRoomTypes.EpisodeState,
  limit: number,
  offset: number,
  resolvedByMe: boolean,
) =>
  action(EpisodesTypes.REQUEST_EPISODES_FROM_STATE, {
    episodesState,
    limit,
    offset,
    resolvedByMe,
  })

export const requestEpisodesFromStateSuccess = (
  episodesState: EmergencyRoomTypes.EpisodeState,
  episodes: EnrichedEpisode[],
  count: number,
) =>
  action(EpisodesTypes.REQUEST_EPISODES_FROM_STATE_SUCCESS, {
    episodesState,
    episodes,
    count,
  })

export const requestEpisodesFromStateFailure = (
  episodesState: EmergencyRoomTypes.EpisodeState,
  error: Error,
) =>
  action(EpisodesTypes.REQUEST_EPISODES_FROM_STATE_FAILURE, {
    episodesState,
    error,
  })

export const getEpisode = (episodeId: string) =>
  action(EpisodesTypes.GET_EPISODE, { episodeId })

export const getEpisodeSuccess = (
  episodeId: string,
  episode: EnrichedEpisode,
) => action(EpisodesTypes.GET_EPISODE_SUCCESS, { episodeId, episode })

export const getEpisodeError = (episodeId: string, error: Error) =>
  action(EpisodesTypes.GET_EPISODE_ERROR, { episodeId, error })

export const createEpisode = (payload: {
  patientId: number
  channel: EpisodeChannel
}) => action(EpisodesTypes.CREATE_EPISODE, payload)

export const createEpisodeSuccess = (payload: {
  episodeId: string
  episode: EnrichedEpisode
}) => action(EpisodesTypes.CREATE_EPISODE_SUCCESS, payload)

export const createEpisodeError = (payload: {
  patientId: number
  error: Error
}) => action(EpisodesTypes.CREATE_EPISODE_ERROR, payload)

export const initPollingEpisode = (episodeId: string) =>
  action(EpisodesTypes.INIT_POLLING_EPISODE, { episodeId })

export const startPollingEpisode = (episodeId: string) =>
  action(EpisodesTypes.START_POLLING_EPISODE, { episodeId })

export const stopPollingEpisode = () =>
  action(EpisodesTypes.STOP_POLLING_EPISODE)

export const resolveAllActiveEpisodes = () =>
  action(EpisodesTypes.RESOLVE_ALL_ACTIVE_EPISODES)
