// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type * as actions from './actions'
import { VideoCallActionTypes, type VideoCallState } from './types'

export const INITIAL_STATE: VideoCallState = {
  error: null,
  publisherWarnings: [],
  subscriberWarnings: [],
  status: null,
  sessionId: null,
  subscriberMuted: false,
  publisherMuted: false,
  initialized: false,
  calling: false,
}

export type VideoCallAction = ActionType<typeof actions>

export const reducer: Reducer<typeof INITIAL_STATE, VideoCallAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft): typeof INITIAL_STATE | void => {
    switch (action.type) {
      case VideoCallActionTypes.START_CALL:
        draft.calling = true
        draft.error = null
        break

      case VideoCallActionTypes.SET_STATUS:
        draft.status = action.payload
        break
      case VideoCallActionTypes.SET_ERROR:
        draft.error = action.payload
        break
      case VideoCallActionTypes.SET_PUBLISHER_MUTED:
        draft.publisherMuted = action.payload
        break
      case VideoCallActionTypes.SET_SUBSCRIBER_MUTED:
        draft.subscriberMuted = action.payload
        break
      case VideoCallActionTypes.SET_SESSION_ID:
        draft.sessionId = action.payload
        break

      case VideoCallActionTypes.ADD_PUBLISHER_WARNING:
        draft.publisherWarnings.unshift(action.payload)
        break
      case VideoCallActionTypes.REMOVE_PUBLISHER_WARNING:
        draft.publisherWarnings = draft.publisherWarnings.filter(
          (warning) => warning !== action.payload,
        )
        break
      case VideoCallActionTypes.ADD_SUBSCRIBER_WARNING:
        draft.subscriberWarnings.unshift(action.payload)
        break
      case VideoCallActionTypes.REMOVE_SUBSCRIBER_WARNING:
        draft.subscriberWarnings = draft.subscriberWarnings.filter(
          (warning) => warning !== action.payload,
        )
        break

      case VideoCallActionTypes.CALL_ENDED:
        draft.subscriberWarnings = []
        draft.sessionId = null
        draft.subscriberMuted = false
        draft.calling = false
        draft.initialized = true
        break

      case VideoCallActionTypes.INIT:
        draft.initialized = false
        break

      case VideoCallActionTypes.READY_TO_CALL:
        draft.initialized = true
        break

      case VideoCallActionTypes.CLEANUP:
        return INITIAL_STATE
    }
  })

export default reducer
