import { createSelector } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

export const selectPractitionerById = (
  state: ReduxState,
  practitioner_id: number,
) => state.practitioners?.profiles[practitioner_id]

export const selectProviderProfiles = (state: ReduxState) =>
  state.practitioners?.profiles

export const selectPractitionersByIds = createSelector(
  (state: ReduxState) => state.practitioners?.profiles,
  (_: ReduxState, practitionerIds?: number[]) => practitionerIds,
  (practitionerProfiles, practitionerIds) =>
    practitionerIds?.map((id) => practitionerProfiles[id]).filter(Boolean) ||
    [],
)

export const selectUserIdToNickname = createSelector(
  selectPractitionersByIds,
  (_: ReduxState, userIds: number[]) => userIds.map(String),
  (practitioners, userIds) =>
    practitioners.reduce((acc: { [key: string]: string }, practitioner) => {
      if (userIds.includes(practitioner.id)) {
        acc[practitioner.id] = practitioner.nickname
      }
      return acc
    }, {}),
)
