import { useCallback, useMemo } from 'react'

import { Form, type FormItemProps } from 'antd'
import { useTranslation } from 'react-i18next'

import {
  TaskOwnerSelect,
  type Owner,
  type TaskOwnerSelectProps,
} from 'app/components/tasks/task-owner-select'
import { useAppSelector } from 'app/hooks'
import { useGetTaskTeamsQuery } from 'app/redux/api/emergency-room/tasks'

type Props = FormItemProps & TaskOwnerSelectProps

export const OwnerField = ({
  allowMultipleAssignees,
  required,
  onChange,
  label,
  disabled = false,
  ...rest
}: Props) => {
  const { t } = useTranslation()

  if (label === undefined) {
    label = t('tasks.properties.labels.assignee')
  }

  const {
    data: teams = [],
    isLoading: isTeamsLoading,
    isError: isTeamsError,
  } = useGetTaskTeamsQuery()

  const practitioners = useAppSelector((state) => state.practitioners.profiles)
  const isPractitionersLoading = useAppSelector(
    (state) => state.practitioners.fetchingProfiles,
  )
  const isPractitionersError = useAppSelector(
    (state) => !!state.practitioners.errorFetchingProfiles,
  )
  const isLoading = isTeamsLoading || isPractitionersLoading

  const teamOptions = useMemo(
    () =>
      teams.map((team) => ({
        value: team.id,
        label: team.name,
      })),
    [teams],
  )

  const ownerFieldValidator = useCallback(
    (_: any, { teams, assignee }: Owner) => {
      if (!required) Promise.resolve()

      // return one or the other, we just validate that at least one is filled
      const hasTeam = Array.isArray(teams) && teams.length > 0
      const hasAssignee = Array.isArray(assignee)
        ? assignee.length > 0
        : !!assignee
      if (hasTeam || hasAssignee) {
        return Promise.resolve()
      } else {
        return Promise.reject(new Error(t('tasks.properties.validators.owner')))
      }
    },
    [required, t],
  )

  return (
    <Form.Item<Owner>
      // This Form Item is used to update both team and assignee_id
      name="owner"
      label={!!label && <span data-dd-privacy="allow">{label}</span>}
      rules={
        required
          ? [
              {
                required,
                validator: ownerFieldValidator,
                message: t('form.validation.required'),
              },
            ]
          : []
      }
      {...rest}
    >
      <TaskOwnerSelect
        teams={teamOptions}
        practitioners={Object.values(practitioners)}
        loading={isLoading}
        isTeamsError={isTeamsError}
        isPractitionersError={isPractitionersError}
        allowMultipleAssignees={allowMultipleAssignees}
        onChange={onChange}
        data-testid={'owner-field'}
        data-dd-action-name="owner-picker:open"
        disabled={disabled}
      />
    </Form.Item>
  )
}
