import type {
  DraftAppointment,
  NewDraftAppointment,
} from '@dialogue/timekeeper'
import {
  createSelector,
  createSlice,
  createEntityAdapter,
  type PayloadAction,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

import {
  filterGroupScheduleActions,
  selectFilterGroupProviderIds,
} from './schedule/filter-group-schedule'
import { providerScheduleActions } from './schedule/provider-schedule'

interface State {
  currentDraftId: DraftAppointment['id'] | null
}

const draftAdapter = createEntityAdapter<DraftAppointment>({
  selectId: (draft) => draft.id,
  sortComparer: false,
})

const INITIAL_STATE = draftAdapter.getInitialState<State>({
  currentDraftId: null,
})

export const { actions: timekeeperDraftActions, reducer } = createSlice({
  name: '@@timekeeper/drafts',
  initialState: INITIAL_STATE,
  reducers: {
    save(state, { payload }: PayloadAction<NewDraftAppointment>) {},
    received(state, action: PayloadAction<DraftAppointment>) {
      state.currentDraftId = action.payload.id
      draftAdapter.setOne(state, action.payload)
    },
    clear(state) {},
    deleted(state, action: PayloadAction<DraftAppointment['id']>) {
      state.currentDraftId = null
      draftAdapter.removeOne(state, action.payload)
    },
  },
  extraReducers(builder) {
    builder.addCase(providerScheduleActions.received, (state, action) => {
      draftAdapter.setAll(state, action.payload.schedule.draft_appointments)
    })
    builder.addCase(filterGroupScheduleActions.received, (state, action) => {
      const drafts = action.payload.schedules.flatMap(
        (schedule) => schedule.draft_appointments,
      )
      draftAdapter.setAll(state, drafts)
    })
  },
})

const selectors = draftAdapter.getSelectors(
  (state: ReduxState) => state.timekeeper.drafts,
)

export const selectDraftsForProvider = createSelector(
  selectors.selectAll,
  (_state: ReduxState, providerId: number) => providerId,
  (draftDataMap, providerId): DraftAppointment[] =>
    draftDataMap.filter((a) => a.provider_id === providerId),
)

export const selectDraftsForFilterGroup = createSelector(
  selectors.selectAll,
  selectFilterGroupProviderIds,
  (draftDataMap, providerIds) =>
    draftDataMap.filter((a) => providerIds.includes(a.provider_id)),
)

export const selectCurrentDraftId = (state: ReduxState) =>
  state.timekeeper.drafts.currentDraftId

export default reducer
