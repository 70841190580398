import { useTranslation } from 'react-i18next'

import { PermissionsGuard } from 'app/components/permissions-guard'
import TasksView from 'app/components/tasks/tasks-view'
import TasksCreateNew from 'app/containers/tasks/create-new'
import { useVariation } from 'app/hooks'
import { ER_LIST_TASKS } from 'app/scopes'
import { Flags } from 'app/services/feature-flags'

import { HeaderLine } from '../common'

export const TasksSection = ({
  memberIdFieldValue,
  episodeId,
  documentId,
}: {
  documentId: string
  memberIdFieldValue?: number
  episodeId?: string
}) => {
  const { t } = useTranslation()
  const enableTaskSystem = useVariation(Flags.enableTaskSystem, true)

  return (
    <PermissionsGuard permissions={[ER_LIST_TASKS]}>
      {enableTaskSystem && (
        <>
          <HeaderLine
            title={t('documents.sections.tasks')}
            extra={
              <TasksCreateNew
                memberId={memberIdFieldValue}
                episodeId={episodeId}
                documentIds={[documentId]}
              />
            }
          />
          <TasksView
            documentId={documentId}
            noTasksLabel={t('tasks.document.noTasksForDocument')}
            noTasksDueLabel={t('tasks.document.noTasksDueForDocument')}
            data-cy="document-view-tasks"
          />
        </>
      )}
    </PermissionsGuard>
  )
}
