// @ts-strict-ignore
import {
  AllowedStatusTransitions,
  NoteStatus,
  PatientsApiFactory,
  type NewNoteWithSubNote,
  type Note,
} from '@dialogue/notepad-client'
import { message } from 'antd'
import { all, call, put, select } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import {
  documentCenterApi,
  Tags as DocumentCenterTags,
} from 'app/redux/api/document-center'
import { notepadApi, Tags as NotePadTags } from 'app/redux/api/notepadApi'
import { selectMainSpecialization } from 'app/redux/authentification/selectors'
import {
  patientNotesActions,
  selectNotesByIdKeyed,
  type UpdateNoteInfo,
} from 'app/redux/structured-notes/patient/notes'
import { selectNoteTemplates } from 'app/redux/structured-notes/templates'
import {
  Operation,
  type NoteWithTempId,
} from 'app/redux/structured-notes/types'
import { takeKeyedLatest } from 'app/sagas/utils'

import { getConfig } from '../utils'

export function* getClient() {
  return PatientsApiFactory(yield* call(getConfig))
}

export function* create({
  payload: { patientId, note, setAsCurrentDraft = true },
}: ReturnType<typeof patientNotesActions.create>) {
  try {
    const client = yield* call(getClient)

    const templates = yield* select(selectNoteTemplates)
    const template = templates[note?.template_id]

    // FIXME: as of now, notepad creats a subnote if you pass it a template in the subnotes property.
    // This makes sure that if min: 0, we skip passing a template.
    // Can be removed once notepad decides itself to create a subnote or not.
    const subNotes = template?.sub_note_templates
      ?.map((config) => {
        return typeof config === 'string'
          ? { template_id: config, min: 1 }
          : config
      })
      .filter((subConfig) => subConfig.min > 0)
      // we want to create subnotes up to subConfig.min
      .map((subConfig) => {
        return Array(subConfig.min).fill({
          template_id: subConfig.template_id,
          template: templates[subConfig.template_id],
        })
      })
      .flat()

    const mainSpecialization = yield* select(selectMainSpecialization)
    const createNotePayload: NewNoteWithSubNote = {
      guardian_id: note?.guardian_id,
      values: note?.values,
      template_id: note?.template_id,
      episode_id: note?.episode_id,
      language: note?.language,
      parent_note_id: note?.parent_note_id,
      practitioner_specialization: mainSpecialization,
      template,
      sub_notes: subNotes,
    }

    const {
      data: { data },
    } = yield* call(client.createNote, Number(patientId), createNotePayload)

    if (note?.sub_note?.values) {
      const subNotesFromTemplate = data?.sub_notes
        ?.filter(
          ({ template_id }: Note) =>
            template_id === note?.sub_note?.template_id,
        )
        .sort((a, b) => a.id - b.id)
      // it's sorted the same when it's rendered, this way we make sure that we put the tempId on the right subNote

      const editedSubNote: NoteWithTempId =
        subNotesFromTemplate[
          Math.min(note?.sub_note?.index, subNotesFromTemplate.length - 1) || 0
        ]

      editedSubNote.tempId = note?.sub_note?.tempId

      yield* put(
        patientNotesActions.update({
          patientId: Number(patientId),
          note: { id: editedSubNote.id, values: note?.sub_note?.values },
        }),
      )
    }

    yield* put(
      patientNotesActions.received({
        patientId,
        note: data,
        operation: Operation.CREATE,
        parentNoteId: note?.parent_note_id,
        setAsCurrentDraft,
      }),
    )

    // TODO: once create has been migrated to RTK, use invalidateTags from notepadApi instead of here
    yield* put(
      notepadApi.util.invalidateTags([
        { type: NotePadTags.PatientNotes, id: patientId },
      ]),
    )
  } catch (e) {
    yield* put(
      patientNotesActions.crudFailure({
        patientId,
        error: e,
        operation: Operation.CREATE,
      }),
    )
  }
}

export function* finalize({
  payload: { patientId, note, subNotes },
}: ReturnType<typeof patientNotesActions.finalize>) {
  try {
    if (subNotes?.length) {
      const subNotesKeyed = yield* select(
        selectNotesByIdKeyed,
        patientId,
        subNotes.map((subNote) => subNote.id),
      )

      // filter out notes that are already finalized/cancelled
      const excludedStatuses: NoteStatus[] = [
        NoteStatus.Completed,
        NoteStatus.Cancelled,
      ]

      yield* all(
        subNotes.map((subNote) => {
          if (excludedStatuses.includes(subNotesKeyed[subNote.id]?.status)) {
            return null
          }

          return call(doUpdate, {
            patientId,
            note: {
              ...subNote,
              status: AllowedStatusTransitions.Completed,
            },
            operation: Operation.FINALIZE,
          })
        }),
      )
    }

    yield* call(doUpdate, {
      patientId,
      note: {
        ...note,
        status: AllowedStatusTransitions.Completed,
      },
      operation: Operation.FINALIZE,
    })

    // TODO: once finalizeNote has been migrated to RTK, use invalidateTags from documentCenterApi instead of here
    yield* put(
      documentCenterApi.util.invalidateTags([
        { type: DocumentCenterTags.MemberDocument, id: patientId },
      ]),
    )

    message.success(i18n.t('structuredNotes.finalized'))
  } catch (e) {
    yield* put(
      patientNotesActions.crudFailure({
        patientId,
        draftId: note.id,
        error: e,
        operation: Operation.FINALIZE,
      }),
    )
  }
}

export function* update({
  payload: { patientId, note },
}: ReturnType<typeof patientNotesActions.update>) {
  try {
    yield* call(doUpdate, { patientId, note, operation: Operation.UPDATE })
  } catch (e) {
    yield* put(
      patientNotesActions.crudFailure({
        patientId,
        draftId: note.id,
        error: e,
        operation: Operation.UPDATE,
      }),
    )
  }
}

export function* doUpdate({
  patientId,
  note,
  operation,
}: {
  patientId: number
  note: UpdateNoteInfo
  operation: Operation
}) {
  const client = yield* call(getClient)

  let updatedNote: Note = null

  if ('values' in note) {
    const {
      data: { data },
    } = yield* call(client.updateAnswers, Number(patientId), note.id, {
      values: note.values,
    })
    updatedNote = data
  }

  if (note.signature) {
    const {
      data: { data },
    } = yield* call(client.signNote, Number(patientId), note.id, {
      signature: note.signature,
    })
    updatedNote = data
  }

  if ('status' in note) {
    const {
      data: { data },
    } = yield* call(client.updateNoteStatus, Number(patientId), note.id, {
      status: note.status,
    })
    updatedNote = data
  }

  yield* put(
    patientNotesActions.received({
      patientId,
      note: updatedNote,
      operation,
    }),
  )
  // TODO: once doUpdate has been migrated to RTK, use invalidateTags from notepadApi instead of here
  yield* put(
    notepadApi.util.invalidateTags([
      { type: NotePadTags.PatientNotes, id: patientId },
    ]),
  )
}

export function* cancelNote({
  payload: { patientId, noteId },
}: ReturnType<typeof patientNotesActions.cancel>) {
  try {
    const client = yield* call(getClient)

    const {
      data: { data },
    } = yield* call(client.updateNoteStatus, Number(patientId), noteId, {
      status: NoteStatus.Cancelled,
    })

    let updatedNote: Note = data

    yield* put(
      patientNotesActions.received({
        patientId,
        note: updatedNote,
        operation: Operation.DELETE,
      }),
    )

    // TODO: once cancelNote has been migrated to RTK, use invalidateTags from notepadApi instead of here
    yield* put(
      notepadApi.util.invalidateTags([
        { type: NotePadTags.PatientNotes, id: patientId },
      ]),
    )
    // TODO: once cancelNote has been migrated to RTK, use invalidateTags from documentCenterApi instead of here
    yield* put(
      documentCenterApi.util.invalidateTags([
        { type: DocumentCenterTags.MemberDocument, id: patientId },
      ]),
    )
  } catch (e) {
    yield* put(
      patientNotesActions.crudFailure({
        patientId,
        draftId: noteId,
        error: e,
        operation: Operation.DELETE,
      }),
    )
  }
}

export function* deleteDraft({
  payload: { patientId, draftId, parentNoteId },
}: ReturnType<typeof patientNotesActions.deleteDraft>) {
  try {
    const client = yield* call(getClient)

    yield* call(client.deleteDraft, Number(patientId), draftId)

    yield* put(
      patientNotesActions.draftDeleted({ patientId, draftId, parentNoteId }),
    )

    // TODO: once deleteDraft has been migrated to RTK, use invalidateTags from notepadApi instead of here
    yield* put(
      notepadApi.util.invalidateTags([
        { type: NotePadTags.PatientNotes, id: patientId },
      ]),
    )
  } catch (e) {
    yield* put(
      patientNotesActions.crudFailure({
        patientId,
        draftId,
        error: e,
        operation: Operation.DELETE,
      }),
    )
  }
}

export function* get({
  payload: { noteId, patientId },
}: ReturnType<typeof patientNotesActions.get>) {
  try {
    const client = yield* call(getClient)

    const {
      data: { data },
    } = yield* call(client.getPatientNote, Number(patientId), Number(noteId))

    yield* put(
      patientNotesActions.received({
        note: data,
        patientId,
        operation: Operation.FETCH,
      }),
    )
  } catch (e) {
    yield* put(
      patientNotesActions.crudFailure({
        patientId,
        error: e,
        operation: Operation.FETCH,
      }),
    )
  }
}

export default function* patientNotesSagas() {
  yield* all([
    takeKeyedLatest(
      patientNotesActions.create,
      (action) =>
        `${action.payload.patientId}-${action.payload.note?.template_id}`,
      create,
    ),
    takeKeyedLatest(
      patientNotesActions.cancel,
      (action) => `${action.payload.patientId}-${action.payload.noteId}`,
      cancelNote,
    ),
    takeKeyedLatest(
      patientNotesActions.update,
      (action) => `${action.payload.patientId}-${action.payload.note.id}`,
      update,
    ),
    takeKeyedLatest(
      patientNotesActions.finalize,
      (action) => `${action.payload.patientId}-${action.payload.note.id}`,
      finalize,
    ),
    takeKeyedLatest(
      patientNotesActions.deleteDraft,
      (action) => `${action.payload.patientId}-${action.payload.draftId}`,
      deleteDraft,
    ),
    takeKeyedLatest(
      patientNotesActions.get,
      (action) => `${action.payload.patientId}-${action.payload.noteId}`,
      get,
    ),
  ])
}
