import { useCallback, useEffect, useState } from 'react'

import deviceInfo from 'app/services/device-info'

const useMediaDevices = (onChange?: (devices: any) => void) => {
  const [state, setState] = useState({})

  const handleChange = useCallback(async () => {
    const devices = await deviceInfo.getDevices()
    setState(devices)
    onChange?.(devices)
  }, [onChange])

  useEffect(() => {
    navigator.mediaDevices.addEventListener('devicechange', handleChange)
    handleChange()

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', handleChange)
    }
  }, [handleChange])

  return state
}

const useMediaDevicesMock = () => ({})

const isNavigator = typeof navigator !== 'undefined'
const isBrowser = typeof window !== 'undefined'

export default isNavigator && isBrowser && !!navigator.mediaDevices
  ? useMediaDevices
  : useMediaDevicesMock
