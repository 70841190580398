import type { ReactNode } from 'react'

import { Navigate } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { selectHasPermissions } from 'app/redux/authentification/selectors'
import routes from 'app/services/routes'

/**
 * Guard a route using Auth0 scopes/permissions.
 * Users without the provided scopes should be redirected to the dashboard.
 */
export const PermissionsGuard = ({
  children,
  permissions,
}: {
  children?: ReactNode
  permissions: string[]
}) => {
  const userHasPermissions = useAppSelector((state) =>
    selectHasPermissions(state, permissions),
  )
  return !userHasPermissions ? (
    <Navigate to={routes.dashboard()} />
  ) : (
    <>{children}</>
  )
}

export default PermissionsGuard
