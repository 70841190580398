export enum RasaIntentsActionType {
  GET_RASA_INTENTS = '@@rasaIntents/GET_RASA_INTENTS',
  GET_RASA_INTENTS_SUCCESS = '@@rasaIntents/GET_RASA_INTENTS_SUCCESS',
  GET_RASA_INTENTS_ERROR = '@@rasaIntents/GET_RASA_INTENTS_ERROR',
}

export interface RasaIntentsState {
  error?: Error
  fetching: boolean
  intents?: Intent[]
}

interface IntentArgument {
  type: string
  values: string[]
}

export interface Intent {
  intent: string
  description: string | null
  positional_args: string[] | null
  args: Record<string, IntentArgument>
  documentation_link: string | null
}
