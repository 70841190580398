import type { EmergencyRoomTypes } from '@dialogue/services'

export enum EpisodeMetaTypes {
  REQUEST_PROPERTIES = '@@episodeMeta/REQUEST_PROPERTIES',
  REQUEST_PROPERTIES_SUCCESS = '@@episodeMeta/REQUEST_PROPERTIES_SUCCESS',
  REQUEST_PROPERTIES_FAILURE = '@@episodeMeta/REQUEST_PROPERTIES_FAILURE',
  UPDATE_EPISODE_PROPERTIES = '@@episodeMeta/UPDATE_EPISODE_PROPERTIES',
  UPDATE_EPISODE_PROPERTIES_SUCCESS = '@@episodeMeta/UPDATE_EPISODE_PROPERTIES_SUCCESS',
  UPDATE_EPISODE_PROPERTIES_FAILURE = '@@episodeMeta/UPDATE_EPISODE_PROPERTIES_FAILURE',
  UPDATE_EPISODE_TITLE = '@@episodeMeta/UPDATE_EPISODE_TITLE',
}

export interface EpisodeMetaState {
  updatingEpisodeProperties: boolean
  errorUpdatingEpisodeProperties?: Record<string, any>

  properties?: EmergencyRoomTypes.EpisodeProperties
  errorProperties?: Record<string, any>
  fetchingProperties: boolean
}
