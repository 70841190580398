import { action } from 'typesafe-actions'

import { type Template, TemplatesActionTypes } from './types'

export const getTemplatesRequest = () =>
  action(TemplatesActionTypes.GET_TEMPLATES_REQUEST)

export const getTemplatesSuccess = (templates: {
  templates: Record<string, Template>
}) => action(TemplatesActionTypes.GET_TEMPLATES_SUCCESS, templates)

export const getTemplatesFailure = (error: Error) =>
  action(TemplatesActionTypes.GET_TEMPLATES_FAILURE, error)
