import { Tag as AntdTag, type TagProps as AntdTagProps } from 'antd'
import styled from 'styled-components'

import { withTooltip, type WithTooltipProps } from 'app/components/hocs'

export interface BaseTagProps extends AntdTagProps {
  label?: string | false
}

const StyledTag = styled(AntdTag)<TagProps>`
  &&& {
    margin: 0 4px;
    padding: 1px 4px;
    font-size: 14px;
    border-radius: 2px;
  }
  &&& span {
    ${({ label }) => (label ? '' : 'margin-left: 0;')}
  }
`

const BaseTag = ({ label, ...rest }: BaseTagProps) => {
  return (
    <StyledTag label={label} {...rest}>
      {label}
    </StyledTag>
  )
}

export interface TagProps extends BaseTagProps, WithTooltipProps {}

export const Tag = withTooltip<TagProps>(BaseTag)
