// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type * as actions from './actions'
import { UserStatusActionTypes, type UserStatusState } from './types'

export const INITIAL_STATE: UserStatusState = {
  showTeamStatusPanel: false,
}

export type UserStatusAction = ActionType<typeof actions>

export const reducer: Reducer<typeof INITIAL_STATE, UserStatusAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UserStatusActionTypes.TOGGLE_TEAM_STATUS_PANEL:
        draft.showTeamStatusPanel = !draft.showTeamStatusPanel
        break
    }
  })

export default reducer
