import { Typography } from 'antd'
import moment from 'moment'

import { colors } from 'app/theme'

interface DateOffsetProps {
  date: string
}

export const DateOffset = ({ date }: DateOffsetProps) => {
  const formattedDate = moment(date).format('YYYY-MM-DD') // format: 2021-12-31
  const formattedTime = moment(date).format('H:mm') // format: 18:00

  const isTimePast = moment(date).isBefore(moment())

  return (
    <Typography.Text
      data-dd-privacy="allow"
      css={`
        color: ${isTimePast ? colors.error : 'inherit'};
        font-size: 12px;
        text-align: right;
        display: flex;
        padding-left: 8px;
      `}
    >
      {formattedDate}
      <br />
      {formattedTime}
    </Typography.Text>
  )
}
