// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { type NavigationSidebarState, NavigationSidebarTypes } from './types'

export { actions as navigationSidebarActions }
export * from './types'

export type NavigationSidebarAction = ActionType<typeof actions>

export const INITIAL_STATE: NavigationSidebarState = {
  assigned_episodes_count: 0,
  fetchingEpisodesError: null,
}

export const reducer: Reducer<typeof INITIAL_STATE, NavigationSidebarAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case NavigationSidebarTypes.REQUEST_MY_ASSIGNED_EPISODES_COUNT_SUCCESS:
        draft.assigned_episodes_count = action.payload.count
        draft.fetchingEpisodesError = null
        break

      case NavigationSidebarTypes.REQUEST_MY_ASSIGNED_EPISODES_COUNT_ERROR:
        draft.fetchingEpisodesError = action.payload.error
        break
    }
  })

export default reducer
