import { useCallback } from 'react'

import { FileOutlined } from '@ant-design/icons'
import type { Task } from '@dialogue/coredata'
import { Popover } from 'antd'
import styled from 'styled-components'

import { DateOffset } from 'app/components/date/date-offset'
import TaskOwner from 'app/components/tasks/task-owner'
import { useOpenMemberDocument } from 'app/containers/documents/document-details/useOpenMemberDocument'
import {
  MemberDocumentsMultiSelect,
  type MemberDocumentsMultiSelectProps,
} from 'app/containers/documents/member-document-multi-select'

interface TaskDetailsProps {
  task: Task
}

const DetailsContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TaskDetails = ({ task }: TaskDetailsProps) => {
  const { assignee_id, teams, scheduled_for } = task

  return (
    <DetailsContainer className="task-row-details">
      <TaskOwner assigneeId={assignee_id} teams={teams} />
      <DateOffset date={scheduled_for} />
    </DetailsContainer>
  )
}

export const Attachments = ({
  documentIds,
  memberId,
}: {
  documentIds: string[]
  memberId: number
}) => {
  const openMemberDocument = useOpenMemberDocument()

  const handleOpenDocument = useCallback<
    NonNullable<MemberDocumentsMultiSelectProps['onDocumentClick']>
  >(
    (id, document) => {
      openMemberDocument(document)
    },
    [openMemberDocument],
  )

  const content = (
    <MemberDocumentsMultiSelect
      value={documentIds}
      memberId={memberId}
      allowEdit={false}
      onDocumentClick={handleOpenDocument}
      style={{ maxWidth: '400px' }}
    />
  )
  const opacity = documentIds.length ? 1 : 0.5
  return (
    <Popover
      content={content}
      placement="topLeft"
      css={{ padding: '5px', opacity }}
    >
      {documentIds.length}
      <FileOutlined
        data-testid="attachment-icon"
        style={{ fontSize: '14px', marginLeft: '2px' }}
      />
    </Popover>
  )
}

export const EnrichedTaskDetails = ({ task }: TaskDetailsProps) => {
  const { scheduled_for, document_ids = [], member_id } = task

  return (
    <DetailsContainer className="task-row-details">
      <Attachments documentIds={document_ids} memberId={member_id} />
      <DateOffset date={scheduled_for} />
    </DetailsContainer>
  )
}
