// @ts-strict-ignore
import { type ReactNode, useEffect } from 'react'

import {
  useNavigate,
  useLocation,
  useParams,
  useNavigationType,
} from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { selectIsRestrictedPractitioner } from 'app/redux/authentification/selectors'
import { requestAccess } from 'app/redux/rlac'
import { getAccessRecord } from 'app/sagas/rlac/selectors'

/**
 * Guard a route using ER's Record Level Access Control logic.
 *
 * Unauthorized users will be prompted to request access
 * followed by redirection back to the previous
 * page they were on if they cancel the access request.
 *
 * This guard requires that the route it's guarding contain
 * `:patientId` as a path parameter, and will fail otherwise.
 * This guard also requires the user to be logged in, so be sure
 * to only use it inside an <AuthenticatedRoute />.
 *
 * Example:
 * ```tsx
 * <AuthenticatedRoute path="/chat/:patientId">
 *   <RlacGuard>
 *     <PatientPage />
 *   </RlacGuard>
 * </AuthenticatedRoute>
 * ```
 */
export const RlacGuard = ({ children }: { children?: ReactNode }) => {
  const { patientId } = useParams<'patientId'>()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const navigationType = useNavigationType()

  const dispatch = useAppDispatch()
  const isRestrictedPractitioner = useAppSelector(
    selectIsRestrictedPractitioner,
  )
  const record = useAppSelector((state) => getAccessRecord(state, patientId))

  useEffect(() => {
    if (isRestrictedPractitioner) {
      dispatch(requestAccess({ patientId, urlPath: pathname.substring(1) }))
    }
  }, [isRestrictedPractitioner, patientId, pathname, dispatch])

  useEffect(() => {
    if (record?.fetching === false && record?.hasAccess === false) {
      if (navigationType === 'POP') {
        // if this is first nav, or the result of going back
        // then redirect the user to the dashboard
        navigate('/', { replace: true })
      } else {
        navigate(-1)
      }
    }
  }, [record, navigate, navigationType])

  if (isRestrictedPractitioner && !record?.hasAccess) {
    return null
  }

  return <>{children}</>
}

export default RlacGuard
