import { TaskPriority } from '@dialogue/coredata'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'

enum TASK_PRIORITY_KEY {
  NONE = 'tasks.values.priority.3',
  FLAGGED = 'tasks.values.priority.2',
  URGENT = 'tasks.values.priority.1',
}

export const PRIORITY_MAPPING = {
  [TaskPriority.NONE]: {
    value: TaskPriority.NONE,
    label: TASK_PRIORITY_KEY.NONE,
    backgroundColor: '#e0e0e0',
    fontColor: 'inherit',
  },
  [TaskPriority.FLAGGED]: {
    value: TaskPriority.FLAGGED,
    label: TASK_PRIORITY_KEY.FLAGGED,
    backgroundColor: '#ffdd92',
    fontColor: '#485156',
  },
  [TaskPriority.URGENT]: {
    value: TaskPriority.URGENT,
    label: TASK_PRIORITY_KEY.URGENT,
    backgroundColor: '#e95d5d',
    fontColor: '#ffffff',
  },
}

interface Props {
  priority: TaskPriority
}

export const TaskPriorityTag = ({ priority, ...rest }: Props) => {
  const { t } = useTranslation()
  const config = PRIORITY_MAPPING[priority]

  if (!config) {
    return null
  }

  return (
    <Tag
      {...rest}
      color={config.backgroundColor}
      css={`
        color: ${config.fontColor};
        font-weight: 700;
      `}
      data-testid="task-priority-tag"
    >
      {t(config.label)}
    </Tag>
  )
}
