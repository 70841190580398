import { createAction, createReducer } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

export interface State {
  darkModeEnabled: boolean
  redactionModeEnabled: boolean
}

export const INITIAL_STATE: State = {
  darkModeEnabled: false,
  redactionModeEnabled: false,
}

export const toggleDarkMode = createAction('@@theming/toggleDarkMode')
export const toggleRedactionMode = createAction('@@theming/toggleRedactionMode')

export const selectDarkModeEnabled = (state: ReduxState) =>
  state.theming.darkModeEnabled
export const selectRedactionModeEnabled = (state: ReduxState) =>
  state.theming.redactionModeEnabled

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(toggleDarkMode, (state) => {
    state.darkModeEnabled = !state.darkModeEnabled
  })
  builder.addCase(toggleRedactionMode, (state) => {
    state.redactionModeEnabled = !state.redactionModeEnabled
  })
})
