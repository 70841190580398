import { useCallback, useContext } from 'react'

import type { MemberDocument } from '@dialogue/document-center'

import { DrawerManagementContext } from 'app/components/drawer-manager/context'
import {
  MODAL_TYPE,
  useViewerInstance,
} from 'app/containers/documents/document-details/viewer-context'
import { useAppDispatch } from 'app/hooks'
import { documentViewerActions } from 'app/redux/documents/viewer'

export const useOpenMemberDocument = () => {
  const dispatch = useAppDispatch()
  const { setModal, setNextDocument, clearCurrentDocument, isViewerEditable } =
    useViewerInstance()
  const { closeDrawer } = useContext(DrawerManagementContext)
  const openMemberDocument = useCallback(
    (document: MemberDocument) => {
      closeDrawer()
      if (isViewerEditable) {
        // Viewer is open and in edit mode, open confirmation modal & capture the requested doc
        // to be handled by the modal.
        setModal?.({ type: MODAL_TYPE.CONFIRM_SAVE })
        setNextDocument?.(document)
      } else {
        // Viewer is in read only mode (or not even open), no blocking necessary.
        // Document must be fully re-loaded, otherwise annotation are not persisted
        clearCurrentDocument()
        dispatch(
          documentViewerActions.viewMemberDocument({
            document: document,
          }),
        )
      }
    },
    [
      isViewerEditable,
      setModal,
      setNextDocument,
      closeDrawer,
      clearCurrentDocument,
      dispatch,
    ],
  )
  return openMemberDocument
}
