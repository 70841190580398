// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type { ReduxState } from 'app/redux'

import type * as actions from './actions'
import { type InputHealthState, InputHealthTypes } from './types'

export const INITIAL_STATE: InputHealthState = {
  records: {},
  documents: null,
  documentsFetching: false,
  documentsError: null,
}

export type InputHealthAction = ActionType<typeof actions>

export const reducer: Reducer<typeof INITIAL_STATE, InputHealthAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case InputHealthTypes.VERIFY_RECORD:
        draft.records[action.payload] = {
          record: null,
          fetching: true,
          error: null,
        }
        break

      case InputHealthTypes.LINK_RECORD:
        draft.records[action.payload] = {
          record: null,
          fetching: true,
          error: null,
        }
        break

      case InputHealthTypes.VERIFY_RECORD_SUCCESS:
        draft.records[action.payload.patientId] = {
          ...draft.records[action.payload.patientId],
          record: {
            link: action.payload.record.links.external,
            familyDoctor: action.payload.record.data.family_doctor,
            address: action.payload.record.data.address,
            preferredPharmacy: action.payload.record.data.preferred_pharmacy,
            preferredPharmacyId:
              action.payload.record.data.preferred_pharmacy_id,
            preferredPharmacyFax:
              action.payload.record.data.preferred_pharmacy_fax,
            identifications: action.payload.record.data.identifications,
            phone: action.payload.record.data.phone,
          },
          fetching: false,
          error: null,
        }
        break

      case InputHealthTypes.VERIFY_RECORD_ERROR:
        draft.records[action.payload.patientId] = {
          record: null,
          fetching: false,
          error: action.payload.error,
        }
        break

      case InputHealthTypes.GET_PATIENT_DOCUMENTS:
        draft.documents = null
        draft.documentsFetching = true
        draft.documentsError = null
        break

      case InputHealthTypes.GET_PATIENT_DOCUMENTS_SUCCESS:
        draft.documents = action.payload
        draft.documentsFetching = false
        break

      case InputHealthTypes.GET_PATIENT_DOCUMENTS_FAILURE:
        draft.documentsError = action.payload
        draft.documentsFetching = false
        break

      case InputHealthTypes.START_POLLING_PATIENT_DOCUMENTS:
        draft.documents = null
        draft.documentsFetching = true
        draft.documentsError = null
        break

      case InputHealthTypes.STOP_POLLING_PATIENT_DOCUMENTS:
        draft.documentsFetching = false
        draft.documentsError = null
        break
    }
  })

export const selectPatientRecordData = (
  state: ReduxState,
  patientId: string | number,
) => state.inputHealth.records[patientId]

export const selectPreferredPharmacyId = (
  state: ReduxState,
  patientId: string | number,
) => state.inputHealth.records[patientId]?.record?.preferredPharmacyId

export const selectPatientRecord = (
  state: ReduxState,
  patientId: string | number,
) => state.inputHealth.records[patientId]?.record

export const selectPatientRecordFetching = (
  state: ReduxState,
  patientId: string | number,
) => state.inputHealth.records[patientId]?.fetching

export const selectPatientRecordError = (
  state: ReduxState,
  patientId: string | number,
) => state.inputHealth.records[patientId]?.error

export const selectPatientDocuments = (state: ReduxState) =>
  state.inputHealth.documents

export const selectPatientDocumentById = (
  state: ReduxState,
  documentId: string,
) => {
  return selectPatientDocuments(state)?.find(
    (doc) => String(doc.id) === documentId,
  )
}

export const selectPatientDocumentsFetching = (state: ReduxState) =>
  state.inputHealth.documentsFetching

export default reducer
