import {
  PropertyTypes,
  type MedicalProfileProperties,
} from '@dialogue/services/dist/er/model'
import { all, call, put } from 'typed-redux-saga/macro'

import { EMERALD_ENABLED } from 'app/config/features'
import { memberMedicalProfileActions } from 'app/redux/medical-profile/member-medical-profile'

import {
  initEmeraldV2Client,
  initEmergencyRoomClient,
  takeKeyedLatest,
} from '../utils'

export function* getMemberMedicalProfile({
  payload: { patientId },
}: ReturnType<typeof memberMedicalProfileActions.get>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    const { data } = yield* call(erClient.getMemberProperties, patientId, {
      latest: true,
      type: [
        PropertyTypes.ALLERGIES,
        PropertyTypes.MEDICATIONS,
        PropertyTypes.MEDICAL_HISTORY,
      ],
    })

    const { allergies, medications, medical_history } = data.reduce(
      (acc, curr) => {
        const { property_type } = curr
        return { ...acc, [property_type]: (curr as any)[property_type] || '' }
      },
      {} as MedicalProfileProperties,
    )

    yield* put(
      memberMedicalProfileActions.received({
        patientId,
        allergies,
        medications,
        medical_history,
      }),
    )
  } catch (error) {
    yield* put(memberMedicalProfileActions.failed({ patientId, error }))
  }
}

export function* updateMemberProfileAllergies({
  payload: { patientId, allergies },
}: ReturnType<typeof memberMedicalProfileActions.updateAllergies>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    const emerald = yield* call(initEmeraldV2Client)

    // TODO: clean up once only ER is used
    if (EMERALD_ENABLED) {
      yield* call(emerald.updatePatientAllergies, patientId, { allergies })
    }

    const response = yield* call(erClient.setMemberAllergies, patientId, {
      allergies,
    })

    yield* put(
      memberMedicalProfileActions.allergiesReceived({
        patientId,
        allergies: response?.allergies ?? '',
      }),
    )
  } catch (error) {
    yield* put(memberMedicalProfileActions.failed({ patientId, error }))
  }
}

export function* updateMemberProfileMedications({
  payload: { patientId, medications },
}: ReturnType<typeof memberMedicalProfileActions.updateMedications>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    const emerald = yield* call(initEmeraldV2Client)

    // TODO: clean up once only ER is used
    if (EMERALD_ENABLED) {
      yield* call(emerald.updatePatientMedications, patientId, { medications })
    }

    const response = yield* call(erClient.setMemberMedications, patientId, {
      medications,
    })

    yield* put(
      memberMedicalProfileActions.medicationsReceived({
        patientId,
        medications: response?.medications ?? '',
      }),
    )
  } catch (error) {
    yield* put(memberMedicalProfileActions.failed({ patientId, error }))
  }
}

export function* updateMemberProfileMedicalHistory({
  payload: { patientId, medical_history },
}: ReturnType<typeof memberMedicalProfileActions.updateMedicalHistory>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    const emerald = yield* call(initEmeraldV2Client)

    if (EMERALD_ENABLED) {
      // TODO: clean up once only ER is used
      yield* call(emerald.updatePatientMedicalHistory, patientId, {
        medical_history,
      })
    }

    const response = yield* call(erClient.setMemberMedicalHistory, patientId, {
      medical_history,
    })

    yield* put(
      memberMedicalProfileActions.medicalHistoryReceived({
        patientId,
        medical_history: response?.medical_history ?? '',
      }),
    )
  } catch (error) {
    yield* put(memberMedicalProfileActions.failed({ patientId, error }))
  }
}

export default function* memberMedicalProfileSagas() {
  yield* all([
    takeKeyedLatest(
      memberMedicalProfileActions.get,
      (action) => action.payload.patientId,
      getMemberMedicalProfile,
    ),
    takeKeyedLatest(
      memberMedicalProfileActions.updateAllergies,
      (action) => action.payload.patientId,
      updateMemberProfileAllergies,
    ),
    takeKeyedLatest(
      memberMedicalProfileActions.updateMedications,
      (action) => action.payload.patientId,
      updateMemberProfileMedications,
    ),
    takeKeyedLatest(
      memberMedicalProfileActions.updateMedicalHistory,
      (action) => action.payload.patientId,
      updateMemberProfileMedicalHistory,
    ),
  ])
}
