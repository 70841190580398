// @ts-strict-ignore
import RWS from 'reconnecting-websocket'
import type {
  WebSocketEventListenerMap,
  WebSocketEventMap,
} from 'reconnecting-websocket/events'
import { eventChannel } from 'redux-saga'
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import config from 'app/config'
import { loginSuccess, logoutSuccess } from 'app/redux/authentification'
import {
  chatActions,
  ChatTypes,
  isWSEvent,
  type WSMessage,
} from 'app/redux/chat'
import { selectChannelMembers } from 'app/redux/chat/selectors'
import { selectCurrentEpisodeId } from 'app/redux/episode-view/selectors'

import { handleNewPostForEpisode } from '../episodes'

export const createOpenChannel = (socket: RWS) =>
  eventChannel<WebSocketEventMap['open']>((emitter) => {
    const handler: WebSocketEventListenerMap['open'] = (event) => {
      emitter(event)
    }

    socket.addEventListener('open', handler)

    return () => {
      socket.removeEventListener('open', handler)
    }
  })

export const createMessageChannel = (socket: RWS) =>
  eventChannel<WSMessage | Error>((emitter) => {
    const handleMessage: WebSocketEventListenerMap['message'] = (event) => {
      const data = JSON.parse(event.data)
      emitter(data)
    }

    socket.addEventListener('message', handleMessage)

    return () => {
      socket.removeEventListener('message', handleMessage)
    }
  })

export let seq = 1

/**
 * Send a MM compliant message through the websocket.
 * Will resolve/return once a message ack is received
 * by the socket unless disabled by passing false as
 * last argument.
 */
export function* sendMessage(
  socket: RWS,
  action: string,
  data?: unknown,
  waitForAck = true,
) {
  const message = { action, seq: seq++, data }

  yield* call([socket, socket.send], JSON.stringify(message))

  if (waitForAck === false) {
    return
  }

  const { payload } = yield* take(
    (a: any): a is ActionType<typeof chatActions.websocketResponseReceived> =>
      a.type === ChatTypes.WEBSOCKET_RESPONSE_RECEIVED &&
      a.payload.seq_reply === message.seq,
  )

  return payload
}

export function initSocket(accessToken: string) {
  seq = 1
  const socketUrl = config.MATTERMOST_SOCKET_URL.replace(
    '{accessToken}',
    accessToken,
  )
  const socket = new RWS(socketUrl, undefined, {
    maxEnqueuedMessages: 0, // disable message buffer
  })

  //socket.addEventListener('error', (err) => console.log('ERR', err, socketUrl))
  //socket.addEventListener('open', (e) => console.log('OPEN', e, socketUrl))
  //socket.addEventListener('close', (e) => console.log('CLOSE', e, socketUrl))

  return socket
}

export function* getUserStatuses(socket: RWS, userIds: string[]) {
  try {
    const response = yield* call(sendMessage, socket, 'get_statuses_by_ids', {
      user_ids: userIds,
    })
    yield* put(
      chatActions.gotStatuses(response.data as Record<string, 'online'>),
    )
  } catch (e) {
    console.error('Failed to get_statuses from mm websocket', e)
  }
}

export function* pollUserStatuses(socket: RWS, pollingInterval = 10000) {
  while (true) {
    const episodeId = yield* select(selectCurrentEpisodeId)
    if (episodeId) {
      const members = yield* select(selectChannelMembers, episodeId)
      if (members && members.length) {
        const userIds = members.map((m) => m.id)
        // don't hang if you don't get a response
        yield* call(getUserStatuses, socket, userIds)
      }
    }

    yield* delay(pollingInterval)
  }
}

export function* handleUserTyping(
  socket: RWS,
  action: ActionType<typeof chatActions.sendUserTyping>,
) {
  const { channelId } = action.payload
  yield* call(
    sendMessage,
    socket,
    'user_typing',
    {
      channel_id: channelId,
      parent_id: '',
    },
    false,
  )
}

export function* watchUserTyping(socket: RWS) {
  while (true) {
    const action = yield* take<ActionType<typeof chatActions.sendUserTyping>>(
      ChatTypes.SEND_USER_TYPING,
    )
    yield* fork(handleUserTyping, socket, action)
    yield* delay(2000)
  }
}

/**
 * Flow to run whenever the socket connection opens.
 *
 * Add any actions here that require that the socket be
 * open (i.e. sending messages on the socket)
 */
export function* handleConnection(socket: RWS) {
  yield* all([call(pollUserStatuses, socket), call(watchUserTyping, socket)])
}

export function* handleNotifyOnPost({
  payload: message,
}: ActionType<typeof chatActions.websocketEventReceived>) {
  const post = JSON.parse(message.data.post)
  yield* call(handleNewPostForEpisode, post)
}

/**
 * Flow to run independently of socket connection.
 *
 * Should not rely on posting messages to the socket.
 */
export function* handleMessages(socket: RWS) {
  yield* all([
    takeEvery(
      (
        action: any,
      ): action is ActionType<typeof chatActions.websocketEventReceived> =>
        action.type === ChatTypes.WEBSOCKET_EVENT_RECEIVED &&
        action.payload.event === 'posted',
      handleNotifyOnPost,
    ),
    takeEvery(
      yield* call(createMessageChannel, socket),
      function* (message: WSMessage) {
        if (isWSEvent(message)) {
          yield* put(chatActions.websocketEventReceived(message))
        } else {
          yield* put(chatActions.websocketResponseReceived(message))
        }
      },
    ),
  ])
}

export function* startWebsocket({
  payload: { accessToken },
}: ActionType<typeof loginSuccess>) {
  let socket, openChannel, workers

  try {
    socket = yield* call(initSocket, accessToken)
    openChannel = yield* call(createOpenChannel, socket)

    workers = yield* all([
      takeLatest(openChannel, handleConnection, socket),
      fork(handleMessages, socket),
    ])

    yield* take(logoutSuccess)
  } finally {
    // Gracefully close on cancellation or logout
    if (workers) {
      yield* cancel(workers)
    }

    openChannel?.close()
    socket?.close()
  }
}

export default function* websocketSagas() {
  while (true) {
    const websocketTask = yield* takeLatest(loginSuccess, startWebsocket)
    yield* take(logoutSuccess)
    yield* cancel(websocketTask)
  }
}
