// @ts-strict-ignore
import { datadogRum } from '@datadog/browser-rum'
import { ApiRequestError, EmeraldTypes } from '@dialogue/services'
import { notification } from 'antd'
import { type Channel, channel } from 'redux-saga'
import {
  actionChannel,
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import i18n from 'app/i18n'
import { loginSuccess, logoutSuccess } from 'app/redux/authentification'
import {
  selectUserId,
  selectUserProfile,
} from 'app/redux/authentification/selectors'
import { chatActions, ChatTypes, type WSEvent } from 'app/redux/chat'
import {
  selectChannel,
  selectFailedPost,
  selectMemberships,
  selectSessionId,
  selectTeamId,
  selectUser,
} from 'app/redux/chat/selectors'
import { UserPostType, type Post } from 'app/redux/chat/types'
import { mentionsActions } from 'app/redux/mentions'
import routes from 'app/services/routes'

import { getPatientFormUrl } from '../input-health'
import {
  initMessageRetractionClient,
  initScribeClient,
  initEmergencyRoomClient,
  takeKeyedLatest,
  initMMClientV4,
} from '../utils'

import { shouldReportChatError } from './utils'
import websocketSagas from './websocket'

export function* joinChannel(channelId: string) {
  const er = yield* call(initEmergencyRoomClient)

  const membership = yield* call(er.joinEpisode, channelId)

  yield* put(chatActions.joinChannelSuccess(membership))
}

/**
 * Same as a call effect, but will retry the request iff
 * the passsed functin returns a Mattermost membership error,
 * and will attempt to join the channel before retrying.
 */
export function* joinAndRetry<Fn extends (...args: any[]) => any>(
  channelId: string,
  fn: Fn,
  ...args: Parameters<Fn>
) {
  try {
    return yield* call(fn, ...args)
  } catch (e) {
    if (e instanceof ApiRequestError) {
      const isMissingMemberError =
        e.code === 403 &&
        [
          'store.sql_channel.get_member.missing.app_error',
          'api.context.permissions.app_error',
        ].includes(e.additionalInfo?.id)

      if (isMissingMemberError) {
        yield* call(joinChannel, channelId)
        return yield* call(fn, ...args)
      }
    }
    throw e
  }
}

export function* loadChannel(channelId: string) {
  const mm = yield* call(initMMClientV4)

  const { channel, members } = yield* all({
    channel: call(mm.getChannel, channelId),
    members: call(mm.getProfilesInChannel, channelId),
  })

  let patientMmId = undefined
  let patientAppId = undefined
  let patientLastViewedAt = undefined

  // patient is channel member whose user_type is empty or 'member'
  const patientInChannel = members.find((m) =>
    ['', 'member'].includes(m?.props?.user_type),
  )

  if (patientInChannel) {
    patientMmId = patientInChannel.id
    patientAppId = patientInChannel?.props?.appId
    patientLastViewedAt = (yield* call(
      mm.getChannelMember,
      channelId,
      patientMmId,
    ))?.last_viewed_at
  }

  yield* put(
    chatActions.loadChannelSuccess(
      channelId,
      channel,
      members,
      patientMmId,
      patientAppId,
      patientLastViewedAt,
    ),
  )
}

export function* loadPosts(channelId: string) {
  const mm = yield* call(initMMClientV4)
  const postsResponse = yield* call(mm.getPosts, channelId, 0, 1000)

  yield* put(chatActions.loadPostsSuccess(channelId, postsResponse))
}

export function* loadLatestPosts(channelId: string) {
  const { order, posts } = yield* select(selectChannel, channelId)

  const latestPostId = order.find((postId) => {
    const post = posts[postId]
    return post && !post.isLocal && !post.props?.failed
  })

  if (!latestPostId) {
    return
  }

  const mm = yield* call(initMMClientV4)
  const newPosts = yield* call(
    mm.getPostsAfter,
    channelId,
    latestPostId,
    0,
    1000,
  )

  const orderedPosts = newPosts.order
    .map((postId: string) => newPosts.posts[postId])
    .reverse()

  for (const post of orderedPosts) {
    yield* put(chatActions.addPost(channelId, post))
  }
}

export function* pollForNewPosts(channelId: string) {
  while (true) {
    yield* delay(2000)
    try {
      yield* joinAndRetry(channelId, loadLatestPosts, channelId)
    } catch (e) {
      console.warn('Failed to poll for new posts', e, { channelId })
    }
  }
}

export function* pollForChannelMembers(channelId: string) {
  while (true) {
    yield* delay(10000)
    try {
      yield* joinAndRetry(channelId, loadChannel, channelId)
    } catch (e) {
      console.warn('Failed to poll channel', e, { channelId })
    }
  }
}

export function* toggleChannelViewed(channelId: string) {
  const mm = yield* call(initMMClientV4)

  try {
    yield* call(mm.viewMyChannel, channelId)
  } catch (e) {
    console.warn('failed to toggle last seen', channelId, e)
  }
}

export function* updateChannelViewed({
  payload: { channelId },
}: ActionType<typeof chatActions.updateChannelViewed>) {
  yield* call(toggleChannelViewed, channelId)
}

export function* handleTypingEvent(channelId: string, userId: string) {
  yield* put(chatActions.userTyping(channelId, userId))
  yield* delay(2500)
  yield* put(chatActions.userTyping(channelId, null))
}

export function* handlePostedEvent(channelId: string, post: Post) {
  const sessionId = yield* select(selectSessionId)

  if (post.props?.session === sessionId) {
    // ignore posts sent from this app instance
    return
  }

  yield* put(chatActions.addPost(channelId, post))

  switch (post.type) {
    case 'system_add_remove':
    case 'system_join_leave':
      yield* call(loadChannel, channelId)
      break
    case UserPostType.INTERNAL:
    case UserPostType.LEGACY_INTERNAL: //TODO: Remove UserPostType.LEGACY_INTERNAL once it's completely deprecated
      if (
        post.props?.dialogue_type === 'episode_state_changed' &&
        post.props?.state === 'resolved'
      ) {
        // if you're still on channel when it gets resolved,
        // rejoin to keep access
        yield* call(joinChannel, channelId)
        yield* call(loadChannel, channelId)
      }
  }
}

export function* handleEvent(
  channelId: string,
  event: WSEvent,
  userTypingChannel: Channel<string>,
) {
  const { patientMmId } = yield* select(selectChannel, channelId)

  switch (event.event) {
    case 'typing':
      yield* put(userTypingChannel, event.data.user_id)
      break
    case 'channel_viewed':
      if (event.broadcast.user_id === patientMmId) {
        yield* put(chatActions.updatePatientLastViewedAt(channelId, Date.now()))
      }
      break
    case 'user_added':
    case 'user_removed':
    case 'user_updated':
      yield* call(loadChannel, channelId)
      break
    case 'posted':
      const post = JSON.parse(event.data.post)
      yield* call(handlePostedEvent, channelId, post)
      break
  }
}

export function* handleChannelEvents(channelId: string) {
  const userTyping = channel<string>()
  // create a buffered channel when load begins,
  // to WS events that may occur before a channel load
  const eventChannel = yield* actionChannel(
    (
      action: any,
    ): action is ReturnType<typeof chatActions.websocketEventReceived> =>
      action.type === ChatTypes.WEBSOCKET_EVENT_RECEIVED &&
      action.payload.broadcast.channel_id === channelId,
  )

  try {
    // we wanna run this as a fork to handle cancellation
    yield* takeLatest(userTyping, handleTypingEvent, channelId)

    // wait for channel and posts to load before processing
    // incoming WS events
    yield* take(
      (action: any) =>
        action.type === ChatTypes.INIT_CHANNEL_SUCCESS &&
        action.payload.channelId === channelId,
    )

    while (true) {
      const action = yield* take(eventChannel)
      try {
        yield* call(handleEvent, channelId, action.payload, userTyping)
      } catch (e) {
        console.warn('Failed to process incoming websocket event', e)
      }
    }
  } finally {
    userTyping.close()
    eventChannel.close()
  }
}

export function* channelFlow(channelId: string) {
  const memberships = yield* select(selectMemberships)

  if (!(channelId in memberships)) {
    // pre-emptive join if no membership exists
    yield* call(joinChannel, channelId)
  }

  yield* fork(handleChannelEvents, channelId)

  yield* joinAndRetry(channelId, function* () {
    yield* call(loadChannel, channelId)
    // we need to load posts after memberships
    // so speakeasy can render user info properly
    yield* call(loadPosts, channelId)
  })

  yield* put(chatActions.initChannelSuccess(channelId))

  yield* all([
    fork(pollForNewPosts, channelId),
    fork(pollForChannelMembers, channelId),
    fork(toggleChannelViewed, channelId),
  ])
}

export function* channelFlowRunner({
  payload: { channelId },
}: ActionType<typeof chatActions.initChannel>) {
  try {
    yield* race([
      call(channelFlow, channelId),
      take(
        (action: any) =>
          action.type === ChatTypes.CLEAR_CHANNEL &&
          action.payload.channelId === channelId,
      ),
    ])
    yield* call(toggleChannelViewed, channelId)
  } catch (err) {
    yield* put(chatActions.initChannelFailure(channelId, err))

    if (shouldReportChatError(err)) {
      datadogRum.addError(err)
    }
  }
}

export function* handlePostFailure(
  post: Post,
  error: Error,
  patientId: string,
) {
  const shortMessage =
    post.message.length > 20 ? post.message.substring(0, 20) : post.message
  const errorMessage = error?.message
  yield* call(notification.error, {
    message: i18n.t('chat.messageSendError.title'),
    description: i18n.t('chat.messageSendError.description', {
      message: shortMessage,
      error: errorMessage,
    }),
    onClick: () => {
      window.location.hash = '#' + routes.channel(patientId, post.channel_id)
      notification.close(post.id)
    },
    duration: 10000,
    key: post.id,
  })
}

export function* sendMessage(
  channelId: string,
  message: string,
  type = UserPostType.DEFAULT,
  props: Record<string, unknown> = {},
  extras: {
    filenames?: string[]
    file_ids?: string[]
    retryForId?: string
  } = {},
) {
  const sessionId = yield* select(selectSessionId)
  const { id: userId } = yield* select(selectUser)
  const appId = (yield* select(selectChannel, channelId))?.patientAppId

  const post = {
    channel_id: channelId,
    message,
    type,
    user_id: userId,
    props: { ...props, session: sessionId },
    ...extras,
  }

  const localTmpId = `local-${Date.now()}`

  yield* put(
    chatActions.addPost(channelId, {
      ...post,
      isLocal: true,
      id: localTmpId,
      user_id: userId,
    }),
  )
  const mm = yield* call(initMMClientV4)

  try {
    // @ts-expect-error bad typings as ID should not be required when creating a post.
    const newPost = yield* joinAndRetry(post.channel_id, mm.createPost, post)
    yield* put(
      chatActions.addPost(channelId, { ...newPost, localId: localTmpId }),
    )
  } catch (err) {
    const failedTmpId = `failed-${Date.now()}`
    const failedPost = {
      ...post,
      props: { ...post.props, failed: true },
      user_id: userId,
      id: failedTmpId,
      localId: localTmpId,
    }

    yield* put(chatActions.addPost(channelId, failedPost))
    yield* call(handlePostFailure, failedPost, err, appId)
  }
}

export function* sendTextMessage({
  payload: { channelId, message, options },
}: ActionType<typeof chatActions.sendTextMessage>) {
  const props = { ...options.props }

  // Sms messages are considered patient facing.
  // They update the channel state just like a patient visible message
  if (!options.internal || message.startsWith('/sms ')) {
    props.update_channel_state = true
  }

  let type = UserPostType.DEFAULT
  if (options.internal) {
    type = UserPostType.INTERNAL
    props.member_app_visible = false
  }

  yield* call(sendMessage, channelId, message, type, props, {
    filenames: options.filenames,
    file_ids: options.file_ids,
  })
}

export function* uploadIHFile({
  payload: { channelId, patientId, file },
}: ActionType<typeof chatActions.uploadIHFile>) {
  try {
    let data: Blob
    if (file.type === EmeraldTypes.DocumentType.REFERRAL) {
      data = yield* call(getPatientFormUrl, patientId, file.id)
    } else {
      const response: Response = yield* call(fetch, file.url)

      if (!response.ok) {
        throw new Error('Failed to download file from IH: ' + String(response))
      }

      data = yield* call([response, response.blob])
    }

    const localUrl = URL.createObjectURL(data)

    const teamId = yield* select(selectTeamId)
    const mm = yield* call(initMMClientV4)

    const formData = new FormData()
    formData.append('channel_id', channelId)
    formData.append('client_ids', teamId)
    formData.append('files', data, file.name)

    const { file_infos: fileInfos } = yield* call(
      mm.uploadFile,
      formData,
      // bad typings, formBoundary is not required, but typings are not inferred
      // properly if setting ts-expect-error
      undefined,
    )

    const fileId = fileInfos[0].id

    yield* put(
      chatActions.receivedFileMetadata(channelId, fileId, {
        ...fileInfos[0],
        localUrl,
      }),
    )

    yield* put(chatActions.uploadFileSuccess(channelId, fileId))
  } catch (e) {
    console.error('Failed to upload IH file', e)
    yield* put(chatActions.uploadFileFailure(channelId, e))
  }
}

export function* createScribeCharge(
  patientId: number,
  amount: number,
  description: string,
  episodeId: string,
) {
  const scribe = yield* call(initScribeClient)

  const charge = yield* call(
    scribe.createUserCharge,
    patientId,
    amount,
    description,
    episodeId,
  )

  return charge.links.self.href
}

export function* sendVideoCallMessage(channelId: string) {
  const profile: ReturnType<typeof selectUserProfile> =
    yield* select(selectUserProfile)

  const messageProps = {
    actions: [
      {
        text: i18n.t('videoCall.encounter.startActionText'),
        type: 'link',
        uri: `dialogue-dev://app/start-encounter/`,
      },
    ],
    practitioner: {
      given_name: profile.givenName,
      family_name: profile.familyName,
    },
    type: 'VIDEO_CALL',
  }

  yield* call(
    sendMessage,
    channelId,
    'Call Requested',
    UserPostType.DEFAULT,
    messageProps,
  )
}

export function* sendCharge({
  payload: { patientId, channelId, description, amount },
}: ActionType<typeof chatActions.sendCharge>) {
  try {
    const message = `Charge sent to patient: ${description} (CAD${amount})`

    const chargeHref = yield* call(
      createScribeCharge,
      patientId,
      amount,
      description,
      channelId,
    )

    const props = { type: 'charge', description, amount, href: chargeHref }

    yield* call(sendMessage, channelId, message, UserPostType.INTERNAL, props)
  } catch (e) {
    // FIXME: add error feedback in UI
    console.error('Failed to send charge', e)
  }
}

export function* uploadFile({
  payload: { channelId, file },
}: ActionType<typeof chatActions.uploadFile>) {
  try {
    const localUrl = URL.createObjectURL(file)

    const fileData = new Blob([file], { type: file.type })

    const teamId = yield* select(selectTeamId)
    const mm = yield* call(initMMClientV4)

    const formData = new FormData()
    formData.append('channel_id', channelId)
    formData.append('client_ids', teamId)
    formData.append('files', fileData, file.name)

    const { file_infos: fileInfos } = yield* call(
      mm.uploadFile,
      formData,
      // bad typings, formBoundary is not required, but typings are not inferred
      // properly if setting ts-expect-error
      undefined,
    )

    const fileId = fileInfos[0].id

    yield* put(
      chatActions.receivedFileMetadata(channelId, fileId, {
        ...fileInfos[0],
        localUrl,
      }),
    )

    yield* put(chatActions.uploadFileSuccess(channelId, fileId))
  } catch (e) {
    console.error('Failed to upload file', e)
    yield* put(chatActions.uploadFileFailure(channelId, e))
  }
}

export function* sendFile({
  payload: { channelId, fileName, fileId, internal, additionalProps },
}: ActionType<typeof chatActions.sendFile>) {
  try {
    const message = `File Upload: ${fileName}`
    const props = { ...additionalProps }

    let type = UserPostType.DEFAULT
    if (internal) {
      type = UserPostType.INTERNAL
      props.member_app_visible = false
    }

    yield* call(sendMessage, channelId, message, type, props, {
      file_ids: [fileId],
    })
  } catch (e) {
    console.error('Failed to send File', e)
  }
}

export function* retryMessage({
  payload: { messageId },
}: ActionType<typeof chatActions.retryMessage>) {
  const post = yield* select(selectFailedPost, messageId)

  if (!post) {
    console.warn('Failed to retry post: post not found')
    return
  }

  // if an error notification is open for this post, close it
  yield* call(notification.close, post.id)

  const props = { ...post.props }
  delete props.failed

  yield* call(
    sendMessage,
    post.channel_id,
    post.message,
    post.type as UserPostType,
    props,
    {
      retryForId: messageId,
      filenames: post.filenames,
      file_ids: post.file_ids,
    },
  )
}

export function* retractMessage({
  payload: { channelId, messageId, patientId },
}: ActionType<typeof chatActions.retractMessage>) {
  try {
    const messageRetraction = yield* call(initMessageRetractionClient)

    const userId = yield* select(selectUserId)

    const retractMessageResponse = yield* call(
      // @ts-expect-error - fix message retraction services type
      messageRetraction.retractMessage,
      messageId,
      userId,
      channelId,
    )

    // @ts-expect-error - fix message retraction services type
    yield* put(chatActions.updatePost(channelId, retractMessageResponse.data))
  } catch (error) {
    yield* call(notification.error, {
      message: `Hiding post ${messageId} failed`,
      description: 'Please contact support or try again',
      duration: 10,
      onClick: () => {
        window.location.hash =
          '#' + routes.channelPost(patientId, channelId, messageId)
      },
    })
  }
}

export function* doInitialLoad() {
  const mm = yield* call(initMMClientV4)

  const user = yield* call(mm.getMe)

  yield* put(chatActions.loadUserSuccess(user))
}

export function* loadMemberships() {
  const mm = yield* call(initMMClientV4)
  const teamId = yield* select(selectTeamId)

  const members = yield* call(mm.getMyChannelMembers, teamId)

  yield* put(
    chatActions.loadMembershipsSuccess(
      members.reduce(
        (memberships, membership) => ({
          ...memberships,
          [membership.channel_id]: membership,
        }),
        {},
      ),
    ),
  )
}

export function* pollMemberships() {
  while (true) {
    yield* delay(30000)

    try {
      yield* call(loadMemberships)
    } catch (e) {
      console.error('Failed to load memberships', e)
    }
  }
}

export function* chatFlowRunner(initChannel: Channel<any>) {
  yield* call(doInitialLoad)
  yield* call(loadMemberships)

  yield* put(mentionsActions.startPolling(yield* select(selectTeamId)))

  yield* all([
    fork(pollMemberships),
    takeKeyedLatest(
      initChannel,
      (action) => action.payload.channelId,
      channelFlowRunner,
    ),
    takeEvery(ChatTypes.SEND_TEXT_MESSAGE, sendTextMessage),
    takeEvery(ChatTypes.RETRY_MESSAGE, retryMessage),
    takeEvery(ChatTypes.UPLOAD_IH_FILE, uploadIHFile),
    takeEvery(ChatTypes.SEND_CHARGE, sendCharge),
    takeEvery(ChatTypes.SEND_FILE, sendFile),
    takeEvery(ChatTypes.UPLOAD_FILE, uploadFile),
    takeEvery(ChatTypes.RETRACT_MESSAGE, retractMessage),
    takeEvery(ChatTypes.UPDATE_CHANNEL_VIEWED, updateChannelViewed),
    takeKeyedLatest(
      ChatTypes.UPDATE_CHANNEL_VIEWED,
      (action) => action.payload.channelId,
      updateChannelViewed,
    ),
  ])
}

export function* chatFlow() {
  while (true) {
    // buffer INIT_CHANNEL actions while waiting for init
    const initChannel = yield* actionChannel(ChatTypes.INIT_CHANNEL)

    yield* take(loginSuccess)

    const runner = yield* fork(chatFlowRunner, initChannel)

    yield* take(logoutSuccess)

    yield* cancel(runner)
    yield* put(chatActions.clearAll())
    yield* put(mentionsActions.stopPolling())
  }
}

export default function* chatSagas() {
  yield* all([chatFlow(), websocketSagas()])
}
