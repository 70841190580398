import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type CSSProperties,
} from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { DocumentContentUpdateReason } from '@dialogue/document-center'
import { Button, Card, Typography, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  useDeleteMemberDocumentMutation,
  useReviewMemberDocumentMutation,
} from 'app/redux/api/document-center/member-documents'
import { memberDocumentsActions } from 'app/redux/documents/members'
import {
  selectDocumentBytesError,
  selectDocumentBytesLoading,
} from 'app/redux/documents/selectors'
import { documentViewerActions } from 'app/redux/documents/viewer'

import { MODAL_TYPE, useViewerInstance } from './viewer-context'

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 4px;
  width: calc(100% - 8px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseButton = styled(Button)`
  position: absolute;
  top: 2px;
  right: 2px;
  margin: 0;
  padding: 0 5px;
  height: initial;
`

const CardStyle: CSSProperties = { textAlign: 'center', position: 'relative' }
const ButtonWrapperStyle: CSSProperties = {
  marginTop: '1.5em',
}
const ButtonStyle: CSSProperties = {
  marginLeft: '0.75em',
  marginRight: '0.75em',
}

const ErrorStyle: CSSProperties = { marginTop: '0.5em' }

interface Props {
  memberId: number
  documentId: string
  documentName: string
}

interface CopyOptions {
  title?: string
  subtitle?: string
  cancel?: string
  confirm?: string
  error?: string
  danger?: boolean
}

export const ModalOverlay = ({ memberId, documentId, documentName }: Props) => {
  const { t } = useTranslation()
  const { t: tCommon } = useTranslation('common')
  const dispatch = useAppDispatch()
  const {
    getFileData,
    enableViewerEdit,
    disableViewerEdit,
    modal,
    setModal,
    nextDocument,
    setNextDocument,
  } = useViewerInstance()
  const [saved, setSaved] = useState<boolean>(false)
  const isSaving = useAppSelector((state) =>
    selectDocumentBytesLoading(state, memberId),
  )
  const errorSaving = useAppSelector((state) =>
    selectDocumentBytesError(state, memberId),
  )
  const [reviewDocument, { isLoading: isReviewing }] =
    useReviewMemberDocumentMutation()
  const [deleteDocument, { isLoading: isDeleting }] =
    useDeleteMemberDocumentMutation()

  const loading = isReviewing || isSaving || isDeleting
  const error = modal?.type === MODAL_TYPE.CONFIRM_SAVE && errorSaving

  const reviewFile = useCallback(() => {
    reviewDocument({ memberId, documentId })
      .unwrap()
      .then(() => {
        notification.success({
          message: t('documents.actions.fileReviewSuccess'),
        })
        modal?.onConfirm?.()
        setModal?.(null)
        // FIXE: This document update is currently required to refresh the document in the viewer
        // Once the getDocument will be migrated to RTK query, the document will be updated automatically
        dispatch(
          memberDocumentsActions.updateDocument({
            memberId,
            documentId,
            documentProperties: {},
          }),
        )
      })
      .catch(() => {
        notification.error({
          message: t('documents.actions.fileReviewError'),
        })
      })
  }, [reviewDocument, memberId, documentId, t, modal, setModal, dispatch])

  const saveFile = useCallback(async () => {
    try {
      const fileBlob = await getFileData()

      if (!fileBlob) {
        console.warn("Missing required arguments to save document, won't send.")
        return
      }

      dispatch(
        memberDocumentsActions.updateDocumentBytes({
          memberId,
          documentId,
          document: new File([fileBlob], documentName),
          reason: DocumentContentUpdateReason.edit,
        }),
      )
      modal?.onConfirm?.()
      setSaved(true)
    } catch (e) {
      dispatch(
        memberDocumentsActions.errorUpdatingDocumentBytes({
          memberId,
          error: e,
        }),
      )
      console.error(
        'Attempting to generate file from viewer failed with error: ',
        e,
      )
    }
  }, [
    memberId,
    documentId,
    documentName,
    modal,
    getFileData,
    setSaved,
    dispatch,
  ])

  const deleteFile = useCallback(() => {
    deleteDocument({ memberId, documentId })
      .unwrap()
      .then(() => {
        notification.success({
          message: t('documents.actions.fileDeleteSuccess'),
        })
        modal?.onConfirm?.()
        setModal?.(null)
      })
      .catch(() => {
        notification.error({
          message: t('documents.actions.fileDeleteError'),
        })
      })
  }, [deleteDocument, memberId, documentId, modal, t, setModal])

  const openNextDocument = useCallback(() => {
    if (!nextDocument) {
      return
    }

    // Load in the next document!
    dispatch(
      documentViewerActions.viewMemberDocument({
        document: nextDocument,
      }),
    )
    // Captured next document correctly, clear it out now.
    setNextDocument?.(null)
  }, [dispatch, nextDocument, setNextDocument])

  const handleCancel = useCallback(() => {
    if (modal?.type === MODAL_TYPE.CONFIRM_SAVE) {
      dispatch(
        memberDocumentsActions.resetUpdatingDocumentBytes({
          memberId,
        }),
      )
      disableViewerEdit()
      modal?.onCancel?.()
    }
    setModal?.(null)

    if (nextDocument) {
      openNextDocument()
    }
  }, [
    modal,
    setModal,
    nextDocument,
    dispatch,
    memberId,
    disableViewerEdit,
    openNextDocument,
  ])

  const handleConfirm = useCallback(() => {
    switch (modal?.type) {
      case MODAL_TYPE.CONFIRM_REVIEW:
        reviewFile()
        break
      case MODAL_TYPE.CONFIRM_EDIT:
        enableViewerEdit()
        setModal?.(null)
        break
      case MODAL_TYPE.CONFIRM_SAVE:
        saveFile()
        break
      case MODAL_TYPE.CONFIRM_DELETE:
        deleteFile()
        break
      case MODAL_TYPE.WARN_REQUIRED_FIELDS:
        setModal?.(null)
        break
      default:
        return
    }
  }, [modal, reviewFile, enableViewerEdit, setModal, saveFile, deleteFile])

  const handleDismiss = useCallback(() => {
    setModal?.(null)
  }, [setModal])

  useEffect(() => {
    // Loading has finished after save was started
    if (!loading && saved) {
      setSaved(false)
      handleCancel()
    }
  }, [loading, saved, setSaved, handleCancel])

  const copy = useMemo((): CopyOptions => {
    switch (modal?.type) {
      case MODAL_TYPE.CONFIRM_REVIEW:
        return {
          title: t('documents.modalCopy.confirmReview.title'),
          cancel: tCommon('button.cancel'),
          confirm: tCommon('button.yes'),
        }
      case MODAL_TYPE.CONFIRM_EDIT:
        return {
          title: t('documents.modalCopy.confirmEdit.title'),
          subtitle: t('documents.modalCopy.confirmEdit.copy'),
          cancel: tCommon('button.cancel'),
          confirm: tCommon('button.yes'),
        }
      case MODAL_TYPE.CONFIRM_SAVE:
        return {
          title: t('documents.modalCopy.confirmSave.title'),
          cancel: t('documents.modalCopy.confirmSave.discard'),
          confirm: tCommon('button.saveChanges'),
          error: t('documents.modalCopy.confirmSave.error'),
        }
      case MODAL_TYPE.CONFIRM_DELETE:
        return {
          title: t('documents.modalCopy.confirmDelete.title'),
          cancel: tCommon('button.cancel'),
          confirm: tCommon('button.delete'),
          danger: true,
        }
      case MODAL_TYPE.WARN_REQUIRED_FIELDS:
        return {
          title: t('documents.modalCopy.warnRequiredFields.title'),
          subtitle: t('documents.modalCopy.warnRequiredFields.copy'),
          confirm: t('documents.modalCopy.warnRequiredFields.back'),
        }
      default:
        return {}
    }
  }, [modal, t, tCommon])

  return (
    <StyledWrapper>
      <Card bodyStyle={CardStyle}>
        {modal?.type === MODAL_TYPE.CONFIRM_SAVE && (
          <CloseButton data-testid="close" onClick={handleDismiss} type="text">
            <CloseOutlined />
          </CloseButton>
        )}
        {copy.title && (
          <Typography.Title level={4}>{copy.title}</Typography.Title>
        )}
        {copy.subtitle && <Typography.Text>{copy.subtitle}</Typography.Text>}
        <div style={ButtonWrapperStyle}>
          {copy.cancel && (
            <Button style={ButtonStyle} type="text" onClick={handleCancel}>
              {copy.cancel}
            </Button>
          )}
          {copy.confirm && (
            <Button
              style={ButtonStyle}
              type="primary"
              danger={copy.danger}
              loading={loading}
              onClick={handleConfirm}
            >
              {copy.confirm}
            </Button>
          )}
        </div>
        {copy.error && error && (
          <div style={ErrorStyle}>
            <Typography.Text type="danger">{copy.error}</Typography.Text>
          </div>
        )}
      </Card>
    </StyledWrapper>
  )
}
