import type { FormInstance } from 'antd'

import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'

import { DocumentProperties } from './document-properties'
import { FaxProperties } from './fax-properties'
import { MemberProperties } from './member-properties'
import { TasksSection } from './tasks-section'

interface Props {
  document: ViewerIncomingFaxDocument
  form: FormInstance
  memberIdFieldValue: number
  onUnassignMember?: () => void
}

export const TriagedPanel = ({
  document,
  form,
  memberIdFieldValue,
  onUnassignMember,
}: Props) => {
  return (
    <>
      <MemberProperties
        document={document}
        form={form}
        onUnassignMember={onUnassignMember}
      />
      <DocumentProperties document={document} form={form} />
      <TasksSection
        memberIdFieldValue={memberIdFieldValue}
        episodeId={document.member_document?.episode_id}
        documentId={document.id}
      />
      <FaxProperties document={document} />
    </>
  )
}
