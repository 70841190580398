import { all, call, put } from 'typed-redux-saga/macro'

import {
  memberNotesActions,
  Operation,
} from 'app/redux/medical-profile/member-notes'

import { initEmergencyRoomClient, takeKeyedLatest } from '../utils'

export function* getMemberNotes({
  payload: { patientId },
}: ReturnType<typeof memberNotesActions.get>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    const { data: notes } = yield* call(erClient.getMemberNotes, patientId)
    yield* put(
      memberNotesActions.getSuccess({
        patientId,
        notes,
      }),
    )
  } catch (error) {
    yield* put(
      memberNotesActions.failure({
        patientId,
        error,
        type: Operation.fetching,
      }),
    )
  }
}

export function* createMemberNote({
  payload: { patientId, note },
}: ReturnType<typeof memberNotesActions.createNote>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    const { data: newNote } = yield* call(
      erClient.createMemberNote,
      patientId,
      note,
    )

    yield* put(
      memberNotesActions.createdNote({
        patientId,
        note: newNote,
      }),
    )
  } catch (error) {
    yield* put(
      memberNotesActions.failure({
        patientId,
        error,
        type: Operation.creating,
      }),
    )
  }
}

export function* updateMemberNote({
  payload: { patientId, note },
}: ReturnType<typeof memberNotesActions.updateNote>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    const { data: updatedNote } = yield* call(
      erClient.updateMemberNote,
      patientId,
      String(note.id),
      note,
    )

    yield* put(
      memberNotesActions.updatedNote({
        patientId,
        note: updatedNote,
      }),
    )
  } catch (error) {
    yield* put(
      memberNotesActions.failure({
        patientId,
        error,
        type: Operation.updating,
      }),
    )
  }
}

export function* deleteMemberNote({
  payload: { patientId, noteId },
}: ReturnType<typeof memberNotesActions.deleteNote>) {
  try {
    const erClient = yield* call(initEmergencyRoomClient)
    yield* call(erClient.deleteMemberNote, patientId, noteId)

    yield* put(memberNotesActions.deletedNote({ patientId, noteId }))
  } catch (error) {
    yield* put(
      memberNotesActions.failure({
        patientId,
        error,
        type: Operation.deleting,
      }),
    )
  }
}

export default function* memberNotesSagas() {
  yield* all([
    takeKeyedLatest(
      memberNotesActions.get,
      (action) => action.payload.patientId,
      getMemberNotes,
    ),
    takeKeyedLatest(
      memberNotesActions.createNote,
      (action) => action.payload.patientId,
      createMemberNote,
    ),
    takeKeyedLatest(
      memberNotesActions.updateNote,
      (action) => action.payload.patientId,
      updateMemberNote,
    ),
    takeKeyedLatest(
      memberNotesActions.deleteNote,
      (action) => action.payload.patientId,
      deleteMemberNote,
    ),
  ])
}
