import { action } from 'typesafe-actions'

import { NavigationSidebarTypes } from './types'

export const startPollMyAssignedEpisodes = () =>
  action(NavigationSidebarTypes.START_POLL_MY_ASSIGNED_EPISODES)

export const stopPollMyAssignedEpisodes = () =>
  action(NavigationSidebarTypes.STOP_POLL_MY_ASSIGNED_EPISODES)

export const requestMyAssignedEpisodesCount = () =>
  action(NavigationSidebarTypes.REQUEST_MY_ASSIGNED_EPISODES_COUNT)

export const requestMyAssignedEpisodesCountSuccess = (count: number) =>
  action(NavigationSidebarTypes.REQUEST_MY_ASSIGNED_EPISODES_COUNT_SUCCESS, {
    count,
  })

export const requestMyAssignedEpisodesCountError = (error: Error) =>
  action(NavigationSidebarTypes.REQUEST_MY_ASSIGNED_EPISODES_COUNT_ERROR, {
    error,
  })
