import type {
  EnrichedReminder,
  Reminder,
  ReminderTag,
} from '@dialogue/coredata'
import type { EmergencyRoomTypes } from '@dialogue/services'

export enum RemindersTypes {
  STATUS_CHANGE_REQUEST = '@@reminders/STATUS_CHANGE_REQUEST',
  STATUS_CHANGE_SUCCESS = '@@reminders/STATUS_CHANGE_SUCCESS',
  STATUS_CHANGE_FAILURE = '@@reminders/STATUS_CHANGE_FAILURE',

  BATCH_STATUS_CHANGE_REQUEST = '@@reminders/BATCH_STATUS_CHANGE_REQUEST',

  CREATE = '@@reminders/CREATE',
  CREATE_SUCCESS = '@@reminders/CREATE_SUCCESS',
  CREATE_FAILURE = '@@reminders/CREATE_FAILURE',

  GET_FOR_EPISODE_REQUEST = '@@reminders/GET_FOR_EPISODE_REQUEST',
  GET_FOR_EPISODE_FETCHING = '@@reminders/GET_FOR_EPISODE_FETCHING',
  GET_FOR_EPISODE_SUCCESS = '@@reminders/GET_FOR_EPISODE_SUCCESS',
  GET_FOR_EPISODE_FAILURE = '@@reminders/GET_FOR_EPISODE_FAILURE',

  GET_TAGS_REQUEST = '@@reminders/GET_TAGS_REQUEST',
  GET_TAGS_SUCCESS = '@@reminders/GET_TAGS_SUCCESS',
  GET_TAGS_ERROR = '@@reminders/GET_TAGS_ERROR',

  GET_LIST_REQUEST = '@@reminders/GET_LIST_REQUEST',
  GET_LIST_SUCCESS = '@@reminders/GET_LIST_SUCCESS',
  GET_LIST_ERROR = '@@reminders/GET_LIST_ERROR',
}

export type ReminderStatus =
  | EmergencyRoomTypes.ReminderStatus.COMPLETED
  | EmergencyRoomTypes.ReminderStatus.INCOMPLETE

export interface ReminderEpisode {
  incomplete: Reminder[]
  completed: Reminder[]
  error?: Error
  fetching?: boolean
}

interface ReminderEpisodes {
  [k: string]: ReminderEpisode
}

export interface RemindersState {
  episodes: { error?: null } & ReminderEpisodes
  tags: ReminderTag[]
  fetching: boolean
  tagsFetching: boolean
  fetchingStatusChange: boolean
  error: Error | null
  errorEpisodes: Error | null
  tagsError: Error | null

  // ER list
  list: EnrichedReminder[]
  listCount: number
  incompleteCount: number | null
  listError: Error | null
  listFetching: boolean
}
