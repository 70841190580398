import { Configuration } from '@dialogue/notepad-client'
import { select } from 'typed-redux-saga/macro'

import config from 'app/config'
import { selectAccessToken } from 'app/redux/authentification/selectors'

import { MissingAccessTokenError } from '../utils/exceptions'

export function* getConfig() {
  const accessToken = yield* select(selectAccessToken)

  if (!accessToken) {
    throw new MissingAccessTokenError()
  }

  return new Configuration({
    accessToken,
    basePath: config.NOTEPAD_URL,
  })
}
