/**
 * To create a new theme, add the new theme
 * mode name to `ThemeModes`, as well as to the
 * `extraModes` array.
 */
import type { CSSProp } from 'styled-components'
import styledTheme from 'styled-theming'

export type ThemeModes = 'default' | 'aubergine' | 'clementine'

const extraModes: ThemeModes[] = ['aubergine', 'clementine']

type ThemeDef = Partial<Record<ThemeModes, any>> & { default: any }

/**
 * Utility function which generates a theme entry
 * compatible with our theming system.
 *
 * Enforces a definition for the 'default' mode. Any
 * extra missing definitions will automatically
 * fall back to the 'default'  mode.
 *
 *
 ```typescript
const background = themed({default: '#FFFFFF'})
const Container = styled.div`
    background-color: ${background}
`
```
 */
export const themed = (themeObj: ThemeDef) => {
  const spec = { ...themeObj }
  const fallback = spec.default
  for (const mode of extraModes) {
    if (!spec[mode]) {
      spec[mode] = fallback
    }
  }
  return styledTheme('mode', spec)
}

export interface Theme {
  mode: ThemeModes
}

export const DEFAULT_THEME: Theme = {
  mode: 'default',
}

declare module 'react' {
  interface Attributes {
    css?: CSSProp
  }
}
