import { all, call, put, takeLatest } from 'typed-redux-saga/macro'

import { providerScheduleActions } from 'app/redux/timekeeper/schedule/provider-schedule'

import { getProvidersApi } from '../utils'

export function* fetchSchedule(
  action: ReturnType<typeof providerScheduleActions.get>,
) {
  const { providerId, range } = action.payload
  try {
    const api = yield* call(getProvidersApi)
    const response = yield* call(
      api.getProviderSchedule,
      providerId,
      range.start.toISOString(),
      range.end.toISOString(),
    )
    const schedule = response.data.data
    yield* put(
      providerScheduleActions.received({ providerId, schedule, range }),
    )
  } catch (e) {
    yield* put(
      providerScheduleActions.erroredFetching({ providerId, range, error: e }),
    )
  }
}

export default function* providerScheduleSagas() {
  yield* all([takeLatest(providerScheduleActions.get, fetchSchedule)])
}
