import { all, call, put, takeLatest } from 'typed-redux-saga/macro'

import { eligibilitySearchActions } from 'app/redux/eligibility-search'
import { initScribeV2Client, SEARCH_LIMIT } from 'app/sagas/utils'

export function* searchEligibility({
  payload,
}: ReturnType<typeof eligibilitySearchActions.request>) {
  const { query } = payload
  try {
    const scribeV2 = yield* call(initScribeV2Client)

    // Bumping client timeout, the search has high latency these days.
    scribeV2._axiosInstance.defaults.timeout = 30_000

    const { data: results } = yield* call(scribeV2.getEligibilityRecords, {
      search: query,
      status: 'active',
      limit: SEARCH_LIMIT,
    })
    yield* put(eligibilitySearchActions.success({ results }))
  } catch (error) {
    yield* put(eligibilitySearchActions.failure({ error }))
  }
}

export default function* eligibilitySearchSagas() {
  yield* all([takeLatest(eligibilitySearchActions.request, searchEligibility)])
}
