import type {
  EnrichedEpisode,
  ItemEnrichedEpisode,
  ItemEpisodesById,
  ItemS3FileDownloadParameters,
  PageWithEpisodeCount,
  Patient,
} from '@dialogue/coredata'
import {
  type ItemResponse,
  ScribeDeprecated,
  type ScribeTypes,
  Waldo,
} from '@dialogue/services'
import { notification } from 'antd'
import { all, call, put, select, takeEvery } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import Config from 'app/config'
import i18n from 'app/i18n'
import { isValidLocation } from 'app/lib/helpers'
import {
  mergePatientProfile,
  normalizeToCoreDataStyle,
  normalizeToScribeStyle,
} from 'app/lib/normalization'
import type { ReduxState } from 'app/redux'
import { selectAccessToken } from 'app/redux/authentification/selectors'
import { PatientsTypes, patientsActions } from 'app/redux/patients'

import {
  initEmergencyRoomClient,
  initScribeClient,
  initScribeV2Client,
  takeKeyedLatest,
} from './utils'

export const userManagementState = (state: ReduxState) => state.userManagement

export function* requestEpisodes({
  payload: { patientId, offset, limit },
}: ActionType<typeof patientsActions.requestEpisodes>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const { data, meta }: PageWithEpisodeCount = yield* call(
      emergencyRoom.getEpisodesByPatient,
      Number(patientId),
      offset,
      limit,
      true,
    )
    yield* put(
      patientsActions.requestEpisodesSuccess(
        patientId,
        data,
        meta.total_items,
        meta.active_episodes_count,
      ),
    )
  } catch (error) {
    yield* put(patientsActions.requestEpisodesFailure(patientId, error))
    notification.error({
      message: i18n.t('episodeList.errorLoading', { patientId }),
      description: String(error),
    })
  }
}

export function* requestMostRecentEpisodes({
  payload: { patientId },
}: ActionType<typeof patientsActions.requestMostRecentEpisodes>) {
  try {
    const limit = 100
    const offset = 0
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const { data }: PageWithEpisodeCount = yield* call(
      emergencyRoom.getEpisodesByPatient,
      Number(patientId),
      offset,
      limit,
      true,
    )
    const episodeIds = []
    const episodeMapping: Record<EnrichedEpisode['id'], EnrichedEpisode> = {}
    for (const ep of data) {
      episodeMapping[ep.id] = ep
      episodeIds.push(ep.id)
    }
    yield* put(
      patientsActions.requestMostRecentEpisodesSuccess(
        patientId,
        episodeIds,
        episodeMapping,
      ),
    )
  } catch (error) {
    yield* put(
      patientsActions.requestMostRecentEpisodesFailure(patientId, error),
    )
  }
}

// Yes, this saga is _almost_ a duplicate of `getEpisode` from 'app/sagas/episodes'
// The difference is that this one uses actions that don't update the episode view.
//
// Reason: in the schedule we fetch episode date for appts, if we view the schedule
// from within the episode view, we can't have our fetching of data change
// the episode view behind the schedule
export function* requestEpisode({
  payload: { patientId, episodeId },
}: ActionType<typeof patientsActions.requestEpisode>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const { data }: ItemEnrichedEpisode = yield* call(
      emergencyRoom.getEpisode,
      episodeId,
    )
    yield* put(
      patientsActions.requestEpisodeSuccess(patientId, episodeId, data),
    )
  } catch (error) {
    yield* put(
      patientsActions.requestEpisodeFailure(patientId, episodeId, error),
    )
  }
}

export function* requestEpisodesByIds({
  payload: { patientId, episodeIds },
}: ActionType<typeof patientsActions.requestEpisodesByIds>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    // @ts-expect-error [er-types] ER typings are what reflects the actual data received
    const { data }: ItemEpisodesById = yield* call(
      emergencyRoom.getPatientEpisodesByIds,
      Number(patientId),
      episodeIds,
    )
    yield* put(patientsActions.requestEpisodesByIdsSuccess(patientId, data))
  } catch (error) {
    yield* put(patientsActions.requestEpisodesByIdsFailure(patientId, error))
  }
}

export function* requestProfile({
  payload: { patientId },
}: ActionType<typeof patientsActions.requestProfile>) {
  if (patientId === null || patientId === undefined) {
    return
  }

  try {
    const accessToken = yield* select(selectAccessToken)
    const ScribeInstance = ScribeDeprecated.create(
      accessToken,
      Config.SCRIBE_DOMAIN + '/v1',
    )
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const scribeV1 = yield* call(initScribeClient)
    const scribeV2 = yield* call(initScribeV2Client)

    // @ts-expect-error use scribe v2 to get types
    const [
      scribeProfileResponse,
      emergencyRoomPatientProfile,
      scribeV2User,
      scribeFamily,
    ]: [
      any, // FIXME: use scribe V2
      Patient,
      ItemResponse<ScribeTypes.V2.User>,
      ScribeTypes.FamilyMember[],
    ] = yield* all([
      call(() => ScribeInstance.getUser(patientId)),
      call(emergencyRoom.getPatientProfile, patientId),
      call(scribeV2.getUser, patientId),
      call(scribeV1.getFamilyMembers, patientId),
    ])

    // FIXME: Rewrite to build profile by explicitly accessing each attribute needed for profile
    // directly from the response
    const mergedProfile = mergePatientProfile(
      scribeProfileResponse.data.attributes,
      emergencyRoomPatientProfile,
    )

    const profile = normalizeToScribeStyle(mergedProfile)

    yield* put(
      patientsActions.requestProfileSuccess({
        patientId,
        patientProfile: profile,
        eligible_services: scribeV2User.data.eligible_services,
        family: scribeFamily,
      }),
    )
  } catch (error) {
    yield* put(patientsActions.requestProfileFailure(patientId, error))
  }
}

export function* requestIdCard({
  payload: { patientId },
}: ActionType<typeof patientsActions.requestIdCard>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const patientIdCardResponse: ItemS3FileDownloadParameters = yield* call(
      () => emergencyRoom.downloadUrlPatientMedicalId(patientId.toString()),
    )
    yield* put(
      patientsActions.requestIdCardSuccess({
        patientId,
        patientIdCardUrl: patientIdCardResponse.data.url,
      }),
    )
  } catch (error) {
    yield* put(patientsActions.requestIdCardFailure({ patientId, error }))
  }
}

export function* requestLocation({
  payload: { patientId },
}: ActionType<typeof patientsActions.requestLocation>) {
  try {
    const accessToken = yield* select(selectAccessToken)
    const WaldoInstance = Waldo.create(accessToken, Config.WALDO_ENDPOINT)
    const patientLocationResponse = yield* call(() =>
      WaldoInstance.getUserLocation(patientId),
    )
    // @ts-expect-error FIXME: need to type Waldo service
    const location = patientLocationResponse && patientLocationResponse[0]
    const locationWithValidity = {
      ...location,
      is_valid: isValidLocation(
        `CA-${location.admin_level_1}`,
        location.country,
      ),
    }
    yield* put(
      patientsActions.requestLocationSuccess(locationWithValidity, patientId),
    )
  } catch (error) {
    yield* put(patientsActions.requestLocationFailure(error, patientId))
  }
}

const updateProfileRequestMap: Record<string, any> = {}
export function* updateProfile({
  payload: { patientId, patientProfile },
}: ActionType<typeof patientsActions.updateProfileRequest>) {
  if (!updateProfileRequestMap[patientId]) {
    updateProfileRequestMap[patientId] = true
    try {
      const profile: any = normalizeToCoreDataStyle(patientProfile)

      // The pin is handled in the updateMultipassPin saga and shouldn't be sent to ER
      delete profile.pin

      const er = yield* call(initEmergencyRoomClient)
      // @ts-expect-error [er-types] ER typings are what reflects the actual data received
      const updateProfileResponse: Patient = yield* call(
        er.updatePatient,
        patientId,
        profile,
      )

      const updatedProfile = normalizeToScribeStyle(updateProfileResponse)
      yield* put(
        patientsActions.updateProfileSuccess(
          updateProfileResponse.id,
          updatedProfile,
        ),
      )
    } catch (error) {
      yield* put(patientsActions.updateProfileFailure(patientId, error))
    }
    delete updateProfileRequestMap[patientId]
  }
}

const actionPatientId = (action: any) => action.payload.patientId

export default function* patientsSagas() {
  yield* all([
    takeEvery(PatientsTypes.REQUEST_EPISODES, requestEpisodes),
    takeEvery(
      PatientsTypes.REQUEST_MOST_RECENT_EPISODES,
      requestMostRecentEpisodes,
    ),
    takeEvery(PatientsTypes.REQUEST_EPISODE, requestEpisode),
    takeEvery(PatientsTypes.REQUEST_EPISODES_BY_IDS, requestEpisodesByIds),
    takeKeyedLatest(
      PatientsTypes.REQUEST_PROFILE,
      actionPatientId,
      requestProfile,
    ),
    takeKeyedLatest(
      PatientsTypes.REQUEST_ID_CARD,
      actionPatientId,
      requestIdCard,
    ),
    takeKeyedLatest(
      PatientsTypes.REQUEST_LOCATION,
      actionPatientId,
      requestLocation,
    ),
    takeEvery(PatientsTypes.UPDATE_PROFILE_REQUEST, updateProfile),
  ])
}
