import {
  DocumentSortOption,
  type MemberDocument,
} from '@dialogue/document-center'
import type { EmeraldTypes } from '@dialogue/services'
import type { SortOrder } from 'antd/es/table/interface'
import type { TFunction } from 'i18next'
import {
  type CountryCode,
  type PhoneNumber,
  parsePhoneNumberWithError,
} from 'libphonenumber-js'
import moment from 'moment'

import { defaultCountry } from 'app/lib/data-models'

export const formatDocumentType = (
  t: TFunction,
  type: EmeraldTypes.Document['type'] | MemberDocument['type'],
): string =>
  t(`documents.documentTypes.${type}`, {
    defaultValue: t('documents.notAvailable'),
  })
export const formatDocumentDate = ({
  t,
  date,
  includeTime = false,
}: {
  t: TFunction
  date?: string
  includeTime?: boolean
}): string => {
  let format = 'YYYY-MM-DD'
  if (includeTime) {
    format += ' HH:mm:ss'
  }
  if (date) return moment(date).format(format)
  return t('documents.notAvailable')
}

export const extractExtension = (fileName: string): string | undefined => {
  const re = /(?:\.([^.]+))?$/
  return re.exec(fileName)?.[1]
}

export const getCreatedAtSortByType = (order: SortOrder): DocumentSortOption =>
  order === 'ascend'
    ? DocumentSortOption.created_atasc
    : DocumentSortOption.created_atdesc

export const parsePhoneNumber = (raw_number: string): PhoneNumber | null => {
  try {
    return parsePhoneNumberWithError(raw_number, {
      defaultCountry: defaultCountry as CountryCode,
      extract: false, // use stricter matching
    })
  } catch (e) {
    console.error(`Could not parse phone number. Error: ${e}`)
    return null
  }
}

export const extractPhoneNumberAreaCode = (
  raw_number: string,
): string | null => {
  const phoneNumber = parsePhoneNumber(raw_number)
  return phoneNumber !== null ? phoneNumber.nationalNumber.slice(0, 3) : null
}

export const extractPhoneNumberMinusAreaCode = (
  raw_number: string,
): string | null => {
  const phoneNumber = parsePhoneNumber(raw_number)
  return phoneNumber !== null ? phoneNumber.nationalNumber.slice(-7) : null
}

export const safeJoin = <T>(arr: T[], separator: string): string => {
  return arr.filter(Boolean).join(separator)
}
