import { call, put, all } from 'typed-redux-saga/macro'

import { timekeeperProviderActions } from 'app/redux/timekeeper/providers'
import { takeKeyedLatest } from 'app/sagas/utils'

import { getProvidersApi } from './utils'

export function* getProvider(
  action: ReturnType<typeof timekeeperProviderActions.get>,
) {
  const { providerId } = action.payload
  try {
    const api = yield* call(getProvidersApi)
    const { data } = yield* call(api.getById, providerId)
    yield* put(
      timekeeperProviderActions.received({
        providerId: providerId,
        data: data.data,
      }),
    )
  } catch (e) {
    yield* put(
      timekeeperProviderActions.errored({ providerId: providerId, error: e }),
    )
  }
}

export default function* timekeeperProviderSagas() {
  yield* all([
    takeKeyedLatest(
      timekeeperProviderActions.get,
      (action) => action.payload.providerId,
      getProvider,
    ),
  ])
}
