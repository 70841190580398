import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Icons } from 'app/images'

const StyledLink = styled(Link)`
  align-items: center;
  flex: 0 0 auto;

  .ant-typography {
    margin-left: 4px;
  }

  margin-left: 6px;
`

type TaskLinkProps = {
  label: string
  to: string
  ddActionName: string
}

export const TaskLink = ({ label, to, ddActionName }: TaskLinkProps) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={<span data-dd-privacy="allow">{label}</span>}>
      <StyledLink
        to={to}
        data-dd-privacy="allow"
        data-dd-action-name={ddActionName}
      >
        <Icons.Link css={'margin-right: 4px'} />
        {t('tasks.navigation.view')}
      </StyledLink>
    </Tooltip>
  )
}
