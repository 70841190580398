// @ts-strict-ignore
import { useEffect } from 'react'

import { notification } from 'antd'
import { Navigate, useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { requestEpisodes } from 'app/redux/patients/actions'
import { selectPatientEpisodes } from 'app/redux/patients/selectors'
import routes from 'app/services/routes'

export const EpisodeRedirect = () => {
  const { patientId } = useParams<'patientId'>()

  const dispatch = useAppDispatch()
  const status = useAppSelector(
    (state) => state.patients.episodesStatuses[patientId],
  )
  const latestEpisode = useAppSelector(
    (state) => selectPatientEpisodes(state, Number(patientId))?.[0],
  )

  const hasFetched = status?.fetching === false

  useEffect(() => {
    dispatch(requestEpisodes(Number(patientId)))
  }, [patientId, dispatch])

  useEffect(() => {
    if (hasFetched && !latestEpisode) {
      notification.warn({
        message:
          'OOPS, no episodes were found for the user. Please contact support.',
      })
    }
  }, [latestEpisode, hasFetched])

  if (!hasFetched) {
    return null
  }

  const redirectTo = latestEpisode
    ? `../${latestEpisode.patient_id}/${latestEpisode.id}`
    : routes.dashboard()

  return <Navigate to={redirectTo} relative="path" />
}
