import {
  forwardRef,
  type ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react'

import type { MemberDocument } from '@dialogue/document-center'
import { Alert, type List } from 'antd'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'

import { DocumentSelectModal } from 'app/components/documents/document-select-modal'
import { MultiSelectList } from 'app/components/documents/multi-select-list'
import { useGetMemberDocumentsQuery } from 'app/redux/api/document-center/member-documents'

const StyledMultiSelectList = styled(MultiSelectList)`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`

export interface MemberDocumentsMultiSelectProps
  extends React.ComponentProps<typeof List> {
  value?: string[]
  onChange?: (newValue: string[]) => void
  onDocumentClick?: (id: string, document: MemberDocument) => void
  memberId: number
  emptyText?: ReactNode
  allowEdit?: boolean
}

export interface ForwardRefProps {
  openModal: () => void
}

export const MemberDocumentsMultiSelect = forwardRef<
  ForwardRefProps,
  MemberDocumentsMultiSelectProps
>(
  (
    { value = [], onChange, memberId, onDocumentClick, emptyText, ...rest },
    ref,
  ) => {
    const { t } = useTranslation()

    const {
      data: documents = [],
      isLoading,
      isError,
    } = useGetMemberDocumentsQuery({ memberId }, { skip: !memberId })
    const attachedDocuments = documents.filter((document) =>
      value.includes(document.id),
    )
    const [isModalOpen, setIsModalOpen] = useState(false)

    // Enables modal to be opened from parent component
    useImperativeHandle(ref, () => ({
      openModal: () => setIsModalOpen(true),
    }))

    const handleRemoveDocument = useCallback(
      (removedId: string) => onChange?.(value.filter((id) => id !== removedId)),
      [onChange, value],
    )

    const handleCloseModal = useCallback(() => {
      setIsModalOpen(false)
    }, [setIsModalOpen])

    const handleSelect = useCallback(
      (selectedDocumentId: string) => {
        const currentDocuments = value.filter((id) => id !== selectedDocumentId)
        onChange?.([...currentDocuments, selectedDocumentId])
        setIsModalOpen(false)
      },
      [onChange, value],
    )

    return (
      <>
        {isError && (
          <Alert
            type={'error'}
            message={t('documents.errorFetchingDocuments')}
          />
        )}
        <StyledMultiSelectList
          documents={attachedDocuments}
          loading={isLoading}
          onCancel={handleRemoveDocument}
          onClick={onDocumentClick}
          emptyText={emptyText}
          {...rest}
        />
        <DocumentSelectModal
          open={isModalOpen}
          onSelect={handleSelect}
          onCancel={handleCloseModal}
          documents={documents}
          loading={isLoading}
        />
      </>
    )
  },
)
