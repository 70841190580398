import type {
  AllProviderMatchingAttributes,
  ItemAllProviderMatchingAttributes,
  ItemProvider,
  Provider,
  ProviderMatchingAttributes,
} from '@dialogue/timekeeper'
import { createApi } from '@reduxjs/toolkit/query/react'

import { getDynamicBaseUrl, API_NAMES } from './utils'

export enum Attributes {
  GENDER = 'gender',
  CULTURAL_BACKGROUNDS = 'cultural_backgrounds',
  ADDITIONAL_INTERESTS = 'additional_interests',
  OTHER_INTERESTS = 'other_interests',
}

export interface GetProviderRequest {
  providerId: string
}

export interface PatchProviderRequest extends GetProviderRequest {
  payload: ProviderMatchingAttributes
}

export enum Tags {
  Attribute = 'Attribute',
  Provider = 'Provider',
}

export const timekeeperApi = createApi({
  tagTypes: Object.values(Tags),
  reducerPath: 'timekeeperApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.TIMEKEEPER),
  endpoints: (builder) => ({
    getProvider: builder.query<Provider, GetProviderRequest>({
      query: ({ providerId }) => ({
        url: `/providers/${providerId}`,
      }),
      transformResponse: (response: ItemProvider) => response.data,
      providesTags: (_result, _error, { providerId }) => [
        { type: Tags.Provider, id: providerId },
      ],
    }),
    updateProvider: builder.mutation<Provider, PatchProviderRequest>({
      query: ({ providerId, payload }) => ({
        url: `/providers/${providerId}`,
        method: 'PATCH',
        body: payload,
      }),
      transformResponse: (response: ItemProvider) => response.data,
      invalidatesTags: (_result, _error, { providerId }) => [
        { type: Tags.Provider, id: providerId },
      ],
    }),
    getBaseProviderAttributes: builder.query<AllProviderMatchingAttributes, {}>(
      {
        query: () => '/providers/attributes',
        providesTags: [Tags.Attribute],
        transformResponse: (response: ItemAllProviderMatchingAttributes) =>
          response.data,
      },
    ),
  }),
})

export const {
  useGetProviderQuery,
  useUpdateProviderMutation,
  useGetBaseProviderAttributesQuery,
} = timekeeperApi
