import type { ReduxState } from 'app/redux'

export const selectAccessToken = (state: ReduxState) =>
  state.authentification.accessToken

export const selectUserId = (state: ReduxState) =>
  state.authentification.profile?.id || null

export const selectUserProfile = (state: ReduxState) =>
  state.authentification.profile

/**
 * This selector checks whether the user should have a restricted view
 * based on the presence of any of the following auth roles:
 * - `MH Network Provider`
 */
export const selectUserHasRestrictedView = (state: ReduxState) => {
  const userRoles = state.authentification.userRoles
  return userRoles?.includes('MH Network Provider') ?? false
}

export const selectPractitionerIdFromMmId = (
  state: ReduxState,
  mmId: string,
) => {
  return state.practitioners.mmIdMap[mmId]
}

export const selectIsAuthenticated = (state: ReduxState) =>
  state.authentification.isAuthenticated

export const selectIsRestrictedPractitioner = (state: ReduxState) =>
  state.authentification.userRoles?.includes('restricted-practitioner') ?? false

export const selectHasPermissions = (
  state: ReduxState,
  permissions: string[],
) =>
  permissions.every((perm) =>
    state.authentification.userPermissions?.includes(perm),
  )

export const selectHasAnyPermissions = (
  state: ReduxState,
  permissions: string[],
) =>
  permissions.some((perm) =>
    state.authentification.userPermissions?.includes(perm),
  )

export const selectMainSpecialization = (state: ReduxState) => {
  const profile = selectUserProfile(state)
  return profile?.mainSpecialization
}
