import { notification } from 'antd'
import { call, put, all, takeLatest } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import { timekeeperAppointmentsActions } from 'app/redux/timekeeper/appointments'

import { getAppointmentsApi, getProvidersApi } from './utils'

export function* getProviderAppointments({
  payload: { providerId, startDate, endDate },
}: ReturnType<typeof timekeeperAppointmentsActions.getForProvider>) {
  try {
    const api = yield* call(getProvidersApi)
    const {
      data: { data: appointments },
    } = yield* call(
      api.getForProvider,
      providerId,
      startDate.toISOString(),
      endDate.toISOString(),
    )
    yield* put(timekeeperAppointmentsActions.receivedAll({ appointments }))
  } catch (e) {
    yield* put(timekeeperAppointmentsActions.erroredReceiving({ error: e }))
  }
}

export function* createAppointment({
  payload,
}: ReturnType<typeof timekeeperAppointmentsActions.create>) {
  try {
    const api = yield* call(getAppointmentsApi)
    const {
      data: { data: appointment },
    } = yield* call(api.create, payload.newAppointment)

    yield* put(
      timekeeperAppointmentsActions.created({
        appointment,
      }),
    )
    yield* call(notification.success, {
      message: i18n.t('schedule.createAppointmentSuccess'),
    })
  } catch (e) {
    yield* put(timekeeperAppointmentsActions.erroredCreating(e))

    yield* call(notification.error, {
      message: i18n.t('schedule.error.createAppointment'),
      description: String(e),
    })
  }
}

export default function* appointmentSagas() {
  yield* all([
    takeLatest(
      timekeeperAppointmentsActions.getForProvider,
      getProviderAppointments,
    ),
    takeLatest(timekeeperAppointmentsActions.create, createAppointment),
  ])
}
