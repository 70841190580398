import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import moment from 'moment'
import { initReactI18next } from 'react-i18next'

import config from 'app/config'

const instance = i18n.createInstance()

instance
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    nonExplicitSupportedLngs: false,
    ns: ['translation', 'common', 'glossary', 'services-and-options'],
    defaultNS: 'translation',
    supportedLngs: ['en', 'fr', 'de'],
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [
        resourcesToBackend((lng, ns, clb) => {
          import(`./locales/${lng}.json`)
            .then((resources) => clb(null, resources[ns]))
            .catch((err) => clb(err, undefined))
        }),
        HttpBackend,
      ],
      backendOptions: [
        null,
        { loadPath: `${config.CMS_URL}/translations/{{ns}}/?locale={{lng}}` },
      ],
    },
  })

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'now',
    ss: 'now',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
  },
})

moment.locale(instance.resolvedLanguage)

export default instance
