// @ts-strict-ignore
import {
  Emerald,
  EmergencyRoom,
  MessageRetraction,
  Multipass,
  Scribe,
  Usher,
  EHH,
} from '@dialogue/services'
import { Client4 } from '@mattermost/client'
import { select } from 'typed-redux-saga/macro'

import config from 'app/config'
import { selectAccessToken } from 'app/redux/authentification/selectors'

export function* initUsherClient() {
  const accessToken = yield* select(selectAccessToken)
  return new Usher(accessToken, config.USHER_DOMAIN)
}

export function* initEmergencyRoomClient() {
  const accessToken = yield* select(selectAccessToken)
  return new EmergencyRoom(accessToken, config.EMERGENCY_ROOM_URL)
}

export function* initMMClientV4() {
  const accessToken = yield* select(selectAccessToken)
  const client = new Client4()
  client.setToken(accessToken)
  client.setUrl(config.MATTERMOST_API_URL)
  return client
}

export function* initMultipassClient() {
  const accessToken = yield* select(selectAccessToken)
  return new Multipass(accessToken, config.MULTIPASS_DOMAIN)
}

export function* initScribeClient() {
  const accessToken = yield* select(selectAccessToken)
  return new Scribe(accessToken, config.SCRIBE_DOMAIN)
}

export function* initScribeV2Client() {
  const accessToken = yield* select(selectAccessToken)
  return new Scribe.V2(accessToken, config.SCRIBE_DOMAIN)
}

export function* initMessageRetractionClient() {
  const accessToken = yield* select(selectAccessToken)
  return new MessageRetraction(accessToken, config.MESSAGE_RETRACTION_URL)
}

export function* initEmeraldClient() {
  const accessToken = yield* select(selectAccessToken)
  return new Emerald(accessToken, config.EMERALD_API_URL)
}

export function* initEmeraldV2Client() {
  const accessToken = yield* select(selectAccessToken)
  return new Emerald.V2(accessToken, config.EMERALD_API_URL)
}

export function* initEHHClient() {
  const accessToken = yield* select(selectAccessToken)
  return new EHH(accessToken, config.ECOUCH_URL)
}
