import { useCallback, useState, type ChangeEvent } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { baseColors } from '@dialogue/basics'
import type { MemberDocument } from '@dialogue/document-center'
import { Alert, Empty, Input, List, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { normalizeString } from 'app/lib/helpers'

const StyledModal = styled(Modal)`
  .ant-modal-header {
    border: none;
    padding-bottom: 0;
  }

  .ant-modal-title {
    font-size: 22px;
  }
`

const StyledSearchWrapper = styled.div`
  margin: 1.6rem 2.4rem 0;
`

const StyledList = styled(List)`
  max-height: 600px;
  min-height: 300px;
  width: 100%;
  padding: 0 2.4rem 1rem;
  overflow: auto;
`

const StyledItem = styled(List.Item)`
  cursor: pointer;
  padding-left: 4px;
  &:hover {
    background: ${baseColors.smokeLight};
  }
`

interface DocumentItemProps {
  document: MemberDocument
  onClick: (id: string) => void
}

const DocumentItem = ({ document, onClick }: DocumentItemProps) => {
  const { name, id } = document

  const handleClick = useCallback(() => onClick(id), [id, onClick])

  return (
    <StyledItem
      onClick={handleClick}
      data-cy="document-item"
      data-dd-action-name="document-picker:select:option"
    >
      <Typography.Text strong>{name}</Typography.Text>
    </StyledItem>
  )
}

interface DocumentListProps {
  loading: boolean
  documents: MemberDocument[]
  onSelect: (id: string) => void
}

const DocumentList = ({ documents, onSelect, loading }: DocumentListProps) => {
  return (
    <StyledList loading={loading}>
      {documents.map((document) => (
        <DocumentItem
          document={document}
          key={document.id}
          onClick={onSelect}
        />
      ))}
      {!documents.length && <Empty description={false} />}
    </StyledList>
  )
}

interface DocumentSelectModalProps {
  open: boolean
  loading: boolean
  documents?: MemberDocument[]
  onSelect: (id: string) => void
  onCancel: () => void
  hasErrorFetching?: boolean
}

export const DocumentSelectModal = ({
  open,
  loading,
  documents = [],
  onSelect,
  onCancel,
  hasErrorFetching,
}: DocumentSelectModalProps) => {
  const { t } = useTranslation()

  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleSearch = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(target?.value)
    },
    [],
  )

  const filteredDocuments = documents.filter((document) =>
    normalizeString(document.name).includes(normalizeString(searchTerm)),
  )

  return (
    <StyledModal
      open={open}
      onCancel={onCancel}
      footer={false}
      title={t('documents.actions.selectDocument')}
    >
      <StyledSearchWrapper>
        <Input
          allowClear
          autoFocus
          placeholder="Search"
          onChange={handleSearch}
          addonBefore={<SearchOutlined />}
          value={searchTerm}
          css={`
            padding-bottom: 10px;
          `}
        />
      </StyledSearchWrapper>
      {hasErrorFetching && (
        <Alert
          message={t('documents.errorFetchingDocuments')}
          type="error"
          data-dd-privacy="allow"
        />
      )}
      <DocumentList
        documents={filteredDocuments}
        onSelect={onSelect}
        loading={loading}
      />
    </StyledModal>
  )
}
