import { type MouseEvent, useCallback } from 'react'

import { CloseCircleOutlined, ExportOutlined } from '@ant-design/icons'
import { IncomingFaxDocumentStatus } from '@dialogue/document-center'
import { Form, Tooltip } from 'antd'
import type { FormInstance } from 'antd/es/form/Form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { EpisodePicker } from 'app/components/episode-picker'
import { MemberPicker } from 'app/components/member-picker'
import type { ViewerIncomingFaxDocument } from 'app/redux/documents/viewer'
import deviceInfo from 'app/services/device-info'
import routes from 'app/services/routes'

import { FormContainer, HeaderLine, LineItem } from '../common'

interface Props {
  document: ViewerIncomingFaxDocument
  form: FormInstance
  readonly?: boolean
  onUnassignMember?: () => void
}

export const MemberProperties = ({
  document,
  form,
  readonly = false,
  onUnassignMember,
}: Props) => {
  const { t } = useTranslation()

  const memberIdFieldValue = Form.useWatch(['shared', 'memberId'], form)
  const episodeIdFieldValue = Form.useWatch(['document', 'episodeId'], form)

  const hasEpisodeLink = memberIdFieldValue && episodeIdFieldValue
  const episodeRoute = document.member_document?.post_id
    ? routes.channelPost(
        memberIdFieldValue,
        episodeIdFieldValue,
        document.member_document?.post_id,
      )
    : routes.channel(memberIdFieldValue, episodeIdFieldValue)

  const stopPropagation = useCallback(
    (e: MouseEvent<HTMLElement>) => e.stopPropagation(),
    [],
  )

  return (
    <>
      <HeaderLine title={t('documents.sections.member')} />
      <div
        css={`
          display: flex;
          flex-direction: column;
          gap: 8px;
        `}
      >
        <LineItem
          label=""
          value={
            <FormContainer>
              <Form.Item noStyle name={['shared', 'memberId']}>
                <MemberPicker
                  $extendedInfo
                  defaultId={memberIdFieldValue}
                  data-cy="fax-member-id-field"
                  variant="dobEmail"
                  // disabled when there is already a member assigned to the document, need to unassign first
                  disabled={readonly || !!document.member_document?.id}
                />
              </Form.Item>
              {!!onUnassignMember && (
                <CloseCircleOutlined
                  onClick={onUnassignMember}
                  data-testid="unassign-member-button"
                />
              )}
            </FormContainer>
          }
        />

        {document.status !== IncomingFaxDocumentStatus.received && (
          <LineItem
            label={t('documents.sections.episode')}
            value={
              <FormContainer>
                <Tooltip
                  title={
                    document.member_document?.post_id
                      ? t('documents.details.episodeDisabledByPostId')
                      : ''
                  }
                >
                  <Form.Item noStyle name={['document', 'episodeId']}>
                    <EpisodePicker
                      memberId={memberIdFieldValue}
                      fallbackTitle={t('episodes.missingTitle')}
                      placeholder={t('documents.details.episodePlaceholder')}
                      disabled={readonly}
                    />
                  </Form.Item>
                </Tooltip>
                {hasEpisodeLink && (
                  <Link
                    to={episodeRoute}
                    target={deviceInfo.isElectron() ? undefined : '_blank'}
                    data-testid="episode-link"
                    css={`
                      && {
                        padding: 0;
                        white-space: normal;
                        text-align: left;
                      }
                    `}
                    onClick={stopPropagation} // Prevents current tab from navigating.
                  >
                    <ExportOutlined />
                  </Link>
                )}
              </FormContainer>
            }
          />
        )}
      </div>
    </>
  )
}
