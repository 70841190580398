import type { CommentMention } from '@dialogue/coredata'

import type { MentionsInputRef } from 'app/containers/chat/chat-input/mentions'

export const extractMentions = (
  message: string,
  mentionsRef: MentionsInputRef | null,
): CommentMention[] => {
  // match usernames of letters/numbers and periods, followed by a word break
  const tags = message.match(/@[A-z\d.]+\b/gm) || []
  return tags.reduce((acc: CommentMention[], tag: string) => {
    const handle = tag.slice(1)
    const appId = mentionsRef?.getAppIdFromHandle(handle)
    if (!appId || acc.some((u) => u.key === handle)) return acc
    return [...acc, { key: handle, provider_id: appId }]
  }, [])
}

// Grab the first letter of each name, and join them together
export const getInitials = (name: string) =>
  name
    // Split on any space character, or hyphen
    .split(/[\s\u00A0-]/g)
    .map((n: string) => n[0])
    .join('')
