import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { TextAreaHistory } from 'app/components/history/text-area-history'
import {
  EditableTextArea,
  ProtectedTextArea,
  type EditingOptionsType,
  type EditableInputType,
} from 'app/components/protected-inputs/protected-text-area'
import { DescriptionTemplateSelect } from 'app/containers/tasks/description-template-select'
import { useTaskDescriptionHistory } from 'app/redux/tasks/hooks'

interface VersionedDescriptionInputProps {
  taskId: string
  value?: string
  onChange?: (value: string) => void
  disabled?: boolean
}

export const VersionedDescriptionInput = ({
  taskId,
  value,
  onChange,
  disabled,
}: VersionedDescriptionInputProps) => {
  const { t } = useTranslation()

  const { isLoading, descriptionHistory, isError } =
    useTaskDescriptionHistory(taskId)
  const renderEditingOptions = useCallback<EditingOptionsType>(
    (props) => {
      return <DescriptionTemplateSelect disabled={disabled} {...props} />
    },
    [disabled],
  )

  const renderEditableInput = useCallback<EditableInputType>(
    (props) => {
      return (
        <EditableTextArea
          {...props}
          editingOptions={renderEditingOptions}
          data-testid={'task-description-field'}
          placeholder={t('tasks.properties.placeholders.description')}
        />
      )
    },
    [renderEditingOptions, t],
  )

  return (
    <>
      <ProtectedTextArea
        fieldName={t('tasks.properties.labels.description')}
        value={value}
        onChange={onChange}
        disabled={disabled}
        renderEditableInput={renderEditableInput}
        placeholder={t('tasks.properties.placeholders.noDescription')}
      />
      <TextAreaHistory
        history={descriptionHistory}
        errorMessage={
          isError ? t('tasks.error.fetchingDescriptionHistory') : null
        }
        isLoading={isLoading}
        placeholder={t('tasks.properties.placeholders.noDescription')}
      />
    </>
  )
}
