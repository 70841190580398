import { notification } from 'antd'
import { takeEvery, call, put, select, all } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import { multipassActions } from 'app/redux/multipass'
import { type patientsActions, PatientsTypes } from 'app/redux/patients'
import { selectPatientAuthId } from 'app/redux/patients/selectors'

import { initMultipassClient } from './utils'

export function* updateMultipassPin({
  payload: { patientId, patientProfile },
}: ReturnType<typeof patientsActions.updateProfileRequest>) {
  const { pin } = patientProfile

  if (!pin) return

  // Do we need to remove PIN from the data? It will be submitted to CD with profile data if we don't
  try {
    const multipass = yield* call(initMultipassClient)
    const auth_id = yield* select(selectPatientAuthId, patientId)

    yield* call(multipass.updateUserPin, { auth_id, pin })
    yield* put(multipassActions.updateSuccess({ patientId }))
    yield* call(notification.info, {
      message: i18n.t('patientProfile.securityPinNotifications.success'),
    })
  } catch (error) {
    yield* put(multipassActions.updateFailure({ patientId, error }))
    yield* call(notification.error, {
      message: i18n.t('patientProfile.securityPinNotifications.error'),
    })
  }
}

export function* authenticatePin({
  payload: { email, pin, patientId },
}: ReturnType<typeof multipassActions.authenticatePinRequest>) {
  try {
    const multipass = yield* call(initMultipassClient)

    const { is_authenticated: isAuthenticated } = yield* call(
      multipass.authenticatePin,
      { email, pin },
    )

    if (isAuthenticated) {
      yield* put(multipassActions.authenticatePinSuccess({ patientId }))
    } else {
      throw new Error('PIN is incorrect')
    }
  } catch (error) {
    yield* put(multipassActions.authenticatePinFailure({ patientId, error }))
  }
}

export function* getChallenges({
  payload: { patientId, auth_id },
}: ReturnType<typeof multipassActions.getChallengesRequest>) {
  try {
    const multipass = yield* call(initMultipassClient)

    const { challenges } = yield* call(multipass.getChallenges, { auth_id })

    if (challenges.length >= 1) {
      yield* put(
        multipassActions.getChallengesSuccess({ patientId, challenges }),
      )
    } else {
      yield* put(
        multipassActions.getChallengesFailure({
          patientId,
        }),
      )
    }
  } catch (error) {
    yield* put(multipassActions.getChallengesFailure({ patientId }))
  }
}

export default function* multipassSagas() {
  yield* all([
    takeEvery(PatientsTypes.UPDATE_PROFILE_REQUEST, updateMultipassPin),
    takeEvery(multipassActions.authenticatePinRequest, authenticatePin),
    takeEvery(multipassActions.getChallengesRequest, getChallenges),
  ])
}
