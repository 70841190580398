import { Avatar, Typography } from 'antd'
import styled from 'styled-components'

import { colors } from 'app/theme'

interface Props {
  picture?: string
  firstName: string
  lastName: string
  email: string
  showEmail?: boolean
}

export const BaseText = styled(Typography.Text)`
  margin-right: 2px;
  margin-left: 2px;
`

export const EmailText = styled(BaseText)`
  color: ${colors.textLight};
`

const StyledText = styled(Typography.Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.textLight}; // Ensure ellipsis text matches email text
`

const StyledAvatar = styled(Avatar)`
  margin-right: 2px;
`

export const PractitionerOptionLabel = ({
  picture,
  firstName,
  lastName,
  email,
  showEmail,
}: Props) => {
  return (
    <StyledText data-testid={'practitioner-option'}>
      <StyledAvatar src={picture} size="small" />
      <BaseText>
        {firstName} {lastName}
      </BaseText>
      {showEmail && <EmailText>{email}</EmailText>}
    </StyledText>
  )
}
