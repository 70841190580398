import type { Appointment as TkAppointment } from '@dialogue/timekeeper'
import { notification } from 'antd'
import { call, put, all, takeLatest } from 'typed-redux-saga/macro'

import i18n from 'app/i18n'
import { getAppointments } from 'app/redux/appointments'
import { schedulingAppointmentActions } from 'app/redux/scheduling/appointments'

import { getPatientAppointments } from '../appointments'
import { initEmeraldV2Client, takeKeyedLatest } from '../utils'

export function* createAppointment({
  payload,
}: ReturnType<typeof schedulingAppointmentActions.create>) {
  try {
    const emerald = yield* call(initEmeraldV2Client)

    const appointment = yield* call(
      emerald.createAppointment,
      payload.newAppointment,
    )

    yield* put(
      schedulingAppointmentActions.created({
        providerId: payload.newAppointment.provider_id,
        appointment,
        tkAppointment: appointment.tk_appointment as TkAppointment | null,
      }),
    )
    yield* call(notification.success, {
      message: i18n.t('schedule.createAppointmentSuccess'),
    })
    /*
      The provider is not returned with the appointments so
      the patient's appointments are refetched to get the new
      provider
    */
    yield* call(
      getPatientAppointments,
      getAppointments({ patientId: payload.newAppointment.patient_id }),
    )
  } catch (e) {
    yield* put(schedulingAppointmentActions.createFailed(e))
    notification.error({
      message: i18n.t('schedule.error.createAppointment'),
      description: String(e),
    })
  }
}

export function* updateAppointment(
  action: ReturnType<typeof schedulingAppointmentActions.update>,
) {
  const { appointmentId, updatedAppointment } = action.payload

  try {
    if (!updatedAppointment.patient_id) {
      throw new Error('Appointment is missing a patient ID')
    }

    const emerald = yield* call(initEmeraldV2Client)
    const appointment = yield* call(
      emerald.editAppointment,
      appointmentId,
      updatedAppointment,
    )

    yield* put(
      schedulingAppointmentActions.updated({
        providerId: updatedAppointment.provider_id,
        appointment,
        tkAppointment: appointment.tk_appointment as TkAppointment | null,
      }),
    )
    notification.success({
      message: i18n.t('schedule.updatedAppointmentSuccess'),
    })
  } catch (e) {
    yield* put(schedulingAppointmentActions.updateFailed({ error: e }))
    notification.error({
      message: i18n.t('schedule.error.updateAppointment'),
      description: String(e),
    })
  }
}

export default function* schedulingAppointmentSagas() {
  yield* all([
    takeLatest(schedulingAppointmentActions.create, createAppointment),
    takeKeyedLatest(
      schedulingAppointmentActions.update,
      (action) => action.payload.appointmentId,
      updateAppointment,
    ),
  ])
}
