import { development } from './development'
import type { Config } from './model'
import { prodUs } from './prod-us'
import { production } from './production'

type TAppEnv = 'development' | 'production' | 'prod-us'

const configs: Record<TAppEnv, Config> = {
  development,
  production,
  'prod-us': prodUs,
}

const appEnv = process.env.VITE_APP_ENV as TAppEnv

export const environment = appEnv || 'development'

const config = configs[environment]

export default config

if (environment === 'development') {
  const CP_CONFIG = {
    useEphemeralNotepad: (prNumber: number) => {
      config.NOTEPAD_URL = `https://notepad-pr-${prNumber}.dev.dialoguecorp.com`
      console.log(
        'App will now use an ephemeral notepad deployment for the following PR until reloaded:',
        `https://github.com/dialoguemd/notepad/pull/${prNumber}`,
      )
    },
    useEphemeralEmerald: (prNumber: number) => {
      config.EMERALD_API_URL = `https://emerald-pr-${prNumber}.dev.dialoguecorp.com`
      console.log(
        'App will now use an ephemeral emerald deployment for the following PR until reloaded:',
        `https://github.com/dialoguemd/emerald/pull/${prNumber}`,
      )
    },
    useEphemeralDocumentCenter: (prNumber: number) => {
      config.DOCUMENT_CENTER_URL = `https://document-center-pr-${prNumber}.dev.dialoguecorp.com`
      console.log(
        'App will now use an ephemeral document center deployment for the following PR until reloaded:',
        `https://github.com/dialoguemd/document-center/pull/${prNumber}`,
      )
    },
    useEphemeralTimekeeper: (prNumber: number) => {
      config.TIMEKEEPER_URL = `https://timekeeper-pr-${prNumber}.dev.dialoguecorp.com`
      console.log(
        'App will now use an ephemeral timekeeper deployment for the following PR until reloaded:',
        `https://github.com/dialoguemd/timekeeper/pull/${prNumber}`,
      )
    },
    useEphemeralER: (prNumber: number) => {
      config.EMERGENCY_ROOM_URL = `https://coredata-pr-${prNumber}.dev.dialoguecorp.com/v1`
      console.log(
        'App will now use an ephemeral coredata deployment for the following PR until reloaded:',
        `https://github.com/dialoguemd/coredata/pull/${prNumber}`,
      )
    },
  }
  // @ts-expect-error this object only exists on the window for
  // debugging/validation.
  window.CP_CONFIG = CP_CONFIG
}
