// @ts-strict-ignore
import type { EHHTypes } from '@dialogue/services'
import { createAction, createReducer } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

import type { iCBTModulesState } from './types'

export const INITIAL_STATE: iCBTModulesState = {
  patients: {},
  error: null,
  fetching: false,
}

export const getModules = createAction<{
  patientId: number | string
}>('@@icbt/GET_MODULES')

export const getModulesFailure = createAction<Error>(
  '@@icbt/GET_MODULES_FAILURE',
)

export const getModulesSuccess = createAction<EHHTypes.MemberProfileResponse>(
  '@@icbt/GET_MODULES_SUCCESS',
)

export const clearModules = createAction<{
  patientId: number | string
}>('@@icbt/CLEAR_MODULES')

export default createReducer(INITIAL_STATE, (builder) => {
  builder.addCase(getModules, (state) => {
    state.fetching = true
    state.error = null
  })

  builder.addCase(getModulesFailure, (state, action) => {
    state.error = action.payload
    state.fetching = false
  })

  builder.addCase(getModulesSuccess, (state, action) => {
    if (action.payload?.data?.profile?.member_id) {
      state.patients[action.payload.data.profile.member_id] = action.payload
    }
    state.fetching = false
    state.error = null
  })

  builder.addCase(clearModules, (state, action) => {
    delete state.patients[action.payload.patientId]
  })
})

export const selectiCBTModules = (
  state: ReduxState,
  patientId: string | number,
) => {
  return state.icbt?.patients[patientId]?.data
}
