import type { ProviderAvailabilityConstraint } from '@dialogue/timekeeper'
import { createSelector, createSlice, type Draft } from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'

import { isIncludedInRange, type ScheduleRange } from './schedule/common'
import {
  filterGroupScheduleActions,
  selectFilterGroupProviderIds,
} from './schedule/filter-group-schedule'
import { providerScheduleActions } from './schedule/provider-schedule'

interface ProviderState {
  data: ProviderAvailabilityConstraint[] | null
  fetching: boolean
  error: Error | null
}

interface State {
  [providerId: number]: ProviderState
}

const INITIAL_STATE: State = {}

const makeProviderState = (): ProviderState => {
  return {
    data: null,
    fetching: false,
    error: null,
  }
}

const getOrInitProviderState = (
  state: Draft<State> | State,
  providerId: number,
): ProviderState => {
  return (state[providerId] = state[providerId] || makeProviderState())
}

/**
 * Selectively set provider's PACs for a loaded range.
 *
 * Will keep any PACs that were previously loaded but not in range,
 * and conversely will clear any PACs that were in range but are no longer
 * returned by API (e.g. because they were deleted).
 */
const setForRange = (
  state: Draft<State>,
  providerId: number,
  pacs: ProviderAvailabilityConstraint[],
  range: ScheduleRange,
) => {
  const providerState = getOrInitProviderState(state, providerId)
  providerState.data = providerState.data || []
  providerState.data = providerState.data.filter(
    (pac) =>
      !isIncludedInRange({ start: pac.start_at, end: pac.end_at }, range),
  )
  providerState.data = [...providerState.data, ...pacs]
}

export const { actions: pacActions, reducer } = createSlice({
  name: '@@timekeeper/pacs',
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(providerScheduleActions.received, (state, action) => {
      setForRange(
        state,
        action.payload.schedule.provider_id,
        action.payload.schedule.availability_constraints,
        action.payload.range,
      )
    })
    builder.addCase(filterGroupScheduleActions.received, (state, action) => {
      for (const schedule of action.payload.schedules) {
        setForRange(
          state,
          schedule.provider_id,
          schedule.availability_constraints,
          action.payload.range,
        )
      }
    })
  },
})

export default reducer

export const selectPacsForProvider = (state: ReduxState, providerId: number) =>
  state.timekeeper.pacs[providerId]?.data

export const selectPacsForFilterGroup = createSelector(
  (state: ReduxState) => state.timekeeper.pacs,
  selectFilterGroupProviderIds,
  (pacs, providerIds) =>
    providerIds.reduce(
      (filterGroupPacs: ProviderAvailabilityConstraint[], providerId) => {
        const providerPacs = pacs[providerId]?.data

        if (!providerPacs) {
          return filterGroupPacs
        }

        return [...filterGroupPacs, ...providerPacs]
      },
      [],
    ),
)
