import { useEffect, useState } from 'react'

import { WarningOutlined } from '@ant-design/icons'
import { Modal, Space, Typography } from 'antd'

export const OfflineModal = () => {
  const [isConnected, setIsConnected] = useState(window.navigator.onLine)

  useEffect(() => {
    const handleOnline = () => setIsConnected(true)
    const handleOffline = () => setIsConnected(false)

    window.addEventListener('online', handleOnline, { passive: true })
    window.addEventListener('offline', handleOffline, { passive: true })

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])

  return (
    <Modal
      open={!isConnected}
      closable={false}
      footer={null}
      style={{ top: 4 }}
      width={700}
    >
      <Space
        css={`
          padding: 8px 4px;
          width: 100%;
          justify-content: center;
        `}
      >
        <WarningOutlined style={{ fontSize: 24 }} />
        <Typography.Text>
          Your computer seems to be offline. Please check your internet
          connection.
        </Typography.Text>
      </Space>
    </Modal>
  )
}

export default OfflineModal
