import { Button, Space, Typography } from 'antd'
import { useTranslation } from 'react-i18next'

import {
  DetailLine,
  HeaderLine,
  PanelContent,
  type OutgoingFaxMetadata,
} from './common'
import { generateKeyFromLabel } from './utils'

interface Props {
  onCancel: () => void
  onConfirm: () => void
  outgoingFaxMetadata: OutgoingFaxMetadata | null
}

export const AnticipatedOutgoingFaxPanel = ({
  onCancel,
  onConfirm,
  outgoingFaxMetadata,
}: Props) => {
  const { t } = useTranslation()

  return (
    <PanelContent size={4} direction="vertical">
      <Typography.Text strong>
        {t('documents.faxDetails.confirmFax')}
      </Typography.Text>
      <HeaderLine title={t('documents.faxDetails.faxPackageProperties')} />
      {outgoingFaxMetadata?.fax_package_properties.map(({ label, value }) => (
        <DetailLine
          key={generateKeyFromLabel(label)}
          label={label}
          value={<Typography.Text>{value}</Typography.Text>}
        />
      ))}
      <HeaderLine title={t('documents.faxDetails.documentProperties')} />
      {outgoingFaxMetadata?.document_properties.map(({ label, value }) => (
        <DetailLine
          key={generateKeyFromLabel(label)}
          label={label}
          value={<Typography.Text>{value}</Typography.Text>}
        />
      ))}
      <Space style={{ float: 'right' }}>
        <Button onClick={onCancel} data-testid="send-fax-back-button">
          {t('documents.actions.back')}
        </Button>
        <Button
          type="primary"
          onClick={onConfirm}
          data-testid="send-fax-confirm-button"
        >
          {t('documents.faxDetails.sendFax')}
        </Button>
      </Space>
    </PanelContent>
  )
}
