import { all, call, put, takeLatest } from 'typed-redux-saga/macro'
import type { ActionType } from 'typesafe-actions'

import {
  getModulesSuccess,
  getModulesFailure,
  getModules,
} from 'app/redux/icbt'

import { initEHHClient } from './utils'

export function* loadiCBTModules({
  payload: { patientId },
}: ActionType<typeof getModules>) {
  try {
    const ehh = yield* call(initEHHClient)
    const response = yield* call(ehh.getMemberProfile, patientId)

    yield* put(getModulesSuccess(response))
  } catch (error: any) {
    yield* put(getModulesFailure(error))
  }
}

export default function* icbtSagas() {
  yield* all([takeLatest(getModules, loadiCBTModules)])
}
