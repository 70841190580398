import styled from 'styled-components'

import { colors } from 'app/theme'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 4px;
`

export const ToggleCompletedContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex: 1;
  justify-content: flex-start;
  margin-bottom: 4px;
  margin-top: 4px;
  text-align: right;
  padding-left: 14px;
  div {
    color: ${colors.primary} !important;
  }
  div:hover {
    color: ${colors.primaryLight} !important;
  }
`
