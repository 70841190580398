import { Templates } from '@dialogue/services'
import { all, call, put, select, takeEvery } from 'typed-redux-saga/macro'

import Config from 'app/config'
import { selectAccessToken } from 'app/redux/authentification/selectors'
import * as TemplatesActions from 'app/redux/templates/actions'
import { TemplatesActionTypes } from 'app/redux/templates/types'

export function* requestTemplates() {
  try {
    const accessToken = yield* select(selectAccessToken)
    const templatesInstance = Templates.create(
      accessToken,
      Config.TEMPLATES_URL,
    )
    const templatesResponse = yield* call(templatesInstance.getTemplates)
    //@ts-expect-error services doesn't return typed response for this
    yield* put(TemplatesActions.getTemplatesSuccess(templatesResponse.data))
  } catch (error) {
    yield* put(TemplatesActions.getTemplatesFailure(error))
  }
}

export default function* templatesSagas() {
  yield* all([
    takeEvery(TemplatesActionTypes.GET_TEMPLATES_REQUEST, requestTemplates),
  ])
}
