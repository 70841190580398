// @ts-strict-ignore
import { produce } from 'immer'
import type { ActionType } from 'typesafe-actions'

import type * as actions from './actions'
import { TemplatesActionTypes, type TemplatesState } from './types'

export type TemplatesActions = ActionType<typeof actions>

export const INITIAL_STATE: TemplatesState = {
  templates: {},
  fetching: false,
  error: null,
}

export const reducer = (state = INITIAL_STATE, action: TemplatesActions) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TemplatesActionTypes.GET_TEMPLATES_REQUEST:
        draft.fetching = true
        draft.error = null
        break
      case TemplatesActionTypes.GET_TEMPLATES_SUCCESS:
        draft.fetching = false
        draft.templates = action.payload.templates
        break
      case TemplatesActionTypes.GET_TEMPLATES_FAILURE:
        draft.fetching = false
        draft.error = action.payload
        break
    }
  })

export default reducer
