/*
 * Used to map core data structures to scribe style keys
 */
const coreDataToScribeKeyMap: Record<string, string> = {
  communication_languages: 'communication_languages',
  date_of_birth: 'birthdate',
  default_pharmacy: 'default_pharmacy',
  default_pharmacy_id: 'default_pharmacy_id',
  diagnostic_orders: 'diagnostic_orders',
  family_name: 'last_name',
  familyId: 'family_id',
  givenName: 'first_name',
  given_name: 'first_name',
  has_consented: 'has_consented',
  healthcardUrl: 'id_card',
  id_card: 'id_card',
  medication_orders: 'medication_orders',
  phone_number: 'phone_number',
  resides_in: 'residence_province',
  user_type: 'user_type',
  preferred_name: 'preferred_name',
  gender_identity_description: 'gender_identity_description',
  pronouns: 'pronouns',
}

const scribeToCoreDataKeyMap: Record<string, string> = Object.entries(
  coreDataToScribeKeyMap,
).reduce(
  (pv, [coredataKey, scribeKey]) => {
    pv[scribeKey] = coredataKey
    return pv
  },
  {} as Record<string, string>,
)

const MAX_DEPTH = 4

/**
 * Recursive normalization of keys
 *
 * @param   {object} data to normalize
 * @param   {number} depth max recursion level
 * @param   {object} keyMap used for key normalization
 * @returns {object} data with the same structure as the object supplied, keys normalized
 */
export const bruteForceNormalize = (
  data: object,
  depth: number = 0,
  keyMap: Record<string, string> = coreDataToScribeKeyMap,
): object | undefined => {
  if (depth > MAX_DEPTH) return undefined
  if (Array.isArray(data))
    return data.map((e) => bruteForceNormalize(e, depth + 1, keyMap))
  if (typeof data === 'object' && data !== null) {
    const result: Record<string, any> = {}
    Object.entries(data).forEach(
      (e) =>
        (result[keyMap[e[0]] || e[0]] = bruteForceNormalize(
          e[1],
          depth + 1,
          keyMap,
        )),
    )
    return result
  }
  return data
}

export const normalizeToScribeStyle = (data: object, depth?: number) =>
  bruteForceNormalize(data, depth, coreDataToScribeKeyMap)

export const normalizeToCoreDataStyle = (data: object, depth?: number) =>
  bruteForceNormalize(data, depth, scribeToCoreDataKeyMap)

export const mergePatientProfile = (
  scribePatientProfile: Record<string, any>, // FIXME. Add type in dialogue services
  emergencyRoomProfile: Record<string, any>,
) => {
  const combinedProfile: Record<string, any> = {}
  Object.keys({
    ...scribePatientProfile,
    ...emergencyRoomProfile,
  }).forEach((key) => {
    combinedProfile[key] =
      scribePatientProfile[key] || emergencyRoomProfile[key]
  })

  return combinedProfile
}
