// src/client/build/register.ts
var autoUpdateMode = "false";
var selfDestroying = "false";
var auto = autoUpdateMode === "true";
var autoDestroy = selfDestroying === "true";
function registerSW(options = {}) {
  const {
    immediate = false,
    onNeedRefresh,
    onOfflineReady,
    onRegistered,
    onRegisteredSW,
    onRegisterError
  } = options;
  let wb;
  let registerPromise;
  let sendSkipWaitingMessage;
  const updateServiceWorker = async (_reloadPage = true) => {
    await registerPromise;
    if (!auto) {
      await sendSkipWaitingMessage?.();
    }
  };
  async function register() {
    if ("serviceWorker" in navigator) {
      wb = await import("workbox-window").then(({ Workbox }) => {
        return new Workbox("./service-worker.js", { scope: "./", type: "classic" });
      }).catch((e) => {
        onRegisterError?.(e);
        return void 0;
      });
      if (!wb)
        return;
      sendSkipWaitingMessage = async () => {
        await wb?.messageSkipWaiting();
      };
      if (!autoDestroy) {
        if (auto) {
          wb.addEventListener("activated", (event) => {
            if (event.isUpdate || event.isExternal)
              window.location.reload();
          });
          wb.addEventListener("installed", (event) => {
            if (event.isUpdate === false) {
              onOfflineReady?.();
            }
          });
        } else {
          let onNeedRefreshCalled = false;
          const showSkipWaitingPrompt = (event) => {
            if (event && onNeedRefreshCalled && event.isExternal)
              window.location.reload();
            onNeedRefreshCalled = true;
            wb?.addEventListener("controlling", (event2) => {
              if (event2.isUpdate === true || event2.isExternal === true)
                window.location.reload();
            });
            onNeedRefresh?.();
          };
          wb.addEventListener("installed", (event) => {
            if (typeof event.isUpdate === "undefined") {
              if (typeof event.isExternal !== "undefined") {
                if (event.isExternal)
                  showSkipWaitingPrompt();
                else
                  !onNeedRefreshCalled && onOfflineReady?.();
              } else {
                if (event.isExternal)
                  window.location.reload();
                else
                  !onNeedRefreshCalled && onOfflineReady?.();
              }
            } else if (!event.isUpdate) {
              onOfflineReady?.();
            }
          });
          wb.addEventListener("waiting", showSkipWaitingPrompt);
        }
      }
      wb.register({ immediate }).then((r) => {
        if (onRegisteredSW)
          onRegisteredSW("./service-worker.js", r);
        else
          onRegistered?.(r);
      }).catch((e) => {
        onRegisterError?.(e);
      });
    }
  }
  registerPromise = register();
  return updateServiceWorker;
}
export {
  registerSW
};
