import {
  Configuration,
  DataSource,
  DataSourcesApiFactory,
} from '@dialogue/notepad-client'
import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro'

import config from 'app/config'
import { selectAccessToken } from 'app/redux/authentification/selectors'
import { locationsActions } from 'app/redux/locations'
import { MissingAccessTokenError } from 'app/sagas/utils/exceptions'

export function* initNotepadDataSourcesClient() {
  const accessToken = yield* select(selectAccessToken)
  if (!accessToken) {
    throw new MissingAccessTokenError()
  }
  return DataSourcesApiFactory(
    new Configuration({ accessToken, basePath: config.NOTEPAD_URL }),
  )
}

export function* getLocations() {
  try {
    const notepad = yield* call(initNotepadDataSourcesClient)
    const locations = yield* call(
      notepad.getDataSource,
      DataSource.DialogueLocations,
    )
    yield* put(locationsActions.receive(locations?.data?.data || []))
  } catch (e) {
    yield* put(locationsActions.error(e))
  }
}

export default function* locationsSagas() {
  yield* all([takeLatest(locationsActions.get, getLocations)])
}
