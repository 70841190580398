import {
  type ComponentProps,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { TemplateType } from '@dialogue/document-center'
import {
  Button,
  Form,
  Input,
  type InputProps,
  type InputRef,
  Radio,
  type RadioChangeEvent,
  Select,
  Space,
  Typography,
} from 'antd'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input/input'
import styled from 'styled-components'

import {
  PreferredPharmacy,
  usePreferredPharmacy,
} from 'app/components/preferred-pharmacy'
import { useGetTemplatesQuery } from 'app/redux/api/document-center/templates'
import { track } from 'app/services/snowplow-analytics'
import { colors } from 'app/theme'

const FormContainer = styled.div`
  width: 50%;
  min-width: 650px;
  padding: 64px;
  margin: 0 auto;

  .ant-form-item-label label {
    font-size: 16px;
    font-weight: 600;
  }
`

const StylizedHeader = styled(Typography.Title)`
  &&& {
    font-weight: 600;
    margin-bottom: 30px;
  }
`

export const FORM_NAME = 'send-fax-form'

type PhoneInputProps = ComponentProps<typeof PhoneInput>

export interface FaxDetailsProps {
  member_document_id: string
  member_id: number
  handleCancel: () => void
  handleSubmit: (data: SendFaxFormValues) => Promise<void>
}

export interface SendFaxFormValues {
  fax_number: string
  fax_recipient: string
  fax_cover_page: string
  fax_cover_page_custom_text?: string
}

export enum PharmacyType {
  preferred = 'preferred',
  custom = 'custom',
}

const DestinationsContainer = styled(Space)`
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 20px;
  background: ${colors.background};
  border: 1px solid;
  border-color: ${colors.border};
  border-radius: 4px;

  > .ant-space-item {
    width: 100%;
  }
`

const WrappedInput = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const setInputRef = useCallback(
    (instance: InputRef) => {
      /* istanbul ignore else */
      if (typeof ref === 'function') {
        ref(instance?.input)
      } else {
        // for completeness, but this shouldn't happen in current context
        ref!.current = instance?.input
      }
    },
    [ref],
  )
  return (
    <Input
      {...props}
      // phone-number-input requires a direct ref to the underlying html input component
      ref={setInputRef}
    />
  )
})

export const FaxDetailsForm = ({
  member_document_id,
  member_id,
  handleCancel,
  handleSubmit,
}: FaxDetailsProps) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [sendFaxFormStartTime, setSendFaxFormStartTime] = useState<Date | null>(
    null,
  )
  const [destination, setDestination] = useState(PharmacyType.preferred)
  const [isCustomTemplateSelected, setIsCustomTemplateSelected] =
    useState(false)
  const [isCoverPageSelectLoading, setIsCoverPageSelectLoading] =
    useState(false)

  const {
    pharmacy,
    fetching: fetchingPharmacy,
    error: errorFetchingPharmacy,
  } = usePreferredPharmacy(member_id)

  const isPreferredPharmacySelected = destination === PharmacyType.preferred
  const noPharmacySet = pharmacy === null
  const pharmacyHasNoFax =
    !fetchingPharmacy &&
    !errorFetchingPharmacy &&
    !noPharmacySet &&
    !pharmacy?.fax

  useEffect(() => {
    setDestination(
      noPharmacySet || pharmacyHasNoFax
        ? PharmacyType.custom
        : PharmacyType.preferred,
    )
  }, [noPharmacySet, pharmacyHasNoFax])

  useEffect(() => {
    // need to track the total time spent on sending a fax document, starting when the form is opened
    setSendFaxFormStartTime(new Date())
  }, [])

  const { data: templatesData } = useGetTemplatesQuery({
    templateType: TemplateType.fax_cover_page_templates,
  })

  const templateOptions = useMemo(
    () =>
      templatesData
        ? Object.values(templatesData).map((template) => ({
            label: template.name.replace('.pdf', ''),
            value: JSON.stringify(template),
          }))
        : [],
    [templatesData],
  )

  const handleSelectPreferredPharmacy = useCallback((e: RadioChangeEvent) => {
    setDestination(e.target.value)
  }, [])

  const filterTemplateForCustomText = useCallback(
    async (templateURL: string) => {
      try {
        setIsCoverPageSelectLoading(true)
        const { Core } = window
        let template = await Core.PDFNet.PDFDoc.createFromURL(templateURL)

        const field = await template.getField('outgoing_fax_template_text')
        const fieldIsValid = await field.isValid()

        setIsCustomTemplateSelected(fieldIsValid)
      } catch (error) {
        console.error('Error filtering template for custom text:', error)
      } finally {
        setIsCoverPageSelectLoading(false)
      }
    },
    [],
  )

  const handleTemplateChange = useCallback(
    (template: string) => {
      const templateJSON = JSON.parse(template)
      filterTemplateForCustomText(templateJSON.url)
    },
    [filterTemplateForCustomText],
  )

  const handleFaxNumberChange = useCallback<PhoneInputProps['onChange']>(
    (value) => {
      form.setFieldValue('fax_number', value)
    },
    [form],
  )

  const trackFaxFlow = useCallback(
    (receiverNumber: string) => {
      track('send_fax_flow', {
        sendFaxFormStartTime: sendFaxFormStartTime?.toString(),
        sendFaxEndTime: new Date().toString(),
        document_id: member_document_id,
        receiverNumber,
      })
    },
    [sendFaxFormStartTime, member_document_id],
  )

  const trackSendFaxError = useCallback(
    (receiverNumber: string, message: string, status: number) => {
      track('send_fax_error', {
        sendFaxFormStartTime: sendFaxFormStartTime?.toString(),
        sendFaxEndTime: new Date().toString(),
        document_id: member_document_id,
        receiverNumber,
        message,
        status,
      })
    },
    [sendFaxFormStartTime, member_document_id],
  )

  const handleFormSubmit = useCallback(
    async ({ fax_recipient, fax_number, ...rest }: SendFaxFormValues) => {
      if (isPreferredPharmacySelected) {
        fax_recipient = pharmacy?.name || ''
        // Preferred pharmacy will not be enabled if fax number is null
        fax_number = pharmacy?.fax ?? ''
      }

      await handleSubmit({ fax_recipient, fax_number, ...rest })
        .then((_res) => {
          trackFaxFlow(fax_number)
        })
        .catch((error) => {
          // TODO: show error message to user
          trackSendFaxError(fax_number, error.data?.detail, error.status)
        })
    },
    [
      handleSubmit,
      isPreferredPharmacySelected,
      pharmacy,
      trackFaxFlow,
      trackSendFaxError,
    ],
  )

  return (
    <FormContainer data-testid="send-fax-form">
      <StylizedHeader level={2}>
        {t('documents.faxDetails.sendFax')}
      </StylizedHeader>
      <Form
        name={FORM_NAME}
        form={form}
        layout="vertical"
        css="max-width: 600px;"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleFormSubmit}
        initialValues={{ fax_cover_page: templateOptions[0]?.value }}
      >
        <Typography.Title
          level={5}
          css={`
            &&& {
              font-weight: bold;
            }
          `}
        >
          {t('documents.faxDetails.destination')}
        </Typography.Title>
        <DestinationsContainer>
          <Radio.Group
            value={destination}
            onChange={handleSelectPreferredPharmacy}
            css={`
              display: flex;
              flex-direction: column;
            `}
          >
            <Radio
              value={PharmacyType.preferred}
              disabled={noPharmacySet || pharmacyHasNoFax}
              data-testid="fax-form-preferred-radio"
            >
              <Space direction="vertical" css="margin-bottom: 16px;">
                <Typography.Title
                  level={5}
                  css={`
                    &&& {
                      font-weight: 600;
                    }
                  `}
                >
                  {t('documents.faxDetails.preferredPharmacy')}
                </Typography.Title>
                <PreferredPharmacy
                  patientId={member_id}
                  // fixed value becaue this prop doesn't work if I use colors.error directly
                  styles={pharmacyHasNoFax && `border-color: #E95D5D;`}
                />
                {pharmacyHasNoFax && (
                  <Typography.Text
                    css={`
                      color: ${colors.error};
                      text-align: right;
                      display: block;
                    `}
                  >
                    {t('documents.faxDetails.pharmacyNoFaxNumber')}
                  </Typography.Text>
                )}
              </Space>
            </Radio>
            <Radio
              value={PharmacyType.custom}
              css={`
                /* unable to style the radio contenct container directly */
                span.ant-radio + * {
                  width: 100%;
                }
              `}
              data-testid="fax-form-custom-radio"
            >
              <Form.Item
                label={t('documents.faxDetails.customRecipient')}
                name="fax_recipient"
                rules={[
                  {
                    required: !isPreferredPharmacySelected,
                    message: t('documents.faxDetails.formRequiredError', {
                      label: t('documents.faxDetails.faxRecipient'),
                    }),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('documents.faxDetails.selectRecipient')}
                  disabled={isPreferredPharmacySelected}
                  data-testid="recipient-input"
                />
              </Form.Item>
              <Form.Item
                label={t('documents.faxDetails.faxNumber')}
                name="fax_number"
                rules={[
                  {
                    required: !isPreferredPharmacySelected,
                    message: t('documents.faxDetails.formRequiredError', {
                      label: t('documents.faxDetails.faxNumber'),
                    }),
                  },
                ]}
              >
                <PhoneInput
                  placeholder={'+1-(xxx)-xxx-xxxx'}
                  autoFocus
                  maxLength={16}
                  defaultCountry="CA"
                  useNationalFormatForDefaultCountryValue={false}
                  size="large"
                  inputComponent={WrappedInput}
                  disabled={isPreferredPharmacySelected}
                  onChange={handleFaxNumberChange}
                  data-testid="fax-number-input"
                />
              </Form.Item>
            </Radio>
          </Radio.Group>
        </DestinationsContainer>

        <Form.Item
          label={t('documents.faxDetails.coverPage')}
          name="fax_cover_page"
          rules={[
            {
              required: true,
              message: t('documents.faxDetails.formRequiredError', {
                label: t('documents.faxDetails.coverPage'),
              }),
            },
          ]}
        >
          <Select
            size="large"
            placeholder={t('documents.faxDetails.selectCoverPage')}
            options={templateOptions}
            onChange={handleTemplateChange}
            data-testid="fax-cover-page-select"
            loading={isCoverPageSelectLoading}
          />
        </Form.Item>
        {isCustomTemplateSelected && (
          <Form.Item
            label={t('documents.faxDetails.customTemplate')}
            name="fax_cover_page_custom_text"
          >
            <Input.TextArea
              rows={4}
              size="large"
              data-testid="fax-cover-page-custom-text"
            />
          </Form.Item>
        )}
        <Space css="width: 100%; justify-content: flex-end;">
          <Button onClick={handleCancel}>
            {t('documents.faxDetails.cancel')}
          </Button>
          <Button type="primary" htmlType="submit" data-testid="fax-next-btn">
            {t('documents.faxDetails.next')}
          </Button>
        </Space>
      </Form>
    </FormContainer>
  )
}
