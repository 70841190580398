// @ts-strict-ignore
import type { ItemResponseDictStrNotepadModelsTemplateResolvedTemplate } from '@dialogue/notepad-client'
import {
  createSlice,
  type PayloadAction,
  createSelector,
} from '@reduxjs/toolkit'

import type { ReduxState } from 'app/redux'
import type {
  Localized,
  LocalizedNoteTemplate,
  TemplateRecord,
} from 'app/redux/structured-notes/types'

import { localizeDeep, localizeTemplate } from './localize-templates'

interface State {
  fetching: boolean
  error: Error | null
  data: TemplateRecord
}

const INITIAL_STATE: State = {
  fetching: false,
  error: null,
  data: null,
}

export type TemplatesResponseData =
  ItemResponseDictStrNotepadModelsTemplateResolvedTemplate['data']

export const { actions: noteTemplatesActions, reducer } = createSlice({
  name: '@@structuredNotes/templates',
  initialState: INITIAL_STATE,
  reducers: {
    get(state) {
      state.fetching = true
      state.error = null
    },
    getSuccess(state, { payload }: PayloadAction<TemplatesResponseData>) {
      state.fetching = false
      state.data = payload
    },
    getFailure(state, { payload: error }: PayloadAction<Error>) {
      state.fetching = false
      state.error = error
    },
  },
})

export default reducer

export const selectNoteTemplatesFetching = (state: ReduxState) =>
  state.structuredNotes.templates.fetching

export const selectNoteTemplatesError = (state: ReduxState) =>
  state.structuredNotes.templates.error

export const selectNoteTemplates = (state: ReduxState) =>
  state.structuredNotes.templates.data

export const selectNoteTemplate = (state: ReduxState, templateId: string) =>
  state.structuredNotes.templates.data?.[templateId]

export const selectLocalizedNoteTemplates = createSelector(
  selectNoteTemplates,
  (_state: ReduxState, locale: string) => locale,
  (templates, locale): Localized<TemplateRecord> =>
    localizeDeep(templates, locale),
)

export const selectLocalizedNoteTemplate = createSelector(
  selectNoteTemplate,
  (_state: ReduxState, _templateId: string, locale: string) => locale,
  (template, locale) => localizeTemplate(template, locale),
)

export const selectNoteTemplatesAsOptions = createSelector(
  selectLocalizedNoteTemplates,
  (templates) =>
    templates &&
    Object.entries(templates)
      .map(([value, { title: label, dev_only: devOnly }]) => {
        return {
          label,
          devOnly,
          value,
        }
      })
      .sort((a, b) => a.label?.localeCompare?.(b.label)),
)

export const createSelectLocalizedTemplates = () =>
  createSelector(
    selectLocalizedNoteTemplates,
    (_state: ReduxState, _locale: string, template_ids: string[] = []) =>
      template_ids,
    (templates, template_ids) => {
      if (templates === null || template_ids.length === 0) {
        return null
      }

      return (
        templates &&
        Object.values(templates).reduce<Record<string, LocalizedNoteTemplate>>(
          (acc, template) => {
            if (template_ids.includes(template.id)) {
              acc[template.id] = template
            }

            return acc
          },
          {},
        )
      )
    },
  )
