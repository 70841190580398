// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { type PatientSearchState, PatientSearchTypes } from './types'

export const INITIAL_STATE: PatientSearchState = {
  results: null,
  fetching: false,
  error: null,
}

export type PatientSearchAction = ActionType<typeof actions>

export { actions as patientSearchActions }
export * from './types'

export const reducer: Reducer<typeof INITIAL_STATE, PatientSearchAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft): typeof INITIAL_STATE | void => {
    switch (action.type) {
      case PatientSearchTypes.SEARCH_PATIENT:
        draft.fetching = true
        draft.error = null
        break
      case PatientSearchTypes.SEARCH_PATIENT_SUCCESS:
        draft.results = action.payload.results
        draft.fetching = false
        break
      case PatientSearchTypes.SEARCH_PATIENT_ERROR:
        draft.fetching = false
        draft.error = action.payload.error
        break
      case PatientSearchTypes.CLEAR_SEARCH_RESULTS:
        return INITIAL_STATE
    }
  })

export default reducer
