import { type ChangeEvent, useCallback, useRef, useState } from 'react'

import {
  DeleteOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import {
  Button,
  Divider,
  Form,
  Input,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { ViewerDocument } from 'app/redux/documents/viewer'
import { colors } from 'app/theme'

import {
  convertStringPageRangeToNumbers,
  hasDuplicatePages,
} from '../document-action-panel/helpers'
import { PanelContent } from '../info-panel/common'

/**
 * A regular expression for validating the page range input.
 * Accepted formats:
 *
 * - Single page: `1`
 * - Page range: `1-3`
 * - Multiple pages: `1,2,3`
 */
const pageRangeRegExp = /^(\d+(-\d+)?)(,\d+(-\d+)?)*$/

interface Props {
  document: ViewerDocument
  onClosePanel: () => void
  onConfirm: (sections: number[][]) => void
  error: Error | null
}

export interface SectionState {
  label: string
  value: string
  numbers: number[]
}

export interface FormData {
  sections: SectionState[]
}

const SectionSpace = styled(Space)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0 16px 0;

  .ant-form-item {
    margin-bottom: 0;
  }

  > .ant-space-item:last-child {
    align-self: center;
  }
`

const SplitSectionContainers = styled(Space)`
  justify-content: space-between;

  > .ant-space-item:last-child {
    align-self: flex-start;
    margin-top: 6px;
  }
`

const DeleteIcon = styled(DeleteOutlined)<{ $disabled: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    `
      pointer-events: none;
      opacity: 0.5;
    `}
`

const ErrorContainer = styled(Space)`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  color: ${colors.error};
`

export const SplitDocumentPanel = ({
  document,
  onClosePanel,
  onConfirm,
  error,
}: Props) => {
  const { t: tCommon } = useTranslation('common')
  const { t } = useTranslation()

  const [form] = useForm<FormData>()
  const formRef = useRef(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  const disabled = !!form
    .getFieldsError()
    .filter(({ errors, warnings }) => errors.length || warnings.length).length

  const handleInputChange =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const isValid = pageRangeRegExp.test(value)

      if (isValid) {
        const numbers = convertStringPageRangeToNumbers(value)
        const { sections } = form.getFieldsValue()
        const newFieldsValue = sections.map((section, i) =>
          i === index
            ? { ...section, value, numbers, label: `Section ${i + 1}` }
            : section,
        )

        form.setFieldsValue({
          sections: newFieldsValue,
        })
      }
    }

  const handleOpenChange = useCallback(
    async (open: boolean) => {
      const { sections } = form.getFieldsValue()
      const hasSamePageSelected = hasDuplicatePages(sections)

      hasSamePageSelected ? setConfirmOpen(open) : form.submit()
    },
    [form],
  )

  const handleConfirm = useCallback(
    (formData: FormData) => {
      const { sections } = formData
      const validSections = sections.filter(
        (section) => section.numbers.length > 0,
      )
      const pageNumberRanges = validSections.map((section) => section.numbers)
      onConfirm(pageNumberRanges)
    },
    [onConfirm],
  )

  return (
    <PanelContent size={4} direction="vertical">
      <Typography.Text>{document?.name}</Typography.Text>
      <Divider
        css={`
          margin: 16px 0;
        `}
      />
      <Space size={8}>
        <Typography.Text>
          {t('documents.actions.splitDocument')}
        </Typography.Text>
        <Tooltip title={t('documents.split.divideTooltip')}>
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
      <Form
        key="split-document-form"
        form={form}
        ref={formRef}
        onFinish={handleConfirm}
      >
        <Form.List
          name="sections"
          initialValue={[
            {
              label: 'Section 1',
              value: '',
              numbers: [],
            },
          ]}
        >
          {(fields, { add, remove }) => {
            return (
              <SectionSpace direction="vertical">
                {fields.map(({ key, name, ...restField }, index) => (
                  <SplitSectionContainers key={key}>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      label={`Section ${name + 1}`}
                      rules={[
                        {
                          required: true,
                          message: t('documents.split.errors.invalidRange'),
                        },
                        {
                          required: true,
                          pattern: pageRangeRegExp,
                          message: t('documents.split.errors.invalidFormat'),
                        },
                      ]}
                    >
                      <Input
                        onChange={handleInputChange(index)}
                        data-testid={`section-${name + 1}-input`}
                      />
                    </Form.Item>
                    <DeleteIcon
                      $disabled={fields.length === 1}
                      // for Form.List components arrow function is acceptable
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => remove(name)}
                      css={`
                        color: ${colors.errorDark};
                        font-size: 18px;
                      `}
                    />
                  </SplitSectionContainers>
                ))}
                <Button
                  type="primary"
                  ghost
                  // for Form.List components arrow function is acceptable
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => add()}
                  css={`
                    align-self: center;
                    font-weight: 600;
                    height: 40px;
                  `}
                  data-testid="add-split-section"
                >
                  {t('documents.split.addSection')}
                </Button>
              </SectionSpace>
            )
          }}
        </Form.List>
        <Space
          css={`
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: absolute;
            bottom: 16px;
            right: 16px;
          `}
        >
          <Button type="text" onClick={onClosePanel}>
            {tCommon('button.cancel')}
          </Button>
          <Form.Item
            shouldUpdate
            css={`
              margin: 0;
            `}
          >
            {() => (
              <Popconfirm
                title={t('documents.split.existingPageConfirmation')}
                open={confirmOpen}
                onOpenChange={handleOpenChange}
                placement="topRight"
                // for Form.Item components arrow function is acceptable
                // eslint-disable-next-line react/jsx-no-bind
                onConfirm={() => form.submit()}
                okButtonProps={{
                  // @ts-expect-error Passing arbitrary HTML props is allowed
                  'data-testid': 'existing-page-confirmation-button',
                }}
              >
                <Button
                  type="primary"
                  disabled={disabled}
                  htmlType="submit"
                  data-testid="split-fax-confirm-button"
                >
                  {tCommon('button.confirm')}
                </Button>
              </Popconfirm>
            )}
          </Form.Item>
        </Space>
        {error && (
          <ErrorContainer>
            <WarningOutlined />
            <Typography.Text type="danger">
              {t('documents.split.errors.requestError')}
            </Typography.Text>
          </ErrorContainer>
        )}
      </Form>
    </PanelContent>
  )
}
