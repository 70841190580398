import { TaskPriority } from '@dialogue/coredata'
import { useTranslation } from 'react-i18next'

import { CriticalTag } from 'app/components/tags/critical-tag'
import { FlaggedTag } from 'app/components/tags/flagged-tag'

interface TaskPriorityTagProps {
  priority: TaskPriority
  showLabel?: boolean
  showTooltip?: boolean
}

export const TaskPriorityTag = ({
  priority,
  showLabel,
  showTooltip,
}: TaskPriorityTagProps) => {
  const { t } = useTranslation()

  return (
    <>
      {priority === TaskPriority.FLAGGED && (
        <FlaggedTag
          css={showLabel && 'width: 100%'}
          label={showLabel && t('tasks.values.priority.2')}
          data-dd-privacy="allow"
          tooltip={
            showTooltip && (
              <span data-dd-privacy="allow">
                {t('tasks.tooltips.priority.flagged')}
              </span>
            )
          }
        />
      )}
      {priority === TaskPriority.URGENT && (
        <CriticalTag
          css={showLabel && 'width: 100%'}
          label={showLabel && t('tasks.values.priority.1')}
          data-dd-privacy="allow"
          tooltip={
            showTooltip && (
              <span data-dd-privacy="allow">
                {t('tasks.tooltips.priority.urgent')}
              </span>
            )
          }
        />
      )}
    </>
  )
}
