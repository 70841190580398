import { all, call, put } from 'typed-redux-saga/macro'

import { questionnairesActions } from 'app/redux/questionnaires'
import { questionnairesGraphActions } from 'app/redux/questionnaires/questionnaires-graph'
import { initEmergencyRoomClient, takeKeyedLatest } from 'app/sagas/utils'

export function* getQuestionnaires({
  payload: { memberId, type, offset, limit },
}: ReturnType<typeof questionnairesActions.get>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const { data, meta } = yield* call(
      emergencyRoom.getQuestionnaires,
      memberId.toString(),
      { type, offset, limit },
    )

    yield* put(
      questionnairesActions.receive({ data, totalItems: meta.total_items }),
    )

    if (data.length) {
      yield* put(questionnairesActions.select(data[0].id))
    }
  } catch (e) {
    yield* put(questionnairesActions.error(e))
  }
}

export function* getQuestionnairesForGraph({
  payload: { memberId, type },
}: ReturnType<typeof questionnairesGraphActions.get>) {
  try {
    const emergencyRoom = yield* call(initEmergencyRoomClient)
    const { data } = yield* call(
      emergencyRoom.getQuestionnaires,
      memberId.toString(),
      {
        type,
        limit: 50,
      },
    )

    yield* put(questionnairesGraphActions.receive({ data, type: type }))
  } catch (e) {
    yield* put(questionnairesGraphActions.error(e))
  }
}

export default function* questionnairesSagas() {
  yield* all([
    takeKeyedLatest(
      questionnairesActions.get,
      ({ payload }) => `${payload.memberId}-${payload.offset}-${payload.type}`,
      getQuestionnaires,
    ),
    takeKeyedLatest(
      questionnairesGraphActions.get,
      ({ payload }) => `${payload.memberId}-${payload.type}`,
      getQuestionnairesForGraph,
    ),
  ])
}
