import { action } from 'typesafe-actions'

import { type Mention, MentionsTypes } from './types'

export const setMentions = (mentions: Record<string, Mention>) =>
  action(MentionsTypes.SET, { mentions })

export const startViewingMentions = (lastViewedAt = Date.now()) =>
  action(MentionsTypes.START_VIEWING, { lastViewedAt })

export const stopViewingMentions = (lastViewedAt = Date.now()) =>
  action(MentionsTypes.STOP_VIEWING, { lastViewedAt })

export const startPolling = (teamId: string) =>
  action(MentionsTypes.START_POLLING, { teamId })
export const stopPolling = () => action(MentionsTypes.STOP_POLLING)
