// @ts-strict-ignore
import type { UsherTypes } from '@dialogue/services'
import { createSelector } from '@reduxjs/toolkit'

import { formatUserNickname } from 'app/lib/helpers'
import type { ReduxState } from 'app/redux'
import type { Post } from 'app/redux/chat/types'

import { selectPatientsProfiles } from '../patients/selectors'

export const selectChannel = (state: ReduxState, channelId: string) =>
  state.chat.channels[channelId]

export const selectPractitioners = (state: ReduxState) =>
  state.chat.practitioners

export const selectPractitionersByIds = createSelector(
  selectPractitioners,
  (_state: ReduxState, practitionerIds: string[]) => practitionerIds,
  (practitioners, practitionerIds) =>
    practitionerIds.reduce(
      (
        mapping: Record<UsherTypes.Physician['id'], UsherTypes.Physician>,
        id,
      ) => {
        mapping[id] = practitioners.find((p) => p.props.app_id === id)
        return mapping
      },
      {},
    ),
)

export const selectChannels = (state: ReduxState) => state.chat.channels

export const selectUser = (state: ReduxState) => state.chat.user

export const selectUsername = (state: ReduxState) => selectUser(state)?.username

export const selectTeamId = (state: ReduxState) =>
  selectUser(state)?.props?.team_id

export const selectSessionId = (state: ReduxState) => state.chat.sessionId

export const selectFailedPost = (state: ReduxState, postId: string) =>
  state.chat.failedPosts[postId]

export const selectStatuses = (state: ReduxState) => state.chat.statuses

export const selectMemberships = (state: ReduxState) => state.chat.memberships

export const selectChannelMembers = (state: ReduxState, channelId: string) =>
  selectChannel(state, channelId)?.members

/**
 * 'nickname' was previously available for the channel members,
 * but was removed due to security concerns [DIA-74814].
 * For this reason, we backfill it using the patient profiles.
 **/
export const selectChannelMembersWithNickname = createSelector(
  [selectChannelMembers, selectPatientsProfiles],
  (members, profiles) =>
    (members || []).map((member) => {
      const profile = profiles[member?.props?.app_id]
      if (!profile) return member
      return {
        ...member,
        nickname: formatUserNickname(profile),
      }
    }),
)

/**
 * The following messages are valid markers that can be scrolled to
 * - last patient visible message
 * - intake summary message
 * - /r command that starts intake or dxa
 */
export const makeSelectMarkers = () =>
  createSelector([selectChannel], (channel) => {
    if (!channel) return []

    const { order, posts } = channel
    const markers: Post[] = []

    order.forEach((postId) => {
      const post = posts[postId]

      // some system messages are not patient visible, but don't use the prop
      const isPatientVisible =
        post.type === '' || post.props.patient_visible === true

      if (markers.length === 0 && isPatientVisible) {
        markers.push(post)
      } else if (post.props.type === 'intakeSummary.v1') {
        markers.push(post)
      } else if (post.message.startsWith('/r')) {
        const commandSplit = post.message.split(' ')
        if (commandSplit.length > 1) {
          const intent = commandSplit[1]
          if (intent.startsWith('intake') || intent.startsWith('dxa')) {
            markers.push(post)
          }
        }
      }
    })

    return markers
  })

/**
 * Get an aggregate of all Usher users + Mattermost users the channel.
 * Usher users are all practitioners and have their nicknames defined,
 * so they are inserted first (used in priority).
 * * Mattermost users are used for non-practicionner users
 * but they are missing their nickname, which are backfilled
 * from patient profiles in `selectChannelMembersWithNickname`.
 */
export const makeSelectMembers = () =>
  createSelector(
    [selectPractitioners, selectChannelMembersWithNickname],
    (practitioners, members = []) => [...practitioners, ...members],
  )
