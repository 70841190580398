// @ts-strict-ignore
import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import type * as actions from './actions'
import { type EpisodeMetaState, EpisodeMetaTypes } from './types'

export const INITIAL_STATE: EpisodeMetaState = {
  properties: null,

  errorProperties: null,
  fetchingProperties: false,

  updatingEpisodeProperties: false,
  errorUpdatingEpisodeProperties: null,
}

export type EpisodeMetaAction = ActionType<typeof actions>

export const reducer: Reducer<typeof INITIAL_STATE, EpisodeMetaAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EpisodeMetaTypes.REQUEST_PROPERTIES:
        draft.fetchingProperties = true
        draft.errorProperties = null
        break
      case EpisodeMetaTypes.REQUEST_PROPERTIES_SUCCESS:
        draft.fetchingProperties = false
        draft.properties = { ...draft.properties, ...action.payload }
        break
      case EpisodeMetaTypes.REQUEST_PROPERTIES_FAILURE:
        draft.fetchingProperties = false
        draft.errorProperties = action.error
        break
      case EpisodeMetaTypes.UPDATE_EPISODE_TITLE:
      case EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES:
        draft.updatingEpisodeProperties = true
        draft.errorUpdatingEpisodeProperties = null
        break
      case EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES_SUCCESS:
        draft.updatingEpisodeProperties = false
        break
      case EpisodeMetaTypes.UPDATE_EPISODE_PROPERTIES_FAILURE:
        draft.updatingEpisodeProperties = false
        draft.errorUpdatingEpisodeProperties = action.error
        break
    }
  })

export default reducer
