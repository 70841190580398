import type { FieldsType } from 'app/containers/documents/models'
import type { ReduxState } from 'app/redux'

export const selectDocumentBytesLoading = (
  state: ReduxState,
  patientId: number,
) => state.documents.members[patientId]?.updatingDocumentBytes

export const selectDocumentBytesError = (
  state: ReduxState,
  patientId: number,
) => state.documents.members[patientId]?.errorUpdatingDocumentBytes

export const selectDocumentFields = (
  state: ReduxState,
  documentType: FieldsType,
  selectorsArguments: Record<string, any> = {},
) => {
  return Object.entries(documentType).reduce((acc, [key, value]) => {
    const { selector, arguments: selectorArguments = [], formatter } = value
    const actualSelectorArguments = selectorArguments.map(
      (arg: string) => selectorsArguments[arg],
    )
    const selectorValue = selector(state, ...actualSelectorArguments)
    let finalValue
    if (selectorValue) {
      finalValue = formatter
        ? typeof formatter === 'string'
          ? selectorValue[formatter]
          : formatter(selectorValue)
        : selectorValue
    }
    return {
      ...acc,
      [key]: finalValue,
    }
  }, {})
}

export const selectAutofillData = (state: ReduxState) =>
  state.documents.autofill.data
export const selectAutofillFetching = (state: ReduxState) =>
  state.documents.autofill.fetching
export const selectAutofillArguments = (state: ReduxState) =>
  state.documents.autofill.arguments
