// @ts-strict-ignore
import {
  createContext,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { Empty, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'

import { StyledDrawer } from 'app/components/ant-design'
import MemberProfileLink from 'app/components/member-profile-link'
import { SuspenseWrapper } from 'app/components/suspense-wrapper'
import { ProfileTab } from 'app/containers/member-profile-page/types'
import { useAppSelector } from 'app/hooks'
import { selectActiveFamilyMemberId } from 'app/redux/patients/selectors'
import { usePatientIdParam } from 'app/routing/utils'

const MemberProfileView = lazy(
  () => import('app/containers/member-profile-page/view'),
)

const tabsVisibilityOverride = {
  [ProfileTab.Documents]: false,
  [ProfileTab.Services]: false,
  [ProfileTab.Episodes]: false,
}

export const MemberProfileDrawerView = ({
  open,
  onClose,
  patientId,
  ...props
}: {
  open: boolean
  onClose: () => void
  patientId: number | null
}) => {
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    onClose?.()
  }, [onClose])

  return (
    <StyledDrawer
      {...props}
      placement="right"
      maskClosable={false}
      mask={false}
      onClose={handleClose}
      extra={
        <MemberProfileLink
          patientId={patientId}
          text={t('memberProfile.linkButtonNavigate')}
        />
      }
      open={open}
      width={'60%'}
    >
      <SuspenseWrapper>
        {patientId && open && (
          <MemberProfileView
            patientId={patientId}
            tabsAsComponent={Tabs}
            tabsVisibilityOverride={tabsVisibilityOverride}
          />
        )}
        {!patientId && <Empty description="No patient selected" />}
      </SuspenseWrapper>
    </StyledDrawer>
  )
}

// Context exposing methods to open/close the drawer
export const OpenMemberProfileDrawerContext = createContext<{
  open: () => void
  close: () => void
  isOpen: boolean
}>({
  open: () => {},
  close: () => {},
  isOpen: false,
})

// wrap drawer to consume context
export const MemberProfileDrawer = (props: {}) => {
  const { isOpen, close } = useContext(OpenMemberProfileDrawerContext)
  const pathPatientId = usePatientIdParam()
  const patientId = useAppSelector((state) =>
    selectActiveFamilyMemberId(state, pathPatientId),
  )

  const [prevPatientId, setPrevPatientId] = useState<string | number>(patientId)

  useEffect(() => {
    // auto close if we switch patient
    if (prevPatientId && patientId !== prevPatientId) {
      close()
    }

    setPrevPatientId(patientId)
  }, [close, patientId, prevPatientId])

  return (
    <MemberProfileDrawerView
      key={patientId}
      data-cy="member-profile-drawer"
      patientId={patientId}
      open={isOpen}
      onClose={close}
      {...props}
    />
  )
}

// Context exposing methods to open/close the drawer
export const MemberProfileDrawerContext = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [showDrawer, setShowDrawer] = useState(false)

  return (
    <OpenMemberProfileDrawerContext.Provider
      value={{
        isOpen: showDrawer,
        open: () => {
          setShowDrawer(true)
        },
        close: () => {
          setShowDrawer(false)
        },
      }}
    >
      {children}
    </OpenMemberProfileDrawerContext.Provider>
  )
}
