import type { ScribeTypes } from '@dialogue/services'

export enum PatientSearchTypes {
  SEARCH_PATIENT = '@@patientSearch/SEARCH_PATIENT',
  SEARCH_PATIENT_SUCCESS = '@@patientSearch/SEARCH_PATIENT_SUCCESS',
  SEARCH_PATIENT_ERROR = '@@patientSearch/SEARCH_PATIENT_ERROR',
  CLEAR_SEARCH_RESULTS = '@@patientSearch/CLEAR_SEARCH_RESULTS',
}

export interface PatientSearchState {
  results: ScribeTypes.V2.User[]
  fetching: boolean
  error?: Error
}
