export enum NavigationSidebarTypes {
  REQUEST_MM_USER = '@@navigationSidebar/REQUEST_MM_USER',
  REQUEST_MM_USER_SUCCESS = '@@navigationSidebar/REQUEST_MM_USER_SUCCESS',
  REQUEST_MM_USER_ERROR = '@@navigationSidebar/REQUEST_MM_USER_ERROR',

  START_POLL_MY_ASSIGNED_EPISODES = '@@navigationSidebar/START_POLL_MY_ASSIGNED_EPISODES',
  STOP_POLL_MY_ASSIGNED_EPISODES = '@@navigationSidebar/STOP_POLL_MY_ASSIGNED_EPISODES',
  REQUEST_MY_ASSIGNED_EPISODES_COUNT = '@@navigationSidebar/REQUEST_MY_ASSIGNED_EPISODES_COUNT',
  REQUEST_MY_ASSIGNED_EPISODES_COUNT_SUCCESS = '@@navigationSidebar/REQUEST_MY_ASSIGNED_EPISODES_COUNT_SUCCESS',
  REQUEST_MY_ASSIGNED_EPISODES_COUNT_ERROR = '@@navigationSidebar/REQUEST_MY_ASSIGNED_EPISODES_COUNT_ERROR',
}
export interface NavigationSidebarState {
  assigned_episodes_count: number
  fetchingEpisodesError?: Error
}
