import type { ScribeTypes } from '@dialogue/services'
import { action } from 'typesafe-actions'

import { PatientSearchTypes } from './types'

export const searchPatient = (query: string) =>
  action(PatientSearchTypes.SEARCH_PATIENT, {
    query,
  })

export const searchPatientSuccess = (results: ScribeTypes.V2.User[]) =>
  action(PatientSearchTypes.SEARCH_PATIENT_SUCCESS, {
    results,
  })

export const searchPatientError = (error: Error) =>
  action(PatientSearchTypes.SEARCH_PATIENT_ERROR, {
    error,
  })

export const clearSearchResults = () =>
  action(PatientSearchTypes.CLEAR_SEARCH_RESULTS)
