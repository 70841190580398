import type { ScribeTypes } from '@dialogue/services'
import {
  createSelector,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'

import type { ReduxState } from 'app/redux'

interface MemberIntervalState {
  fetching: boolean
  error: Error | null
  intervals: ScribeTypes.V2.EligibleInterval[] | null
}

const INITIAL_STATE: Record<string | number, MemberIntervalState> = {}

export const { actions: eligibilityIntervalActions, reducer } = createSlice({
  name: '@@memberEligibilityIntervals',
  initialState: INITIAL_STATE,
  reducers: {
    get(state, { payload }: PayloadAction<number | string>) {
      state[payload] = state[payload] || {
        fetching: false,
        error: null,
        intervals: null,
      }
      state[payload].fetching = true
      state[payload].error = null
    },
    getSuccess(
      state,
      {
        payload: { intervals, memberId },
      }: PayloadAction<{
        memberId: string | number
        intervals: ScribeTypes.V2.EligibleInterval[]
      }>,
    ) {
      if (!state[memberId]) return

      state[memberId].fetching = false
      state[memberId].intervals = intervals
    },
    getFailure(
      state,
      {
        payload: { memberId, error },
      }: PayloadAction<{ memberId: string | number; error: Error }>,
    ) {
      if (!state[memberId]) return

      state[memberId].fetching = false
      state[memberId].error = error
    },
    clear(state, { payload }: PayloadAction<number | string>) {
      delete state[payload]
    },
  },
})

export default reducer

export const selectEligibilityIntervals = (
  state: ReduxState,
  memberId: string | number,
) => state.memberEligibilityInterval[memberId]

export const selectOrgNotes = createSelector(
  selectEligibilityIntervals,
  (memberState) => {
    if (!memberState?.intervals) return null

    // there can be multiple eligibility records -> one org
    const uniqueOrgs = uniqBy(
      memberState.intervals,
      (interval) => interval.eligibility_record.organization.id,
    )

    const activeOrgs = uniqueOrgs.filter((interval) => interval.is_active)

    return activeOrgs
      .map(
        (interval) => interval.eligibility_record.organization.care_team_notes,
      )
      .filter(Boolean)
  },
)

/**
 * get the deactivation date of the latest interval that has started
 */
export const selectLatestDeactivationDate = createSelector(
  selectEligibilityIntervals,
  (memberState) => {
    if (!memberState?.intervals) return null

    const latestStartedInterval = memberState.intervals.find((interval) =>
      moment(interval.start_date).isSameOrBefore(moment.utc(), 'day'),
    )
    return latestStartedInterval?.end_date ?? null
  },
)

export const selectIsDeactivated = createSelector(
  selectEligibilityIntervals,
  (memberState) => {
    if (!memberState?.intervals) return null

    // The member is inactive if none of the intervals are currently active
    return !memberState.intervals.find((interval) => interval.is_active)
  },
)

/**
 * Get currently active records for activated members,
 * and previously active records for deactivated members
 */
export const selectActiveEligRecords = createSelector(
  selectEligibilityIntervals,
  selectIsDeactivated,
  (memberState, isDeactivated) => {
    if (!memberState?.intervals) return null

    const filteredIntervals = memberState.intervals.filter((interval) => {
      const hasStarted = moment(interval.start_date).isSameOrBefore(
        undefined,
        'day',
      )
      if (isDeactivated) {
        return hasStarted
      } else {
        return (
          hasStarted &&
          moment(interval.end_date).isSameOrAfter(undefined, 'day')
        )
      }
    })

    const uniqueOrgs = uniqBy(
      filteredIntervals,
      (record) => record.eligibility_record.organization.id,
    )

    return uniqueOrgs.map((record) => record.eligibility_record)
  },
)
