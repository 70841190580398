import { useCallback, useMemo } from 'react'

import { CaretDownOutlined } from '@ant-design/icons'
import { IncomingFaxDocumentStatus } from '@dialogue/document-center'
import { Button, Dropdown, Tooltip } from 'antd'
import type { ItemType } from 'antd/lib/menu/hooks/useItems'
import { useTranslation } from 'react-i18next'

import { useViewerInstance } from 'app/containers/documents/document-details/viewer-context'
import { useVariation } from 'app/hooks'
import { DocumentKind, type ViewerDocument } from 'app/redux/documents/viewer'
import { Flags } from 'app/services/feature-flags'

import { DOCUMENT_ACTION } from './types'

interface Props {
  document: ViewerDocument
  onActionClick?: (action: DOCUMENT_ACTION | null) => void
}

export const ModifyDocumentActions = ({ document, onActionClick }: Props) => {
  const { t } = useTranslation()
  const isReceived =
    document?.kind === DocumentKind.INCOMING_FAX_DOCUMENT &&
    document.status === IncomingFaxDocumentStatus.received

  const enableRedactFaxes = useVariation(Flags.enableRedactFaxes, true)

  const {
    disableViewerRedact,
    enableViewerRedact,
    isViewerRedacting,
    loadedDocumentId,
  } = useViewerInstance()

  const handleSplitDocument = useCallback(() => {
    if (isViewerRedacting) {
      disableViewerRedact()
    }

    onActionClick?.(DOCUMENT_ACTION.SPLIT)
  }, [disableViewerRedact, isViewerRedacting, onActionClick])

  const handleRedactDocument = useCallback(() => {
    // Clear any existing action
    onActionClick?.(null)

    enableViewerRedact()
  }, [enableViewerRedact, onActionClick])

  const actionItems: ItemType[] = useMemo(() => {
    const items: ItemType[] = []

    if (document?.kind === DocumentKind.INCOMING_FAX_DOCUMENT) {
      items.push({
        key: 'split-document',
        label: (
          <Tooltip
            title={!isReceived ? t('documents.actions.actionDisabled') : null}
          >
            {t('documents.actions.splitDocument')}
          </Tooltip>
        ),
        onClick: handleSplitDocument,
        disabled: !isReceived,
        // @ts-expect-error ad-hoc properties are passed down to the DOM
        'data-testid': 'split-document',
      })

      if (enableRedactFaxes) {
        items.push({
          key: 'redact-document',
          label: (
            <Tooltip
              title={!isReceived ? t('documents.actions.actionDisabled') : null}
            >
              {t('documents.actions.redactDocument')}
            </Tooltip>
          ),
          onClick: handleRedactDocument,
          disabled: !isReceived,
          // @ts-expect-error ad-hoc properties are passed down to the DOM
          'data-testid': 'redact-document',
        })
      }
    }

    return items
  }, [
    document?.kind,
    enableRedactFaxes,
    handleRedactDocument,
    handleSplitDocument,
    isReceived,
    t,
  ])

  return (
    actionItems.length > 0 && (
      <Dropdown
        disabled={!loadedDocumentId}
        menu={{
          items: actionItems,
        }}
      >
        <Button
          size="small"
          type="primary"
          ghost
          data-testid="actions-dropdown"
        >
          {t('documents.actions.actionsDropdown')}
          <CaretDownOutlined />
        </Button>
      </Dropdown>
    )
  )
}
