import { type MouseEvent, useCallback, useContext } from 'react'

import { baseColors } from '@dialogue/basics'
import {
  type Task,
  TaskStatus,
  type TaskWithLinkedTasks,
} from '@dialogue/coredata'
import styled from 'styled-components'

import { TaskDrawerContext } from 'app/containers/tasks/task-drawer-context'

import { EnrichedTaskDetails, TaskDetails } from './task-row-details'
import TaskTitle from './task-row-title'

const TaskRowContainer = styled.div<{ status: TaskStatus }>`
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid ${baseColors.smoke};
  width: auto;

  cursor: pointer;
  opacity: ${(props) => (props.status === TaskStatus.DONE ? 0.5 : 1)};

  .task-row-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
    margin-bottom: 0;
    font-weight: 400;
    display: inline-grid;
    grid-template-columns: auto 1fr;
    flex: 1;
  }

  .task-row-details {
    float: right;
  }
`

interface Props {
  task: TaskWithLinkedTasks | Task
  enrichedView?: boolean
}

const isHTMLElement = (target: EventTarget): target is HTMLInputElement =>
  'id' in target

export const TaskRow = ({ task, enrichedView = false }: Props) => {
  const { setDrawerTask } = useContext(TaskDrawerContext)

  const handleClick = useCallback(
    ({ target }: MouseEvent<HTMLElement>) => {
      // Prevent modal from opening if the checkbox is clicked
      if (isHTMLElement(target) && target.type === 'checkbox') return

      setDrawerTask(task.id)
    },
    [setDrawerTask, task],
  )

  return (
    <TaskRowContainer
      data-testid={'task-row'}
      key={task.id}
      data-cy={`task-row-${task.id}`}
      onClick={handleClick}
      status={task.status}
    >
      <TaskTitle title={task.title} priority={task.priority} />
      {enrichedView ? (
        <EnrichedTaskDetails task={task} />
      ) : (
        <TaskDetails task={task} />
      )}
    </TaskRowContainer>
  )
}
