import { produce } from 'immer'
import type { Reducer } from 'redux'
import type { ActionType } from 'typesafe-actions'

import * as actions from './actions'
import { type EpisodesState, EpisodesTypes } from './types'

export const INITIAL_STATE: EpisodesState = {
  active: {
    fetching: false,
    episodes: [],
    error: null,
  },

  pending: {
    fetching: false,
    episodes: [],
    error: null,
  },

  resolved: {
    fetching: false,
    episodes: [],
    error: null,
  },
}

export type EpisodesAction = ActionType<typeof actions>

export { actions as episodesActions }
export * from './types'

export const reducer: Reducer<typeof INITIAL_STATE, EpisodesAction> = (
  state = INITIAL_STATE,
  action,
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case EpisodesTypes.REQUEST_EPISODES_FROM_STATE:
        draft[action.payload.episodesState].fetching = true
        draft[action.payload.episodesState].error = null
        break
      case EpisodesTypes.REQUEST_EPISODES_FROM_STATE_SUCCESS:
        draft[action.payload.episodesState].episodes = action.payload.episodes
        draft[action.payload.episodesState].count = action.payload.count
        draft[action.payload.episodesState].fetching = false
        break
      case EpisodesTypes.REQUEST_EPISODES_FROM_STATE_FAILURE:
        draft[action.payload.episodesState].fetching = false
        draft[action.payload.episodesState].error = action.payload.error
        break
    }
  })

export default reducer
