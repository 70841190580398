import type { Middleware } from '@reduxjs/toolkit'

import { documentCenterApi } from './document-center'
import { emergencyRoomApi } from './emergency-room'
import { notepadApi } from './notepadApi'
import { timekeeperApi } from './timekeeperApi'

const apis = [emergencyRoomApi, documentCenterApi, notepadApi, timekeeperApi]

const apiMiddlewares: Middleware[] = apis.map((api) => api.middleware)

const apiReducers = apis.reduce(
  (acc, api) => ({ ...acc, [api.reducerPath]: api.reducer }),
  {},
)

export { apiMiddlewares, apiReducers }
