// @ts-strict-ignore
import { type ReactNode, useEffect, useState } from 'react'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import {
  Navigate,
  useNavigate,
  useParams,
  useNavigationType,
} from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  selectUserId,
  selectUserHasRestrictedView,
} from 'app/redux/authentification/selectors'
import { selectEpisodeViewEpisode } from 'app/redux/episode-view/selectors'
import {
  initPollingEpisode,
  stopPollingEpisode,
} from 'app/redux/episodes/actions'
import routes from 'app/services/routes'

/**
 * If the user should have a restricted view,
 * only enable them to access episodes assigned to them.
 * This is a no-op for all other users.
 *
 * Unauthorized users will be redirected back to the previous
 * page they were on.
 *
 * This guard requires that the route it's guarding contain
 * `:episodeId` as a path parameter, and will fail otherwise.
 * This guard also requires the user to be logged in, so be sure
 * to only use it inside an <AuthenticatedRoute />.
 *
 * Example:
 * ```tsx
 * <AuthenticatedRoute path="/chat/:patientId/:episodeId">
 *   <RedirectOnRestrictedAccess>
 *     <EpisodePage />
 *   </RedirectOnRestrictedAccess>
 * </AuthenticatedRoute>
 * ```
 */
export const RedirectOnRestrictedAccess = ({
  children,
}: {
  children?: ReactNode
}) => {
  const { episodeId } = useParams<'episodeId'>()
  const navigate = useNavigate()
  const navigationType = useNavigationType()

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const episodeView = useAppSelector(selectEpisodeViewEpisode)
  const ownUserId = useAppSelector(selectUserId)
  const [restrictedAccess, setRestrictedAccess] = useState(true)

  useEffect(() => {
    setRestrictedAccess(true)
    dispatch(initPollingEpisode(episodeId))
    return () => {
      dispatch(stopPollingEpisode())
    }
  }, [episodeId, dispatch])

  useEffect(() => {
    if (episodeView.fetchingEpisode !== false) return

    let shouldRestrict = false

    if (episodeView.queueError?.code === 400) {
      notification.error({ message: t('errorMessages.missingEpisodes') })
      shouldRestrict = true
    }
    if (episodeView.queueError?.code === 403) {
      notification.error({
        message: t('errorMessages.error'),
        description: t('errorMessages.noAccessToEpisode'),
      })
      shouldRestrict = true
    }

    if (!shouldRestrict) {
      setRestrictedAccess(false)
      return
    }

    // redirect
    dispatch(stopPollingEpisode())
    if (navigationType === 'POP') {
      navigate('/', { replace: true })
    } else {
      navigate(-1)
    }
  }, [episodeView, navigate, navigationType, ownUserId, dispatch, t])

  if (restrictedAccess) return null
  return <>{children}</>
}

/**
 * Guard a route against users who should have a restricted view.
 * These users should be redirected to the dashboard.
 */
const RestrictRoute = ({
  children,
  restrictView,
}: {
  children?: ReactNode
  restrictView: boolean
}) => {
  return restrictView ? <Navigate to={routes.dashboard()} /> : <>{children}</>
}

/**
 * Guard a route using Role-Based Access Control logic.
 *
 * Users with any of the following auth roles:
 * - `MH Network Provider`
 * should have a restricted view of the Care Platform.
 */
export const RbacGuard = ({
  children,
  guardEpisode,
}: {
  children?: ReactNode
  guardEpisode?: boolean
}) => {
  const userHasRestrictedView = useAppSelector(selectUserHasRestrictedView)

  if (guardEpisode) {
    return <RedirectOnRestrictedAccess children={children} />
  }

  return (
    <RestrictRoute children={children} restrictView={userHasRestrictedView} />
  )
}

export default RbacGuard
