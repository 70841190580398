import { useCallback } from 'react'

import { notification, Button } from 'antd'

const NOTIFICATION_KEY = 'new-update-nofication'

const UpdateButton = ({ doUpdate }: { doUpdate: () => void }) => {
  const updateServiceWorker = useCallback(() => {
    notification.close(NOTIFICATION_KEY)
    doUpdate()
  }, [doUpdate])

  return (
    <Button type="primary" size="small" onClick={updateServiceWorker}>
      Update now
    </Button>
  )
}

export const handleNewUpdate = (doUpdate: () => void) => {
  const updateButton = <UpdateButton doUpdate={doUpdate} />

  notification.info({
    message: 'New update available',
    description: 'A new version of the Care Platform is now ready to use.',
    btn: updateButton,
    top: 42,
    duration: 0,
    placement: 'topRight',
    key: NOTIFICATION_KEY,
  })
}
